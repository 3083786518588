import {
  AdditionalInfoStatus,
  BankVerificationStatus,
  IdentityVerificationStatus,
  RiskProfileStatus,
  type UserDto,
} from "Infrastructure/Api/Api";

export const isUserVerified = (user: UserDto) =>
  user.verificationRiskProfile === RiskProfileStatus.NoRisk &&
  user.verificationAdditionalInfoStatus === AdditionalInfoStatus.Verified &&
  user.verificationBankVerificationStatus === BankVerificationStatus.Verified &&
  user.verificationIdentityVerificationStatus ===
    IdentityVerificationStatus.Verified;

export const createLicensePaymentReferenceFromUserID = (userID: number) =>
  `25${userID}`; // Warning! This has to be in sync with how BE is creating the reference number
