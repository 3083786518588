import { Stack } from "@mui/material";
import { BankButton } from "Components/Onboarding/BusinessEntity/Components/BanksList/BankButton/BankButton";
import { BanksList } from "Components/Onboarding/BusinessEntity/Components/BanksList/BanksList";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { useBankingAuth } from "Hooks/useBankingAuth";
import type { BankEnvironment } from "Infrastructure/Api/Api";

export const BankAccountStep = () => {
  const { isPending, authorize, selectedEnvironment } = useBankingAuth();
  const { nextStep } = useOnboardingContext();

  const onBankSelected = (environment: BankEnvironment) => {
    if (!isPending) {
      authorize({ environment });
    }
  };

  return (
    <FullHeightForm>
      <Stack gap={4}>
        <PaperTitle>Nabídka bank</PaperTitle>
        <BanksList
          actionType="add"
          onSelect={onBankSelected}
          pendingEnvironment={isPending ? selectedEnvironment : undefined}
        />
        <BankButton
          bank="Unknown"
          actionType="select"
          onClick={() => nextStep({ bank: null })}
        />
      </Stack>
    </FullHeightForm>
  );
};
