import { Box, Typography } from "@mui/material";
import { usePartyLicensesQuery } from "Api/Queries/Licenses/usePartyLicensesQuery";
import { Colors } from "Components/Layout/Themes/Colors";
import { LicenseDetailWithPaymentActions } from "Components/Licenses/LicensesPageContent/LicenseDetailWithPaymentActions";
import { LicensesPageContentSkeleton } from "Components/Licenses/LicensesPageContent/LicensesPageContentSkeleton";
import { OrderNewLicenseButton } from "Components/Licenses/LicensesPageContent/OrderNewLicenseButton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { useIsMobile } from "Hooks/useIsMobile";
import { useLoggedInUser } from "Hooks/useLoggedInUser";

export const LicensesPageContent = () => {
  const isMobile = useIsMobile();
  const currentUser = useLoggedInUser();
  const {
    data: { items: licenses = [] } = {},
    isLoading,
    isError,
  } = usePartyLicensesQuery(currentUser?.partyPublicID);

  if (isLoading) {
    return <LicensesPageContentSkeleton />;
  }

  if (isError) {
    return (
      <PrimaryPaper>
        <Typography color={Colors.Red}>
          Informace o Vašich licencích se nepodařilo načíst, zkuste to prosím
          později.
        </Typography>
      </PrimaryPaper>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={isMobile ? 2 : 1}>
      {licenses.map((license) => (
        <LicenseDetailWithPaymentActions
          key={license.publicID}
          license={license}
        />
      ))}
      <Box mt={3}>
        <OrderNewLicenseButton />
      </Box>
    </Box>
  );
};
