import {
  type GetRateQueryResult,
  type InvoiceSettingsDto,
  OwnershipType,
} from "Api/Api";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { CurrencyCode } from "Models/CurrencyCodes";
import type { CodeListItem } from "Models/ICodeListDto";
import {
  addWatchedRate,
  getRateAsync,
  removeWatchedRates,
} from "State/Rates/RateReducer";
import type { RootStateType } from "State/Store";
import { formatCurrency, satoshiToBitcoin } from "Utils/CurrencyUtils";
import React from "react";
import type { Control } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

type Props = {
  control: Control<InvoiceSettingsDto, object>;
};

const getOwnershipTypes = (
  rate: GetRateQueryResult | undefined,
): CodeListItem[] => [
  { code: OwnershipType.Custodial, name: "Ponechat na Moone" },
  {
    code: OwnershipType.NonCustodial,
    name: "Odeslat do mojí peněženky",
    description: `Poplatek za transakci Bitcoinové síti 1 000 SAT ${
      rate
        ? `(aktuálně ${formatCurrency(
            rate.askRate * satoshiToBitcoin(1000),
            rate.fiatCurrencyCode,
          )})`
        : ""
    }`,
  },
];

export const OwnershipRadioButtons: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props) => {
  const { control } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(
      addWatchedRate({
        cryptoCurrencyCode: CurrencyCode.BTC,
        fiatCurrencyCode: CurrencyCode.CZK,
        useCache: true,
      }),
    );
    dispatch(getRateAsync.request());

    return () => {
      dispatch(removeWatchedRates());
    };
  }, [dispatch]);

  const { rates } = useSelector((e: RootStateType) => e.rate);

  const btcRate = rates.find((r) => r.cryptoCurrencyCode === CurrencyCode.BTC);

  const [ownershipTypes, setOwnershipTypes] = React.useState<CodeListItem[]>(
    getOwnershipTypes(btcRate),
  );

  React.useEffect(() => {
    if (btcRate) {
      setOwnershipTypes(getOwnershipTypes(btcRate));
    }
  }, [btcRate]);

  return (
    <>
      <RadioButtons
        control={control}
        codeList={ownershipTypes}
        name="ownershipType"
      />
    </>
  );
};
