import { useTransactionInitiateMutation } from "Api/Mutations/Transactions/useTransactionInitiateMutation";
import { appUrl } from "Utils/UrlUtils";
import { useNavigate } from "react-router";

export const useTransactionInitiate = () => {
  const navigate = useNavigate();

  return useTransactionInitiateMutation({
    onSuccess: (result) => {
      if (result.transaction?.publicID !== undefined) {
        navigate(
          `${appUrl("initiate-payment-detail")}/${result.transaction.publicID}`,
        );
      }
    },
  });
};
