import { AnonymousLayout } from "Components/Layout/AnonymousLayout";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { TransactionDetail } from "Components/Transactions/Anonymous/TransactionDetail";
import { appUrl } from "Utils/UrlUtils";
import { Navigate, useParams } from "react-router-dom";

export const AnonymousTransactionDetailPage = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    // this should never happen, but just in case
    return <Navigate to={appUrl("transactions")} replace />;
  }

  return (
    <AnonymousLayout title="Transakce byla úspěšná">
      <StyledPageWrapper>
        <div className="left-content">
          <PrimaryPaper>
            <TransactionDetail transactionPublicID={id} />
          </PrimaryPaper>
        </div>
      </StyledPageWrapper>
    </AnonymousLayout>
  );
};
