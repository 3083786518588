import { useQuery } from "@tanstack/react-query";
import { postVerificationGetUserVerificationDetail } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const VERIFICATIONS_QUERY_KEY = ["USER_DETAIL_VERIFICATIONS"];

export const useUserVerificationDetailQuery = () =>
  useQuery({
    queryKey: VERIFICATIONS_QUERY_KEY,
    queryFn: processResponseWithApiProblemDetails(() =>
      postVerificationGetUserVerificationDetail(),
    ),
    refetchOnWindowFocus: false,
  });
