import type { BottomApplicationLink } from "Components/Layout/Menu/BottomMenuItem";
import {
  type BottomApplicationLinkWithAccessCode,
  useBottomMenuLinks,
} from "Hooks/Layout/useBottomMenuLinks";
import type { RootStateType } from "State/Store";
import type { UserType } from "Types/types";
import { useSelector } from "react-redux";

type UseBottomMenuReturn = Array<BottomApplicationLink>;

const filterByAccessRightCode = (
  links: Array<BottomApplicationLinkWithAccessCode>,
  allowedAccessRightCodes: Array<string>,
) =>
  links
    .filter(
      ({ accessRightCode }) =>
        !accessRightCode ||
        allowedAccessRightCodes.some((code) => code === accessRightCode),
    )
    .map(({ accessRightCode, ...rest }) => rest); //remove accessRightCode prop from the links

export const useBottomMenu = (userType: UserType): UseBottomMenuReturn => {
  const currentUserAccessRightCodes =
    useSelector((e: RootStateType) => e.auth).user?.accessRightCodes || [];

  const {
    overview,
    transactions,
    bankAccounts,
    settings,
    customerPaymentPay,
    users,
  } = useBottomMenuLinks();

  const USER_TYPE_TO_MENU: Record<UserType, Array<BottomApplicationLink>> = {
    admin: filterByAccessRightCode(
      [overview, users, settings],
      currentUserAccessRightCodes,
    ),
    hunter: filterByAccessRightCode(
      [overview, transactions, customerPaymentPay, bankAccounts, settings],
      currentUserAccessRightCodes,
    ),
    merchant: filterByAccessRightCode(
      [overview, transactions, customerPaymentPay, bankAccounts, settings],
      currentUserAccessRightCodes,
    ),
    customer: filterByAccessRightCode(
      [overview, transactions, customerPaymentPay, bankAccounts, settings],
      currentUserAccessRightCodes,
    ),
  };

  return USER_TYPE_TO_MENU[userType];
};
