import { Button } from "@mui/material";
import { PlusMathIcon } from "Components/Shared/Icons";
import { getAppUrl } from "Utils/UrlUtils";

export const OrderNewLicenseButton = () => {
  const appUrl = getAppUrl();

  return (
    <Button
      fullWidth
      color="primary"
      variant="contained"
      href={appUrl("orderLicense")}
      startIcon={<PlusMathIcon width={24} />}
    >
      Objednat licenci
    </Button>
  );
};
