import type {
  GetUserLimitsQueryResult,
  ProcessBankIDVerificationCommandResultStatus,
  UserVerificationDetailDto,
} from "Api/Api";
import { setAdditionalInfoAsync } from "State/Verification/AdditionalInfo/SetAdditionalInfoState";
import { getUserLimitsAsync } from "State/Verification/GetUserLimits/GetUserLimitsState";
import { getUserVerificationDetailAsync } from "State/Verification/GetUserVerificationDetail/GetUserVerificationDetailState";
import { uploadUserIdentificationAsync } from "State/Verification/UserIdentifications/UploadUserIdentificationState";
import { type ActionType, createAction, createReducer } from "typesafe-actions";

export enum BankIDVerificationStatus {
  None = "None",
  Processing = "Processing",
}

type VerificationState = {
  detail: UserVerificationDetailDto | null;
  isDetailLoading: boolean;
  userLimits: GetUserLimitsQueryResult | null;
  bankIDVerificationStatus:
    | BankIDVerificationStatus
    | ProcessBankIDVerificationCommandResultStatus;

  isLoading: boolean;
};

export const setBankIDVerificationStatus = createAction(
  "@verification/SET_BANK_ID_VERIFICATION_STATUS",
)<BankIDVerificationStatus | ProcessBankIDVerificationCommandResultStatus>();

type VerificationAction =
  | ActionType<typeof setBankIDVerificationStatus>
  | ActionType<typeof setAdditionalInfoAsync>
  | ActionType<typeof getUserVerificationDetailAsync>
  | ActionType<typeof uploadUserIdentificationAsync>
  | ActionType<typeof getUserLimitsAsync>;

export const verificationReducer = createReducer<
  VerificationState,
  VerificationAction
>({
  detail: null,
  isDetailLoading: false,
  userLimits: null,
  bankIDVerificationStatus: BankIDVerificationStatus.None,
  isLoading: false,
})
  .handleAction(getUserVerificationDetailAsync.request, (state, _action) => {
    return { ...state, isDetailLoading: true };
  })
  .handleAction(getUserVerificationDetailAsync.success, (state, action) => {
    return { ...state, detail: action.payload, isDetailLoading: false };
  })
  .handleAction(getUserVerificationDetailAsync.failure, (state, _action) => {
    return { ...state, isDetailLoading: false };
  })
  .handleAction(getUserLimitsAsync.success, (state, action) => {
    return { ...state, userLimits: action.payload };
  })
  .handleAction(setBankIDVerificationStatus, (state, action) => {
    return { ...state, bankIDVerificationStatus: action.payload };
  })
  .handleAction(setAdditionalInfoAsync.request, (state, _action) => {
    return { ...state, isLoading: true };
  })
  .handleAction(setAdditionalInfoAsync.success, (state, action) => {
    return {
      ...state,
      detail: action.payload.verificationDetail,
      isLoading: false,
    };
  })
  .handleAction(setAdditionalInfoAsync.failure, (state, _action) => {
    return { ...state, isLoading: false };
  })
  .handleAction(uploadUserIdentificationAsync.request, (state, _action) => {
    return { ...state, isLoading: true };
  })
  .handleAction(uploadUserIdentificationAsync.success, (state, _action) => {
    return {
      ...state,
      isLoading: false,
    };
  })
  .handleAction(uploadUserIdentificationAsync.failure, (state, _action) => {
    return { ...state, isLoading: false };
  });
