import { Box } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { LicenseOrder } from "Components/Licenses/LicenseOrder";
import { LicensePayment } from "Components/Licenses/LicensePayment";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useSearchParams } from "react-router-dom";

const LicenseOrderPage = () => {
  const [searchParams] = useSearchParams();
  const transactionID = searchParams.get("transaction");

  return (
    <AuthenticatedLayout title="Objednávka licence">
      <StyledPageWrapper>
        <Box className="left-content">
          <PrimaryPaper>
            {transactionID ? (
              <LicensePayment transactionPublicID={transactionID} />
            ) : (
              <LicenseOrder />
            )}
          </PrimaryPaper>
        </Box>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default LicenseOrderPage;
