import { Box, Typography } from "@mui/material";
import { usePartyLicensesQuery } from "Api/Queries/Licenses/usePartyLicensesQuery";
import { Colors } from "Components/Layout/Themes/Colors";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { TransactionPaymentOptions } from "Components/Transactions/TransactionPaymentOptions";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { LicensePaymentStatus } from "Infrastructure/Api/Api";

export const NoValidLicensePopup = () => {
  const currentUser = useLoggedInUser();

  const { data, isLoading } = usePartyLicensesQuery(currentUser?.partyPublicID);

  if (isLoading) {
    return null;
  }

  const hasValidLicense = data?.items.some(
    (license) => license.paymentStatus === LicensePaymentStatus.Success,
  );

  const transactionPublicID = data?.items[0]?.transactionPublicID || "";

  //this should not normally happen, either the user has a valid license, or at least an unpaid one assigned
  if (!hasValidLicense && !transactionPublicID) {
    return (
      <Box>
        <PrimaryPaper sx={{ border: `1px solid ${Colors.Red}` }}>
          <Typography
            color={Colors.Red}
            align="left"
            fontWeight={600}
            marginBottom={3}
          >
            Omezený účet
          </Typography>
          <Typography align="left" color={Colors.Red}>
            Nemáte přiřazenou žádnou platnou licenci.
          </Typography>
        </PrimaryPaper>
      </Box>
    );
  }

  if (!hasValidLicense) {
    return (
      <Box>
        <PrimaryPaper sx={{ border: `1px solid ${Colors.Red}` }}>
          <Typography
            color={Colors.Red}
            align="left"
            fontWeight={600}
            marginBottom={3}
          >
            Omezený účet
          </Typography>
          <Box display="flex" flexDirection="column" gap={5}>
            <Typography align="left" color={Colors.Red}>
              Účet bude aktivován po připsání platby za licenci. Pokud máte již
              zaplaceno, vyčkejte na připsání peněz.
            </Typography>
            <TransactionPaymentOptions
              transactionPublicID={transactionPublicID}
            />
          </Box>
        </PrimaryPaper>
      </Box>
    );
  }

  return null;
};
