import { zodResolver } from "@hookform/resolvers/zod";
import { IdentityCardForms } from "Components/Onboarding/Components/IdentityCardForms";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import type { PersonalDocumentModel } from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { z } from "zod";

const StepResources = Resources.Onboarding.Steps.Documents;

type Props = {
  defaultValues?: Partial<PersonalDocumentModel>;
  onSubmit: (data: PersonalDocumentModel) => void;
};

export const DocumentsUploadForm = ({ defaultValues, onSubmit }: Props) => {
  const { t } = useResource();

  const validationSchema = z.object({
    frontSide: z.instanceof(File, {
      message: t(StepResources.Validations.ForegroundMissing),
    }),
    backSide: z.instanceof(File, {
      message: t(StepResources.Validations.BackgroundMissing),
    }),
  });

  const { control, handleSubmit } = useForm<PersonalDocumentModel>({
    resolver: zodResolver(validationSchema),
    defaultValues,
  });

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        title={t(StepResources.LegalInformationBlock1)}
        subTitle={t(StepResources.LegalInformationBlock2)}
        submitButtonLabel={t(Resources.Common.Continue)}
        withDivider
      >
        <IdentityCardForms
          control={control}
          frontSideName="frontSide"
          backSideName="backSide"
        />
      </StepBox>
    </FullHeightForm>
  );
};
