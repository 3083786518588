import { Box } from "@mui/material";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";
import { type FormData, SignUpForm } from "Components/Auth/SignUp/SignUpForm";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { PartyType } from "Infrastructure/Api/Api";
import { resetError, resetUser, setSignInStep } from "State/Auth/AuthReducer";
import { SignInStep } from "State/Auth/Models/AuthStateModels";
import { signUpAsync } from "State/Auth/SignUp/SignUpState";
import type { RootStateType } from "State/Store";
import { Resources, useServerError } from "Translations/Resources";
import type { UserType } from "Types/types";
import { LocalStorageKeys } from "Utils/LocalStorageKeys";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const USER_TYPE_TO_PARTY_TYPE: Record<UserType, PartyType> = {
  customer: PartyType.NaturalPerson,
  merchant: PartyType.LegalEntity,
  hunter: PartyType.SelfEmployed,
  admin: PartyType.NaturalPerson,
};

type SignUpPageProps = {
  userType: UserType;
};

export const SignUpPage = ({ userType }: SignUpPageProps) => {
  const [keyToResetForm, toggleKeyToResetForm] = useState(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useLoggedInUser();

  useEffect(() => {
    dispatch(resetError());
  }, [dispatch]);

  const { translateError } = useServerError(
    Resources.SignUp.Error,
    Resources.SignUp.Error.General,
  );

  const { isLoading, errorCode } = useSelector((e: RootStateType) => e.auth);

  const [searchParams, setSearchParams] = useSearchParams();
  const affiliateCode = searchParams.get("affiliateCode");
  const email = searchParams.get("email");
  const isHunter = userType === "hunter";
  const partyType = USER_TYPE_TO_PARTY_TYPE[userType];

  //const isPasswordless = searchParams.get("isPasswordless") === "true";
  const voucherCode = searchParams.get("voucherCode");

  useEffect(() => {
    if (voucherCode) {
      localStorage.setItem(LocalStorageKeys.VoucherCodeSubsidy, voucherCode);

      // 👇️ delete each query param
      searchParams.delete("voucherCode");

      // 👇️ update state after
      setSearchParams(searchParams);
    }
  }, [voucherCode, searchParams, setSearchParams]);

  useEffect(() => {
    if (affiliateCode) {
      localStorage.setItem("affiliateCode", affiliateCode);
    }
  }, [affiliateCode]);

  const onSubmit = ({ login, password }: FormData) => {
    //If there's a user logged in, sign him out before creating another one
    if (currentUser) {
      dispatch(resetUser({ isPageReloadDisabled: true }));
      if (affiliateCode) {
        localStorage.setItem("affiliateCode", affiliateCode);
      }
    }

    if (password) {
      dispatch(
        signUpAsync.request({
          model: {
            login,
            password,
            affiliateCode,
            partyType,
            ...(isHunter ? { accessRoleCode: "Hunter" } : {}),
          },
          navigate,
        }),
      );
    }

    // if (isPasswordless) {
    //   dispatch(
    //     signUpPasswordlessAsync.request({
    //       model: {
    //         login,
    //         voucherCode: localStorage.getItem(
    //           LocalStorageKeys.VoucherCodeSubsidy,
    //         ),
    //       },
    //       navigate,
    //     }),
    //   );
    // }
  };

  const onFormReset = () => {
    toggleKeyToResetForm(!keyToResetForm);
    dispatch(setSignInStep(SignInStep.Credentials));
  };

  return (
    <AuthPageLayout
      title="Vytvořte si účet"
      key={`${keyToResetForm}`}
      isLoading={isLoading}
      pageType="sign-up"
      userType={userType}
    >
      <Box mx={5}>
        <SignUpForm
          key={email}
          isLoading={isLoading}
          onSubmit={onSubmit}
          serverError={translateError(errorCode)}
          onReset={onFormReset}
          defaultLogin={email}
          //isPasswordless={isPasswordless}
        />
      </Box>
    </AuthPageLayout>
  );
};

export default SignUpPage;
