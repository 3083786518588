import { useQuery } from "@tanstack/react-query";
import { getLicensesTypes } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const LICENSE_QUERY_KEY = "LICENSE";

export const getLicenseQueryKey = (countryAlpha2Code?: string) => [
  LICENSE_QUERY_KEY,
  countryAlpha2Code,
];

export const useLicenseQuery = (countryAlpha2Code?: string) =>
  useQuery({
    queryKey: getLicenseQueryKey(countryAlpha2Code),
    queryFn: processResponseWithApiProblemDetails(() =>
      getLicensesTypes(countryAlpha2Code),
    ),
    enabled: !!countryAlpha2Code,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 1,
  });
