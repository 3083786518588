import Error404Page from "Pages/Error/Error404Page";
import { getAppUrl } from "Utils/UrlUtils";
import type { ReactNode } from "react";
import { Navigate, type RouteProps } from "react-router-dom";

type AccessRoles = Array<string>;

type Props = {
  allowedRoles: AccessRoles;
  userAccessRoles?: AccessRoles;
  children: ReactNode;
  protectionMode?: "404" | "sign-in";
} & RouteProps;

export const RoleAuthorizedRoute = ({
  allowedRoles,
  userAccessRoles = [],
  children,
  protectionMode = "sign-in",
}: Props) => {
  const appUrl = getAppUrl();
  const isAuthorized = userAccessRoles.some((role) =>
    allowedRoles.includes(role),
  );

  if (!isAuthorized) {
    if (protectionMode === "404") {
      return <Error404Page />;
    }

    return <Navigate to={appUrl("sign-in")} state={{ from: location }} />;
  }

  return <>{children}</>;
};
