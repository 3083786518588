import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { IdentificationCardDropdown } from "Components/Shared/Inputs/Form/Dropdowns/IdentificationCardDropdown";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { CountryDropdown } from "Components/Shared/SelectorsForm/CountryDropdown";
import { AddressFields } from "Components/Users/UserDetailPageContent/Components/AddressFields";
import { FormSection } from "Components/Users/UserDetailPageContent/Components/FormSection";
import type { PartyFormFieldsProps } from "Components/Users/UserDetailPageContent/Components/PartyForm/types";
import { useGenderList } from "Hooks/Options/useGenderList";
import { usePartyTypeList } from "Hooks/Options/usePartyTypeList";
import { Resources, useResource } from "Translations/Resources";

export const NaturalPersonFields = ({
  control,
  addresses,
  additionalIdentificationsSlot,
}: PartyFormFieldsProps) => {
  const { t } = useResource();
  const genderList = useGenderList();
  const partyTypeList = usePartyTypeList({ onlyBusiness: false });

  return (
    <>
      <Dropdown
        codeList={partyTypeList}
        name="type"
        control={control}
        label="Typ osoby"
        disabled
      />
      <FormSection legend="Osobní údaje">
        <FormInput control={control} name="firstName" label="Jméno" />
        <FormInput control={control} name="lastName" label="Příjmení" />
        <FormInput
          control={control}
          name="personalNumber"
          label="Rodné číslo"
        />
        <FormInput
          type="date"
          control={control}
          name="birthDate"
          label="Datum narození"
        />
        <Dropdown
          control={control}
          name="gender"
          label="Pohlaví"
          codeList={genderList}
        />
        <FormInput control={control} name="birthPlace" label="Místo narození" />
        <CountryDropdown
          control={control}
          name="nationalityCountryID"
          label={t(Resources.Common.Nationality)}
        />
      </FormSection>
      <FormSection legend="Kontakt">
        <FormInput
          control={control}
          name="phone"
          label="Telefon"
          mask="+000 000 000 000"
          inputProps={{
            inputMode: "tel",
          }}
        />
        <FormInput control={control} name="email" label="E-mail" />
      </FormSection>
      <FormSection legend="Doklady">
        {/* What about this section */}
        <IdentificationCardDropdown
          control={control}
          name="identification"
          label="Typ identifikace"
        />
        <FormInput
          control={control}
          name="identificationNumber"
          label="Číslo dokladu"
        />
        <FormInput
          type="date"
          control={control}
          name="identificationValidFrom"
          label="Platnost od"
        />
        <FormInput
          type="date"
          control={control}
          name="identificationValidTo"
          label="Platnost do"
        />
        <FormInput
          control={control}
          name="identificationIssuer"
          label="Vydavatel"
        />
        {additionalIdentificationsSlot}
      </FormSection>
      <AddressFields addresses={addresses} control={control} />
    </>
  );
};
