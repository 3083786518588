import { Box, Link, Typography } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useNavigationWithPreviousPage } from "Hooks/useNavigateBackManager";

const HelpPage = () => {
  const { previousPageUrl } = useNavigationWithPreviousPage();

  return (
    <AuthenticatedLayout title="Nápověda" goBackUrl={previousPageUrl}>
      <StyledPageWrapper>
        <Box className="left-content">
          <PrimaryPaper>
            <Typography variant="h6" mb={2}>
              Podrobnou nápovědu aktuálně připravujeme.
            </Typography>
            <Typography>
              V případě dotazů se na nás můžete obrátit písemně e-mailem na
              adresu:
              <Link
                fontWeight={500}
                href="mailto:info@mo.one"
                sx={{ textDecoration: "inherit", ml: 1 }}
              >
                info@mo.one
              </Link>
            </Typography>
          </PrimaryPaper>
        </Box>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default HelpPage;
