import { Box, Skeleton, Typography, styled } from "@mui/material";
import { useCurrencySymbol } from "Hooks/useCurrencySymbol";
import { CurrencyCode } from "Models/CurrencyCodes";

type Props = {
  balance: number;
  currency?: CurrencyCode;
  prefix?: string;
  isLoading?: boolean;
  isBigCurrency?: boolean;
  colorVariant?: "light" | "dark";
};

const Currency = styled(Typography)`
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const Balance = ({
  balance,
  currency,
  prefix,
  isLoading,
  isBigCurrency,
  colorVariant = "dark",
}: Props) => {
  const textColor =
    colorVariant === "light" ? "primary.contrastText" : "primary.main";
  const currencySymbol = useCurrencySymbol(currency || CurrencyCode.CZK);
  const formattedValue =
    balance === 0
      ? "0"
      : `${prefix || ""} ${new Intl.NumberFormat("cs-CZ", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        }).format(balance ?? 0)}`;

  return (
    <Box display="flex" gap={2} alignItems="baseline">
      <Typography variant="h2" color={textColor}>
        {isLoading ? <Skeleton width={140} /> : formattedValue}
      </Typography>
      {!isLoading && (
        <Currency variant={isBigCurrency ? "h2" : "h6"} color={textColor}>
          {currencySymbol}
        </Currency>
      )}
    </Box>
  );
};
