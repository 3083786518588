import { Box, Typography } from "@mui/material";

import aevi from "Assets/Images/Images/aevi.png";
import logo_moone from "Assets/Images/Images/logo_moone.png";
import logo_sbk from "Assets/Images/Images/logo_sbk.png";
import logo_znpay from "Assets/Images/Images/logo_znpay.png";

export const Partners = () => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 8,
      textAlign: "center",
      width: "100%",
      backgroundColor: "transparent",
      p: 5,
    }}
  >
    <Typography variant="h3">Partnerství na úrovni</Typography>
    <Box
      display="flex"
      gap={10}
      minWidth="40vw"
      justifyContent="center"
      flexWrap="wrap"
      alignItems="center"
      width="100%"
    >
      <Box component="img" src={logo_moone} maxHeight={40} />
      <Box component="img" src={aevi} maxHeight={40} />
      <Box component="img" src={logo_znpay} maxHeight={40} />
      <Box component="img" src={logo_sbk} maxHeight={40} />
    </Box>
  </Box>
);
