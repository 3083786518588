import { Skeleton, Typography } from "@mui/material";
import type {
  PaymentInstructionStatus,
  TransactionStatus2,
} from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import {
  getInstructionStatusDescription,
  getInstructionStatusName,
  getTransactionStatusName,
} from "Utils/TransactionUtils";
import type { FC } from "react";

const ComponentResources = Resources.Merchant.InitiateDetail;

type Props = {
  transactionStatus?: TransactionStatus2;
  paymentInstructionStatus?: PaymentInstructionStatus;
  isLoading?: boolean;
};

export const getResource = (
  t: (resourcePath: string, options?: any) => string,
  transactionStatus?: TransactionStatus2,
  paymentInstructionStatus?: PaymentInstructionStatus,
) => {
  if (paymentInstructionStatus) {
    return getInstructionStatusName(t, paymentInstructionStatus);
  }

  if (transactionStatus) {
    return getTransactionStatusName(t, transactionStatus);
  }
};

export const PaymentStatus: FC<Props> = ({
  transactionStatus,
  paymentInstructionStatus,
  isLoading,
}) => {
  const { t } = useResource();

  const label = getResource(t, transactionStatus, paymentInstructionStatus);

  return (
    <div>
      <Typography fontWeight={500} marginBottom={2}>
        {t(ComponentResources.PaymentStatus)}
      </Typography>

      <Typography variant="h2">
        {isLoading ? <Skeleton variant="rectangular" /> : label}
      </Typography>

      {paymentInstructionStatus && (
        <Typography fontWeight={500} marginTop={2}>
          {getInstructionStatusDescription(t, paymentInstructionStatus)}
        </Typography>
      )}
    </div>
  );
};
