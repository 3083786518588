import type * as React from "react";
export const ForwardIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={20}
    viewBox="0 0 24 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.800049 19.1978C0.791299 19.1978 0.784737 19.1978 0.775987 19.1978C0.474112 19.1847 0.235675 18.9353 0.240049 18.6334C0.242237 18.4956 0.563799 5.02063 13.68 4.64875V0.72C13.68 0.503437 13.8047 0.306563 13.9994 0.214687C14.1919 0.120625 14.426 0.149063 14.5922 0.284687L23.5522 7.56469C23.6835 7.67188 23.76 7.83156 23.76 8C23.76 8.16844 23.6835 8.32812 23.5544 8.43531L14.5944 15.7153C14.426 15.8509 14.1941 15.8772 13.9994 15.7853C13.8047 15.6934 13.68 15.4966 13.68 15.28V11.3644C2.00974 11.5766 1.37974 18.3841 1.35786 18.6772C1.33817 18.9725 1.09317 19.1978 0.800049 19.1978Z"
      fill="currentColor"
    />
  </svg>
);
