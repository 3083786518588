import { Box, Typography } from "@mui/material";
import type { ReactNode } from "react";

type InfoGroupProps = {
  title?: string;
  children: ReactNode;
};

//this could potentially go to Shared components
export const InfoGroup = ({ title, children }: InfoGroupProps) => (
  <Box component="section" fontWeight={300}>
    {title && <Typography fontWeight={600}>{title}</Typography>}
    {children}
  </Box>
);
