import { Typography, type TypographyProps } from "@mui/material";
import type { ElementType } from "react";

type PaperTitleProps = TypographyProps & { component?: ElementType };

export const PaperTitle = ({
  component = "span",
  ...rest
}: PaperTitleProps) => (
  <Typography
    fontWeight={600}
    fontSize={16}
    textTransform="uppercase"
    component={component}
    {...rest}
  />
);
