import { captureEvent } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { useOCR } from "Api/Mutations/Onboarding/useOCR";
import { PARTY_QUERY_KEY } from "Api/Queries/Party/usePartyQuery";
import { ProcessingOcr } from "Components/Onboarding/Components/ProcessingOcr";
import {
  OnboardingSteps,
  type PersonalDocumentModel,
  useOnboardingContext,
} from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import { DocumentsUploadForm } from "Components/Onboarding/NaturalPerson/Steps/DocumentsStep/DocumentsUploadForm";
import { getUserDetailFromOCRResult } from "Components/Onboarding/NaturalPerson/Utils/ocrUtils";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { logError } from "Infrastructure/Utils/LoggingUtils";
import { Resources, useResource } from "Translations/Resources";
import { toast } from "sonner";

const StepResources = Resources.Onboarding.Steps.Documents;

export const DocumentsUpload = () => {
  const { t } = useResource();
  const user = useLoggedInUser();
  const queryClient = useQueryClient();
  const { nextStep, setFormValues, getFormValues } = useOnboardingContext();
  const { mutateAsync: ocrRequest, isPending: isProcessingOcr } = useOCR({
    onError: () => {
      toast.error("Údaje z dokumentů se nepodařilo přečíst");
      nextStep();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: PARTY_QUERY_KEY });
    },
  });

  if (isProcessingOcr) {
    return <ProcessingOcr />;
  }

  const onSubmit = async (data: PersonalDocumentModel) => {
    setFormValues(OnboardingSteps.PersonalDocument, data);

    try {
      const result = await ocrRequest({
        documents: {
          firstIDCardBackSide: data.backSide,
          firstIDCardFrontSide: data.frontSide,
        },
        partyPublicID: user?.partyPublicID || "",
      });

      if (!result) {
        return;
      }

      if (!result.data?.publicID) {
        toast.error(t(StepResources.ProcessingFailed));
        logError(new Error("OCR failed - publicID missing"));
      }

      if (result.data) {
        setFormValues(
          OnboardingSteps.UserDetail,
          getUserDetailFromOCRResult(result.data),
        );
      }
      nextStep();
    } catch (e) {
      const error = e as Error;
      logError(error, error.cause);
    }
  };

  return (
    <DocumentsUploadForm
      defaultValues={getFormValues(OnboardingSteps.PersonalDocument)}
      onSubmit={onSubmit}
    />
  );
};
