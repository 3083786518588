import { Box } from "@mui/material";
import { RelatedPartyFormWrapper } from "Components/Users/UserDetailPageContent/Components/RelatedPartyFormWrapper";
import { type RelatedPartyDto, RelationType } from "Infrastructure/Api/Api";

type RelatedPartiesProps = {
  relatedParties: Array<RelatedPartyDto>;
};

export const RelatedParties = ({ relatedParties }: RelatedPartiesProps) => {
  const groupedParties = groupRelatedParties(relatedParties);

  return (
    <Box display="flex" flexDirection="column" gap="1rem">
      {groupedParties.map((relatedParty) => (
        <RelatedPartyFormWrapper
          key={relatedParty.relatedPartyPublicID}
          relationType={
            relatedParty.relationTypes.filter(
              (type) => type !== RelationType.BeneficialOwner,
            )[0] || RelationType.BeneficialOwner
          }
          relatedPartyPublicID={relatedParty.relatedPartyPublicID}
          isOwner={relatedParty.relationTypes.includes(
            RelationType.BeneficialOwner,
          )}
        />
      ))}
    </Box>
  );
};

/**
 * Groups parties with the same relatedPartyPublicID and collects all their relationTypes into an array.
 * This is needed when a party has more roles (e.g. BeneficialOwner is also a ManagingDirector), otherwise they would appear in the form multiple times.
 */
const groupRelatedParties = (relatedParties: Array<RelatedPartyDto>) => {
  const groupedPartiesMap = new Map<string, Array<RelationType>>();

  for (const { relatedPartyPublicID, relationType } of relatedParties) {
    if (!groupedPartiesMap.has(relatedPartyPublicID)) {
      groupedPartiesMap.set(relatedPartyPublicID, []);
    }
    groupedPartiesMap.get(relatedPartyPublicID)?.push(relationType);
  }

  return Array.from(
    groupedPartiesMap,
    ([relatedPartyPublicID, relationTypes]) => ({
      relatedPartyPublicID,
      relationTypes,
    }),
  );
};
