import type { CheckboxProps } from "@mui/material";
import { CheckboxInput as Checkbox } from "Components/Shared/Inputs/CheckboxInput";
import { get } from "lodash-es";
import {
  type Control,
  Controller,
  type FieldErrors,
  type FieldPath,
  type FieldValues,
} from "react-hook-form";

type Props<FormType extends FieldValues> = CheckboxProps & {
  name: FieldPath<FormType>;
  label?: string | JSX.Element;
  control: Control<FormType, object>;
  errors?: FieldErrors<FormType>;
  formInfo?: { [key: string]: string };
  isLabelFirst?: boolean;
  isStrong?: boolean;
};

export const CheckboxInput = <T extends object>(props: Props<T>) => {
  const { control, errors, formInfo, name, isLabelFirst, ...rest } = props;
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value, ref },
          fieldState: { error },
        }) => (
          <Checkbox
            onChange={onChange}
            checked={!!value}
            name={name}
            fieldError={errors ? get(errors, name as string) : error}
            isLabelFirst={isLabelFirst}
            inputRef={ref}
            {...rest}
          />
        )}
      />
    </>
  );
};
