import { Box, ListItem } from "@mui/material";
import { DeleteAccountButton } from "Components/BankAccounts/BankAccountList/BankAccountRowLink/DeleteAccountButton";
import { Account } from "Components/Dashboard/ConnectedAccounts/Components/Account";
import type { BankAccountListItem } from "Infrastructure/Api/Api";

type Props = {
  account: BankAccountListItem;
  isEdit?: boolean;
};

export const BankAccountRowLink = ({ account, isEdit }: Props) => (
  <ListItem disablePadding>
    <Box
      width="100%"
      display="flex"
      overflow="hidden"
      justifyContent="space-between"
      sx={({ spacing, palette }) => ({
        borderRadius: spacing(2),
        border: `1px ${isEdit ? "dashed" : "solid"} ${palette.grey[300]}`,
      })}
    >
      <Box width="100%" px={4} py={2}>
        <Account {...account} />
      </Box>
      {!isEdit && <DeleteAccountButton account={account} />}
    </Box>
  </ListItem>
);
