import { OwnershipType } from "Api/Api";
import { useCreateLicenseMutation } from "Api/Mutations/Licenses/useCreateLicenseMutation";
import { useLicenseQuery } from "Api/Queries/Licenses/useLicenseQuery";
import { LicenseOrderForm } from "Components/Licenses/LicenseOrderForm/LicenseOrderForm";
import { LicenseOrderFormSkeleton } from "Components/Licenses/LicenseOrderForm/LicenseOrderFormSkeleton";
import {
  DEFAULT_COUNTRY_ALPHA2_CODE,
  LIFETIME_PERIODICITY,
} from "Components/Licenses/LicenseOrderForm/constants";
import { getNextAvailableLicense } from "Components/Licenses/licenseUtils";
import type { LicenseSelectionModel } from "Components/Onboarding/BusinessEntity/types";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";

export const LicenseOrder = () => {
  const [, setSearchParams] = useSearchParams();

  const {
    data: { items: licenses } = {},
    isLoading,
    isError,
  } = useLicenseQuery(DEFAULT_COUNTRY_ALPHA2_CODE);

  const { mutate: createLicense, isPending: isCreateLicensePending } =
    useCreateLicenseMutation({
      onSuccess: ({ transaction }) => {
        setSearchParams({ transaction: transaction.publicID });
        toast.success("Objednávka licence byla vytvořena");
      },
      onError: () => {
        toast.error("Objednávku licence se nepodařilo vytvořit");
      },
    });

  if (isLoading) {
    return <LicenseOrderFormSkeleton />;
  }

  if (isError || !licenses) {
    return <>Omlouváme se, něco se pokazilo. Zkuste to prosím později.</>;
  }

  const defaultLicense = getNextAvailableLicense(licenses);

  const defaultValues: Partial<LicenseSelectionModel> = {
    license: defaultLicense?.code,
    countryAlpha2Code: DEFAULT_COUNTRY_ALPHA2_CODE,
  };

  const onSubmit = (data: LicenseSelectionModel) => {
    createLicense({
      countryAlpha2Code: data.countryAlpha2Code,
      code: data.license,
      walletAddress: data.privateWalletAddress || "",
      ownershipType: data.isUsingPrivateWallet
        ? OwnershipType.NonCustodial
        : OwnershipType.Custodial,
      subscriptionPeriodicity: LIFETIME_PERIODICITY,
    });
  };

  return (
    <LicenseOrderForm
      onSubmit={onSubmit}
      defaultValues={defaultValues}
      isSubmitting={isCreateLicensePending}
    />
  );
};
