import {
  type CreateUserBankAccountCommandResult,
  postUserBankAccountCreate,
} from "Api/Api";
import { getAppUrl } from "Utils/UrlUtils";
import type { NavigateFunction } from "react-router-dom";
import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const createBankAddressAsync = createAsyncAction(
  "@bank_address/CREATE_REQUEST",
  "@bank_address/CREATE_SUCCESS",
  "@bank_address/CREATE_FAILURE",
)<{ navigate: NavigateFunction }, CreateUserBankAccountCommandResult, Error>();

function* createBankAddress(
  action: ReturnType<typeof createBankAddressAsync.request>,
): Generator {
  try {
    const response = yield* call(postUserBankAccountCreate);
    if (response.status === 200) {
      yield put(createBankAddressAsync.success(response.json));
      action.payload.navigate(
        `${getAppUrl()("bank-address-detail")}/${response.json.externalID}`,
      );
    } else {
      yield put(
        createBankAddressAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(createBankAddressAsync.failure(err as Error));
  }
}
export function* createBankAddressSaga() {
  yield takeLatest(getType(createBankAddressAsync.request), createBankAddress);
}
