import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { NotificationSettingsForm } from "Components/Settings/NotificationSettingsForm";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import {
  getUserNotificationSettingsAsync,
  saveUserNotificationSettingsAsync,
} from "State/Notification/Settings/UserNotificationSettingsState";
import type { RootStateType } from "State/Store";
import { getAppUrl } from "Utils/UrlUtils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

export const NotificationSettingsPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUserNotificationSettingsAsync.request());

    return () => {
      dispatch(getUserNotificationSettingsAsync.success(null));
    };
  }, [dispatch]);

  const { notificationSettings } = useSelector(
    (e: RootStateType) => e.notification,
  );

  const appUrl = getAppUrl();

  return (
    <AuthenticatedLayout title="Notifikace" goBackUrl={appUrl("settings")}>
      <StyledPageWrapper>
        <div className="left-content">
          {!!notificationSettings && (
            <PrimaryPaper>
              <NotificationSettingsForm
                model={notificationSettings}
                onSubmit={(e) =>
                  dispatch(saveUserNotificationSettingsAsync.request(e))
                }
              />
            </PrimaryPaper>
          )}
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default NotificationSettingsPage;
