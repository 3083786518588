import { Box, type BoxProps, Typography } from "@mui/material";

type ParameterItem = {
  label: string;
  value: string;
  textSize?: "small" | "normal";
};

type ParameterListProps = BoxProps & {
  items: Array<ParameterItem>;
};

export const ParameterList = ({ items, ...boxProps }: ParameterListProps) => (
  <Box display="flex" flexDirection="column" component="dl" m={0} {...boxProps}>
    {items.map((item) => (
      <Parameter key={item.label} {...item} />
    ))}
  </Box>
);

const Parameter = ({ label, value, textSize }: ParameterItem) => (
  <Box display="flex" flexDirection="column" gap={1}>
    <Typography component="dt" fontWeight={500}>
      {label}
    </Typography>
    <Typography
      component="dd"
      variant="h3"
      fontSize={textSize === "small" ? "1rem" : undefined}
      sx={{ wordBreak: "break-word" }}
    >
      {value}
    </Typography>
  </Box>
);
