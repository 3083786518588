import { Box } from "@mui/material";
import { useLicenseQuery } from "Api/Queries/Licenses/useLicenseQuery";
import { usePartyLicensesQuery } from "Api/Queries/Licenses/usePartyLicensesQuery";
import { LicenseOrderForm } from "Components/Licenses/LicenseOrderForm/LicenseOrderForm";
import { LicenseOrderFormSkeleton } from "Components/Licenses/LicenseOrderForm/LicenseOrderFormSkeleton";
import { DEFAULT_COUNTRY_ALPHA2_CODE } from "Components/Licenses/LicenseOrderForm/constants";
import {
  getNextAvailableLicense,
  transformLicenseToDefaultValues,
} from "Components/Licenses/licenseUtils";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import type { LicenseSelectionModel } from "Components/Onboarding/BusinessEntity/types";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { LicensePaymentStatus } from "Infrastructure/Api/Api";

export const LicenseSelectionStep = () => {
  const { nextStep } = useOnboardingContext();
  const user = useLoggedInUser();

  const {
    data: existingLicenses,
    isLoading,
    isError,
  } = usePartyLicensesQuery(user?.partyPublicID);

  const {
    data: { items: licenses } = {},
    isLoading: isLicensesLoading,
    isError: isLicensesError,
  } = useLicenseQuery(DEFAULT_COUNTRY_ALPHA2_CODE);

  if (isLoading || isLicensesLoading) {
    return <LicenseOrderFormSkeleton />;
  }

  if (!licenses || isError || isLicensesError) {
    return <>Omlouváme se, něco se pokazilo. Zkuste to prosím později.</>;
  }

  const existingLicense = existingLicenses?.items?.filter(
    (license) => license.paymentStatus === LicensePaymentStatus.Initiated,
  )[0]; // created license, that haven't been paid yet
  const defaultLicense = getNextAvailableLicense(licenses);
  const defaultValues: Partial<LicenseSelectionModel> = existingLicense
    ? transformLicenseToDefaultValues(existingLicense)
    : {
        license: defaultLicense?.code,
        countryAlpha2Code: DEFAULT_COUNTRY_ALPHA2_CODE,
      };

  const onSubmit = (data: LicenseSelectionModel) => {
    nextStep(data);
  };

  return (
    <Box display="flex" flexDirection="column" height="100%" gap={6}>
      Koupí licence získáváte právo stát se hunterem pro Legi.one.
      <LicenseOrderForm defaultValues={defaultValues} onSubmit={onSubmit} />
    </Box>
  );
};
