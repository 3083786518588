import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Divider, Link, Typography } from "@mui/material";
import { schema } from "Components/Licenses/LicenseOrderForm/schema";
// import { CountrySelector } from "Components/Onboarding/BusinessEntity/Components/CountrySelector/CountrySelector";
import { LicensePicker } from "Components/Licenses/LicensePicker/LicensePicker";
import { LicenseProgressBar } from "Components/Licenses/LinceseProgressBar/LicenseProgressBar";
import type { LicenseSelectionModel } from "Components/Onboarding/BusinessEntity/types";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { ValuesBox } from "Components/Shared/ValuesBox/ValuesBox";
import { getApiUrl } from "Infrastructure/Api/Api";
import { useForm } from "react-hook-form";

type LicenseOrderFormProps = {
  onSubmit: (data: LicenseSelectionModel) => void;
  isSubmitting?: boolean;
  defaultValues: Partial<LicenseSelectionModel>;
};

export const LicenseOrderForm = ({
  onSubmit,
  isSubmitting,
  defaultValues,
}: LicenseOrderFormProps) => {
  const { control, handleSubmit, watch, setValue } =
    useForm<LicenseSelectionModel>({
      defaultValues,
      resolver: zodResolver(schema),
    });

  const isUsingPrivateWallet = watch("isUsingPrivateWallet");
  const currentCountryAlpha2Code = watch("countryAlpha2Code");
  const currentLicense = watch("license");

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        submitButtonLabel="Objednat a zaplatit"
        isSubmitting={isSubmitting}
        submitButtonDescription={
          <Typography textAlign="justify">
            Objednáním a zaplacením licence uzavírám
            <Link
              target="_blank"
              href={`${getApiUrl()}/content/terms/1-hunters-precontract-contract-info.pdf`}
              mx={1}
            >
              Hunterskou smlouvu
            </Link>
            s LEGI.ONE LTD., odštěpný závod, jejíž součástí jsou tyto
            <Link
              target="_blank"
              href={`${getApiUrl()}/content/terms/2-hunters-related-terms.pdf`}
              ml={1}
            >
              Související podmínky
            </Link>
            .
          </Typography>
        }
      >
        {/* TODO: Temporarily hiding the countries sections, only supporting CZ for now */}
        {/* <ValuesBox title={"Země"} withTopMargin>
          <CountrySelector control={control} isDisabled={hasExistingLicense} />
        </ValuesBox> */}
        <ValuesBox title="Licence">
          <Typography>
            Vyberte verzi licence, kterou si chcete zakoupit.
          </Typography>
          {defaultValues.license && currentLicense ? (
            <LicensePicker
              onSelect={(license) => setValue("license", license)}
              defaultValue={defaultValues.license}
              currentValue={currentLicense}
              countryAlpha2Code={currentCountryAlpha2Code}
            />
          ) : (
            <>
              Pro zvolenou zemi již nejsou k dispozici žádné dostupné licence.
            </>
          )}
        </ValuesBox>
        <LicenseProgressBar />
        <Divider sx={{ mt: 3 }} />
        <ValuesBox>
          <CheckboxInput
            control={control}
            name="isUsingPrivateWallet"
            label="Chci poslat do vlastní peněženky"
          />
          {isUsingPrivateWallet && (
            <Box display="flex" flexDirection="column" gap={2}>
              <Typography>
                Zadejte adresu své peněženky na Polygon blockchain.
              </Typography>
              <FormInput
                type="text"
                control={control}
                label="Adresa peněženky"
                name="privateWalletAddress"
              />
            </Box>
          )}
        </ValuesBox>
        <Divider sx={{ my: 3 }} />
      </StepBox>
    </FullHeightForm>
  );
};
