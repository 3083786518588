import { Skeleton } from "@mui/material";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { RelatedPartyForm } from "Components/Users/UserDetailPageContent/Components/RelatedPartyForm";
import type { RelationType } from "Infrastructure/Api/Api";

type RelatedPartyFormWrapperProps = {
  relatedPartyPublicID: string;
  relationType: RelationType;
  isOwner: boolean;
};

export const RelatedPartyFormWrapper = ({
  relatedPartyPublicID,
  isOwner,
  relationType,
}: RelatedPartyFormWrapperProps) => {
  const { data: relatedPartyData, isLoading } =
    usePartyQuery(relatedPartyPublicID);

  if (isLoading) {
    return <Skeleton height={800} variant="rounded" sx={{ mt: "0.5rem" }} />;
  }

  if (!relatedPartyData) {
    return <>Chyba při načítání dat související osoby.</>;
  }

  return (
    <RelatedPartyForm
      relationType={relationType}
      partyData={relatedPartyData}
      isOwner={isOwner}
    />
  );
};
