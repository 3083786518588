﻿import { useQuery } from "@tanstack/react-query";
import { postLeadsList } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const LEAD_LIST_QUERY_KEY = ["LEAD_LIST"];

export const useGetLeadListQuery = () =>
  useQuery({
    queryKey: LEAD_LIST_QUERY_KEY,
    queryFn: processResponseWithApiProblemDetails(() => postLeadsList()),
  });
