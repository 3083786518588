import {
  type SetAdditionalInfoCommandResult,
  type SetAdditionalInfoRequest,
  postVerificationVerifyAdditionalInfo,
} from "Api/Api";
import { getUserVerificationDetailAsync } from "State/Verification/GetUserVerificationDetail/GetUserVerificationDetailState";
import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const setAdditionalInfoAsync = createAsyncAction(
  "@verification/SET_ADDITIONAL_INFO_REQUEST",
  "@verification/SET_ADDITIONAL_INFO_SUCCESS",
  "@verification/SET_ADDITIONAL_INFO_FAILURE",
)<SetAdditionalInfoRequest, SetAdditionalInfoCommandResult, Error>();

function* setAdditionalInfo(
  action: ReturnType<typeof setAdditionalInfoAsync.request>,
): Generator {
  try {
    const response = yield* call(
      postVerificationVerifyAdditionalInfo,
      action.payload,
    );
    if (response.status === 200) {
      yield put(getUserVerificationDetailAsync.request());
      yield put(setAdditionalInfoAsync.success(response.json));
    } else {
      yield put(
        setAdditionalInfoAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(setAdditionalInfoAsync.failure(err as Error));
  }
}
export function* setAdditionalInfoSaga() {
  yield takeLatest(getType(setAdditionalInfoAsync.request), setAdditionalInfo);
}
