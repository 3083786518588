import { useMutation } from "@tanstack/react-query";
import { postTransactionsInvoiceSend } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useTransactionInvoiceSend = ({ onSuccess, onError }: Props) =>
  useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      postTransactionsInvoiceSend,
    ),
    onSuccess,
    onError,
  });
