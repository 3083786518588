import { Typography } from "@mui/material";
import { EmployerGuidelineButton } from "Components/Invoices/EmployerGuidelineButton";
import { HelpIcon } from "Components/Shared/Icons";
import { ManagementIcon } from "Components/Shared/Icons/Management";
import { JobTypeHeader } from "Components/Welcome/Styles";
import styled from "styled-components";

const JobTypeWrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(2)};
  margin-right: ${(props) => props.theme.spacing(4)};
`;

const Trivia = styled.div`
  display: flex;

  p {
    font-size: 13px;
    color: ${(props) => props.theme.palette.text.secondary};
  }

  svg {
    width: 24px;
    height: auto;
    margin-right: ${(props) => props.theme.spacing(1)};
    color: ${(props) => props.theme.palette.text.secondary};
  }
`;

export const EmployeePanel: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  return (
    <div>
      <JobTypeWrapper>
        <JobTypeHeader>
          <ManagementIcon />
          <Typography>
            Jsem zaměstnanec, chci část výplaty v bitcoinech
          </Typography>
        </JobTypeHeader>

        <Typography>
          Jako zaměstnanec musíte zaměstnavateli oznámit záměr o posílání části
          výplaty na Moone účet s&nbsp;danými údaji.
        </Typography>

        <br />
        <Typography>
          Připravili jsme Vám pokyn pro zaměstnavatele. Pokyn stáhněte a doplňte
          Vaše osobní údaje a částku, kterou chcete posílat na účet Moone. Tento
          pokyn následně předejte na HR nebo účetní.
        </Typography>
        <br />

        <EmployerGuidelineButton />
        <br />
        <br />
        <Trivia>
          <div>
            <HelpIcon />
          </div>

          <div>
            <Typography>
              Pokud budete chtít v budoucnu upravit částku ke směně, stačí
              nahlásit tuto směnu pouze zaměstnavateli.
            </Typography>
          </div>
        </Trivia>
      </JobTypeWrapper>
    </div>
  );
};
