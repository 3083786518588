import { Box } from "@mui/material";

type Props = {
  totalOptions: number;
  currentIndex: number;
};

export const CarouselDots = ({ totalOptions, currentIndex }: Props) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      marginTop: 4,
    }}
  >
    {Array.from({ length: totalOptions }).map((_, index) => (
      <Box
        key={index}
        sx={{
          width: 10,
          height: 10,
          borderRadius: "50%",
          backgroundColor: index === currentIndex ? "#000" : "#ccc",
          margin: ({ spacing }) => spacing(0, 1),
        }}
      />
    ))}
  </Box>
);
