import { Box, MenuItem, Select, type SelectChangeEvent } from "@mui/material";
import { ExpandArrowIcon } from "Components/Shared/Icons";
import { CheckboxInput } from "Components/Shared/Inputs/CheckboxInput";
import type { LookupItem } from "Models/LookupItem";
import * as React from "react";
import type { ReactNode, Ref } from "react";
import styled from "styled-components";

type ValueType = string | number | Array<string> | Array<number>;

type Props = {
  name: string;
  options: Array<LookupItem>;
  value: ValueType;
  defaultValue?: ValueType;
  onChange?: (value: ValueType) => void;
  onBlur?: () => void;
  disabled?: boolean;
  multiple?: boolean;
  label?: string;
  accessibilityLabel?: string;
  placeholder?: string;
  allSelectedText?: string;
  hasError?: boolean;
  inputRef?: Ref<any>;
};

const StyledSelect = styled(Select)`
  &:before,
  &:after {
    content: none;
  }

  padding: 0;

  .MuiSelect-select {
    min-height: auto;
    border-radius: 0;
    background-color: #fff;
  }

  .MuiInputBase-input {
    padding: ${(props) => props.theme.spacing(3, 4)};
  }

  &:hover {
    .MuiSelect-select {
      border-color: ${(props) => props.theme.palette.text.primary};
    }
  }

  &.Mui-focused {
    .MuiSelect-select {
      border-color: ${(props) => props.theme.palette.primary.main};
    }
  }

  &.Mui-error {
    .MuiSelect-select {
      border-color: ${(props) => props.theme.palette.error.main};
    }
  }

  &.Mui-disabled {
    .MuiSelect-select {
      background-color: ${(props) => props.theme.colors.gray}80;
      border-color: ${(props) => props.theme.colors.gray}80;
      color: ${(props) => props.theme.palette.text.primary}80;
    }
  }
`;

const StyledLabel = styled.div`
  color: ${(props) => props.theme.palette.text.primary};
  margin-bottom: ${(props) => props.theme.spacing(1)};
`;

const StyledIcon = styled.div`
  position: absolute;
  right: 16px;
  pointer-events: none;

  svg {
    width: 18px;
    height: auto;
    opacity: 0.5;
  }
`;

const DropdownIcon = () => (
  <StyledIcon>
    <ExpandArrowIcon />
  </StyledIcon>
);

const StyledPlaceholder = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
`;

const StyledMenuItem = styled(MenuItem)`
  .MuiTouchRipple-root {
    color: ${(props) => props.theme.palette.primary.main};
  }

  .MuiCheckbox-root {
    padding: 0;
  }
`;

const StyledSelectedValue = styled.div<{ upperCase?: boolean }>`
  overflow: hidden;
  margin-right: 22px;
  text-overflow: ellipsis;
  text-transform: ${(props) => (props.upperCase ? "uppercase" : "none")};
  min-height: 16px;
  font-size: 16px;
  font-weight: 500;
`;

const renderSelectedValue = (
  value: ValueType,
  { placeholder, multiple, options, allSelectedText }: Props,
): ReactNode => {
  if (!value || (value as Array<string | number>).length === 0) {
    return placeholder ? (
      <StyledPlaceholder>{placeholder}</StyledPlaceholder>
    ) : (
      <StyledSelectedValue />
    );
  }

  if (multiple && (value as Array<string | number>).length === options.length) {
    return (
      <StyledSelectedValue upperCase>{allSelectedText}</StyledSelectedValue>
    );
  }

  const text = multiple
    ? options
        .filter((o) => (value as Array<number>).some((v) => v === o.Value))
        .map((x) => x.Name)
        .join(", ")
    : options.find((x) => x.Value.toString() === value.toString())?.Name;

  return <StyledSelectedValue>{text}</StyledSelectedValue>;
};

export const Dropdown = (props: Props) => {
  const {
    options,
    value,
    disabled,
    name,
    onChange,
    multiple,
    label,
    onBlur,
    hasError,
    inputRef,
    defaultValue,
    accessibilityLabel,
  } = props;

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    onChange?.(event.target.value as ValueType);
  };

  return (
    <div>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledSelect
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        onBlur={onBlur}
        inputProps={{
          "aria-label": accessibilityLabel || label || "No label provided", //TODO Nice to have, ideally we'd want to enforce to always require either a label or an accessibilityLabel
        }}
        IconComponent={DropdownIcon}
        disabled={disabled}
        multiple={multiple}
        error={hasError}
        fullWidth
        displayEmpty
        MenuProps={{
          variant: "menu",
        }}
        renderValue={(value) => renderSelectedValue(value as ValueType, props)}
        variant="outlined"
        inputRef={inputRef}
      >
        {options.map(({ Value, Name }) => (
          <StyledMenuItem key={`${name}-${Value}`} value={Value}>
            {multiple ? (
              <CheckboxInput
                checked={(value as Array<number>).some((x) => x === Value)}
                label={Name}
              />
            ) : (
              <Box sx={{ fontSize: "16px" }}>{Name}</Box>
            )}
          </StyledMenuItem>
        ))}
      </StyledSelect>
    </div>
  );
};
