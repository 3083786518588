import { useMutation } from "@tanstack/react-query";
import {
  type UserVerificationUpdateDto,
  putVerifications,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: () => void;
};

export const useUpdateVerificationsMutation = ({ onSuccess }: Props = {}) =>
  useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      (requestContract: UserVerificationUpdateDto) =>
        putVerifications(requestContract),
    ),
    onSuccess: () => {
      onSuccess?.();
    },
  });
