import { Box } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { appUrl } from "Utils/UrlUtils";
import { useNavigate } from "react-router-dom";

type Props = {
  transactionPublicID: string;
  isDisabled?: boolean;
};

export const TransactionPaymentOptions = ({
  transactionPublicID,
  isDisabled,
}: Props) => {
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="row" width="100%" gap={4}>
      <PrimaryButton
        fullWidth
        type="button"
        color="primary"
        disabled={isDisabled}
        onClick={() =>
          navigate(`${appUrl("customer-payment-pay")}/${transactionPublicID}`)
        }
      >
        Platba přes připojený účet
      </PrimaryButton>
      <PrimaryButton
        fullWidth
        type="button"
        color="info"
        variant="outlined"
        disabled={isDisabled}
        onClick={() =>
          navigate(`${appUrl("initiate-qr-payment")}/${transactionPublicID}`)
        }
      >
        QR Platba
      </PrimaryButton>
    </Box>
  );
};
