import { Box, Link, Typography } from "@mui/material";
import { useSignUpPasswordless } from "Api/Mutations/Auth/useSignUpPasswordless";
import { useTransactionsDetail } from "Api/Queries/Transactions/useTransactionsDetail";
import { BlDivider } from "Components/Shared/BlDivider";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { CommonError } from "Components/Shared/Errors/CommonError";
import { PayPaymentDetail } from "Components/Shared/Payments/PayPaymentDetail";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { UserStatus, getApiUrl } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  transactionPublicID: string;
  onBankSelector: () => void;
};

const ComponentResources = Resources.Customer.Pay;

export const AnonymousCustomerPaymentPayComponent = ({
  transactionPublicID,
  onBankSelector,
}: Props) => {
  const { t } = useResource();
  const user = useLoggedInUser();
  const { data, isLoading, isError } =
    useTransactionsDetail(transactionPublicID);
  const { mutate: signUpPasswordLess, isPending } = useSignUpPasswordless({
    onSignUpSuccess: onBankSelector,
  });

  const onPay = () => {
    if (user?.userStatus !== UserStatus.Anonymous) {
      signUpPasswordLess();
    } else {
      onBankSelector();
    }
  };

  if (isError) {
    return <CommonError />;
  }

  return (
    <PrimaryPaper>
      <PayPaymentDetail
        isError={isError}
        isLoading={isLoading}
        transactionDetail={data}
      />
      <BlDivider />
      <Box mb={4}>
        <Typography textAlign="center" fontStyle="italic">
          Kliknutím na tlačítko zaplatit souhlasíte <br /> s
          <Link
            href={`${getApiUrl()}/content/terms/1-one-time-payment-docs.pdf`}
            fontWeight="bold"
            display="inline"
            sx={{ textDecoration: "none" }}
            ml={1}
          >
            Podmínkami AIS a PIS
          </Link>
          .
        </Typography>
      </Box>
      <PrimaryButton
        fullWidth
        color="primary"
        onClick={onPay}
        isLoading={isPending}
      >
        {t(ComponentResources.PayButton)}
      </PrimaryButton>
    </PrimaryPaper>
  );
};
