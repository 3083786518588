import { SystemSettingType } from "Api/Api";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { SystemSettingController } from "Components/System/SystemSettingController";
import styled from "styled-components";

const Wrapper = styled.div`
  margin: ${(props) => props.theme.spacing(7, 0)};
`;

const Setting: React.FunctionComponent<
  React.PropsWithChildren<{ type: SystemSettingType }>
> = ({ type }) => {
  return (
    <Wrapper>
      <SystemSettingController type={type} />
    </Wrapper>
  );
};

export const SystemControlPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  return (
    <AuthenticatedLayout title="Nastavení systému">
      <StyledPageWrapper>
        <div className="left-content">
          <Setting type={SystemSettingType.InvoicePaymentProcessorJob} />
          <Setting type={SystemSettingType.TradeJob} />
          <Setting type={SystemSettingType.WithdrawalJob} />
          <Setting type={SystemSettingType.SendinblueEmails} />
          <Setting type={SystemSettingType.MempoolFeeLevel} />
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default SystemControlPage;
