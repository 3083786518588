import type {
  EntityListOfInvoiceListItemDto,
  GetInvoiceRateChangeDetailQueryResult,
  GetUserFeeRateQueryResult,
  InvoiceSettingsDto,
} from "Api/Api";
import type { createInvoiceAsync } from "State/Invoices/Create/CreateInvoiceState";
import { downloadEmployeeGuideAsync } from "State/Invoices/DownloadEmployerGuideline/DownloadEmployerGuidelineState";
import {
  getInvoiceSettingsAsync,
  type saveInvoiceSettingsAsync,
} from "State/Invoices/InvoiceSettings/InvoiceSettingsState";
import { getInvoiceListAsync } from "State/Invoices/List/GetInvoiceListState";
import { getInvoiceRateChangeAsync } from "State/Invoices/RateChanges/GetInvoiceRateChangeState";
import type { processInvoiceRateChangeAsync } from "State/Invoices/RateChanges/ProcessInvoiceRateChangeState";
import { getUserFeeRateAsync } from "State/Invoices/UserFeeRate/GetUserFeeRateState";
import { type ActionType, createReducer } from "typesafe-actions";

type InvoiceState = {
  isInvoiceListLoading: boolean;
  invoiceList: EntityListOfInvoiceListItemDto | null;
  isInvoiceRateChangeLoading: boolean;
  invoiceRateChange: GetInvoiceRateChangeDetailQueryResult | null;
  invoiceSettings: InvoiceSettingsDto | null;
  userFeeRate: GetUserFeeRateQueryResult | null;
  isDownloading: boolean;
};

type InvoiceAction =
  | ActionType<typeof getInvoiceRateChangeAsync>
  | ActionType<typeof processInvoiceRateChangeAsync>
  | ActionType<typeof getInvoiceListAsync>
  | ActionType<typeof getInvoiceSettingsAsync>
  | ActionType<typeof saveInvoiceSettingsAsync>
  | ActionType<typeof getUserFeeRateAsync>
  | ActionType<typeof createInvoiceAsync>
  | ActionType<typeof downloadEmployeeGuideAsync>;

export const invoiceReducer = createReducer<InvoiceState, InvoiceAction>({
  isInvoiceListLoading: false,
  invoiceList: null,
  isInvoiceRateChangeLoading: false,
  invoiceRateChange: null,
  invoiceSettings: null,
  userFeeRate: null,
  isDownloading: false,
})
  .handleAction(getInvoiceListAsync.request, (state) => {
    return { ...state, isInvoiceListLoading: true };
  })
  .handleAction(getInvoiceListAsync.success, (state, action) => {
    return {
      ...state,
      isInvoiceListLoading: false,
      invoiceList: action.payload,
    };
  })
  .handleAction(getInvoiceListAsync.failure, (state, _action) => {
    return {
      ...state,
      isInvoiceListLoading: false,
    };
  })
  .handleAction(getInvoiceRateChangeAsync.request, (state) => {
    return { ...state, isInvoiceRateChangeLoading: true };
  })
  .handleAction(getInvoiceRateChangeAsync.success, (state, action) => {
    return {
      ...state,
      isInvoiceRateChangeLoading: false,
      invoiceRateChange: action.payload,
    };
  })
  .handleAction(getInvoiceRateChangeAsync.failure, (state, _action) => {
    return {
      ...state,
      isInvoiceRateChangeLoading: false,
    };
  })
  .handleAction(getInvoiceSettingsAsync.success, (state, action) => {
    return {
      ...state,
      invoiceSettings: action.payload,
    };
  })
  .handleAction(getUserFeeRateAsync.success, (state, action) => {
    return {
      ...state,
      userFeeRate: action.payload,
    };
  })
  .handleAction(downloadEmployeeGuideAsync.request, (state) => {
    return {
      ...state,
      isDownloading: true,
    };
  })
  .handleAction(downloadEmployeeGuideAsync.success, (state) => {
    return {
      ...state,
      isDownloading: false,
    };
  })
  .handleAction(downloadEmployeeGuideAsync.failure, (state) => {
    return {
      ...state,
      isDownloading: false,
    };
  });
