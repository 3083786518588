import { SettingsRowButton } from "Components/Settings/SettingsItem/SettingsRowButton";
import { SettingsRowLink } from "Components/Settings/SettingsItem/SettingsRowLink";
import { useNavigationWithPreviousPage } from "Hooks/useNavigateBackManager";
import type { ApplicationLink } from "Types/types";

export const SettingsItem = ({ link }: { link: ApplicationLink }) => {
  const { buildNavLinkWithPreviousPage } =
    useNavigationWithPreviousPage("settings");
  const { to, toPage, isDisabled, onClick, isExternalLink, ...restLink } = link;

  if (isDisabled) {
    return <div>{link.text}</div>;
  }

  if (onClick) {
    return <SettingsRowButton onClick={onClick} {...restLink} />;
  }

  const url = isExternalLink ? to : buildNavLinkWithPreviousPage(toPage);

  return (
    <SettingsRowLink url={url} isExternalLink={isExternalLink} {...restLink} />
  );
};
