import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Typography } from "@mui/material";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { resetUser } from "State/Auth/AuthReducer";
import { Resources, useResource } from "Translations/Resources";
import type * as React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledIcon = styled(NotInterestedIcon)`
  margin: 20px;
  font-size: 120px;
`;

export const Error403Page: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const { t } = useResource();
  const dispatch = useDispatch();
  return (
    <UnauthenticatedLayout title="403">
      <StyledPage>
        <Typography variant="h1">Error403</Typography>
        <Typography variant="h2">
          {t(Resources.Errors.Error403.Subtitle)}
        </Typography>
        <StyledIcon />
        <PrimaryButton onClick={() => dispatch(resetUser({}))}>
          Sign out
        </PrimaryButton>
      </StyledPage>
    </UnauthenticatedLayout>
  );
};

export default Error403Page;
