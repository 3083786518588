import { BankVerificationStatus } from "Infrastructure/Api/Api";
import type { CodeListItem } from "Models/ICodeListDto";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

type ListItem = CodeListItem<BankVerificationStatus>;
type Result = ListItem[];

export const useBankVerificationStatusList = () => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      Object.entries(BankVerificationStatus).map<ListItem>(([key]) => ({
        code: key as BankVerificationStatus,
        name: t(Resources.Form.BankVerification[key as BankVerificationStatus]),
      })),
    [t],
  );
};
