import type { UserNotificationSettingsDto } from "Api/Api";
import {
  getUserNotificationSettingsAsync,
  type saveUserNotificationSettingsAsync,
} from "State/Notification/Settings/UserNotificationSettingsState";
import { type ActionType, createReducer } from "typesafe-actions";

type NotificationState = {
  notificationSettings: UserNotificationSettingsDto | null;
};

type NotificationAction = ActionType<
  | typeof getUserNotificationSettingsAsync
  | typeof saveUserNotificationSettingsAsync
>;

export const notificationReducer = createReducer<
  NotificationState,
  NotificationAction
>({ notificationSettings: null }).handleAction(
  getUserNotificationSettingsAsync.success,
  (state, action) => {
    return { ...state, notificationSettings: action.payload };
  },
);
