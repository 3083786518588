import { Box, Typography } from "@mui/material";
import type { CurrencyCode } from "Models/CurrencyCodes";
import { getCurrencySymbol } from "Utils/CurrencyUtils";

type PriceProps = {
  amount: number;
  currencyCode: CurrencyCode;
};

export const Price = ({ amount, currencyCode }: PriceProps) => {
  const formattedAmount = new Intl.NumberFormat("cs-CZ").format(amount ?? 0);

  const currencySymbol = getCurrencySymbol(currencyCode);

  return (
    <Box display="flex" alignItems="baseline" gap="0.188rem">
      <Typography fontSize="0.75rem">Cena:</Typography>
      <Typography component="strong" fontSize="1.375rem" fontWeight="600">
        {formattedAmount}
      </Typography>
      <Typography fontWeight="600">{currencySymbol}</Typography>
    </Box>
  );
};
