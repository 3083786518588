import { RelationType } from "Infrastructure/Api/Api";
import type { CodeListItem } from "Models/ICodeListDto";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

type ListItem = CodeListItem<RelationType>;
type Result = Array<ListItem>;

type Props = {
  allowedRelations?: Array<RelationType>;
};

export const useRelationTypeValueList = ({
  allowedRelations,
}: Props = {}): Result => {
  const { t } = useResource();

  const allRelations = Object.values(RelationType);
  const relations = allowedRelations || allRelations;

  return useMemo(
    () =>
      relations.map((relationType) => ({
        code: relationType,
        name: t(Resources.Form.RelationType[relationType]),
      })),
    [relations, t],
  );
};
