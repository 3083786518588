import "./index.css";
import { registerSW } from "virtual:pwa-register";
import * as Sentry from "@sentry/react";
import { ApplicationError } from "Components/Error/ApplicationError";
import { logError } from "ErrorService";
import { initSentry } from "Infrastructure/Utils/SentryUtils";
import { createRoot } from "react-dom/client";
import TagManager from "react-gtm-module";
import App from "./App";

registerSW({ immediate: true });
initSentry();

if (import.meta.env.VITE_GOOGLE_TAG_MANAGER_IS_ENABLED === "true") {
  const tagManagerArgs = {
    gtmId: import.meta.env.VITE_GOOGLE_TAG_MANAGER_CODE as string,
  };
  TagManager.initialize(tagManagerArgs);
}

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <Sentry.ErrorBoundary
    fallback={(errorInfo) => <ApplicationError error={errorInfo.error} />}
    showDialog={true}
    dialogOptions={{
      lang: "cs",
    }}
  >
    <Sentry.Profiler name="Moone app">
      <App />
    </Sentry.Profiler>
  </Sentry.ErrorBoundary>,
);

window.addEventListener("error", (event) => {
  logError(event.error);
});

const setVhUnit = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};
setVhUnit();
window.addEventListener("resize", () => {
  setVhUnit();
});
