import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { Resources, useResource } from "Translations/Resources";
import { getAppUrl } from "Utils/UrlUtils";
import { useNavigate } from "react-router";

export const AddAccountButton = () => {
  const navigate = useNavigate();
  const { t } = useResource();
  const appUrl = getAppUrl();

  return (
    <PrimaryButton
      color="info"
      onClick={() => navigate(appUrl("banking-auth"))}
      fullWidth
    >
      {t(Resources.BankAccounts.List.AddAccount)}
    </PrimaryButton>
  );
};
