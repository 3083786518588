import {
  AskQuestionIcon,
  ForwardArrowIcon,
  FriendsIcon,
  GmailLogoIcon,
  HomeIcon,
  InstagramVerificationBadgeIcon,
  LogoutRoundedIcon,
  MerchantAccountIcon,
  MoreIcon,
  SelectUsersIcon,
  SortingArrowsIcon,
  UserIcon,
} from "Components/Shared/Icons";
import { AccessRightCodes } from "Models/AccessRightCodes";
import { resetUser } from "State/Auth/AuthReducer";
import { Resources, useResource } from "Translations/Resources";
import type { ApplicationLink } from "Types/types";
import { useDispatch } from "react-redux";

const MenuItemsResources = Resources.Menu.MainMenuItems;

export const useMenuLinks = () => {
  const { t } = useResource();
  const dispatch = useDispatch();

  const topLinks = {
    overview: {
      text: t(MenuItemsResources.Overview),
      toPage: "dashboard",
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <HomeIcon />,
    },
    merchantPayment: {
      text: t(MenuItemsResources.InitiatePayment),
      toPage: "merchant-payment",
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <ForwardArrowIcon />,
    },
    transactions: {
      text: t(MenuItemsResources.History),
      toPage: "transactions",
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <SortingArrowsIcon />,
    },
    bankAccounts: {
      text: t(MenuItemsResources.Accounts),
      toPage: "bank-address-list",
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <MerchantAccountIcon />,
    },
    licenses: {
      text: t(MenuItemsResources.Licenses),
      toPage: "licenses",
      accessRightCode: AccessRightCodes.Dashboard, //TODO: maybe new AccessRightCode.License?
      icon: <InstagramVerificationBadgeIcon />,
    },
    settings: {
      text: t(MenuItemsResources.Settings),
      toPage: "settings",
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <MoreIcon />,
    },
    users: {
      text: "Uživatelé",
      toPage: "user-list",
      accessRightCode: AccessRightCodes.Users,
      icon: <SelectUsersIcon />,
    },
  } satisfies Record<string, ApplicationLink>;

  const bottomLinks = {
    contact: {
      text: "Napište nám",
      toPage: "contact",
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <GmailLogoIcon />,
    },
    generalHelp: {
      text: "Nápověda",
      toPage: "help",
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <AskQuestionIcon />,
    },
    hunterHelp: {
      text: "Nápověda",
      to: "https://legi.one",
      accessRightCode: AccessRightCodes.Dashboard,
      isExternalLink: true,
      icon: <AskQuestionIcon />,
    },
    logout: {
      text: "Odhlásit se",
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <LogoutRoundedIcon />,
      onClick: () => {
        dispatch(resetUser({ isPageReloadDisabled: true }));
      },
    },
  } satisfies Record<string, ApplicationLink>;

  const settingsLinks = {
    profile: {
      text: "Profil",
      toPage: "account",
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <UserIcon />,
    },
    invite: {
      text: "Pozvat přítele",
      toPage: "affiliate",
      accessRightCode: AccessRightCodes.Dashboard,
      icon: <FriendsIcon />,
    },
  } satisfies Record<string, ApplicationLink>;

  return { ...topLinks, ...bottomLinks, ...settingsLinks };
};
