export const formatDate = (
  date: Date | string | undefined,
  withTime = false,
  exact = false,
) => {
  if (!date) {
    return "-";
  }

  const options: Intl.DateTimeFormatOptions = !withTime
    ? {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }
    : !exact
      ? {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
        }
      : {
          year: "numeric",
          month: "numeric",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        };
  const format = new Intl.DateTimeFormat("cs", options);

  return format.format(new Date(date));
};
