import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { useRelationTypeValueList } from "Hooks/Options/useRelationTypeValueList";
import type { RelationType } from "Infrastructure/Api/Api";
import type { Control, FieldPath, FieldValues } from "react-hook-form";

type Props<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  label?: string;
  disabled?: boolean;
  allowedRelations?: Array<RelationType>;
};

export const RelationTypeDropdown = <FormType extends FieldValues>({
  control,
  name,
  label,
  allowedRelations,
  disabled,
}: Props<FormType>) => {
  const codeList = useRelationTypeValueList({
    allowedRelations,
  });

  return (
    <Dropdown
      codeList={codeList}
      name={name}
      control={control}
      label={label}
      disabled={disabled}
    />
  );
};
