import { useMutation } from "@tanstack/react-query";
import {
  type CreateRelatedPartyCommandResult,
  type RelatedPartyDto,
  postPartiesPartyPublicIDRelation,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: (publicID: CreateRelatedPartyCommandResult) => void;
};

export const useCreatePartyRelationMutation = ({ onSuccess }: Props = {}) =>
  useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      ({
        data,
        partyPublicID,
      }: {
        data: RelatedPartyDto;
        partyPublicID: string;
      }) => postPartiesPartyPublicIDRelation(data, partyPublicID),
    ),
    onSuccess: (data: CreateRelatedPartyCommandResult) => {
      onSuccess?.(data);
    },
  });
