import { Box, Divider, Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import type { PropsWithChildren, ReactNode } from "react";

const TITLE_PADDING = 6;

type Props = PropsWithChildren<{
  title?: string;
  subTitle?: ReactNode;
  withDivider?: boolean;
  submitButtonLabel?: string;
  submitButtonDescription?: ReactNode; // TODO Nice to have, refactor as submitButtonConfig?: {label:string, description?: ReactNode, onClick....}
  submitDisabled?: boolean;
  onSubmitClick?: () => void;
  isSubmitting?: boolean;
}>;

export const StepBox = ({
  title,
  subTitle,
  children,
  withDivider,
  submitButtonLabel,
  submitButtonDescription,
  submitDisabled,
  onSubmitClick,
  isSubmitting,
}: Props) => {
  const marginBottom = withDivider ? 0 : TITLE_PADDING;

  return (
    <Box
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="space-between"
    >
      <div>
        {!!title && (
          <Typography marginBottom={marginBottom} marginTop={2}>
            {title}
          </Typography>
        )}
        {!!subTitle && (
          <Typography marginBottom={marginBottom}>{subTitle}</Typography>
        )}

        {withDivider && <Divider sx={{ marginY: TITLE_PADDING }} />}

        {children}
      </div>

      {!!submitButtonLabel && (
        <Box>
          {submitButtonDescription}
          <PrimaryButton
            color="primary"
            fullWidth
            sx={{ marginTop: 3 }}
            type={onSubmitClick ? "button" : "submit"}
            onClick={onSubmitClick}
            disabled={submitDisabled}
            isLoading={isSubmitting}
          >
            {submitButtonLabel}
          </PrimaryButton>
        </Box>
      )}
    </Box>
  );
};
