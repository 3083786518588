import type { TooltipProps } from "@mui/material";
import Button, { type ButtonProps } from "@mui/material/Button";
import { PrimaryTooltip } from "Components/Shared/PrimaryTooltip";
import type * as React from "react";
import styled from "styled-components";

type DefaultButtonProps = ButtonProps & {
  title?: string;
  titleEl?: JSX.Element;
  placement?: TooltipProps["placement"];
  tooltipColor?: {
    background: string;
    text: string;
  };
};

const StyledButton = styled(Button)`
  min-width: 0;

  &.Mui-disabled {
    opacity: 0.5;
  }
`;

export const DefaultButton: React.FunctionComponent<
  React.PropsWithChildren<DefaultButtonProps>
> = (props) => {
  const { title, tooltipColor, titleEl, ...rest } = props;
  const t = titleEl ? titleEl : title || "";
  return (
    <>
      {t ? (
        <PrimaryTooltip
          title={t}
          placement={props.placement ?? "bottom-end"}
          tooltipColor={tooltipColor}
        >
          <div>
            <StyledButton {...rest} color={props.color ?? "primary"} />
          </div>
        </PrimaryTooltip>
      ) : (
        <div>
          <StyledButton {...rest} color={props.color ?? "primary"} />
        </div>
      )}
    </>
  );
};
