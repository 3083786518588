import { Box, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { BlDivider } from "Components/Shared/BlDivider";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { GmailLogoIcon } from "Components/Shared/Icons";
import { resetUser } from "State/Auth/AuthReducer";
import { appUrl } from "Utils/UrlUtils";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";

const onClose = () => {
  // TODO: env file
  window.location.href = "https://mo.one";
};

export const ConfirmBox = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onCreateAccount = () => {
    // TODO: redirect to onboarding after merged
    dispatch(resetUser({}));
    navigate(appUrl("sign-up"));
  };

  return (
    <Box display="grid" gap={3}>
      <Box display="flex" flexDirection="column" alignItems="center">
        <GmailLogoIcon width="50" height="50" color={Colors.PrimaryMain} />
        <Typography variant="h5" marginY={1}>
          Účtenka již letí na Váš e-mail
        </Typography>
      </Box>
      <BlDivider marginY={0} />
      <Typography lineHeight="28px" textAlign="center">
        Chcete příště platit jedním ťapnutím a mít všechny platby na jednom
        místě? Vytvořte si vlastní Moone účet, bude to vcukuletu! 🚀
      </Typography>
      <PrimaryButton color="primary" onClick={onCreateAccount} fullWidth>
        Vytvořit účet
      </PrimaryButton>
      <PrimaryButton onClick={onClose} fullWidth>
        Zavřít
      </PrimaryButton>
    </Box>
  );
};
