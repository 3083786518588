import { useMediaQuery, useTheme } from "@mui/material";
import { appUrl } from "Utils/UrlUtils";
import { useLocation } from "react-router";

export const useHasBackgroundImage = () => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const urlsWithImage = [appUrl("dashboard")];

  return {
    hasBackgroundImage: isMd && urlsWithImage.includes(pathname),
  };
};
