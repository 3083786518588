import { type GetRateQuery, postRateGetRate } from "Api/Api";
import { getRateAsync } from "State/Rates/RateReducer";
import type { RootStateType } from "State/Store";
import { delay, put, takeLatest } from "redux-saga/effects";
import { call, select } from "typed-redux-saga";
import { getType } from "typesafe-actions";

function* fetchRates(
  action: ReturnType<typeof getRateAsync.request>,
): Generator {
  try {
    const watchedRates: GetRateQuery[] = yield* select(
      (e: RootStateType) => e.rate.watchedRates,
    );
    for (const rate of watchedRates) {
      const response = yield* call(postRateGetRate, rate);
      if (response.status === 200) {
        yield put(getRateAsync.success(response.json));
      } else {
        yield put(
          getRateAsync.failure(
            new Error(`Error in action ${action.type}`, {
              cause: response.status,
            }),
          ),
        );
      }
    }
    if (watchedRates.length > 0) {
      yield delay(9500);
      yield put(getRateAsync.request());
    }
  } catch (err) {
    yield put(getRateAsync.failure(err as Error));
  }
}

export function* getRateSaga() {
  yield takeLatest(getType(getRateAsync.request), fetchRates);
}
