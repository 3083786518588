import { useIdentificationCardList } from "Hooks/Options/useIdentificationCardList";
import type { Control, FieldPath, FieldValues } from "react-hook-form";
import { Dropdown } from "../Dropdown";

type Props<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  label?: string;
};

export const IdentificationCardDropdown = <FormType extends FieldValues>({
  control,
  name,
  label,
}: Props<FormType>) => {
  const { identificationCards } = useIdentificationCardList();

  return (
    <Dropdown
      codeList={identificationCards}
      name={name}
      control={control}
      label={label}
    />
  );
};
