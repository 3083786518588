import { useMutation } from "@tanstack/react-query";
import type { PostAuthSignUpPasswordlessFetchResponse } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";
import { signUpPasswordlessAsync } from "State/Auth/SignUp/SignUpPasswordlessState";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";

type Props = {
  onSignUpSuccess: () => void;
};

export const useSignUpPasswordless = ({ onSignUpSuccess }: Props) => {
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      () =>
        new Promise<PostAuthSignUpPasswordlessFetchResponse>(
          (resolve, reject) => {
            dispatch(
              signUpPasswordlessAsync.request({
                model: { isAnonymous: true, login: uuid() },
                successCallback: resolve,
                failedCallback: reject,
              }),
            );
          },
        ),
    ),
    onSuccess: onSignUpSuccess,
  });
};
