import { SyncLock } from "@mui/icons-material";
import { Box, CircularProgress } from "@mui/material";
import { BankButton } from "Components/Onboarding/BusinessEntity/Components/BanksList/BankButton/BankButton";
import { useBankingAuth } from "Hooks/useBankingAuth";
import type { BankEnvironment } from "Infrastructure/Api/Api";
import { mapBankEnvironmentToBank } from "Utils/BankUtils";

type Props = { environments: Array<BankEnvironment> };

export const ExpiringBanksList = ({ environments }: Props) => {
  const { authorize, isPending, selectedEnvironment } = useBankingAuth();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {environments.map((environment) => {
        const isLoading = environment === selectedEnvironment && isPending;

        return (
          <BankButton
            key={environment}
            bank={mapBankEnvironmentToBank(environment)}
            onClick={() => authorize({ environment })}
            isLoading={isLoading}
            actionType="reauth"
          />
        );
      })}
    </Box>
  );
};
