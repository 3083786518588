import {
  Grid,
  Paper,
  type PaperProps,
  Typography,
  styled,
} from "@mui/material";
import type { ElementType } from "react";

const StyledPaper = styled(Paper)`
  margin-top: ${(props) => props.theme.spacing(1)};
  padding: 10px;
  height: fit-content;
  ${(props) => props.theme.breakpoints.up("sm")} {
    padding: 25px;
  }
  border-radius: 10px;
`;

export const PrimaryPaper = (props: PaperProps) => <StyledPaper {...props} />;

type PaperRowProps = {
  left: JSX.Element | string;
  right: JSX.Element | string;
  rightComponent?: ElementType;
};

export const PaperRow = ({ left, right, rightComponent }: PaperRowProps) => (
  <>
    <Grid item xs={4}>
      <Typography textAlign="left">{left}</Typography>
    </Grid>
    <Grid item xs={8}>
      <Typography
        fontSize={16}
        fontWeight={500}
        textAlign="right"
        sx={{ wordBreak: "break-all" }}
        component={rightComponent ?? "p"}
      >
        {right}
      </Typography>
    </Grid>
  </>
);
