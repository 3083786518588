import { Skeleton, Stack } from "@mui/material";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { BankAccountListPageContent } from "Components/BankAccounts/BankAccountList/BankAccountListPageContent";
import { BankAccountRowLinkSkeleton } from "Components/BankAccounts/BankAccountList/BankAccountRowLink/BankAccountRowLinkSkeleton";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useNavigationWithPreviousPage } from "Hooks/useNavigateBackManager";
import type { ReactNode } from "react";

export const BankAccountListPage = () => {
  const { data, isLoading } = useBankingAccounts();

  if (isLoading) {
    return (
      <Layout>
        <BankAccountRowLinkSkeleton />
        <BankAccountRowLinkSkeleton />
        <BankAccountRowLinkSkeleton />
        <BankAccountRowLinkSkeleton />
        <Skeleton variant="rounded" height="60px" />
      </Layout>
    );
  }

  if (!data) {
    return (
      <Layout>
        Vaše bankovní účty se nepodařilo načíst. Zkuste to prosím znovu později.
      </Layout>
    );
  }

  return (
    <Layout>
      <BankAccountListPageContent accounts={data.items} />
    </Layout>
  );
};

export default BankAccountListPage;

const Layout = ({ children }: { children: ReactNode }) => {
  const { previousPageUrl } = useNavigationWithPreviousPage();

  return (
    <AuthenticatedLayout title="Bankovní účty" goBackUrl={previousPageUrl}>
      <StyledPageWrapper>
        <div className="left-content">
          <PrimaryPaper>
            <Stack gap={4}>{children}</Stack>
          </PrimaryPaper>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};
