import {
  type SendPhoneVerificationTokenCommand,
  type SendPhoneVerificationTokenCommandResult,
  postAuthSendPhoneVerificationToken,
} from "Api/Api";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const sendPhoneVerificationTokenAsync = createAsyncAction(
  "@auth/REQUEST_PHONE_VERIFICATION_REQUEST",
  "@auth/REQUEST_PHONE_VERIFICATION_SUCCESS",
  "@auth/REQUEST_PHONE_VERIFICATION_FAILURE",
)<
  SendPhoneVerificationTokenCommand,
  SendPhoneVerificationTokenCommandResult,
  Error
>();

function* sendPhoneVerificationToken(
  action: ReturnType<typeof sendPhoneVerificationTokenAsync.request>,
): Generator {
  try {
    const response = yield* call(
      postAuthSendPhoneVerificationToken,
      action.payload,
    );
    if (response.status === 200) {
      yield put(sendPhoneVerificationTokenAsync.success(response.json));
    } else {
      yield put(
        sendPhoneVerificationTokenAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(sendPhoneVerificationTokenAsync.failure(err as Error));
  }
}
export function* sendPhoneVerificationCodeSaga() {
  yield takeLatest(
    getType(sendPhoneVerificationTokenAsync.request),
    sendPhoneVerificationToken,
  );
}
