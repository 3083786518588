import type * as React from "react";
export const CloseIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="black"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.15625 6.3125L6.3125 9.15625L22.1562 25L6.21875 40.9688L9.03125 43.7812L25 27.8438L40.9375 43.7812L43.7812 40.9375L27.8438 25L43.6875 9.15625L40.8438 6.3125L25 22.1562L9.15625 6.3125Z"
        fill="inherit"
      />
    </svg>
  );
};
