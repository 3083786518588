import { Box, CircularProgress } from "@mui/material";
import { RevalidateBankingAuthModal } from "Components/Banking/Auth/RevalidateBankingAuthModal";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { UserStatus } from "Infrastructure/Api/Api";
import { resetUser } from "State/Auth/AuthReducer";
import type { RootStateType } from "State/Store";
import { getAppUrl } from "Utils/UrlUtils";
import { type ReactNode, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, type RouteProps, useLocation } from "react-router";

type Props = RouteProps & {
  isUserAuthenticated: boolean;
  allowAnonymous?: boolean;
  children: ReactNode;
};

export const AuthenticatedRoute = ({
  children,
  isUserAuthenticated,
  allowAnonymous,
}: Props) => {
  const appUrl = getAppUrl();
  const dispatch = useDispatch();
  const location = useLocation();
  const auth = useSelector((state: RootStateType) => state.auth);
  const user = useLoggedInUser();

  useEffect(() => {
    if (!isUserAuthenticated && location.pathname !== "/") {
      dispatch(
        resetUser({ authRedirectUrl: location.pathname + location.search }),
      );
    }
  }, [dispatch, isUserAuthenticated, location.pathname, location.search]);

  if (auth.isUserFetching) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress color="inherit" sx={{ color: "grey.300" }} />
      </Box>
    );
  }

  if (!isUserAuthenticated) {
    return (
      <Navigate
        to={appUrl("sign-in")}
        state={{ from: location }}
        replace={true}
      />
    );
  }

  if (!allowAnonymous && user?.userStatus === UserStatus.Anonymous) {
    return (
      <Navigate
        to={appUrl("dashboard")}
        state={{ from: location }}
        replace={true}
      />
    );
  }

  if (user?.userStatus === UserStatus.Onboarding) {
    return <Navigate to={appUrl("onboarding")} replace={true} />;
  }

  return (
    <>
      {children}
      <RevalidateBankingAuthModal />
    </>
  );
};
