import type {
  LicenseCountryCode,
  LicenseSelectionModel,
} from "Components/Onboarding/BusinessEntity/types";
import {
  LicenseCode,
  type LicenseTypeListItem,
  OwnershipType,
  type PartyLicenseDto,
} from "Infrastructure/Api/Api";

import cz_bronze from "Assets/Images/Licenses/LOHCZ-Bronze.png";
import cz_gold from "Assets/Images/Licenses/LOHCZ-Gold.png";
import cz_silver from "Assets/Images/Licenses/LOHCZ-Silver.png";
import cz_standard from "Assets/Images/Licenses/LOHCZ-Standard.png";
import sk_bronze from "Assets/Images/Licenses/LOHSK-Bronze.png";
import sk_gold from "Assets/Images/Licenses/LOHSK-Gold.png";
import sk_silver from "Assets/Images/Licenses/LOHSK-Silver.png";
import sk_standard from "Assets/Images/Licenses/LOHSK-Standard.png";
import test from "Assets/Images/Licenses/test.png";

export const LICENSE_CODE_TO_DATA_MAP: Record<
  LicenseCode,
  { title: string; czImg: string; skImg: string }
> = {
  [LicenseCode.Gold]: {
    title: "Gold",
    czImg: cz_gold,
    skImg: sk_gold,
  },
  [LicenseCode.Silver]: {
    title: "Silver",
    czImg: cz_silver,
    skImg: sk_silver,
  },
  [LicenseCode.Bronze]: {
    title: "Bronze",
    czImg: cz_bronze,
    skImg: sk_bronze,
  },
  [LicenseCode.Standard]: {
    title: "Standard",
    czImg: cz_standard,
    skImg: sk_standard,
  },
  [LicenseCode.Test]: {
    title: "Testovací",
    czImg: test,
    skImg: test,
  },
};

export const transformLicenseToDefaultValues = (
  license: PartyLicenseDto,
): LicenseSelectionModel => ({
  license: license.code,
  privateWalletAddress: license.walletAddress ?? undefined,
  isUsingPrivateWallet: license.ownWallet === OwnershipType.NonCustodial,
  countryAlpha2Code: license.countryAlpha2Code as LicenseCountryCode,
});

export const getNextAvailableLicense = (
  licenses: Array<LicenseTypeListItem>,
): LicenseTypeListItem | undefined =>
  licenses
    .filter(({ code }) => code !== LicenseCode.Test)
    .sort((a, b) => a.price - b.price)
    .find(({ availableAmount }) => availableAmount > 0);
