import { BottomNavigation } from "@mui/material";
import { BottomMenuItem } from "Components/Layout/Menu/BottomMenuItem";
import { useBottomMenu } from "Hooks/Layout/useBottomMenu";
import { useCurrentUserRole } from "Hooks/useCurrentUserAccessRole";
import type { UserType } from "Types/types";
import type { CSSProperties } from "react";
import { useLocation, useNavigate } from "react-router";

export const BOTTOM_MENU_HEIGHT = "61px";

const NAVIGATION_STYLES: CSSProperties = {
  paddingTop: 1,
  paddingBottom: 4,
  boxShadow: "0px -1px 4px rgba(0, 0, 0, 0.05)",
  zIndex: 10_000,
  height: BOTTOM_MENU_HEIGHT,
  position: "fixed",
  width: "100%",
  bottom: 0,
};

type UserRoleMenuProps = { userRole: UserType };

const UserRoleMenu = ({ userRole }: UserRoleMenuProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const menuLinks = useBottomMenu(userRole);

  return (
    <BottomNavigation
      showLabels
      value={pathname}
      onChange={(_, value) => navigate(value)}
      sx={NAVIGATION_STYLES}
    >
      {menuLinks.map((link) => (
        <BottomMenuItem key={link.value} {...link} />
      ))}
    </BottomNavigation>
  );
};

export const BottomMenu = () => {
  const userRole = useCurrentUserRole();

  if (!userRole) {
    return <>Chyba, uživatel nemá přiřazenou žádnou roli</>;
  }

  return <UserRoleMenu userRole={userRole} />;
};
