import type * as React from "react";
export const RiskIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 50 50"
      width="50px"
      height="50px"
      {...props}
    >
      <path
        d="M 25 3.0390625 C 23.966557 3.0390625 22.933144 3.5390433 22.363281 4.5410156 L 1.40625 41.398438 C 0.26719849 43.402298 1.7410553 46 4.0449219 46 L 45.955078 46 C 48.258945 46 49.732349 43.400998 48.59375 41.396484 L 27.636719 4.5410156 C 27.066856 3.5390433 26.033443 3.0390625 25 3.0390625 z M 25 4.9609375 C 25.341057 4.9609375 25.6823 5.1512223 25.898438 5.53125 L 46.855469 42.384766 L 46.853516 42.384766 C 47.284964 43.144252 46.793212 44 45.955078 44 L 4.0449219 44 C 3.2067884 44 2.7135828 43.144859 3.1445312 42.386719 L 24.101562 5.53125 C 24.3177 5.1512223 24.658943 4.9609375 25 4.9609375 z M 23.998047 17 C 23.446047 17 23 17.448 23 18 L 23 32 C 23 32.552 23.443094 33 23.996094 33 L 26 33 C 26.552 33 26.998047 32.552 26.998047 32 L 27 18 C 27 17.448 26.554953 17 26.001953 17 L 23.998047 17 z M 23.998047 36 C 23.446047 36 23 36.448 23 37 L 23 39 C 23 39.552 23.445047 40 23.998047 40 L 26.001953 40 C 26.553953 40 27 39.552 27 39 L 27 37 C 27 36.448 26.554953 36 26.001953 36 L 23.998047 36 z"
        fill="currentColor"
      />
    </svg>
  );
};
