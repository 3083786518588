import type * as React from "react";
export const TrashBinIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={16}
      height={20}
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.39999 0.800049C5.74218 0.800049 5.19999 1.34224 5.19999 2.00005V2.80005H1.19999C1.05624 2.79849 0.921869 2.87349 0.848432 2.99849C0.776556 3.12349 0.776556 3.27661 0.848432 3.40161C0.921869 3.52661 1.05624 3.60161 1.19999 3.60005H1.59999V18.0001C1.59999 18.661 2.13906 19.2001 2.79999 19.2001H13.2C13.8609 19.2001 14.4 18.661 14.4 18.0001V3.60005H14.8C14.9437 3.60161 15.0781 3.52661 15.1516 3.40161C15.2234 3.27661 15.2234 3.12349 15.1516 2.99849C15.0781 2.87349 14.9437 2.79849 14.8 2.80005H10.8V2.00005C10.8 1.34224 10.2578 0.800049 9.59999 0.800049H6.39999ZM6.39999 1.60005H9.59999C9.82187 1.60005 9.99999 1.77817 9.99999 2.00005V2.80005H5.99999V2.00005C5.99999 1.77817 6.17812 1.60005 6.39999 1.60005ZM5.19999 8.00005C5.30156 8.00005 5.40468 8.03911 5.48281 8.11724L7.99999 10.6344L10.5172 8.11724C10.6734 7.96099 10.9266 7.96099 11.0828 8.11724C11.2391 8.27349 11.2391 8.52661 11.0828 8.68286L8.56562 11.2L11.0828 13.7172C11.2391 13.8735 11.2391 14.1266 11.0828 14.2829C11.0047 14.361 10.9031 14.4001 10.8 14.4001C10.6969 14.4001 10.5953 14.361 10.5172 14.2829L7.99999 11.7657L5.48281 14.2829C5.40468 14.361 5.30312 14.4001 5.19999 14.4001C5.09687 14.4001 4.99531 14.361 4.91718 14.2829C4.76093 14.1266 4.76093 13.8735 4.91718 13.7172L7.43437 11.2L4.91718 8.68286C4.76093 8.52661 4.76093 8.27349 4.91718 8.11724C4.99531 8.03911 5.09843 8.00005 5.19999 8.00005Z"
        fill="currentColor"
      />
    </svg>
  );
};
