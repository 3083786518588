import type * as React from "react";
export const VerificationBadgeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_555_603)">
        <path
          d="M18.0412 9.99791L19.3192 7.49991L16.9624 5.97711L16.8208 3.17471L14.0184 3.03471L12.4944 0.678711L9.99761 1.95871L7.49961 0.680311L5.97721 3.03751L3.17521 3.17911L3.03441 5.98191L0.678406 7.50591L1.95841 10.0027L0.680806 12.4999L3.03761 14.0227L3.17921 16.8251L5.98161 16.9651L7.50561 19.3215L10.0024 18.0415L12.5004 19.3195L14.0228 16.9623L16.8248 16.8207L16.9656 14.0183L19.3216 12.4943L18.0412 9.99791ZM8.89601 13.0247L6.16801 10.2971L7.01641 9.44871L8.90921 11.3411L12.99 7.38591L13.8252 8.24751L8.89601 13.0247Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_555_603">
          <rect width={20} height={20} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
