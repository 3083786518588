import { UserStatus } from "Infrastructure/Api/Api";
import type { RootStateType } from "State/Store";
import { getAppUrl } from "Utils/UrlUtils";
import type { FC, PropsWithChildren } from "react";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router";

type Props = PropsWithChildren<{
  isUserAuthenticated: boolean;
}>;

export const OnboardingRoute: FC<Props> = ({
  isUserAuthenticated,
  children,
}) => {
  const appUrl = getAppUrl();
  const location = useLocation();
  const user = useSelector((state: RootStateType) => state.auth.user);

  if (!isUserAuthenticated) {
    return (
      <Navigate
        to={appUrl("sign-in")}
        state={{ from: location }}
        replace={true}
      />
    );
  }

  if (user?.userStatus !== UserStatus.Onboarding) {
    return (
      <Navigate
        to={appUrl("dashboard")}
        state={{ from: location }}
        replace={true}
      />
    );
  }

  return <>{children}</>;
};
