import { type ThemeOptions, createTheme, lighten } from "@mui/material/styles";
import { Colors } from "Components/Layout/Themes/Colors";
import type { DefaultTheme } from "styled-components";

export type ColorsType = {
  PrimaryMain: string;
  PrimaryDark: string;
  PrimaryText: string;
  SecondaryMain: string;
  SecondaryDark: string;
  SecondaryText: string;
  Body: string;
  Text: string;
  TextLight: string;
  Black: string;
  White: string;
  Green: string;
  Red: string;
  RedDark: string;
  DeleteRed: string;
  Warning: string;
  Gray: string;
  Border: string;
  DarkGrey: string;
  BackgroundGrey: string;
  Link: string;
};

const theme = (color: ColorsType): DefaultTheme => {
  const theme: ThemeOptions = {
    palette: {
      primary: {
        light: color.PrimaryMain,
        main: color.PrimaryMain,
        dark: color.PrimaryDark,
        contrastText: color.PrimaryText,
      },
      secondary: {
        light: color.SecondaryMain,
        main: color.SecondaryMain,
        dark: color.SecondaryDark,
        contrastText: color.SecondaryText,
      },
      text: {
        primary: color.Text,
        secondary: color.TextLight,
      },
      error: {
        main: color.Red,
        dark: color.RedDark,
        contrastText: color.White,
      },
      success: {
        main: color.Green,
      },
      info: {
        main: color.PrimaryMain,
        light: color.PrimaryMain,
        dark: color.PrimaryMain,
        contrastText: color.PrimaryText,
      },
      background: {
        default: color.Body,
      },
      common: {
        black: color.Black,
        white: color.White,
      },
      warning: {
        main: color.Warning,
      },
      divider: color.Border,
      action: {
        hover: lighten(color.BackgroundGrey, 0.3),
        disabledBackground: lighten(color.PrimaryDark, 0.7),
      },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 1050,
        lg: 1200,
        xl: 1536,
      },
    },
    spacing: 4,
    shape: {
      borderRadius: 4,
    },
    typography: {
      fontSize: 14,
      fontFamily: "'Outfit', sans-serif",
      body1: {
        fontSize: 14,
        fontWeight: 300,
      },
      body2: {
        color: color.Text,
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "20px",
      },
      h1: {
        color: color.Text,
        fontWeight: 700,
        fontSize: 55,
      },
      h2: {
        color: color.Text,
        fontWeight: 700,
        fontSize: 36,
        lineHeight: "48px",
      },
      h3: {
        color: color.Text,
        fontWeight: 600,
        fontSize: 28,
        lineHeight: "36px",
      },
      h4: {
        color: color.Text,
        fontWeight: 700,
        fontSize: 24,
      },
      subtitle1: {
        fontWeight: 400,
        color: color.TextLight,
        fontSize: 14,
      },
      button: {
        fontSize: 16,
        fontWeight: 500,
      },
    },

    components: {
      MuiButtonBase: {
        defaultProps: {
          // The props to apply
          disableRipple: true, // No more ripple, on the whole application 💣!
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            color: color.Text,
            fontWeight: 400,
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: color.TextLight,
              borderRadius: "4px",
            },
            "&::-webkit-scrollbar": {
              width: "9px",
              height: "7px",
              background: color.Gray,
              borderRadius: "4px",
            },
            "& *::-webkit-scrollbar-thumb": {
              backgroundColor: color.TextLight,
              borderRadius: "4px",
            },
            "& *::-webkit-scrollbar": {
              width: "7px",
              height: "7px",
              background: color.Gray,
              borderRadius: "4px",
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            boxShadow: "0 2px 6px 0 rgba(215, 215, 215, 0.5)",
            // boxShadow: "0 0 0 0 rgba(215, 215, 215, 0.5)",
            "& .MuiPickersBasePicker-container .MuiPickerDTTabs-tabs .MuiButtonBase-root":
              {
                color: color.White,
              },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: "8px 8px 8px 16px",
          },
          input: {
            padding: 0,
            fontSize: "16px",
            fontWeight: 400,
          },
        },
      },
    },
  };

  const customVariables = {
    colors: {
      red: color.Red,
      redDark: color.RedDark,
      border: color.Border,
      gray: color.Gray,
      darkGray: color.DarkGrey,
      deleteRed: color.DeleteRed,
    },
    shadow: {
      primary: "0 2px 6px 2px rgba(215, 215, 215, 0.5)",
      primaryHover: "0 2px 12px 0 rgba(215, 215, 215, 0.5)",
    },
    borderRadius: "4px",
    vh: (val: number) => {
      return `calc(var(--vh, 1vh) * ${val})`;
    },
  };

  return createTheme(Object.assign(theme, customVariables)) as DefaultTheme;
};

export const getTheme = () => {
  return theme(Colors);
};
