import { Box } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { LicensesPageContent } from "Components/Licenses/LicensesPageContent/LicensesPageContent";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useNavigationWithPreviousPage } from "Hooks/useNavigateBackManager";

export const LicensesPage = () => {
  const { previousPageUrl } = useNavigationWithPreviousPage();

  return (
    <AuthenticatedLayout title="Licence" goBackUrl={previousPageUrl}>
      <StyledPageWrapper>
        <Box className="left-content">
          <LicensesPageContent />
        </Box>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};
export default LicensesPage;
