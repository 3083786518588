import { type GetRateHistoryQueryResult, getRateHistory } from "Api/Api";
import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const getRatesHistoryAsync = createAsyncAction(
  "@rates/GET_HISTORY_REQUEST",
  "@rates/GET_HISTORY_SUCCESS",
  "@rates/GET_HISTORY_FAILURE",
)<
  {
    askCurrency: string;
    bidCurrency: string;
    startDate: Date;
  },
  GetRateHistoryQueryResult,
  Error
>();

function* getRatesHistory(
  action: ReturnType<typeof getRatesHistoryAsync.request>,
): Generator {
  try {
    const response = yield* call(
      getRateHistory,
      action.payload.askCurrency,
      action.payload.bidCurrency,
      action.payload.startDate.toISOString(),
    );
    if (response.status === 200) {
      yield put(getRatesHistoryAsync.success(response.json));
    } else {
      yield put(
        getRatesHistoryAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getRatesHistoryAsync.failure(err as Error));
  }
}
export function* getRatesHistorySaga() {
  yield takeLatest(getType(getRatesHistoryAsync.request), getRatesHistory);
}
