import { useMutation } from "@tanstack/react-query";
import {
  type PartyDto,
  type PartyType,
  getPartiesSearchAres,
} from "Infrastructure/Api/Api";
import {
  ApiCallError,
  processResponseWithApiProblemDetails,
} from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: (aresData: PartyDto | null) => void;
};

export const usePartySearchAresMutation = ({ onSuccess }: Props = {}) => {
  return useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      async ({
        query,
        partyType,
      }: {
        query: string;
        partyType?: PartyType;
      }) => {
        try {
          const result = await getPartiesSearchAres(query, partyType);

          if (result.status === 404) {
            throw new Error("Subjekt nebyl nalezen");
          }

          return result;
        } catch (err) {
          if (err instanceof ApiCallError) {
            console.log({ err });
          }
          console.log({ err });

          throw err;
        }
      },
    ),
    onSuccess: (data: PartyDto) => {
      if (onSuccess) {
        onSuccess(data);
      }
    },
  });
};
