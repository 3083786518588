import { Skeleton, Stack, Typography } from "@mui/material";
import { useUserVerificationDetailQuery } from "Api/Queries/Verification/useGetUserVerificationDetail";
import { AffiliateLink } from "Components/Affiliate/AffiliateLink";
import { InviteButton } from "Components/Affiliate/InviteButton";
import { isFullyVerified } from "Utils/VerificationUtils";

export const AffiliateLinkWithInviteButton = () => {
  const { data, isLoading } = useUserVerificationDetailQuery();

  if (isLoading) {
    return (
      <Stack gap={4}>
        <Skeleton width="100%" height={42} variant="rounded" />
        <Skeleton width="100%" height={60} variant="rounded" />
      </Stack>
    );
  }

  if (!data) {
    return (
      <Typography>
        Vyskytla se chyba - nepodařilo se ověřit uživatelský účet
      </Typography>
    );
  }

  const isUserVerified = isFullyVerified(data);

  if (!isUserVerified) {
    return (
      <Typography>
        Před pozváním přátel je nutné počkat na ověření Vašeho účtu.
      </Typography>
    );
  }

  return (
    <Stack gap={4}>
      <AffiliateLink />
      <InviteButton />
    </Stack>
  );
};
