import { Box, Skeleton } from "@mui/material";
import { LicensePickerSkeleton } from "Components/Licenses/LicensePicker/LicensePicker";
import { BlDivider } from "Components/Shared/BlDivider";

export const LicenseOrderFormSkeleton = () => (
  <>
    <Skeleton width={100} />
    <Skeleton width={350} />
    <LicensePickerSkeleton />
    <Box display="flex" flexDirection="column" alignItems="center" my={4}>
      <Skeleton width={350} />
      <Skeleton width={150} />
      <Skeleton width={100} height={50} />
    </Box>
    <BlDivider />
    <Skeleton width={150} />
    <BlDivider />
    <Skeleton width={250} />
    <Skeleton width={150} />
    <Box mt={4}>
      <Skeleton height={60} variant="rounded" />
    </Box>
  </>
);
