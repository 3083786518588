import { Typography } from "@mui/material";
import { TransactionItem } from "Components/Shared/Transactions/TransactionItem";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import type { TransactionItem as TransactionItemType } from "Infrastructure/Api/Api";
import { getTransactionDirection } from "Utils/TransactionUtils";

type Props = {
  transactions: Array<TransactionItemType>;
};

export const TransactionItems = ({ transactions }: Props) => {
  const user = useLoggedInUser();
  const currentUserID = user?.userID || -1; // We should always have UserID at this point

  if (!transactions.length) {
    return (
      <Typography marginTop={({ spacing }) => spacing(2)}>
        Zatím nebyla provedena žádná transakce.
      </Typography>
    );
  }

  return (
    <>
      {transactions.map((transaction) => (
        <TransactionItem
          key={transaction.publicID}
          item={transaction}
          direction={getTransactionDirection(transaction, currentUserID)}
        />
      ))}
    </>
  );
};
