import { type EntityListOfOfferListItemDto, getCoinbackOffers } from "Api/Api";
import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const getOfferListAsync = createAsyncAction(
  "@coinback/OFFER_LIST_REQUEST",
  "@coinback/OFFER_LIST_SUCCESS",
  "@coinback/OFFER_LIST_FAILURE",
)<string | null, EntityListOfOfferListItemDto, Error>();

function* getOfferList(
  action: ReturnType<typeof getOfferListAsync.request>,
): Generator {
  try {
    const response = yield* call(
      getCoinbackOffers,
      0,
      1000,
      action.payload as string,
    );
    if (response.status === 200) {
      yield put(getOfferListAsync.success(response.json));
    } else {
      yield put(
        getOfferListAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getOfferListAsync.failure(err as Error));
  }
}
export function* getOfferListSaga() {
  yield takeLatest(getType(getOfferListAsync.request), getOfferList);
}
