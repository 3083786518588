import { useQuery } from "@tanstack/react-query";
import { getVerificationsCurrencyCodeBankAccount } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";
import type { CurrencyCode } from "Models/CurrencyCodes";

type SUPPORTED_CURRENCIES = CurrencyCode.CZK | CurrencyCode.EUR;

export const VERIFICATION_BANK_ACCOUNT_QUERY_KEY = [
  "VERIFICATION_BANK_ACCOUNT",
];

export const getVerificationBankAccountQueryKey = (
  currencyCode: SUPPORTED_CURRENCIES,
) => [VERIFICATION_BANK_ACCOUNT_QUERY_KEY, { currencyCode }];

export const useVerificationBankAccountQuery = (
  currencyCode: SUPPORTED_CURRENCIES,
) =>
  useQuery({
    queryKey: getVerificationBankAccountQueryKey(currencyCode),
    queryFn: processResponseWithApiProblemDetails(() =>
      getVerificationsCurrencyCodeBankAccount(currencyCode),
    ),
    refetchOnWindowFocus: false,
  });
