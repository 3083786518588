import { Box, Typography } from "@mui/material";
import { useBankingAccountDelete } from "Api/Mutations/Accounts/useBankingAccountDelete";
import { Account } from "Components/Dashboard/ConnectedAccounts/Components/Account";
import { Colors } from "Components/Layout/Themes/Colors";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { ConfirmDialog } from "Components/Shared/Dialogs/ConfirmDialog";
import { CloseIcon } from "Components/Shared/Icons";
import { useConfirmDialog } from "Hooks/useConfirmDialog";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import styled from "styled-components";

const StyledButton = styled(PrimaryButton)`${({ theme: { palette } }) => `
  padding: 0;
  min-width: 60px;
  width: 60px;
  border-radius: 0;
  box-shadow: none;

  &.Mui-disabled {
    background: none;
    background-color: ${palette.grey[500]};
    color: ${palette.primary.contrastText};
  }`}
`;

type Props = { account: BankAccountListItem };

export const DeleteAccountButton = ({ account }: Props) => {
  const { handleResult, isOpen, open } = useConfirmDialog();
  const { mutate: deleteAccount, isPending: isDeletingAccount } =
    useBankingAccountDelete();

  const modalContent = (
    <>
      <Typography>Opravdu chcete smazat tento účet?</Typography>
      <Box
        sx={({ spacing, palette }) => ({
          p: spacing(2, 4),
          borderRadius: spacing(2),
          border: `1px solid ${palette.grey[300]}`,
        })}
      >
        <Account {...account} />
      </Box>
    </>
  );

  return (
    <>
      <StyledButton color="error" onClick={open} isLoading={isDeletingAccount}>
        <CloseIcon fill={Colors.PrimaryText} height={18} />
      </StyledButton>
      <ConfirmDialog
        isDestructive
        isOpen={isOpen}
        modalTitle="Smazat účet"
        modalContent={modalContent}
        handleResult={handleResult(() => deleteAccount(account.bankAccountID))}
      />
    </>
  );
};
