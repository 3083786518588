import { zodResolver } from "@hookform/resolvers/zod";
import type { UserAdminDetailDto } from "Infrastructure/Api/Api";
import { useForm } from "react-hook-form";

import { useUpdateUserMutation } from "Api/Mutations/User/useUpdateUserMutation";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledForm } from "Components/Users/UserDetailPageContent/Components/StyledForm";
import { UserFormFields } from "Components/Users/UserDetailPageContent/Components/UserFormFields";
import { userValidationSchema } from "Components/Users/schemas";
import { toast } from "sonner";

type UserFormProps = {
  user: UserAdminDetailDto;
};

export const UserForm = ({ user }: UserFormProps) => {
  const {
    handleSubmit,
    control: userControl,
    formState: { isDirty },
    reset,
  } = useForm<UserAdminDetailDto>({
    resolver: zodResolver(userValidationSchema),
    defaultValues: user,
  });

  const { mutate: updateUser, isPending } = useUpdateUserMutation({
    onSuccess: (data) => {
      reset(data);
      toast.success("Uložení údajů uživatele proběhlo úspěšně");
    },
    onError: () =>
      toast.error("Při ukládání údajů uživatele se vyskytla chyba"),
  });

  const onSubmit = (data: UserAdminDetailDto) => {
    updateUser(data);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <PrimaryPaper>
        <UserFormFields control={userControl} />
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={!isDirty}
          isLoading={isPending}
          variant="contained"
          color="primary"
        >
          Uložit
        </PrimaryButton>
      </PrimaryPaper>
    </StyledForm>
  );
};
