import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { OnboardingSteps } from "Components/Onboarding/BusinessEntity/types";
import { PersonalInformation } from "Components/Onboarding/Components/PersonalInformation/PersonalInformation";
import type { UserDetailModel } from "Components/Onboarding/types";
import type { FC } from "react";

export const PersonalInformationStep: FC = () => {
  const { getFormValues, nextStep } = useOnboardingContext();

  const onSave = (values: UserDetailModel) => {
    nextStep(values);
  };

  return (
    <PersonalInformation
      onSave={onSave}
      initialValues={getFormValues(OnboardingSteps.UserDetail) ?? {}}
    />
  );
};
