import { BankAccountDetail } from "Components/BankAccounts/BankAccountDetail";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { getBankAddressDetailAsync } from "State/BankAddresses/Detail/BankAddressDetailState";
import type { RootStateType } from "State/Store";
import { getAppUrl } from "Utils/UrlUtils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const BankAccountDetailPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const { id } = useParams();

  const dispatch = useDispatch();
  React.useEffect(() => {
    if (id) {
      dispatch(getBankAddressDetailAsync.request({ externalID: id }));
    }

    return () => {
      dispatch(getBankAddressDetailAsync.success(null));
    };
  }, [dispatch, id]);

  const { bankAccountDetail } = useSelector(
    (e: RootStateType) => e.bankAccount,
  );

  const appUrl = getAppUrl();

  return (
    <AuthenticatedLayout
      title="Bankovní účet"
      goBackUrl={appUrl("bank-address-list")}
    >
      <StyledPageWrapper>
        <div className="left-content">
          <PrimaryPaper>
            {!!bankAccountDetail && (
              <BankAccountDetail detail={bankAccountDetail} />
            )}
          </PrimaryPaper>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default BankAccountDetailPage;
