import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getPartyLicensesQueryKey } from "Api/Queries/Licenses/usePartyLicensesQuery";
import {
  type UpdateLicenseCommandResult,
  type UpdateLicenseRequest,
  putLicensesPublicID,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

type Variables = {
  publicID: string;
  requestPayload: UpdateLicenseRequest;
};

type Props = {
  onSuccess?: (data: UpdateLicenseCommandResult) => void;
  onError?: () => void;
};

export const usePutLicensesMutation = ({ onSuccess, onError }: Props = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      ({ publicID, requestPayload }: Variables) =>
        putLicensesPublicID(requestPayload, publicID),
    ),

    onSuccess: (data: UpdateLicenseCommandResult, { publicID }: Variables) => {
      queryClient.invalidateQueries({
        queryKey: getPartyLicensesQueryKey(publicID),
      });
      onSuccess?.(data);
    },
    onError,
  });
};
