import { useQuery } from "@tanstack/react-query";
import { type GetUserListQuery, postUserList } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const USERS_LIST_QUERY_KEY = "USERS_LIST";

export const useUsersListQuery = (paginationParams: GetUserListQuery) =>
  useQuery({
    queryKey: [USERS_LIST_QUERY_KEY, paginationParams],
    queryFn: processResponseWithApiProblemDetails(() =>
      postUserList(paginationParams),
    ),
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // Cache stays fresh for 5 minutes
  });
