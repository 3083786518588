import { SyncLock } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { PlusMathIcon, ShoppingCartIcon } from "Components/Shared/Icons";
import { ChevronRightIcon } from "Components/Shared/Icons/ChevronRight";
import { Resources, useResource } from "Translations/Resources";
import type { ReactNode } from "react";
import styled from "styled-components";

const ComponentResources = Resources.Banking.Auth.BankList;

export type ActionType = "add" | "select" | "buy" | "reauth";

const StyledIcon = styled.span<{
  $isDisabled?: boolean;
}>`
${({ theme: { palette } }) => `
  padding: 0;
  width: 60px;
  height: 56px;
  border-radius: 0;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${palette.common.white};
  background: ${palette.primary.main};
  `}
`;

// biome-ignore format: let's keep one icon per line
const ACTION_TYPE_TO_ICON_MAP: Record<ActionType, ReactNode> = {
  add: <PlusMathIcon width={28} height={50} color={Colors.PrimaryText} />,
  buy: <ShoppingCartIcon width={28} height={50} color={Colors.PrimaryText} />,
  select: <ChevronRightIcon width={20} height={40} color={Colors.PrimaryText} />,
  reauth: <SyncLock sx={{ fontSize: "1.8rem" }} />
};

type Props = {
  isDisabled?: boolean;
  isLoading?: boolean;
  actionType?: ActionType;
};

export const IconSection = ({
  isDisabled,
  isLoading,
  actionType = "add",
}: Props) => {
  const { t } = useResource();

  const icon = ACTION_TYPE_TO_ICON_MAP[actionType];

  return (
    <Box component="span" display="flex" alignItems="center" gap={5}>
      {isDisabled && (
        <Typography component="span">
          {t(ComponentResources.ComingSoon)}
        </Typography>
      )}
      <StyledIcon $isDisabled={isDisabled}>
        {isLoading ? <CircularProgress size={18} color="inherit" /> : icon}
      </StyledIcon>
    </Box>
  );
};
