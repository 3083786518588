import { useQuery } from "@tanstack/react-query";
import { getTransactionsPublicID } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const useTransactionsDetail = (
  publicID: string,
  paymentProcess?: boolean,
) =>
  useQuery({
    queryKey: ["TRANSACTIONS_DETAIL", publicID, paymentProcess],
    queryFn: processResponseWithApiProblemDetails(() =>
      getTransactionsPublicID(publicID, paymentProcess),
    ),
    enabled: !!publicID,
  });
