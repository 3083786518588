import { Box, Stack, Typography } from "@mui/material";
import { AffiliateLinkWithInviteButton } from "Components/Affiliate/AffiliateLinkWithInviteButton";
import { InvitationsList } from "Components/Affiliate/InvitationsList";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

export const HunterAffiliatePageContent = () => (
  <Box display="flex" flexDirection="column" gap={4}>
    <PrimaryPaper>
      <Typography variant="h6">
        Staň se legendou & vydělávej dlouhodobě! 🚀
      </Typography>
      <Box mb={6} display="flex" flexDirection="column">
        <p>
          Pozvi své známé do Legi.one a získej 10% provizi za každého nového
          huntera! 🏆 Ale tím to nekončí – pokud budou úspěšní, získáš
          dlouhodobé provize z jejich jednoho obchodu a o druhý se s tebou
          podělí o 50 %. 💰 Tobě se zároveň navýší provize z části Tvých
          obchodů.
        </p>
        <p>
          📩 Sdílej svůj affiliate kód teď a aktivně si buduj pasivní příjem!
        </p>
        <p>
          📜 Podmínky affiliate programu najdeš
          <Box
            component="a"
            href="https://legi.one/assets/cesta/Affiliate_Hunters_Rulers_11-2024.pdf"
            target="_blank"
            rel="noreferrer"
            ml={1}
          >
            zde
          </Box>
          .
        </p>
      </Box>
      <Stack gap={1}>
        <Typography fontWeight={600}>Váš affiliate odkaz:</Typography>
        <AffiliateLinkWithInviteButton />
      </Stack>
    </PrimaryPaper>
    <InvitationsList />
  </Box>
);
