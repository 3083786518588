import { zodResolver } from "@hookform/resolvers/zod";
import { useQueryClient } from "@tanstack/react-query";
import { usePutLicensesMutation } from "Api/Mutations/Licenses/usePutLicenses";
import { getPartyQueryKey } from "Api/Queries/Party/usePartyQuery";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { LicenseFormFields } from "Components/Users/UserDetailPageContent/Components/LicenseFormFields";
import { StyledForm } from "Components/Users/UserDetailPageContent/Components/StyledForm";
import {
  LicensePaymentStatus,
  type PartyLicenseDto,
} from "Infrastructure/Api/Api";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const schema = z.object({
  paymentStatus: z.nativeEnum(LicensePaymentStatus),
  licenseNo: z.string().nullish(),
});

type LicenseFormProps = {
  license: PartyLicenseDto;
  partyPublicID: string;
};

export const LicenseForm = ({ license, partyPublicID }: LicenseFormProps) => {
  const queryClient = useQueryClient();
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<PartyLicenseDto>({
    resolver: zodResolver(schema),
    defaultValues: license,
  });

  const { mutate: updateLicensePaymentStatus, isPending } =
    usePutLicensesMutation({
      onSuccess: (data) => {
        reset({ ...license, ...data.partyLicenseDto });
        queryClient.invalidateQueries({
          queryKey: getPartyQueryKey(partyPublicID),
        });
        toast.success("Uložení údajů licence proběhlo úspěšně");
      },
      onError: () =>
        toast.error("Při ukládání údajů licence se vyskytla chyba"),
    });

  const onSubmit = (data: PartyLicenseDto) => {
    updateLicensePaymentStatus({
      publicID: license.publicID,
      requestPayload: {
        paymentStatus: data.paymentStatus,
        licenseNo: data.licenseNo,
      },
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <PrimaryPaper>
        <LicenseFormFields control={control} />
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={!isDirty}
          isLoading={isPending}
          variant="contained"
          color="primary"
        >
          Uložit
        </PrimaryButton>
      </PrimaryPaper>
    </StyledForm>
  );
};
