import { Typography } from "@mui/material";
import { type SystemSettingDto, SystemSettingType } from "Api/Api";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { Dropdown } from "Components/Shared/Inputs/Dropdown";
import { StyledFlex } from "Components/Shared/StyledComponents";
import type { RootStateType } from "State/Store";
import { getSystemSettingAsync } from "State/System/SystemSettings/GetSystemSettingState";
import { saveSystemSettingAsync } from "State/System/SystemSettings/SaveSystemSettingState";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

type Props = { type: SystemSettingType };

export const SystemSettingController: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props) => {
  const { type } = props;
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getSystemSettingAsync.request({ systemSettingType: type }));
  }, [dispatch, type]);

  const { systemSettings, isSaving } = useSelector(
    (e: RootStateType) => e.system,
  );
  const systemSetting = systemSettings.find((e) => e.type === type);

  const setBoolSystemSetting = (isEnabled: boolean) => {
    if (!isSaving) {
      dispatch(
        saveSystemSettingAsync.request({
          value: isEnabled === true ? "True" : "False",
          type,
        }),
      );
    }
  };

  const setStringSystemSetting = (value: string) => {
    if (!isSaving) {
      dispatch(
        saveSystemSettingAsync.request({
          value,
          type,
        }),
      );
    }
  };

  const boolTypes = [
    SystemSettingType.InvoicePaymentProcessorJob,
    SystemSettingType.TradeJob,
    SystemSettingType.WithdrawalJob,
    SystemSettingType.SendinblueEmails,
    SystemSettingType.LeadRewardForBoth,
  ];

  const isBoolType = boolTypes.includes(type);

  const isMempoolFeeLevel =
    systemSetting?.type === SystemSettingType.MempoolFeeLevel;

  return (
    <div>
      {!!systemSetting && (
        <>
          {isBoolType && (
            <div>
              <StyledFlex gap="3px">
                <Typography fontWeight={600}>{systemSetting.type} </Typography>
                <Typography>je </Typography>
                <Typography color={isActive(systemSetting) ? "green" : "red"}>
                  {isActive(systemSetting) ? "aktivní" : "neaktivní"}
                </Typography>
              </StyledFlex>

              <PrimaryButton
                fullWidth={true}
                disabled={isSaving}
                onClick={() => {
                  setBoolSystemSetting(!isActive(systemSetting));
                }}
              >
                {isActive(systemSetting) ? "Deaktivovat" : "Aktivovat"}
              </PrimaryButton>
            </div>
          )}

          {isMempoolFeeLevel && (
            <>
              <div>
                <Typography fontWeight={600}>{systemSetting.type}</Typography>
              </div>

              <Dropdown
                name="MempoolFeeLevel"
                label=""
                value={systemSetting.value}
                options={[
                  { Value: "FastestFee", Name: "FastestFee" },
                  { Value: "HalfHourFee", Name: "HalfHourFee" },
                  { Value: "HourFee", Name: "HourFee" },
                  { Value: "EconomyFee", Name: "EconomyFee" },
                  { Value: "MinimumFee", Name: "MinimumFee" },
                ]}
                onChange={(e) => {
                  setStringSystemSetting(e as string);
                }}
              />
            </>
          )}
        </>
      )}
    </div>
  );
};

function isActive(systemSetting: SystemSettingDto) {
  return systemSetting.value.toLowerCase() === "true";
}
