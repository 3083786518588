import {
  type ResendVerificationEmailCommand,
  type ResendVerificationEmailCommandResult,
  postAuthResendVerificationEmail,
} from "Api/Api";
import { put, takeLatest } from "redux-saga/effects";
import { toast } from "sonner";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const resendVerificationEmailAsync = createAsyncAction(
  "@auth/RESEND_VERIFICATION_EMAIL_REQUEST",
  "@auth/RESEND_VERIFICATION_EMAIL_SUCCESS",
  "@auth/RESEND_VERIFICATION_EMAIL_FAILURE",
)<
  ResendVerificationEmailCommand,
  ResendVerificationEmailCommandResult,
  Error
>();

function* resendVerificationEmail(
  action: ReturnType<typeof resendVerificationEmailAsync.request>,
): Generator {
  try {
    const response = yield* call(
      postAuthResendVerificationEmail,
      action.payload,
    );
    if (response.status === 200) {
      yield put(resendVerificationEmailAsync.success(response.json));
      toast.success("Verifikační email byl odeslán.");
    } else {
      yield put(
        resendVerificationEmailAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(resendVerificationEmailAsync.failure(err as Error));
  }
}
export function* resendVerificationEmailSaga() {
  yield takeLatest(
    getType(resendVerificationEmailAsync.request),
    resendVerificationEmail,
  );
}
