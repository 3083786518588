import {
  Box,
  CircularProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { BlDivider } from "Components/Shared/BlDivider";

export const LoadingSkeleton = () => (
  <>
    <Box display="flex" flexDirection="column" alignItems="center">
      <CircularProgress />
      <Typography variant="h5" my={1}>
        <Skeleton variant="text" width={150} />
      </Typography>
      <Typography lineHeight="28px" textAlign="center">
        <Skeleton variant="text" width={300} />
        <Skeleton variant="text" width={300} />
      </Typography>
    </Box>
    <BlDivider />
    <Stack gap={2}>
      <Skeleton variant="rectangular" height={50} />
      <Skeleton variant="rectangular" height={50} />
      <Skeleton variant="rectangular" height={50} />
    </Stack>
  </>
);
