import type { IdentityProvider } from "Api/Api";
import type { UserStatus } from "Infrastructure/Api/Api";

export type AppUser = {
  login: string;
  firstName: string;
  lastName: string;
  accessRightCodes: string[];
  accessRoleCodes: string[];
  isFirstSignIn: boolean;
  tokenExpiration: string;
  userIdentityProviders: IdentityProvider[];
  profilePicture: string | null;
  userStatus: UserStatus;
  partyPublicID?: string;
  userID: number;
};

export enum SignInStep {
  Credentials = 0,
  VerificationToken = 1,
}

export enum ResetPasswordStep {
  Email = 0,
  Success = 1,
}

export enum SetPasswordStep {
  Password = 0,
  Success = 1,
}
