import {
  type SignInCommand,
  type SignInResult,
  SignInStatus,
  postAuthSignIn,
} from "Infrastructure/Api/Api";
import { setVerificationEmail } from "State/Auth/AuthReducer";
import { afterSignInSuccess } from "Utils/AuthUtils";
import { getAppUrl } from "Utils/UrlUtils";
import type { NavigateFunction } from "react-router-dom";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const signInAsync = createAsyncAction(
  "@auth/SIGN_IN_REQUEST",
  "@auth/SIGN_IN_SUCCESS",
  "@auth/SIGN_IN_FAILURE",
)<
  { model: SignInCommand; navigate: NavigateFunction },
  SignInResult & { isPasswordlessSign?: boolean },
  Error
>();

function* signIn({
  payload: { model, navigate },
}: ReturnType<typeof signInAsync.request>): Generator {
  try {
    const response = yield* call(postAuthSignIn, model);
    const appUrl = getAppUrl();
    const { status, data } = response;

    const emailVerificationUrl = appUrl("awaiting-hunter-email-verification");
    const hunterEmailVerificationUrl = appUrl(
      "awaiting-hunter-email-verification",
    );

    if (status === 403) {
      if (data.errors.WaitingForEmailConfirmationHunter) {
        yield put(setVerificationEmail(model.login));
        yield* call(() => navigate(hunterEmailVerificationUrl));
        return;
      }

      if (data.errors.WaitingForEmailConfirmation) {
        yield put(setVerificationEmail(model.login));
        yield* call(() => navigate(emailVerificationUrl));
        return;
      }

      throw new Error(data.errors.Login[0].code, { cause: status });
    }

    if (status !== 200) {
      throw new Error(data?.errors.Login[0].code, { cause: status });
    }

    if (data.status === SignInStatus.Failed) {
      throw new Error(data.error, { cause: status });
    }

    // Handle successful sign-in
    yield put(signInAsync.success(data));

    if (data.status === SignInStatus.Success) {
      yield* call(afterSignInSuccess, data, navigate);
    }
  } catch (err) {
    yield put(signInAsync.failure(err as Error));
  }
}

export function* signInSaga() {
  yield takeLatest(getType(signInAsync.request), signIn);
}
