import { Typography } from "@mui/material";
import { OwnerCard } from "Components/Onboarding/BusinessEntity/Components/OwnerCard";
import { OwnerModal } from "Components/Onboarding/BusinessEntity/Components/OwnerModal/OwnerModal";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import {
  type AdditionalInformationModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { BlTextButton } from "Components/Shared/Buttons/BlTextButton";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { ValuesBox } from "Components/Shared/ValuesBox/ValuesBox";
import { Resources, useResource } from "Translations/Resources";
import { useState } from "react";
import { useForm } from "react-hook-form";

const StepResources =
  Resources.Onboarding.BusinessOnboarding.Steps.AdditionalInformation;

const NEW_OWNER_ID = "";
const EMPTY_OWNER_ID = null;

export const AdditionalInformationStep = () => {
  const { t } = useResource();
  const { getFormValues, nextStep, setFormValues } = useOnboardingContext();

  const [modalOwnerID, setModalOwnerID] = useState<string | null>(null);

  const { control, handleSubmit, watch } = useForm<AdditionalInformationModel>({
    defaultValues: getFormValues(OnboardingSteps.AdditionalInformation),
  });

  const isUserOwner = watch("isOwner");

  const ownersPublicIDs =
    getFormValues(OnboardingSteps.AdditionalInformation).ownersPublicIDs ?? [];

  const onSubmit = (data: AdditionalInformationModel) => {
    nextStep(data);
  };

  const addOwnerIDToContext = (publicID: string) => {
    if (!ownersPublicIDs.includes(publicID)) {
      setFormValues(OnboardingSteps.AdditionalInformation, {
        ...getFormValues(OnboardingSteps.AdditionalInformation),
        ownersPublicIDs: [...ownersPublicIDs, publicID],
      });
    }
    setModalOwnerID(EMPTY_OWNER_ID);
  };

  const removeOwnerIDFromContext = (publicID: string) => {
    setFormValues(OnboardingSteps.AdditionalInformation, {
      ...getFormValues(OnboardingSteps.AdditionalInformation),
      ownersPublicIDs: ownersPublicIDs.filter((id) => id !== publicID),
    });
    setModalOwnerID(EMPTY_OWNER_ID);
  };

  return (
    <>
      <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
        <StepBox
          submitButtonLabel={t(Resources.Common.Continue)}
          submitDisabled={!isUserOwner && !ownersPublicIDs.length}
        >
          <CheckboxInput
            label={t(StepResources.CheckboxTitle)}
            control={control}
            name="isOwner"
            isLabelFirst
            isStrong
          />
          <Typography mb={6}>{t(StepResources.CheckboxDescription)}</Typography>
          <ValuesBox title={t(StepResources.OwnersTitle)}>
            {ownersPublicIDs.map((id) => (
              <OwnerCard
                key={id}
                publicID={id}
                onEdit={() => setModalOwnerID(id)}
              />
            ))}
            <div>
              <BlTextButton
                color="primary"
                onClick={() => setModalOwnerID(NEW_OWNER_ID)}
              >
                {`+ ${t(
                  ownersPublicIDs.length
                    ? StepResources.AddNextOwner
                    : StepResources.AddOwner,
                )}`}
              </BlTextButton>
            </div>
          </ValuesBox>
        </StepBox>
      </FullHeightForm>
      <OwnerModal
        onClose={() => setModalOwnerID(EMPTY_OWNER_ID)}
        onSave={addOwnerIDToContext}
        isOpen={modalOwnerID !== EMPTY_OWNER_ID}
        onDelete={removeOwnerIDFromContext}
        publicID={modalOwnerID || undefined}
      />
    </>
  );
};
