import { UnstyledButton } from "Components/Shared/Buttons/UnstyledButton";
import { StyledFlex } from "Components/Shared/StyledComponents";
import type { ApplicationLink } from "Types/types";
import { getAppUrl } from "Utils/UrlUtils";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

type Props = { item: ApplicationLink; pathname: string };

const StyledLink = css`
  text-decoration: none;
  color: #555555;

  svg {
    width: 50px;
    height: 50px;
    fill: #555555;
  }

  > div {
    width: 70%;
  }

  &.active {
    color: ${(props) => props.theme.palette.primary.main};

    > div {
      background-color: ${(props) => props.theme.palette.background.paper};
      border-radius: 10px;
    }

    svg {
      fill: ${(props) => props.theme.palette.primary.main};
    }
  }
  ${(props) => props.theme.breakpoints.up("md")} {
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};

      svg {
        fill: ${(props) => props.theme.palette.primary.main};
      }
    }
  }

  svg {
    height: 24px;
    align-self: center;
  }
`;

const StyledNavLink = styled(NavLink)`
  ${StyledLink}
`;

const StyledAnchor = styled.a`
  ${StyledLink}
`;

const StyledButton = styled(UnstyledButton)`
  ${StyledLink}
`;

const ContentWrapper = styled(StyledFlex)`
  padding: ${(props) => props.theme.spacing(3, 2)};
  p {
    font-size: 16px;
    font-weight: 400;
  }
`;

const Content = ({ item: { icon, text } }: Props) => (
  <ContentWrapper alignItems="center">
    {icon}
    <div>{text}</div>
  </ContentWrapper>
);

export const MenuItem = ({ item, pathname }: Props) => {
  const appUrl = getAppUrl();
  const { to, toPage, isDisabled, text, onClick, isExternalLink } = item;
  const className = pathname === "/" && toPage === "dashboard" ? "active" : "";

  if (isDisabled) {
    return <div>{text}</div>;
  }

  if (onClick) {
    return (
      <StyledButton onClick={onClick}>
        <Content pathname={pathname} item={item} />
      </StyledButton>
    );
  }

  if (isExternalLink) {
    return (
      <StyledAnchor href={to} target="_blank">
        <Content pathname={pathname} item={item} />
      </StyledAnchor>
    );
  }

  return (
    <StyledNavLink className={className} to={{ pathname: appUrl(toPage) }}>
      <Content pathname={pathname} item={item} />
    </StyledNavLink>
  );
};
