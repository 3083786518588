import { API_ROUTES } from "Api/Api";
import { downloadFile } from "Utils/FileUtils";
import { format } from "date-fns";
import FileSaver from "file-saver";
import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const downloadUserIdentificationZipAsync = createAsyncAction(
  "@user/IDENTIFICATION_ZIP_REQUEST",
  "@user/IDENTIFICATION_ZIP_SUCCESS",
  "@user/IDENTIFICATION_ZIP_FAILURE",
)<{ login: string; userID: number }, void, Error>();

function* downloadUserIdentificationZip(
  action: ReturnType<typeof downloadUserIdentificationZipAsync.request>,
): Generator {
  try {
    const route = `${import.meta.env.VITE_API_URL}${
      API_ROUTES.getUserUserIdentificationsZipUserID
    }/${action.payload.userID}`;
    const response = yield* downloadFile(route, "GET");

    if (response.clone.status === 200) {
      const blob = yield* call(() => response.clone.blob());

      FileSaver.saveAs(
        blob,
        `${action.payload.login}_${format(new Date(), "yyyy-MM-dd_HH:mm")}.zip`,
      );
      yield put(downloadUserIdentificationZipAsync.success());
    } else {
      yield put(
        downloadUserIdentificationZipAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.clone.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(downloadUserIdentificationZipAsync.failure(err as Error));
  }
}
export function* downloadUserIdentificationZipSaga() {
  yield takeLatest(
    getType(downloadUserIdentificationZipAsync.request),
    downloadUserIdentificationZip,
  );
}
