import { Box } from "@mui/material";
import { LICENSE_CODE_TO_DATA_MAP } from "Components/Licenses/licenseUtils";
import { LicenseCountryCode } from "Components/Onboarding/BusinessEntity/types";
import { ParameterList } from "Components/Shared/ParameterList/ParameterList";
import { useIsMobile } from "Hooks/useIsMobile";
import {
  LicensePaymentStatus,
  type PartyLicenseDto,
} from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources = Resources.Licenses;

type Props = {
  license: PartyLicenseDto;
};

export const LicenseDetail = ({
  license: { code, paymentStatus, countryAlpha2Code, licenseNo, walletAddress },
}: Props) => {
  const { t } = useResource();
  const isMobile = useIsMobile();

  const isActive = paymentStatus === LicensePaymentStatus.Success;
  const { title, czImg, skImg } = LICENSE_CODE_TO_DATA_MAP[code];

  const licenseParameters = [
    { label: "Typ licence", value: title },
    { label: "Číslo licence", value: licenseNo || "-" },
    { label: "Země", value: countryAlpha2Code },
    {
      label: "Stav platby",
      value: t(ComponentResources.LicenseStatus[paymentStatus]),
    },
    {
      label: "Adresa polygon blockchain peněženky",
      value: walletAddress || "-",
      textSize: "small" as const,
    },
  ];

  return (
    <Box
      display="flex"
      justifyContent={isMobile ? "center" : "space-between"}
      flexWrap="wrap-reverse"
      gap={3}
    >
      <ParameterList
        items={licenseParameters}
        justifyContent="space-between"
        maxWidth="50%"
        gap={6}
      />
      <Box display="flex">
        <Box
          alt={title}
          component="img"
          src={countryAlpha2Code === LicenseCountryCode.CZ ? czImg : skImg}
          sx={{
            borderRadius: "10px",
            filter: isActive ? "none" : "grayscale(100%)",
            opacity: isActive ? "1" : "0.3",
            height: "425px",
          }}
        />
      </Box>
    </Box>
  );
};
