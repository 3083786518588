import {
  type VerifyPhoneCommand,
  type VerifyPhoneCommandResult,
  postAuthVerifyPhone,
} from "Api/Api";
import { setToken } from "Utils/LocalStorageUtils";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const verifyPhoneAsync = createAsyncAction(
  "@auth/VERIFY_PHONE_REQUEST",
  "@auth/VERIFY_PHONE_SUCCESS",
  "@auth/VERIFY_PHONE_FAILURE",
)<VerifyPhoneCommand, VerifyPhoneCommandResult, Error>();

function* verifyPhone(
  action: ReturnType<typeof verifyPhoneAsync.request>,
): Generator {
  try {
    const response = yield* call(postAuthVerifyPhone, action.payload);
    if (response.status === 200 && response.json.signInResult) {
      setToken(response.json.signInResult);
      yield put(verifyPhoneAsync.success(response.json));
    } else {
      yield put(
        verifyPhoneAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(verifyPhoneAsync.failure(err as Error));
  }
}
export function* verifyPhoneSaga() {
  yield takeLatest(getType(verifyPhoneAsync.request), verifyPhone);
}
