import { Box, Skeleton } from "@mui/material";

export const BankAccountRowLinkSkeleton = () => (
  <Box
    height="57px"
    display="flex"
    alignItems="center"
    gap={2}
    p={2}
    border={({ palette }) => `1px solid ${palette.grey[100]}`}
    borderRadius="8px"
  >
    <Skeleton variant="rectangular" width={50} height={30} />
    <Box flexGrow={1}>
      <Skeleton variant="text" width={100} height={20} />
      <Skeleton variant="text" width={150} height={15} />
    </Box>
    <Skeleton variant="text" width={80} height={30} />
  </Box>
);
