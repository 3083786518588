import { Grid2, Typography } from "@mui/material";

import type { ReactNode } from "react";

type Props = {
  onClick: () => void;
  text: string;
  icon: JSX.Element;
};

export const SettingsRowButton = ({ onClick, text, icon }: Props) => (
  <ContainerButton onClick={onClick}>
    <Grid2 size={{ xs: 1 }} display="flex">
      {icon}
    </Grid2>
    <Grid2 size={{ xs: 10 }}>
      <Typography fontSize={16} textAlign="left">
        {text}
      </Typography>
    </Grid2>
  </ContainerButton>
);

type ContainerButtonProps = {
  children: ReactNode;
  onClick: () => void;
};

const ContainerButton = ({ children, onClick }: ContainerButtonProps) => (
  <Grid2
    container
    component="button"
    onClick={onClick}
    sx={({ palette, spacing }) => ({
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "center",
      border: "none",
      background: "none",
      cursor: "pointer",
      m: 0,
      p: spacing(4, 0),
      color: palette.text.secondary,
      borderBottom: `1px solid ${palette.divider}`,
      svg: {
        height: "16px",
        width: "16px",
      },
    })}
  >
    {children}
  </Grid2>
);
