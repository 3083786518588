import { useQuery } from "@tanstack/react-query";
import { getBankingBanksEnvironments } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const BANKING_BANKS_ENVIRONMENTS_QUERY_KEY = [
  "BANKING_BANKS_ENVIRONMENTS",
];

export const useBanksEnvironment = () =>
  useQuery({
    queryKey: BANKING_BANKS_ENVIRONMENTS_QUERY_KEY,
    queryFn: processResponseWithApiProblemDetails(() =>
      getBankingBanksEnvironments(),
    ),
  });
