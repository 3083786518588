import { useQuery } from "@tanstack/react-query";
import { getLeadsAffiliateLink } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const AFFILIATE_LINK_QUERY_KEY = ["AFFILIATE_LINK"];

export const useGetAffiliateLink = () =>
  useQuery({
    queryKey: AFFILIATE_LINK_QUERY_KEY,
    queryFn: processResponseWithApiProblemDetails(() =>
      getLeadsAffiliateLink(),
    ),
  });
