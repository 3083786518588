import { deactivateUserAsync } from "State/Users/Detail/DeactivateUserState";
import { getUserAdminDetailAsync } from "State/Users/Detail/GetUserAdminDetailState";
import { downloadUserIdentificationZipAsync } from "State/Users/Detail/GetUserIdentificationZipState";
import { getUserListAsync } from "State/Users/UserList/UserListState";
import { type ActionType, createReducer } from "typesafe-actions";
import type {
  EntityListOfUserDto,
  UserAdminDetailDto,
} from "../../Infrastructure/Api/Api";

type UserState = {
  userList: EntityListOfUserDto | null;
  isUserListLoading: boolean;
  userDetail: UserAdminDetailDto | null;
  isUserDetailLoading: boolean;
  isZipDownloading: boolean;
  isDeactivatingUser: boolean;
};

type UserAction =
  | ActionType<typeof getUserListAsync>
  | ActionType<typeof getUserAdminDetailAsync>
  | ActionType<typeof downloadUserIdentificationZipAsync>
  | ActionType<typeof deactivateUserAsync>;

export const userReducer = createReducer<UserState, UserAction>({
  userList: null,
  isUserListLoading: false,
  userDetail: null,
  isUserDetailLoading: false,
  isZipDownloading: false,
  isDeactivatingUser: false,
})
  .handleAction(getUserListAsync.request, (state) => {
    return { ...state, isUserListLoading: true };
  })
  .handleAction(getUserListAsync.success, (state, action) => {
    return { ...state, userList: action.payload, isUserListLoading: false };
  })
  .handleAction(getUserListAsync.failure, (state, _action) => {
    return {
      ...state,
      isUserListLoading: false,
    };
  })
  .handleAction(getUserAdminDetailAsync.request, (state) => {
    return { ...state, isUserDetailLoading: true };
  })
  .handleAction(getUserAdminDetailAsync.success, (state, action) => {
    return { ...state, userDetail: action.payload, isUserDetailLoading: false };
  })
  .handleAction(getUserAdminDetailAsync.failure, (state, _action) => {
    return {
      ...state,
      isUserDetailLoading: false,
    };
  })
  .handleAction(downloadUserIdentificationZipAsync.request, (state) => {
    return { ...state, isZipDownloading: true };
  })
  .handleAction(
    downloadUserIdentificationZipAsync.success,
    (state, _action) => {
      return { ...state, isZipDownloading: false };
    },
  )
  .handleAction(
    downloadUserIdentificationZipAsync.failure,
    (state, _action) => {
      return {
        ...state,
        isZipDownloading: false,
      };
    },
  )
  .handleAction(deactivateUserAsync.request, (state) => {
    return { ...state, isDeactivatingUser: true };
  })
  .handleAction(deactivateUserAsync.success, (state, _action) => {
    return { ...state, isDeactivatingUser: false };
  })
  .handleAction(deactivateUserAsync.failure, (state, _action) => {
    return {
      ...state,
      isDeactivatingUser: false,
    };
  });
