import { Typography } from "@mui/material";
import { StyledHtmlLink } from "Components/Routes/UnstyledLink";
import { StyledFlex } from "Components/Shared/StyledComponents";
import styled from "styled-components";

const StyledText = styled(StyledFlex)`
  flex-wrap: wrap;
`;

export const VerifiedStep: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  return (
    <>
      <br />
      <Typography>Děkujeme, jste plně ověřeni.</Typography>
      <br />
      <StyledText gap="6px">
        <Typography>Pokud ještě potřebujete navýšit limity,</Typography>
        <StyledHtmlLink href="mailto:info@coinwage.io">
          kontaktujte nás.
        </StyledHtmlLink>
      </StyledText>
    </>
  );
};
