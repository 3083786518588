import { zodResolver } from "@hookform/resolvers/zod";
import { Typography } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { z } from "zod";

const ComponentResources = Resources.Transactions;

const FormModelSchema = z.object({
  email: z.string().email(),
});

type FormModel = z.infer<typeof FormModelSchema>;

type Props = {
  onSubmit: (email: string) => void;
  isPending: boolean;
};

export const EmailForm = ({ onSubmit, isPending }: Props) => {
  const { t } = useResource();
  const { control, handleSubmit } = useForm<FormModel>({
    resolver: zodResolver(FormModelSchema),
  });

  const onInternalSubmit = ({ email }: FormModel) => {
    onSubmit(email);
  };

  return (
    <>
      <Typography mb={4}>
        {t(ComponentResources.Anonymous.FillEmail)}
      </Typography>
      <form onSubmit={handleSubmit(onInternalSubmit)}>
        <FormInput control={control} name="email" label="E-mail" />
        <PrimaryButton
          type="submit"
          color="primary"
          isLoading={isPending}
          fullWidth
        >
          {t(Resources.Common.Send)}
        </PrimaryButton>
      </form>
    </>
  );
};
