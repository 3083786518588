import { Typography } from "@mui/material";
import { useVerificationsMutation } from "Api/Mutations/Verifications/useVerificationsMutation";
import { useVerificationBankAccountQuery } from "Api/Queries/Verifications/useVerificationBankAccountQuery";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { OnboardingSteps } from "Components/Onboarding/BusinessEntity/types";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { QrCodePayment } from "Components/Shared/Payments/QrCodePayment";
import { CurrencyCode } from "Models/CurrencyCodes";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";
import { Resources, useResource } from "Translations/Resources";
import { getPaymentDescriptor } from "Utils/BankUtils";
import { useDispatch } from "react-redux";

const StepResources =
  Resources.Onboarding.BusinessOnboarding.Steps.AccountValidation;

const AMOUNT = 1;
const CURRENCY_CODE = CurrencyCode.CZK;

export const ManualAccountVerificationStep = () => {
  const { t } = useResource();
  const dispatch = useDispatch();
  const { getFormValues } = useOnboardingContext();
  const {
    data: { accountNumber, iban } = {},
    isLoading,
    isError,
  } = useVerificationBankAccountQuery(CurrencyCode.CZK);
  const { mutate, isPending } = useVerificationsMutation({
    onSuccess: () => {
      dispatch(getUserInfoAsync.request(undefined));
    },
  });

  if (isLoading) {
    return <QrCodePayment isLoading />;
  }

  const businessInformation = getFormValues(
    OnboardingSteps.BusinessInformation,
  );

  if (
    !businessInformation.identificationNumber ||
    !accountNumber ||
    !iban ||
    isError
  ) {
    return <>Něco se pokazilo, zkuste to prosím znovu později.</>;
  }

  const reference = businessInformation.identificationNumber;
  const message = t(StepResources.PaymentNote, {
    name: businessInformation.companyName,
  });

  const paymentDescriptor = getPaymentDescriptor(
    iban,
    reference,
    AMOUNT,
    CURRENCY_CODE,
    message,
  );

  return (
    <FullHeightForm>
      <StepBox
        submitButtonLabel="Odesláno"
        onSubmitClick={mutate}
        isSubmitting={isPending}
      >
        <Typography mb={5} fontSize="1rem">
          {t(StepResources.Description)}
        </Typography>
        <QrCodePayment
          isNoteMandatory
          amount={AMOUNT}
          note={message}
          paymentReference={reference}
          accountNumber={accountNumber}
          paymentDescriptor={paymentDescriptor}
          qrInstructions={`${t(StepResources.QRInstructions)} ${t(Resources.Transactions.BankTransferInfo)}`}
        />
      </StepBox>
    </FullHeightForm>
  );
};
