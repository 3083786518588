import { zodResolver } from "@hookform/resolvers/zod";
import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { PARTY_TYPE_TO_FORM_FIELDS } from "Components/Users/UserDetailPageContent/Components/PartyForm/utils";
import { StyledForm } from "Components/Users/UserDetailPageContent/Components/StyledForm";
import { createPartyValidationSchema } from "Components/Users/schemas";
import type { PartyDto } from "Infrastructure/Api/Api";
import type { ReactNode } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

type PartyFormProps = {
  party: PartyDto;
  additionalIdentificationsSlot?: ReactNode;
};

export const PartyForm = ({
  party,
  additionalIdentificationsSlot,
}: PartyFormProps) => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<PartyDto>({
    resolver: zodResolver(createPartyValidationSchema(party.type)),
    defaultValues: party,
  });

  const { mutate: updateParty, isPending } = usePartyMutation({
    onSuccess: ({ data }) => {
      reset(data);
      toast.success("Uložení údajů osoby proběhlo úspěšně");
    },
    onError: () => toast.error("Při ukládání údajů osoby se vyskytla chyba"),
  });

  const onSubmit = (data: PartyDto) => {
    updateParty({ publicID: data.publicID!, data });
  };

  const PartyFields = PARTY_TYPE_TO_FORM_FIELDS[party.type];

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <PrimaryPaper>
        <PartyFields
          control={control}
          addresses={party.addresses}
          additionalIdentificationsSlot={additionalIdentificationsSlot}
        />
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={!isDirty}
          isLoading={isPending}
          variant="contained"
          color="primary"
        >
          Uložit
        </PrimaryButton>
      </PrimaryPaper>
    </StyledForm>
  );
};
