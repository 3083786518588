import { Backdrop, Box, Fade, Modal, Typography } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { getInvoiceRateChangeAsync } from "State/Invoices/RateChanges/GetInvoiceRateChangeState";
import { processInvoiceRateChangeAsync } from "State/Invoices/RateChanges/ProcessInvoiceRateChangeState";
import type { RootStateType } from "State/Store";
import { formatCurrency } from "Utils/CurrencyUtils";
import { getAppUrl } from "Utils/UrlUtils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  border: 2px solid #000;
  background-color: white;
  box-shadow: 24;
  p: 4;
`;

export const RateConfirmationPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const appUrl = getAppUrl();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { externalID } = useParams();
  const { invoiceRateChange } = useSelector((e: RootStateType) => e.invoice);

  React.useEffect(() => {
    if (externalID) {
      dispatch(
        getInvoiceRateChangeAsync.request({
          request: { externalID },
          navigate,
        }),
      );
    }
  }, [dispatch, navigate, externalID]);

  const processInvoiceRateChange = (isAccepted: boolean) => {
    if (externalID) {
      dispatch(
        processInvoiceRateChangeAsync.request({
          request: {
            isAccepted,
            externalID,
          },
          navigate,
        }),
      );
    }
  };

  return (
    <AuthenticatedLayout title="Rate confirmation">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={true}
        onClose={() => {
          navigate(appUrl("dashboard"));
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <StyledBox>
            {!!invoiceRateChange && (
              <>
                <Typography variant="h1">Rate confirmation</Typography>
                <Typography sx={{ mt: 2 }}>
                  Rate change occurred
                  <br />
                  You have ordered to buy
                  {formatCurrency(
                    invoiceRateChange.invoiceAskAmount,
                    invoiceRateChange.invoiceAskCurrencyCode,
                  )}
                  at rate
                  {formatCurrency(
                    invoiceRateChange.invoiceFixedRate,
                    invoiceRateChange.invoiceAskCurrencyCode,
                  )}
                  but we need a confirmation because rate crossed a rate of
                  {formatCurrency(
                    invoiceRateChange.rate,
                    invoiceRateChange.invoiceAskCurrencyCode,
                  )}
                  <br />
                </Typography>

                <PrimaryButton
                  color="primary"
                  onClick={() => processInvoiceRateChange(true)}
                >
                  Confirm current rate
                </PrimaryButton>
                <PrimaryButton
                  color="primary"
                  onClick={() => processInvoiceRateChange(false)}
                >
                  Decline trade
                </PrimaryButton>
              </>
            )}
          </StyledBox>
        </Fade>
      </Modal>
    </AuthenticatedLayout>
  );
};

export default RateConfirmationPage;
