import { IdentificationType } from "Infrastructure/Api/Api";
import type { CodeListItem } from "Models/ICodeListDto";
import {
  type ResourceDictionary,
  Resources,
  useResource,
} from "Translations/Resources";
import { useMemo } from "react";

const Translactions: {
  [key in IdentificationType]: keyof ResourceDictionary["CodeLists"]["IdentificationList"];
} = {
  IDCard: "IDCard",
  Passport: "Passport",
  Other: "Other",
};

export const useIdentificationCardList = () => {
  const { t } = useResource();

  const identificationCards = useMemo<CodeListItem[]>(
    () =>
      Object.values(IdentificationType).map((value) => ({
        code: value as IdentificationType,
        name:
          value in Translactions
            ? t(Resources.CodeLists.IdentificationList[Translactions[value]])
            : value,
      })),
    [t],
  );

  return {
    isLoading: false,
    identificationCards,
  };
};
