import { Box, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { DoneIcon } from "Components/Shared/Icons";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources = Resources.Transactions.SuccessPayment;

export const SuccessfulTransactionInfo = () => {
  const { t } = useResource();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <DoneIcon color={Colors.PrimaryMain} />
      <Typography variant="h5" my={1}>
        {t(ComponentResources.Title)}
      </Typography>
      <Typography lineHeight="28px" textAlign="center">
        {t(ComponentResources.DescriptionFirstLine)}
        <br />
        {t(ComponentResources.DescriptionSecondLine)}
      </Typography>
    </Box>
  );
};
