import { AddressType } from "Infrastructure/Api/Api";
import type { CodeListItem } from "Models/ICodeListDto";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

type ListItem = CodeListItem<AddressType>;
type Result = ListItem[];

export const useAddressTypeList = () => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      Object.entries(AddressType).map<ListItem>(([key]) => ({
        code: key as AddressType,
        name: t(Resources.Form.AddressType[key as AddressType]),
      })),
    [t],
  );
};
