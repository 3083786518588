import type * as React from "react";

export const ChevronRightIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="50"
    height="50"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0688 2.98055C15.5884 2.99618 16.0806 3.21493 16.4438 3.58602L36.4438 23.586C37.2251 24.3673 37.2251 25.6329 36.4438 26.4141L16.4438 46.4141C15.9438 46.9376 15.1978 47.1485 14.4946 46.9649C13.7954 46.7813 13.2485 46.2345 13.0649 45.5352C12.8813 44.8321 13.0923 44.086 13.6157 43.586L32.2017 25.0001L13.6157 6.41414C13.0259 5.83993 12.8462 4.96102 13.1665 4.1993C13.4907 3.44149 14.2446 2.95711 15.0688 2.98055Z"
      fill="currentColor"
    />
  </svg>
);
