import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { LicensePaymentStatus } from "Infrastructure/Api/Api";
import type { Control, FieldPath, FieldValues } from "react-hook-form";

type PaymentStatusDropdownProps<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  label?: string;
};

const OPTIONS = [
  { code: LicensePaymentStatus.Initiated, name: "Platba vytvořena" },
  { code: LicensePaymentStatus.Processing, name: "Probíhá zpracování" },
  { code: LicensePaymentStatus.Success, name: "Zaplaceno" },
  { code: LicensePaymentStatus.Fail, name: "Neúspěšná" },
  { code: LicensePaymentStatus.Cancelled, name: "Zrušeno" },
];

export const PaymentStatusDropdown = <FormType extends FieldValues>({
  control,
  name,
  label,
}: PaymentStatusDropdownProps<FormType>) => (
  <Dropdown codeList={OPTIONS} name={name} control={control} label={label} />
);
