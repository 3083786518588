import type { UserDetailModel } from "Components/Onboarding/types";
import { AddressType, type PartyDto } from "Infrastructure/Api/Api";
import type { DeepPartial } from "types";

export const getUserDetailFromOCRResult = ({
  firstName,
  lastName,
  personalNumber,
  birthPlace,
  nationalityCountryID,
  gender,
  identification,
  identificationNumber: documentNumber,
  identificationValidFrom,
  identificationValidTo,
  identificationIssuer: issued,
  addresses,
}: PartyDto): DeepPartial<UserDetailModel> => {
  const permanentAddress = addresses.find(
    (address) => address.type === AddressType.Permanent,
  );

  const validFrom = identificationValidFrom
    ? new Date(identificationValidFrom)
    : undefined;
  const validTo = identificationValidTo
    ? new Date(identificationValidTo)
    : undefined;

  const document = identification
    ? {
        type: identification,
        number: documentNumber || undefined,
        valid: {
          from: validFrom,
          to: validTo,
        },
        issued: issued || undefined,
      }
    : undefined;

  return {
    firstName: firstName || undefined,
    lastName: lastName || undefined,
    personalIdentificationNumber: personalNumber || undefined,
    birthPlace: birthPlace || undefined,
    country: nationalityCountryID || 1, // TODO: ocr currently isn't picking this up, so let's select 1 by default (it's not index, but ID, so it will break if db doesn't have country with ID 1)
    gender: gender || undefined,
    address: permanentAddress,
    document,
  };
};
