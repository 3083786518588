import {
  type GetInvoiceRateChangeDetailQuery,
  type GetInvoiceRateChangeDetailQueryResult,
  postInvoiceGetInvoiceRateChange,
} from "Api/Api";
import { getAppUrl } from "Utils/UrlUtils";
import type { NavigateFunction } from "react-router-dom";
import { put, takeLatest } from "redux-saga/effects";
import { toast } from "sonner";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const getInvoiceRateChangeAsync = createAsyncAction(
  "@invoice/GET_INVOICE_RATE_CHANGE_REQUEST",
  "@invoice/GET_INVOICE_RATE_CHANGE_SUCCESS",
  "@invoice/GET_INVOICE_RATE_CHANGE_FAILURE",
)<
  { request: GetInvoiceRateChangeDetailQuery; navigate: NavigateFunction },
  GetInvoiceRateChangeDetailQueryResult,
  Error
>();

function* getInvoiceRateChange(
  action: ReturnType<typeof getInvoiceRateChangeAsync.request>,
): Generator {
  try {
    const response = yield* call(
      postInvoiceGetInvoiceRateChange,
      action.payload.request,
    );
    if (response.status === 200) {
      yield put(getInvoiceRateChangeAsync.success(response.json));
    } else if (response.status === 204) {
      toast.warning("Invoice rate change not found.");
      const url = getAppUrl();
      yield* call(() => action.payload.navigate(url("dashboard")));
    } else {
      yield put(
        getInvoiceRateChangeAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getInvoiceRateChangeAsync.failure(err as Error));
  }
}
export function* getInvoiceRateChangeSaga() {
  yield takeLatest(
    getType(getInvoiceRateChangeAsync.request),
    getInvoiceRateChange,
  );
}
