import { CountryDropdown } from "Components/Shared/Inputs/Form/Dropdowns/CountryDropdown";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { AddressFields } from "Components/Users/UserDetailPageContent/Components/AddressFields";
import { FormSection } from "Components/Users/UserDetailPageContent/Components/FormSection";
import type { AddressDto } from "Infrastructure/Api/Api";
import type { PartyDto } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import type { Control } from "react-hook-form";

type BeneficialOwnerPartyFormFieldsProps = {
  control: Control<PartyDto>;
  addresses: Array<AddressDto>;
};

export const BeneficialOwnerPartyFormFields = ({
  control,
  addresses,
}: BeneficialOwnerPartyFormFieldsProps) => {
  const { t } = useResource();

  return (
    <>
      <FormSection legend="Osobní údaje">
        <FormInput control={control} name="firstName" label="Jméno" />
        <FormInput control={control} name="lastName" label="Příjmení" />
        <FormInput
          control={control}
          name="birthDate"
          label="Datum narození"
          type="date"
        />
        <CountryDropdown
          control={control}
          name="nationalityCountryID"
          label={t(Resources.Common.Nationality)}
        />
      </FormSection>
      <AddressFields control={control} addresses={addresses} />
    </>
  );
};
