import Skeleton, { type SkeletonProps } from "@mui/material/Skeleton";
import type * as React from "react";
import styled from "styled-components";

type Props = SkeletonProps;

const StyledSkeleton = styled(Skeleton)`
  background-color: ${(props) => props.theme.palette.secondary.main}33;
  border-radius: ${(props) => props.theme.borderRadius};
`;

export const PrimarySkeleton: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props) => {
  return <StyledSkeleton {...props} />;
};
