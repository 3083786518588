import Button, { type ButtonProps } from "@mui/material/Button";
import type * as React from "react";
import styled from "styled-components";

type Props = ButtonProps;

const StyledButton = styled(
  ({ hasIcon, ...rest }: Props & { hasIcon: boolean }) => <Button {...rest} />,
)`
  padding: 5px ${(props) => props.theme.spacing(2)};
  border: none;
  background-color: ${(props) => props.theme.colors.gray};
  color: ${(props) => props.theme.palette.text.primary};
  min-width: 130px;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);

  &:hover {
    background-color: ${(props) => props.theme.colors.gray};
    color: ${(props) => props.theme.palette.text.primary};
    border: none;
  }

  .MuiButton-startIcon {
    margin-right: ${(props) => props.theme.spacing(2)};
    margin-left: 0;

    svg {
      height: 24px;
      width: auto;
    }
  }
`;

export const SecondaryButton: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props) => {
  return (
    <StyledButton
      {...props}
      hasIcon={!!props.startIcon}
      color={props.color ?? "primary"}
      variant="contained"
      disabled={props.disabled}
    >
      {props.children}
    </StyledButton>
  );
};
