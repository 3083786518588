import {
  type ProcessSystemWithdrawalResult,
  type SystemWithdrawalRequest,
  postSystemWithdraw,
} from "Api/Api";
import { getSystemReportAsync } from "State/System/SystemReport/SystemReportState";
import { put, takeLatest } from "redux-saga/effects";
import { toast } from "sonner";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const systemWithdrawAsync = createAsyncAction(
  "@system/WITHDRAW_REQUEST",
  "@system/WITHDRAW_SUCCESS",
  "@system/WITHDRAW_FAILURE",
)<SystemWithdrawalRequest, ProcessSystemWithdrawalResult, Error>();

function* systemWithdraw(
  action: ReturnType<typeof systemWithdrawAsync.request>,
): Generator {
  try {
    const response = yield* call(postSystemWithdraw, action.payload);
    if (response.status === 200) {
      toast("Withdrawal request sent successfully");
      yield put(getSystemReportAsync.success(null));
      yield put(getSystemReportAsync.request());
      yield put(systemWithdrawAsync.success(response.json));
    } else {
      yield put(
        systemWithdrawAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(systemWithdrawAsync.failure(err as Error));
  }
}
export function* systemWithdrawSaga() {
  yield takeLatest(getType(systemWithdrawAsync.request), systemWithdraw);
}
