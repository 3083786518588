import { Box } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { SettingsItem } from "Components/Settings/SettingsItem/SettingsItem";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useSettingsMenu } from "Hooks/Layout/useSettingsMenu";

export const SettingsPage = () => {
  const settingsLinks = useSettingsMenu();

  return (
    <AuthenticatedLayout title="Více">
      <StyledPageWrapper>
        <Box className="left-content" display="flex" flexDirection="column">
          {settingsLinks.map((link) => (
            <SettingsItem link={link} key={link.text} />
          ))}
        </Box>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default SettingsPage;
