import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { useUpdateVerificationsMutation } from "Api/Mutations/Verifications/useUpdateVerificationsMutation";
import { useVerificationsMutation } from "Api/Mutations/Verifications/useVerificationsMutation";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import {
  OnboardingSteps,
  useOnboardingContext,
} from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import {
  FinalStepForm,
  type FinalStepFormModel,
} from "Components/Onboarding/NaturalPerson/Steps/FinalStep/FinalStepForm";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import type { UserVerificationDto } from "Infrastructure/Api/Api";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";
import { useDispatch } from "react-redux";

type Props = { userVerificationData?: UserVerificationDto };

export const FinalStepContent = ({ userVerificationData }: Props) => {
  const user = useLoggedInUser();
  const { setFormValues } = useOnboardingContext();
  const { data: apiPartyData, isLoading } = usePartyQuery(user?.partyPublicID);

  const dispatch = useDispatch();

  const { mutate: putParty, isPending: isCreatingParty } = usePartyMutation();
  const { mutate: postVerifications, isPending: isPostingVerifications } =
    useVerificationsMutation({
      onSuccess: () => {
        // after we refresh the user info with the new data, user will be redirected to the dashboard
        dispatch(getUserInfoAsync.request(undefined));
      },
    });
  const { mutate: verify, isPending: isVerifying } =
    useUpdateVerificationsMutation({
      onSuccess: () => {
        postVerifications();
      },
    });

  if (isLoading || !apiPartyData) {
    return null;
  }

  const onSubmit = (data: FinalStepFormModel) => {
    setFormValues(OnboardingSteps.LastStep, data);

    if (!apiPartyData?.publicID) {
      throw new Error("Error: Missing party publicID");
    }

    putParty(
      {
        data: { ...apiPartyData, phone: data.phone },
        publicID: apiPartyData?.publicID,
      },
      {
        onSuccess: () => {
          verify({
            isPoliticallyExposedPerson: data.politicallyExposed ?? false,
            isResidingInCzechia: data.isResidingInCzechia ?? false,
            hasNoOtherNationality: data.hasNoOtherNationality ?? false,
          });
        },
      },
    );
  };

  return (
    <FinalStepForm
      defaultValues={{
        phone: "420",
        politicallyExposed: false,
        isResidingInCzechia: userVerificationData?.isResidingInCzechia ?? false,
        hasNoOtherNationality:
          userVerificationData?.hasNoOtherNationality ?? false,
      }}
      onSubmit={onSubmit}
      isSubmitting={isCreatingParty || isPostingVerifications || isVerifying}
    />
  );
};
