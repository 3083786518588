import { CircularProgress } from "@mui/material";
import { useVerificationsQuery } from "Api/Queries/Verifications/useVerificationsQuery";
import { FinalStepContent } from "Components/Onboarding/NaturalPerson/Steps/FinalStep/FinalStepContent";

export const FinalStep = () => {
  const { data, isLoading } = useVerificationsQuery();

  if (isLoading) {
    return <CircularProgress />;
  }

  return <FinalStepContent userVerificationData={data} />;
};
