import { composeIBAN, extractIBAN } from "ibantools";

// CZ and SK have the same format of IBAN (XXkk bbbb pppp ppcc cccc cccc)
export const getLocalBankAccountNumberFromIBAN = (IBAN: string): string => {
  const { valid, iban, countryCode } = extractIBAN(IBAN);

  if (!valid || (countryCode !== "CZ" && countryCode !== "SK")) {
    return IBAN;
  }

  const bank = iban.slice(4, 8);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const _prefix = iban.slice(8, 14); // not needed now
  const account = iban.slice(14, 24);

  return `${account}/${bank}`;
};

export const getIBANFromLocalBankAccountNumber = (
  accountNumber: string,
  countryCode: "CZ" | "SK",
): string => {
  const [account, bank] = accountNumber.split("/");

  if (!account || !bank || account.length > 10 || bank.length !== 4) {
    return "";
  }

  // Ensure account number is padded to 10 digits
  const paddedAccount = account.padStart(10, "0");

  // Construct IBAN without checksum
  const bban = `${bank}000000${paddedAccount}`; // Prefix is assumed to be 000000

  return composeIBAN({ countryCode, bban }) || "";
};
