import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Grid } from "@mui/material";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import { useAres } from "Components/Onboarding/BusinessEntity/Hooks/useAres";
import {
  type BusinessInformationModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { ValuesBox } from "Components/Onboarding/NaturalPerson/Components/ValuesBox";
import { businessAddressValidationSchema } from "Components/Onboarding/schemas";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { BusinessDropdown } from "Components/Shared/SelectorsForm/BusinessDropdown";
import { useIsCurrentUserHunter } from "Hooks/useIsCurrentUserHunter";
import { AddressType, type PartyDto, PartyType } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

type ExtendedBusinessInformationModel = BusinessInformationModel & {
  hasHunterNoIdentificationNumberYet?: boolean;
};

const validationSchema: z.ZodType<ExtendedBusinessInformationModel> = z
  .object({
    partyType: z.nativeEnum(PartyType),
    identificationNumber: z
      .string()
      .regex(/^\d{8}$/, "IČO musí být číslo o délce 8 znaků..")
      .nullish(),
    companyName: z.string().nullish(),
    address: businessAddressValidationSchema,
    contact: z.object({
      phone: z.string().min(12, "Prosím, zadejte platné telefonní číslo"),
    }),
    hasHunterNoIdentificationNumberYet: z.boolean().optional(),
  })
  .superRefine(
    (
      {
        hasHunterNoIdentificationNumberYet,
        identificationNumber,
        address,
        companyName,
      },
      ctx,
    ) => {
      if (!hasHunterNoIdentificationNumberYet && !identificationNumber) {
        ctx.addIssue({
          path: ["identificationNumber"],
          message: "IČO musí být číslo o délce 8 znaků.",
          code: "custom",
        });
      }

      if (!hasHunterNoIdentificationNumberYet) {
        if (!companyName) {
          ctx.addIssue({
            path: ["companyName"],
            message: "Název subjektu je povinný.",
            code: "custom",
          });
        }
        if (!address.streetNumber) {
          ctx.addIssue({
            path: ["address", "descriptionNumber"],
            message: "Číslo popisné je povinné.",
            code: "custom",
          });
        }
        if (!address.municipality) {
          ctx.addIssue({
            path: ["address", "municipality"],
            message: "Město je povinné.",
            code: "custom",
          });
        }
        if (!address.postalCode) {
          ctx.addIssue({
            path: ["address", "postalCode"],
            message: "PSČ je povinné.",
            code: "custom",
          });
        }
      }
    },
  );

export const BusinessInformationStep = () => {
  const { t } = useResource();
  const isUserHunter = useIsCurrentUserHunter();
  const { nextStep, getFormValues, setAresData } = useOnboardingContext();

  const partyValuesFromContext = getFormValues(
    OnboardingSteps.BusinessInformation,
  );
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
    setValue,
    trigger,
  } = useForm<ExtendedBusinessInformationModel>({
    defaultValues: {
      ...partyValuesFromContext,
      address: {
        ...partyValuesFromContext.address,
        type: AddressType.Headquarters,
      },
    },
    resolver: zodResolver(validationSchema),
  });

  const onAresData = (aresData: PartyDto | null) => {
    setAresData(aresData);
    reset(getFormValues(OnboardingSteps.BusinessInformation));
  };

  const currentIdentificationNumber = watch("identificationNumber");

  const { isFetchingAres } = useAres({
    initialIdentifierNumber:
      getFormValues(OnboardingSteps.BusinessInformation)
        ?.identificationNumber || undefined,
    partyType: watch("partyType"),
    identifierNumber: currentIdentificationNumber || undefined,
    onAresData,
    disabled: !isDirty,
  });

  const hasHunterNoIdentificationNumberYet = watch(
    "hasHunterNoIdentificationNumberYet",
  );

  useEffect(
    function resetBusinessFieldsValues() {
      if (hasHunterNoIdentificationNumberYet) {
        setValue("identificationNumber", null);
        setValue("companyName", null);
        setValue("address.street", null);
        setValue("address.streetNumber", null);
        setValue("address.orientationNumber", null);
        setValue("address.municipality", null);
        setValue("address.postalCode", null);

        trigger([
          "identificationNumber",
          "companyName",
          "address.street",
          "address.streetNumber",
          "address.orientationNumber",
          "address.municipality",
          "address.postalCode",
        ]);
      }
    },
    [hasHunterNoIdentificationNumberYet, setValue, trigger],
  );

  const onSubmit = (data: ExtendedBusinessInformationModel) => {
    const { hasHunterNoIdentificationNumberYet, ...model } = data; // take out the hasHunterNoIdentificationNumberYet field, as it's used only for validation
    const formData: BusinessInformationModel = model;
    nextStep(formData);
  };

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox submitButtonLabel="Pokračovat" submitDisabled={isFetchingAres}>
        <ValuesBox title="Základní údaje" hideDivider>
          <BusinessDropdown
            control={control}
            name="partyType"
            label="Forma podnikání"
          />
          <div>
            <FormInput
              control={control}
              name="identificationNumber"
              label="IČO"
              disabled={hasHunterNoIdentificationNumberYet}
              placeholder={hasHunterNoIdentificationNumberYet ? "-" : undefined}
            />
            {isUserHunter && (
              <CheckboxInput
                label="Ještě nemám IČO"
                control={control}
                name="hasHunterNoIdentificationNumberYet"
              />
            )}
          </div>
          {!hasHunterNoIdentificationNumberYet && (
            <FormInput
              disabled
              control={control}
              name="companyName"
              label="Název subjektu"
              placeholder={hasHunterNoIdentificationNumberYet ? "-" : undefined}
            />
          )}
        </ValuesBox>
        {!hasHunterNoIdentificationNumberYet && (
          <ValuesBox title="Sídlo" hideDivider>
            <FormInput
              disabled
              control={control}
              name="address.street"
              label="Ulice"
            />
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormInput
                  disabled
                  control={control}
                  name="address.streetNumber"
                  label={t(Resources.Common.StreetNumber)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  disabled
                  control={control}
                  name="address.orientationNumber"
                  label={t(Resources.Common.OrientationNumber)}
                />
              </Grid>
            </Grid>
            <FormInput
              disabled
              control={control}
              name="address.municipality"
              label="Město"
            />
            <FormInput
              disabled
              control={control}
              name="address.postalCode"
              label="PSČ"
              mask="000 00"
            />
          </ValuesBox>
        )}
        <Box mt={hasHunterNoIdentificationNumberYet ? 6 : 0}>
          <ValuesBox title="Kontakt" hideDivider>
            <FormInput
              control={control}
              name="contact.phone"
              label={t(Resources.Common.Phone)}
              mask="+000 000 000 000"
              inputProps={{
                inputMode: "tel",
              }}
            />
          </ValuesBox>
        </Box>
      </StepBox>
    </FullHeightForm>
  );
};
