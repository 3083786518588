import { useTransactionsStatus } from "Api/Queries/Transactions/useTransactionsStatus";
import { BlDivider } from "Components/Shared/BlDivider";
import { ConfirmBox } from "Components/Transactions/Anonymous/ConfirmBox";
import { EmailOrDownloadInvoiceForm } from "Components/Transactions/Anonymous/EmailOrDownloadInvoiceForm";
import { FailedTransactionInfo } from "Components/Transactions/Anonymous/FailedTransactionInfo";
import { LoadingSkeleton } from "Components/Transactions/Anonymous/LoadingSkeleton";
import { SuccessfulTransactionInfo } from "Components/Transactions/Anonymous/SuccessfulTransactionInfo";
import { TransactionStatus2 } from "Infrastructure/Api/Api";
import { useState } from "react";

type Props = {
  transactionPublicID: string;
};

export const TransactionDetail = ({ transactionPublicID }: Props) => {
  const [wasEmailInvoiceSent, setWasEmailInvoiceSent] = useState(false);
  const { data, isLoading, isError } = useTransactionsStatus(
    transactionPublicID,
    { enabled: !wasEmailInvoiceSent },
  );

  if (wasEmailInvoiceSent) {
    return <ConfirmBox />;
  }

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  const hasTransactionFailed =
    !data || isError || data.transactionStatus === TransactionStatus2.Fail;

  if (hasTransactionFailed) {
    return <FailedTransactionInfo />;
  }

  return (
    <>
      <SuccessfulTransactionInfo />
      <BlDivider />
      <EmailOrDownloadInvoiceForm
        transactionPublicID={transactionPublicID}
        onEmailSent={() => setWasEmailInvoiceSent(true)}
      />
    </>
  );
};
