import { zodResolver } from "@hookform/resolvers/zod";
import { FormFields } from "Components/Merchant/Initiate/FormFields";
import { useFormValidationSchema } from "Components/Merchant/Initiate/useFormValidationSchema";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { useTransactionInitiate } from "Hooks/useTransactionInitiate";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { FormProvider, useForm } from "react-hook-form";

const PageResources = Resources.Merchant.Initiate;

export type FormModel = {
  accountType: "bank" | "iban";
  bankAccountID?: number;
  publicID: string;
  iban?: string;
  amount: number;
};

type Props = {
  bankAccounts: Array<BankAccountListItem>;
  defaultValues: Partial<FormModel>;
};

export const InitiatePaymentForm = ({ bankAccounts, defaultValues }: Props) => {
  const { mutate: initiateTransaction, isPending } = useTransactionInitiate();
  const { t } = useResource();

  const initiateMerchantSchema = useFormValidationSchema();

  const form = useForm<FormModel>({
    resolver: zodResolver(initiateMerchantSchema),
    defaultValues,
  });

  const onSubmit = async ({
    iban,
    amount,
    accountType,
    bankAccountID,
  }: FormModel) => {
    if (amount) {
      initiateTransaction({
        ...(accountType === "bank"
          ? { receiverBankAccountID: bankAccountID }
          : { receiverIBAN: iban?.replace(/\s/g, "") }),
        amount,
      });
    }
  };

  return (
    <form onSubmit={form.handleSubmit(onSubmit)}>
      <FormProvider {...form}>
        <FormFields bankingAccounts={bankAccounts} />
      </FormProvider>
      <PrimaryButton
        fullWidth
        type="submit"
        color="primary"
        disabled={isPending}
        sx={{ marginTop: 2 }}
      >
        {t(PageResources.GenerateButton)}
      </PrimaryButton>
    </form>
  );
};
