import { Box } from "@mui/material";
import { LicenseForm } from "Components/Users/UserDetailPageContent/Components/LicenseForm";
import type { PartyLicenseDto } from "Infrastructure/Api/Api";

type LicensesProps = {
  licenses: Array<PartyLicenseDto>;
  partyPublicID: string;
};

export const Licenses = ({ licenses, partyPublicID }: LicensesProps) => (
  <Box display="flex" flexDirection="column" gap="1rem">
    {licenses.map((license) => (
      <LicenseForm
        key={license.publicID}
        license={license}
        partyPublicID={partyPublicID}
      />
    ))}
  </Box>
);
