import type * as React from "react";
export const MerchantAccountIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={17}
    height={16}
    viewBox="0 0 17 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_1585_455)">
      <path
        fill="currentColor"
        d="M8.90002 1.36523C8.49857 1.36523 8.09762 1.49581 7.7659 1.75716L3.09077 5.44075C3.00925 5.50509 2.94978 5.59322 2.92063 5.6929C2.89147 5.79257 2.89407 5.89885 2.92807 5.99699C2.96207 6.09512 3.02578 6.18023 3.11035 6.2405C3.19492 6.30077 3.29616 6.33322 3.40002 6.33333H14.4C14.5039 6.33322 14.6051 6.30077 14.6897 6.2405C14.7743 6.18023 14.838 6.09512 14.872 5.99698C14.906 5.89885 14.9086 5.79257 14.8794 5.6929C14.8503 5.59322 14.7908 5.50509 14.7093 5.44075L10.0348 1.75716C9.70306 1.49581 9.30146 1.36523 8.90002 1.36523ZM8.90002 2.36263C9.08174 2.36263 9.26336 2.42299 9.41564 2.54297L12.958 5.33333H4.84207L8.38439 2.54297C8.53667 2.42299 8.71829 2.36263 8.90002 2.36263ZM8.90002 3.33333C8.72321 3.33333 8.55364 3.40357 8.42861 3.5286C8.30359 3.65362 8.23335 3.82319 8.23335 4C8.23335 4.17681 8.30359 4.34638 8.42861 4.4714C8.55364 4.59643 8.72321 4.66667 8.90002 4.66667C9.07683 4.66667 9.2464 4.59643 9.37142 4.4714C9.49645 4.34638 9.56668 4.17681 9.56668 4C9.56668 3.82319 9.49645 3.65362 9.37142 3.5286C9.2464 3.40357 9.07683 3.33333 8.90002 3.33333ZM4.06668 7V11.3737C3.40016 11.5261 2.90002 12.1215 2.90002 12.8333C2.90002 13.66 3.57335 14.3333 4.40002 14.3333H7.90002V13.3333H4.40002C4.12335 13.3333 3.90002 13.11 3.90002 12.8333C3.90002 12.5567 4.12335 12.3333 4.40002 12.3333H7.90002V11.3333H7.23335V7H6.23335V11.3333H5.06668V7H4.06668ZM8.40002 7V9.54687C8.58668 9.41021 8.82002 9.33333 9.06668 9.33333H9.40002V7H8.40002ZM10.5667 7V9.33333H11.5667V7H10.5667ZM12.7333 7V9.33333H13.7333V7H12.7333ZM9.40002 10C8.94549 10 8.56668 10.3788 8.56668 10.8333V14.5C8.56668 14.9545 8.94549 15.3333 9.40002 15.3333H15.4C15.8545 15.3333 16.2333 14.9545 16.2333 14.5V10.8333C16.2333 10.3788 15.8545 10 15.4 10H9.40002ZM9.56668 11H15.2333V11.6667H9.56668V11ZM9.56668 12.6667H15.2333V14.3333H9.56668V12.6667Z"
      />
    </g>
    <defs>
      <clipPath id="clip0_1585_455">
        <rect width={16} height={16} transform="translate(0.900017)" />
      </clipPath>
    </defs>
  </svg>
);
