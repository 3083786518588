import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { useExpectedMonthlyRevenueList } from "Hooks/Options/useExpectedMonthlyRevenueList";
import type { Control, FieldPath, FieldValues } from "react-hook-form";

type Props<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  label?: string;
};

export const ExpectedMonthlyRevenueDropdown = <FormType extends FieldValues>({
  control,
  name,
  label,
}: Props<FormType>) => {
  const codeList = useExpectedMonthlyRevenueList();

  return (
    <Dropdown codeList={codeList} name={name} control={control} label={label} />
  );
};
