import { Box } from "@mui/material";
import type { CarouselOption } from "Components/Shared/Inputs/CarouselPicker/types";

type Props<T extends string> = {
  option: CarouselOption<T>;
  index: number;
  currentIndex: number;
  hasDragged: boolean;
  onSelect: (index: number) => void;
  isDisabled?: boolean;
};

export const CarouselSlide = <T extends string>({
  option: { imgSrc, title },
  index,
  currentIndex,
  hasDragged,
  isDisabled,
  onSelect,
}: Props<T>) => {
  const isSelected = index === currentIndex;

  return (
    <Box
      component="button"
      type="button"
      onClick={() => !hasDragged && !isDisabled && onSelect(index)}
      sx={{
        all: "unset",
        transform: isSelected ? "scale(1)" : "scale(0.8)",
        filter: isDisabled && !isSelected ? "grayscale(100%)" : "none",
        opacity: isDisabled && !isSelected ? "0.3" : "1",
        transition: "transform 0.5s ease",
        flexShrink: 0,
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: !isSelected && !isDisabled ? "pointer" : "default",
      }}
    >
      <Box
        component="img"
        onDragStart={(e) => e.preventDefault()}
        src={imgSrc}
        alt={title}
        sx={{
          height: "100%",
          objectFit: "cover",
          objectPosition: "center",
          borderRadius: "10px",
          boxShadow: "0px 0px 12px 0px #00000029",
        }}
      />
    </Box>
  );
};
