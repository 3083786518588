import { Box } from "@mui/material";
import { LogoutButton } from "Components/Onboarding/Components/LogoutButton";
import { PageTitle } from "Components/Shared/PageTitles/PageTitle";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { Resources, useResource } from "Translations/Resources";
import { type ReactNode, useEffect } from "react";
import styled from "styled-components";

const StyledWrapper = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${(props) => props.theme.spacing(6, 0, 0, 12)};
  z-index: 2;

  ${(props) => props.theme.breakpoints.down("lg")} {
    padding: ${(props) => props.theme.spacing(6, 3)};
  }
`;

const StyledChildrenWrapper = styled.div`
  margin-top: ${(props) => props.theme.spacing(6)};
  height: 100%;
`;

type Props = {
  title: string;
  children: ReactNode;
  goBackUrl?: string;
  onChevronClick?: () => void;
};

export const OnboardingLayout = ({
  title,
  onChevronClick,
  children,
}: Props) => {
  const { t } = useResource();

  useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  return (
    <Box overflow="hidden" display="flex">
      <StyledWrapper>
        <PageTitle title={title} onChevronClick={onChevronClick} />
        <StyledChildrenWrapper>
          <StyledPageWrapper>
            <Box height="100%" className="left-content">
              <PrimaryPaper sx={{ height: "100%", overflowX: "hidden" }}>
                {children}
              </PrimaryPaper>
            </Box>
          </StyledPageWrapper>
        </StyledChildrenWrapper>
        <Box
          display="flex"
          justifyContent="flex-start"
          m={({ spacing }) => spacing(3, 3, 3, 0)}
        >
          <LogoutButton />
        </Box>
      </StyledWrapper>
    </Box>
  );
};
