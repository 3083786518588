import {
  type InfiniteData,
  type QueryKey,
  useInfiniteQuery,
} from "@tanstack/react-query";
import {
  type EntityListOfTransactionItem,
  getTransactions,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

type Props = {
  limit?: number;
  nextPageLimit?: number;
};

type PageParams = {
  offset: number;
  limit: number;
};

export const useTransactions = ({ limit, nextPageLimit = 50 }: Props = {}) =>
  useInfiniteQuery<
    EntityListOfTransactionItem,
    unknown,
    InfiniteData<EntityListOfTransactionItem>,
    QueryKey,
    PageParams
  >({
    queryKey: ["TRANSACTIONS", { limit }],
    queryFn: processResponseWithApiProblemDetails(
      ({ pageParam: { limit, offset } }) => getTransactions(offset, limit),
    ),
    initialPageParam: { offset: 0, limit: limit ?? 10 },
    getNextPageParam: (lastPage: EntityListOfTransactionItem | null) => {
      if (!lastPage) {
        return undefined; //somehow we got lastPage as null, so I added a check for it, https://business-logic-sro.sentry.io/issues/6333395682
      }

      const isFinalPage =
        lastPage.offset + lastPage.limit >= lastPage.totalCount;

      if (isFinalPage) {
        return undefined;
      }

      return { offset: lastPage.offset + lastPage.limit, limit: nextPageLimit };
    },
  });
