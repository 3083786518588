import { Box, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { RiskIcon } from "Components/Shared/Icons";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources = Resources.Transactions.ErrorPayment;

export const FailedTransactionInfo = () => {
  const { t } = useResource();

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      color={Colors.Red}
    >
      <RiskIcon />
      <Typography variant="h5" my={1}>
        {t(ComponentResources.Title)}
      </Typography>
      <Typography lineHeight="28px" textAlign="center">
        {t(ComponentResources.Description)}
      </Typography>
    </Box>
  );
};
