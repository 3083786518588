import { useQuery } from "@tanstack/react-query";
import { getVerifications } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const VERIFICATIONS_QUERY_KEY = ["VERIFICATIONS"];

export const getVerificationsQueryKey = (userID: number) => [
  VERIFICATIONS_QUERY_KEY,
  { userID },
];

export const useVerificationsQuery = (userID?: number) =>
  useQuery({
    queryKey: userID
      ? getVerificationsQueryKey(userID)
      : [VERIFICATIONS_QUERY_KEY],
    queryFn: processResponseWithApiProblemDetails(() =>
      getVerifications(userID),
    ),
    refetchOnWindowFocus: false,
  });
