import { Typography } from "@mui/material";
import { useInviteLeadMutation } from "Api/Mutations/Lead/useLeadInvitationMutation";
import { LeadInvitationForm } from "Components/Account/LeadInvitationForm";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { LeadInvitationCommandResultStatus } from "Infrastructure/Api/Api";
import { getAppUrl } from "Utils/UrlUtils";
import { useState } from "react";

export const LeadInvitationPage = () => {
  const [leadInvitationStatus, setLeadInvitationStatus] =
    useState<LeadInvitationCommandResultStatus | null>(null);

  const { mutate: inviteLead, isPending: isLoading } = useInviteLeadMutation({
    onSettled: (status: LeadInvitationCommandResultStatus) => {
      setLeadInvitationStatus(status);
    },
  });

  const isFormShown =
    !leadInvitationStatus ||
    leadInvitationStatus === LeadInvitationCommandResultStatus.UserExists ||
    leadInvitationStatus === LeadInvitationCommandResultStatus.LeadExists;

  const isSuccessShown =
    leadInvitationStatus === LeadInvitationCommandResultStatus.Success;

  const appUrl = getAppUrl();

  return (
    <AuthenticatedLayout title="Pozvat přítele" goBackUrl={appUrl("affiliate")}>
      <StyledPageWrapper>
        <div className="left-content">
          <PrimaryPaper>
            {isFormShown && (
              <>
                <Typography>
                  Zadejte e-mail přítele, kterého chcete pozvat. Pokud bude Váš
                  přítel účet aktivně využívat, dostanete za něj odměnu!
                </Typography>
                <br />
                <LeadInvitationForm
                  isLoading={isLoading}
                  status={leadInvitationStatus}
                  onSubmit={({ email }) => inviteLead({ email })}
                />
              </>
            )}

            {isSuccessShown && (
              <Typography>Děkujeme. Váš přítel byl úspěšně pozván.</Typography>
            )}
          </PrimaryPaper>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default LeadInvitationPage;
