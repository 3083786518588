import { getAppUrl } from "Utils/UrlUtils";
import type * as React from "react";
import { Navigate, type RouteProps, useLocation } from "react-router-dom";

type Props = {
  code: string;
  userAccessRightCodes: string[] | undefined;
  fallbackUrl?: string;
} & RouteProps;

export const AuthorizedRoute: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props) => {
  const { code, userAccessRightCodes, children, fallbackUrl } = props;
  const isAuthorized =
    !!userAccessRightCodes && userAccessRightCodes.some((e) => e === code);
  const location = useLocation();
  const appUrl = getAppUrl();

  if (!isAuthorized) {
    return (
      <Navigate
        to={fallbackUrl ? fallbackUrl : appUrl("sign-in")}
        state={{ from: location }}
      />
    ) as any;
  }

  return (children ?? null) as any;
};
