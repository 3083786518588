import { useMutation } from "@tanstack/react-query";
import { BANKING_ACCOUNTS_QUERY_KEY } from "Api/Queries/useBankingAccounts";
import { queryClient } from "App";
import {
  type BankAccountListItem,
  type EntityListOfBankAccountListItem,
  putBankingAccountsRank,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const useBankingAccountsOrder = () => {
  return useMutation({
    mutationFn: async (items: BankAccountListItem[]) => {
      await queryClient.cancelQueries({ queryKey: BANKING_ACCOUNTS_QUERY_KEY });
      const accounts =
        queryClient.getQueryData<EntityListOfBankAccountListItem>(
          BANKING_ACCOUNTS_QUERY_KEY,
        );
      queryClient.setQueryData(BANKING_ACCOUNTS_QUERY_KEY, {
        ...accounts,
        items,
      });

      await processResponseWithApiProblemDetails(() =>
        putBankingAccountsRank({
          bankAccountIDs: items.map((i) => i.bankAccountID),
        }),
      )();
    },
    onSettled: (_, error) => {
      if (error) {
        queryClient.invalidateQueries({ queryKey: BANKING_ACCOUNTS_QUERY_KEY });
      }
    },
  });
};
