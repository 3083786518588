import {
  type GetUserFeeRateQueryResult,
  postInvoiceGetUserFeeRate,
} from "Api/Api";
import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const getUserFeeRateAsync = createAsyncAction(
  "@invoice/USER_FEE_RATE_REQUEST",
  "@invoice/USER_FEE_RATE_SUCCESS",
  "@invoice/USER_FEE_RATE_FAILURE",
)<void, GetUserFeeRateQueryResult, Error>();

function* getUserFeeRate(
  action: ReturnType<typeof getUserFeeRateAsync.request>,
): Generator {
  try {
    const response = yield* call(postInvoiceGetUserFeeRate);
    if (response.status === 200) {
      yield put(getUserFeeRateAsync.success(response.json));
    } else {
      yield put(
        getUserFeeRateAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getUserFeeRateAsync.failure(err as Error));
  }
}
export function* getUserFeeRateSaga() {
  yield takeLatest(getType(getUserFeeRateAsync.request), getUserFeeRate);
}
