import { useMutation, useQueryClient } from "@tanstack/react-query";
import { PARTY_LICENSE_QUERY_KEY } from "Api/Queries/Licenses/usePartyLicensesQuery";
import {
  type CreateLicenseCommandResult,
  type CreateLicenseRequest,
  type LicenseDto,
  postLicenses,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: (license: LicenseDto) => void;
  onError?: () => void;
};

export const useCreateLicenseMutation = ({
  onSuccess,
  onError,
}: Props = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      (data: CreateLicenseRequest) => postLicenses(data),
    ),
    onSuccess: (data: CreateLicenseCommandResult) => {
      queryClient.invalidateQueries({
        queryKey: [PARTY_LICENSE_QUERY_KEY],
      });
      onSuccess?.(data.license);
    },
    onError,
  });
};
