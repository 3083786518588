import type { AppUser } from "State/Auth/Models/AuthStateModels";

export type Page =
  | ""
  | "403"
  | "404"
  | "account-settings"
  | "account"
  | "affiliate"
  | "apple-redirect"
  | "awaiting-email-verification"
  | "awaiting-hunter-email-verification"
  | "bank-address-detail"
  | "bank-address-list"
  | "bankID-redirect"
  | "banking-auth"
  | "coinback-redirect"
  | "coinback"
  | "cold-wallet"
  | "contact"
  | "customer-payment-pay"
  | "customer-payments-callback"
  | "dashboard-sell"
  | "dashboard-withdraw"
  | "dashboard"
  | "deactivate-user"
  | "email-verification"
  | "faq-investment"
  | "faq-quick-exchange"
  | "faq-wage"
  | "faq"
  | "forgottenPassword"
  | "google-callback"
  | "google-redirect"
  | "help"
  | "initiate-payment-detail"
  | "initiate-payment"
  | "initiate-qr-payment"
  | "invite-lead"
  | "invoice-create"
  | "latest-pay-point-transaction"
  | "licenses"
  | "merchant-payment"
  | "notification-settings"
  | "onboarding"
  | "orderLicense"
  | "quick-exchange"
  | "rate-confirmation"
  | "redeem-public"
  | "redeem"
  | "registration-verification"
  | "sell"
  | "setPassword"
  | "settings"
  | "sign-in"
  | "sign-up-hunter"
  | "sign-up-merchant"
  | "sign-up"
  | "system-control"
  | "system"
  | "transaction-detail"
  | "transactions"
  | "user-detail"
  | "user-list"
  | "verification"
  | "welcome";

export const getAppUrl = (asRoutes = false) => {
  return (page: Page) => {
    switch (page) {
      case "403":
        return "/403";
      case "404":
        return "/404";
      case "account-settings":
        return "/account-settings";
      case "account":
        return "/account";
      case "affiliate":
        return "/account/affiliate";
      case "apple-redirect":
        return "/auth/apple";
      case "awaiting-email-verification":
        return "/awaiting-email-verification";
      case "awaiting-hunter-email-verification":
        return "/awaiting-hunter-email-verification";
      case "bank-address-detail":
        return `/bank-address${asRoutes ? "/:id" : ""}`;
      case "bank-address-list":
        return "/bank-address";
      case "bankID-redirect":
        return "/auth/bankID";
      case "banking-auth":
        return "/banking-auth";
      case "orderLicense":
        return "/licenses/order";
      case "coinback-redirect":
        return "/coinback/redirect";
      case "coinback":
        return "/coinback";
      case "cold-wallet":
        return "/cold-wallet";
      case "contact":
        return "/contact";
      case "customer-payment-pay":
        return `/pay${asRoutes ? "/:id?" : ""}`;
      case "dashboard-sell":
        return "/dashboard/sell";
      case "dashboard-withdraw":
        return "/dashboard/withdraw";
      case "dashboard":
        return "/dashboard";
      case "deactivate-user":
        return "/smazat-ucet";
      case "email-verification":
        return `/email-verification${asRoutes ? "/:token" : ""}`;
      case "faq-investment":
        return "/faq/investment";
      case "faq-quick-exchange":
        return "/faq/quick-exchange";
      case "faq-wage":
        return "/faq/wage";
      case "faq":
        return "/faq";
      case "forgottenPassword":
        return "/forgotten-password";
      case "google-callback":
        return "/auth/google/callback";
      case "google-redirect":
        return "/auth/google";
      case "help":
        return "/help";
      case "initiate-payment-detail":
        return `/merchant-payment/initiate${asRoutes ? "/:id" : ""}`;
      case "initiate-payment":
        return "/merchant-payment/initiate";
      case "initiate-qr-payment":
        return `/qr-payment/initiate${asRoutes ? "/:id" : ""}`;
      case "invite-lead":
        return "/account/invite-lead";
      case "invoice-create":
        return "/invoice/create";
      case "latest-pay-point-transaction":
        return `/ppt${asRoutes ? "/:id" : ""}`; //ppt as PayPointTransaction
      case "licenses":
        return "/licenses";
      case "merchant-payment":
        return "/merchant-payment";
      case "notification-settings":
        return "/notification-settings";
      case "onboarding":
        return "/onboarding";
      case "quick-exchange":
        return "/quick-exchange";
      case "rate-confirmation":
        return `/rate-confirmation${asRoutes ? "/:externalID" : ""}`;
      case "redeem-public":
        return "/mam-kartu";
      case "redeem":
        return "/karta";
      case "registration-verification":
        return `/registration-verification${asRoutes ? "/:token" : ""}`;
      case "sell":
        return "/sell";
      case "setPassword":
        return `/set-password${asRoutes ? "/:token" : ""}`;
      case "settings":
        return "/settings";
      case "sign-in":
        return "/sign-in";
      case "sign-up-hunter":
        return "/sign-up-hunter";
      case "sign-up-merchant":
        return "/sign-up-merchant";
      case "sign-up":
        return "/sign-up";
      case "system-control":
        return "/system-control";
      case "system":
        return "/system";
      case "transaction-detail":
        return `/transaction-detail${asRoutes ? "/:id" : ""}`;
      case "transactions":
        return "/transactions";
      case "user-detail":
        return `/users${asRoutes ? "/:id" : ""}`;
      case "user-list":
        return "/users";
      case "verification":
        return "/verification";
      case "welcome":
        return "/welcome";

      default:
        return "";
    }
  };
};

export const appUrl = getAppUrl();

export function resolveStartPage(
  appUrl: (page: Page) => string,
  user: AppUser | null,
  unauthenticatedUrl: string | null,
) {
  if (user === null) {
    return appUrl("sign-in");
  }

  if (unauthenticatedUrl) {
    return unauthenticatedUrl;
  }

  // if (user.isFirstSignIn) {
  //   return appUrl("welcome");
  // }

  return appUrl("dashboard");
}
