import { Grid } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { SettingsRowLink } from "Components/Settings/SettingsItem/SettingsRowLink";
import { MultiplyIcon, TrashBinIcon } from "Components/Shared/Icons";
import { PaperRow, PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useNavigationWithPreviousPage } from "Hooks/useNavigateBackManager";
import type { AppUser } from "State/Auth/Models/AuthStateModels";
import type { RootStateType } from "State/Store";
import { getAppUrl } from "Utils/UrlUtils";
import { useSelector } from "react-redux";

export const AccountPage = () => {
  const user = useSelector((e: RootStateType) => e.auth.user) as AppUser;
  const { previousPageUrl } = useNavigationWithPreviousPage();

  const appUrl = getAppUrl();

  return (
    <AuthenticatedLayout title="Profil" goBackUrl={previousPageUrl}>
      <StyledPageWrapper>
        <div className="left-content">
          {!!user && (
            <PrimaryPaper>
              <Grid container direction="column">
                <Grid container item direction="row" spacing={3}>
                  {!!user.firstName && (
                    <PaperRow left="Jméno" right={user.firstName} />
                  )}
                  {!!user.lastName && (
                    <PaperRow left="Příjmení" right={user.lastName} />
                  )}
                  <PaperRow left="E-mail" right={user.login} />
                </Grid>
              </Grid>
            </PrimaryPaper>
          )}
          <SettingsRowLink
            isDestructiveAction
            text="Smazat účet"
            url={appUrl("deactivate-user")}
            icon={<TrashBinIcon />}
            rightIcon={
              <MultiplyIcon
                style={{ marginLeft: "5px" }}
                width={12}
                height={12}
              />
            }
          />
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default AccountPage;
