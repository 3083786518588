import { CircularProgress } from "@mui/material";
import { useVerificationsQuery } from "Api/Queries/Verifications/useVerificationsQuery";
import { DocumentsUpload } from "Components/Onboarding/NaturalPerson/Steps/DocumentsStep/DocumentsUpload";
import { ExistingDocumentsInfo } from "Components/Onboarding/NaturalPerson/Steps/DocumentsStep/ExistingDocumentsInfo";
import { IdentityVerificationStatus } from "Infrastructure/Api/Api";

export const DocumentsStep = () => {
  const { data, isLoading, isError } = useVerificationsQuery();

  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError || !data) {
    return <>Chyba, nepodařilo se načíst dokumenty</>;
  }

  const hasUploadedDocuments =
    data.identityVerificationStatus === IdentityVerificationStatus.Processing;

  return hasUploadedDocuments ? <ExistingDocumentsInfo /> : <DocumentsUpload />;
};
