import { Box } from "@mui/material";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { PaymentStatusDropdown } from "Components/Users/UserDetailPageContent/Components/PaymentStatusDropdown";
import type { PartyLicenseDto } from "Infrastructure/Api/Api";
import type { Control } from "react-hook-form";

type LicenseFormFieldsProps = {
  control: Control<PartyLicenseDto>;
};

export const LicenseFormFields = ({ control }: LicenseFormFieldsProps) => (
  <Box
    component="fieldset"
    sx={{ all: "unset", display: "grid", width: "100%" }}
  >
    <FormInput control={control} name="code" label="Typ licence" disabled />
    <FormInput control={control} name="licenseNo" label="Číslo licence" />
    <PaymentStatusDropdown
      control={control}
      name="paymentStatus"
      label="Stav Platby"
    />
    <FormInput
      control={control}
      name="walletAddress"
      label="Adresa peněženky"
      disabled
    />
  </Box>
);
