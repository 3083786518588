import { Box, LinearProgress } from "@mui/material";
import styled from "styled-components";

const Progress = styled(LinearProgress)`
  margin-top: ${(props) => props.theme.spacing(10)};
`;

export const AuthRedirectProgress: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Progress />
      </Box>
    </>
  );
};
