import { Box, CircularProgress, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { UserIcon } from "Components/Shared/Icons";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import styled, { type DefaultTheme } from "styled-components";

type Props = {
  file: File | null;
  title: string;
  id?: string;
  description?: string;
  onChange: (file: File) => void;
  acceptFileName?: string;
  isLoading?: boolean;
  isError?: boolean;
};

const getBorderColor = (
  theme: DefaultTheme,
  isDragActive: boolean,
  isError?: boolean,
) => {
  if (isError) {
    return theme.colors.red;
  }

  return isDragActive ? theme.colors.darkGray : theme.colors.border;
};

const StyledWrapper = styled.div<{
  $isDragActive: boolean;
  $isError?: boolean;
}>`
  padding: ${(props) => props.theme.spacing(2, 3)};
  border: 1px dashed
    ${(props) => getBorderColor(props.theme, props.$isDragActive, props.$isError)};

  .label {
    font-weight: 700;
  }

  .file-names {
    font-weight: 300;
    font-size: 12px;
  }

  svg {
    width: 35px;
    height: auto;
    stroke-width: 20px;

    path {
      stroke-width: 1px;
    }
    #cloud {
      fill: #fff;
    }
  }
`;

export const DragNDrop: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props) => {
  const { onChange, title, description, id, isError } = props;
  const [file, setFile] = React.useState<File | null>(props.file);

  const acceptFiles = {
    "image/png": [".png"],
    "image/jpeg": [".jpeg", ".jpg"],
    "application/pdf": [".pdf"],
  };

  const onDrop = React.useCallback(
    (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0]);
      onChange(acceptedFiles[0]);
    },
    [onChange],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptFiles,
  });

  return (
    <StyledWrapper
      {...getRootProps()}
      $isDragActive={isDragActive}
      $isError={isError}
      tabIndex={-1}
    >
      <input {...getInputProps({ id })} />

      <Box display="flex" alignItems="center">
        <Box marginRight={4} lineHeight={0}>
          {props.isLoading ? (
            <CircularProgress size={21} color="secondary" />
          ) : (
            <UserIcon color={Colors.SecondaryText} height="36" width="36" />
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
          justifyContent="center"
          minWidth={0}
        >
          {file ? (
            <Typography noWrap fontWeight={600}>
              {file.name}
            </Typography>
          ) : (
            <div>
              <Typography fontWeight={500} fontSize={18} textAlign="center">
                {title}
              </Typography>
              {!!description && (
                <Typography textAlign="center">{description}</Typography>
              )}
            </div>
          )}
        </Box>
      </Box>
    </StyledWrapper>
  );
};
