import type { UserVerificationDetailDto } from "Api/Api";
import { postVerificationGetUserVerificationDetail } from "Api/Api";
import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const getUserVerificationDetailAsync = createAsyncAction(
  "@verification/GET_USER_VERIFICATION_DETAIL_REQUEST",
  "@verification/GET_USER_VERIFICATION_DETAIL_SUCCESS",
  "@verification/GET_USER_VERIFICATION_DETAIL_FAILURE",
)<void, UserVerificationDetailDto | null, Error>();

function* getUserVerificationDetail(
  action: ReturnType<typeof getUserVerificationDetailAsync.request>,
): Generator {
  try {
    const response = yield* call(postVerificationGetUserVerificationDetail);
    if (response.status === 200) {
      yield put(getUserVerificationDetailAsync.success(response.json));
    } else {
      yield put(
        getUserVerificationDetailAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getUserVerificationDetailAsync.failure(err as Error));
  }
}
export function* getUserVerificationDetailSaga() {
  yield takeLatest(
    getType(getUserVerificationDetailAsync.request),
    getUserVerificationDetail,
  );
}
