import { Box, Link, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";

export const Contacts = () => (
  <Box
    width="100%"
    px={4}
    pt={2}
    mx={[3, 30]}
    textAlign="center"
    color={Colors.TextLight}
  >
    <Typography>
      Partnery Legi.one jsou regulované finanční instituce UniCredit Bank Czech
      Republic and Slovakia a.s., Comgate a.s. a ZNPay a.s. a nadnárodní
      procesor bezhotovostních transakcí Aevi.
    </Typography>
    <Typography>
      Produkt Mo.one je založen na regulovaných platebních službách PIS a AIS, k
      jejichž poskytování byla společnosti ZNPay a.s. udělena licence platební
      instituce od České národní banky.
    </Typography>
    <Box display="flex" gap={1} width="100%" justifyContent="center">
      <Link
        href="mailto:info@legi.one"
        fontWeight={500}
        sx={{ textDecoration: "inherit" }}
      >
        info@legi.one
      </Link>
      |
      <Link
        href="tel:510510851"
        fontWeight={500}
        sx={{ textDecoration: "inherit" }}
      >
        +420 510 510 851
      </Link>
    </Box>
    <Typography mt={6}>© 2025 Legi.one | Všechna práva vyhrazena</Typography>
  </Box>
);
