import NotInterestedIcon from "@mui/icons-material/Error";
import ErrorIcon from "@mui/icons-material/Home";
import { Button, Typography } from "@mui/material";
import { Resources, useResource } from "Translations/Resources";
import type * as React from "react";
import styled from "styled-components";

const StyledPage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 25px;
`;

const StyledIcon = styled(NotInterestedIcon)`
  margin: 20px;
  font-size: 120px;
`;

type Props = {
  error: Error | null;
};

const ApplicationError: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props) => {
  const { error } = props;

  const { t } = useResource();

  const refresh = () => {
    window.location.href = "/";
  };

  return (
    <StyledPage>
      <Typography variant="h3">
        {t(Resources.Errors.ApplicationError.Title)}
      </Typography>
      <StyledIcon />
      <Typography variant="h4">
        Je nám líto, ale v aplikaci došlo k chybě.
      </Typography>
      <br />
      <Typography>
        Naši programátoři již o chybě byli informování a na opravě už pracují.
      </Typography>
      <br />

      <Button
        startIcon={<ErrorIcon />}
        size="large"
        variant="contained"
        color="primary"
        onClick={refresh}
      >
        {t(Resources.Errors.ApplicationError.Home)}
      </Button>

      <br />

      <Typography>Jste programátor? Koukněte v čem byla chyba.</Typography>

      <pre>
        <code>{JSON.stringify(error?.name)}</code>
      </pre>
      <pre>
        <code>{JSON.stringify(error?.message)}</code>
      </pre>
    </StyledPage>
  );
};

export { ApplicationError };
