import { useMenuLinks } from "Hooks/Layout/useMenuLinks";
import type { RootStateType } from "State/Store";
import type { ApplicationLink, UserType } from "Types/types";
import { filterByAccessRightCode } from "Utils/menuUtils";
import { useSelector } from "react-redux";

const EMPTY_MENU = {
  topMenu: [],
  bottomMenu: [],
};

type UseMenuReturn = {
  topMenu: Array<ApplicationLink>;
  bottomMenu: Array<ApplicationLink>;
};

export const useMenu = (userType: UserType | null): UseMenuReturn => {
  const currentUserAccessRightCodes =
    useSelector((e: RootStateType) => e.auth).user?.accessRightCodes || [];

  const {
    contact,
    generalHelp,
    hunterHelp,
    logout,
    overview,
    merchantPayment,
    transactions,
    bankAccounts,
    settings,
    users,
    licenses,
  } = useMenuLinks();

  const USER_TYPE_TO_MENU: Record<UserType, UseMenuReturn> = {
    admin: {
      topMenu: filterByAccessRightCode(
        [overview, users, settings],
        currentUserAccessRightCodes,
      ),
      bottomMenu: filterByAccessRightCode(
        [contact, generalHelp, logout],
        currentUserAccessRightCodes,
      ),
    },
    hunter: {
      topMenu: filterByAccessRightCode(
        [overview, licenses, transactions, bankAccounts, settings, users],
        currentUserAccessRightCodes,
      ),
      bottomMenu: filterByAccessRightCode(
        [contact, hunterHelp, logout],
        currentUserAccessRightCodes,
      ),
    },
    merchant: {
      topMenu: filterByAccessRightCode(
        [
          overview,
          merchantPayment,
          transactions,
          bankAccounts,
          settings,
          users,
        ],
        currentUserAccessRightCodes,
      ),
      bottomMenu: filterByAccessRightCode(
        [contact, generalHelp, logout],
        currentUserAccessRightCodes,
      ),
    },
    customer: {
      topMenu: filterByAccessRightCode(
        [
          overview,
          merchantPayment,
          transactions,
          bankAccounts,
          settings,
          users,
        ],
        currentUserAccessRightCodes,
      ),
      bottomMenu: filterByAccessRightCode(
        [contact, generalHelp, logout],
        currentUserAccessRightCodes,
      ),
    },
  };

  return userType ? USER_TYPE_TO_MENU[userType] : EMPTY_MENU;
};
