import { useLatestPayPointTransaction } from "Api/Queries/Transactions/useLatestPayPointTransaction";
import { getAppUrl } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const LatestPayPointTransaction: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const { id } = useParams();
  const { data } = useLatestPayPointTransaction(id);
  const navigate = useNavigate();
  const appUrl = getAppUrl();

  useEffect(() => {
    if (data) {
      navigate(`${appUrl("customer-payment-pay")}/${data.publicID}`);
    }
  }, [data, navigate, appUrl]);
  return null;
};

export default LatestPayPointTransaction;
