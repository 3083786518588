import { useMutation } from "@tanstack/react-query";
import type {
  FetchResponse,
  PayTransactionResponse,
  PostTransactionsPayFetchResponse,
} from "Infrastructure/Api/Api";
import { postTransactionsPay } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";
import { logError } from "Infrastructure/Utils/LoggingUtils";
import { toast } from "sonner";

const DEFAULT_ERROR_MESSAGE =
  "Při zpracování transakce se vyskytla neznámá chyba";

export type ErrorType = Exclude<
  PostTransactionsPayFetchResponse,
  FetchResponse<PayTransactionResponse, 200>
>; // all possible response types, except the 200

export const useTransactionPay = () =>
  useMutation({
    mutationFn: processResponseWithApiProblemDetails(postTransactionsPay),
    onSuccess: (data: PayTransactionResponse) => {
      if (data.signingUrl) {
        window.location.href = data.signingUrl;
      } else {
        toast.error("Neznámá chyba během platby");
      }
    },
    onError: undefined, // this disables the global handler from App.tsx (so we don't show toast 2x since we have custom error handling)
  });

export const handleTransactionPayError = (error: ErrorType) => {
  const message = getTransactionErrorMessage(error);
  const shouldShowSentryFeedbackDialog = error.status > 405 || !error.status;

  if (message === DEFAULT_ERROR_MESSAGE) {
    logError(new Error(message), error, shouldShowSentryFeedbackDialog);
  }

  toast.error(message);
};

export const getTransactionErrorMessage = ({ data, status }: ErrorType) => {
  const errors = data?.errors || {};

  if (status === 404) {
    return "Transakce neexistuje nebo je nedostupná";
  }

  //Balance Errors
  if (errors.accountBalance) {
    return "Nedostatečný zůstatek na účtu";
  }
  if (errors.balanceStatus) {
    return "Nepodařilo se ověřit zůstatek na účtu";
  }

  //Payment Errors
  if (errors.accountConflict) {
    return "Účet odesílatele a příjemce nemůže být stejný";
  }
  if (errors.accountInvalidCurrency) {
    return "Neplatná měna účtu";
  }
  if (errors.transactionInvalidCurrency) {
    return "Neplatná měna transakce";
  }
  if (errors.transactionForbidden) {
    return "Transakce pro tento typ účtu není povolena";
  }
  if (errors.limitExceeded) {
    return "Byl překročen limit transakcí";
  }
  if (errors.unspecifiedError) {
    return "Nastala nespecifikovaná chyba.";
  }

  if (status === 403) {
    return "Transakci se nepodařilo zpracovat, nebo již byla uhrazena";
  }

  //... add more errors keys if necessary

  return DEFAULT_ERROR_MESSAGE;
};
