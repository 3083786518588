import {
  type GetSystemSettingQuery,
  type SystemSettingDto,
  postSystemGetSystemSetting,
} from "Api/Api";
import { put } from "redux-saga/effects";
import { call, takeEvery } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const getSystemSettingAsync = createAsyncAction(
  "@system/GET_SYSTEM_SETTING_REQUEST",
  "@system/GET_SYSTEM_SETTING_SUCCESS",
  "@system/GET_SYSTEM_SETTING_FAILURE",
)<GetSystemSettingQuery, SystemSettingDto, Error>();

function* getSystemSetting(
  action: ReturnType<typeof getSystemSettingAsync.request>,
): Generator {
  try {
    const response = yield* call(postSystemGetSystemSetting, action.payload);
    if (response.status === 200) {
      yield put(getSystemSettingAsync.success(response.json));
    } else {
      yield put(
        getSystemSettingAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getSystemSettingAsync.failure(err as Error));
  }
}
export function* getSystemSettingSaga() {
  yield takeEvery(getType(getSystemSettingAsync.request), getSystemSetting);
}
