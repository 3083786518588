import type { CurrencyCode } from "Models/CurrencyCodes";
import { getCurrencySymbol } from "Utils/CurrencyUtils";

export const useCurrencySymbol = (currency?: CurrencyCode) => {
  if (!currency) {
    return null;
  }

  return getCurrencySymbol(currency);
};
