import { SharedBusinessFields } from "Components/Onboarding/BusinessEntity/Steps/IncomeInformationStep/SharedBusinessFields";
import type { IncomeInformationModel } from "Components/Onboarding/BusinessEntity/types";
import { PoliticallyExposedCheckbox } from "Components/Onboarding/Components/PoliticallyExposedCheckbox";
import { AverageTransactionValueDropdown } from "Components/Shared/SelectorsForm/AverageTransactionValueDropdown";
import { CountryDropdown } from "Components/Shared/SelectorsForm/CountryDropdown";
import { ExpectedMonthlyRevenueDropdown } from "Components/Shared/SelectorsForm/ExpectedMonthlyRevenueDropdown.";
import { RelationTypeDropdown } from "Components/Shared/SelectorsForm/RelationTypeDropdown";
import {
  type BusinessActivity,
  PartyType,
  RelationType,
  type SourceOfIncome,
} from "Infrastructure/Api/Api";
import type { Control } from "react-hook-form";

const ALLOWED_RELATION_TYPES: Array<RelationType> = [
  RelationType.BoardMember,
  RelationType.CooperativeChairman,
  RelationType.GeneralPartner,
  RelationType.HOAChairman,
  RelationType.HOACommitteeMember,
  RelationType.ManagingDirector,
  RelationType.Partner,
  RelationType.ProxyHolder,
  RelationType.SupervisoryBoardMember,
];

type Props = {
  control: Control<IncomeInformationModel>;
  partyType?: PartyType;
  incomeSource?: SourceOfIncome;
  businessActivity?: BusinessActivity;
  isPoliticallyExposed: boolean;
};

export const MerchantIncomeInformationFields = ({
  control,
  partyType,
  incomeSource,
  businessActivity,
  isPoliticallyExposed,
}: Props) => (
  <>
    <SharedBusinessFields
      control={control}
      businessActivity={businessActivity}
      incomeSource={incomeSource}
    />
    <ExpectedMonthlyRevenueDropdown
      control={control}
      name="expectedMonthlyRevenue"
      label="Očekávaný měsíční objem"
    />
    <AverageTransactionValueDropdown
      control={control}
      name="averageTransactionValue"
      label="Průměrná hodnota jedné transakce"
    />
    <CountryDropdown
      control={control}
      name="taxResidence"
      label="Daňová rezidence"
    />
    {partyType === PartyType.LegalEntity && (
      <RelationTypeDropdown
        control={control}
        name="relationType"
        label="Vaše pozice"
        allowedRelations={ALLOWED_RELATION_TYPES}
      />
    )}
    <PoliticallyExposedCheckbox
      control={control}
      name="isPoliticallyExposed"
      isChecked={isPoliticallyExposed}
    />
  </>
);
