import { Box, Skeleton, Stack } from "@mui/material";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { InitiatePaymentForm } from "Components/Merchant/Initiate/InitiatePaymentForm";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

type Props = {
  publicID?: string;
};

export const MerchantInitiateComponent = ({ publicID }: Props) => {
  const { data, isLoading } = useBankingAccounts();

  if (isLoading) {
    return (
      <PrimaryPaper>
        <Stack gap={2}>
          <Skeleton height={140} variant="rectangular" />
          <Skeleton height={140} variant="rectangular" />
          <Skeleton height={80} variant="rectangular" />
          <Skeleton variant="rectangular" height={58} sx={{ marginTop: 2 }} />
        </Stack>
      </PrimaryPaper>
    );
  }

  if (!data) {
    return <PrimaryPaper>Chyba - Nepodařilo se načíst Vaše účty</PrimaryPaper>;
  }

  const bankAccounts = data.items;

  const getSelectedBankAccountID = () => {
    let bankAccountID: number | undefined = undefined;

    if (publicID) {
      bankAccountID = bankAccounts.find(
        (account) => account.publicID === publicID,
      )?.bankAccountID;
    } else {
      bankAccountID = bankAccounts.sort((a, b) => b.rank - a.rank)?.[0]
        ?.bankAccountID;
    }

    return bankAccountID;
  };

  const defaultValues = {
    publicID,
    accountType: "bank" as const,
    bankAccountID: getSelectedBankAccountID(),
  };

  return (
    <PrimaryPaper>
      <InitiatePaymentForm
        bankAccounts={bankAccounts}
        defaultValues={defaultValues}
      />
    </PrimaryPaper>
  );
};
