import { useMenuLinks } from "Hooks/Layout/useMenuLinks";
import { useCurrentUserAccessRights } from "Hooks/useCurrentUserAccessRights";
import { useCurrentUserRole } from "Hooks/useCurrentUserAccessRole";
import type { ApplicationLink, UserType } from "Types/types";
import { filterByAccessRightCode } from "Utils/menuUtils";

type UseSettingsMenuReturn = Array<ApplicationLink>;

export const useSettingsMenu = (): UseSettingsMenuReturn => {
  const currentUserRole = useCurrentUserRole();
  const currentUserAccessRights = useCurrentUserAccessRights();

  const {
    bankAccounts,
    licenses,
    profile,
    invite,
    contact,
    generalHelp,
    hunterHelp,
    logout,
  } = useMenuLinks();

  const userTypeToMenuMap: Record<UserType, Array<ApplicationLink>> = {
    admin: filterByAccessRightCode(
      [profile, bankAccounts, contact, generalHelp, logout],
      currentUserAccessRights,
    ),
    hunter: filterByAccessRightCode(
      [profile, bankAccounts, invite, licenses, contact, hunterHelp, logout],
      currentUserAccessRights,
    ),
    merchant: filterByAccessRightCode(
      [profile, bankAccounts, contact, generalHelp, logout],
      currentUserAccessRights,
    ),
    customer: filterByAccessRightCode(
      [profile, bankAccounts, contact, generalHelp, logout],
      currentUserAccessRights,
    ),
  };

  return currentUserRole ? userTypeToMenuMap[currentUserRole] : [];
};
