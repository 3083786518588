import { useQueryClient } from "@tanstack/react-query";
import { useBankingAuthQuery } from "Api/Mutations/useBankingAuth";
import { BANKING_AUTHS_EXPIRATION_QUERY_KEY } from "Api/Queries/Banks/useGetBankingAuthExpiration";

export const useBankingAuth = () => {
  const queryClient = useQueryClient();

  const {
    isPending,
    mutate: authorize,
    variables: selectedEnvironment,
  } = useBankingAuthQuery({
    onAuthorizeUrl: ({ url }) => {
      window.location.href = url;

      queryClient.invalidateQueries({
        queryKey: BANKING_AUTHS_EXPIRATION_QUERY_KEY,
      });
    },
  });

  return {
    authorize,
    isPending,
    selectedEnvironment: selectedEnvironment?.environment,
  };
};
