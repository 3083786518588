import { Grid2, Stack, Typography } from "@mui/material";
import { AccountOrderEditor } from "Components/BankAccounts/BankAccountList/AccountOrderEditor/AccountOrderEditor";
import { AddAccountButton } from "Components/BankAccounts/BankAccountList/AddAccountButton";
import { BankAccountRowLink } from "Components/BankAccounts/BankAccountList/BankAccountRowLink/BankAccountRowLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { useState } from "react";

const PageResources = Resources.BankAccounts.List;

type Props = {
  accounts: Array<BankAccountListItem>;
};

export const BankAccountListPageContent = ({ accounts }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { t } = useResource();

  const hasNoAccount = accounts.length === 0;
  const hasMultipleAccounts = accounts.length > 1;

  if (hasNoAccount) {
    return (
      <>
        <Typography fontSize={16} fontWeight={600}>
          {t(PageResources.NoAccounts)}
        </Typography>
        <AddAccountButton />
      </>
    );
  }

  if (isEditing) {
    return (
      <>
        <Typography fontSize={16}>
          Pořadí účtů můžete změnit podržením a přetažením účtu na požadovanou
          pozici.
        </Typography>
        <AccountOrderEditor
          initialItems={accounts}
          onSave={() => setIsEditing(false)}
        />
      </>
    );
  }

  return (
    <>
      <Typography fontSize={16}>{t(PageResources.Description)}</Typography>
      <Stack gap={6}>
        <Stack gap={2}>
          {accounts.map((account) => (
            <BankAccountRowLink key={account.bankAccountID} account={account} />
          ))}
        </Stack>
        <Grid2 container spacing={4}>
          <Grid2 size={{ xs: 12, md: hasMultipleAccounts ? 7 : 12 }}>
            <AddAccountButton />
          </Grid2>
          {hasMultipleAccounts && (
            <Grid2 size={{ xs: 12, md: 5 }}>
              <PrimaryButton
                color="info"
                fullWidth
                variant="outlined"
                onClick={() => setIsEditing(true)}
                disabled={!accounts.length}
              >
                {t(PageResources.EditOrder)}
              </PrimaryButton>
            </Grid2>
          )}
        </Grid2>
      </Stack>
    </>
  );
};
