import { Typography } from "@mui/material";
import { useGetBankingAuthExpiration } from "Api/Queries/Banks/useGetBankingAuthExpiration";
import { ExpiringBanksList } from "Components/Banking/Auth/ExpiringBanksList";
import { ModalDialog } from "Components/Shared/Dialogs/ModalDialog";
import { useState } from "react";

const SESSION_STORAGE_KEY = "wasBankExpirationModalDismissed";

export const RevalidateBankingAuthModal = () => {
  const [switchToRerender, setSwitchToRerender] = useState<boolean>(false); //hackish way to rerender the component after updating session storage
  const { data: expiringBanks } = useGetBankingAuthExpiration();

  if (!expiringBanks || expiringBanks.length === 0) {
    return null;
  }

  const alreadyDismissedInCurrentSession =
    !!sessionStorage.getItem(SESSION_STORAGE_KEY);

  if (alreadyDismissedInCurrentSession) {
    return null;
  }

  const rerenderComponent = () => setSwitchToRerender(!switchToRerender);
  const onDismiss = () => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, "true");
    rerenderComponent();
  };

  return (
    <ModalDialog
      isOpen
      onClose={onDismiss}
      title="U některých z Vašich bank brzy vyprší ověření"
    >
      <Typography>
        Abyste mohli používat Moone jak jste zvyklí i nadále, prosíme Vás o
        jejich opětovné ověření.
      </Typography>
      <ExpiringBanksList environments={expiringBanks} />
    </ModalDialog>
  );
};
