import { type OfferDto, getCoinbackOffer } from "Api/Api";
import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const getOfferDetailAsync = createAsyncAction(
  "@coinback/OFFER_REQUEST",
  "@coinback/OFFER_SUCCESS",
  "@coinback/OFFER_FAILURE",
)<
  {
    webUrl: string;
  },
  OfferDto,
  Error
>();

function* getOfferDetail(
  action: ReturnType<typeof getOfferDetailAsync.request>,
): Generator {
  try {
    const response = yield* call(getCoinbackOffer, action.payload.webUrl);
    if (response.status === 200) {
      yield put(getOfferDetailAsync.success(response.json));
    } else {
      yield put(getOfferDetailAsync.failure(new Error()));
    }
  } catch (err) {
    yield put(getOfferDetailAsync.failure(err as Error));
  }
}
export function* getOfferDetailSaga() {
  yield takeLatest(getType(getOfferDetailAsync.request), getOfferDetail);
}
