import {
  type ActionType,
  IconSection,
} from "Components/Onboarding/BusinessEntity/Components/BanksList/BankButton/Components/IconSection";
import { ImageWithTitle } from "Components/Onboarding/BusinessEntity/Components/BanksList/BankButton/Components/ImageWithTitle";
import type { Bank } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import styled from "styled-components";

const StyledButton = styled("button")`${({ theme: { palette }, disabled }) => `
  all: unset;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  border: 1px solid ${palette.grey[200]};
  border-radius: 10px;
  overflow: hidden;
  opacity: ${disabled ? "0.45" : "1"};
  cursor: ${disabled ? "default" : "pointer"};
  background-color: ${disabled ? palette.action.hover : "inherit"};
  &:hover { background-color: ${palette.action.hover}; }
  &:focus-visible { border: 1px solid ${palette.primary.main}; }
  `}
`;

const BankNameResources = Resources.BankNames;

type Props = {
  bank: Bank | "Unknown";
  isDisabled?: boolean;
  customName?: string;
  isSandbox?: boolean;
  isLoading?: boolean;
  onClick?: () => void;
  actionType?: ActionType;
};

export const BankButton = ({
  isDisabled,
  bank,
  isSandbox,
  customName,
  onClick,
  isLoading,
  actionType,
}: Props) => {
  const { t } = useResource();

  const isButtonDisabled = isDisabled || isLoading;
  const bankName = customName || t(BankNameResources[bank]);

  return (
    <StyledButton onClick={onClick} disabled={isButtonDisabled} type="button">
      <ImageWithTitle
        bank={bank}
        title={bankName}
        isDisabled={isDisabled}
        isSandbox={isSandbox}
      />
      <IconSection
        isDisabled={isDisabled}
        isLoading={isLoading}
        actionType={actionType}
      />
    </StyledButton>
  );
};
