import { usePartySearchAresMutation } from "Api/Mutations/Party/usePartySearchAresMutation";
import type { PartyDto, PartyType } from "Infrastructure/Api/Api";
import { useEffect, useRef } from "react";

type Props = {
  initialIdentifierNumber?: string;
  identifierNumber?: string;
  partyType?: PartyType;
  onAresData: (aresData: PartyDto | null) => void;
  disabled?: boolean;
};

export const useAres = ({
  initialIdentifierNumber,
  identifierNumber,
  partyType,
  onAresData,
  disabled,
}: Props) => {
  const lastAres = useRef<string | undefined>(initialIdentifierNumber);
  const { mutate: getAres, isPending: isFetchingAres } =
    usePartySearchAresMutation({ onSuccess: onAresData });

  useEffect(() => {
    if (
      !disabled &&
      identifierNumber?.length === 8 &&
      !isFetchingAres &&
      lastAres.current !== identifierNumber
    ) {
      getAres({ partyType, query: identifierNumber });
    }

    lastAres.current = identifierNumber;
  }, [getAres, identifierNumber, isFetchingAres, partyType, disabled]);

  return {
    isFetchingAres,
  };
};
