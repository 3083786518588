import { zodResolver } from "@hookform/resolvers/zod";
import { CircularProgress, Typography } from "@mui/material";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { useVerificationsQuery } from "Api/Queries/Verifications/useVerificationsQuery";
import { useOnboardingContext } from "Components/Onboarding/BusinessEntity/Context/OnboardingContext";
import {
  OnboardingSteps,
  type PersonalDocumentModel,
} from "Components/Onboarding/BusinessEntity/types";
import { IdentityCardForms } from "Components/Onboarding/Components/IdentityCardForms";
import { ProcessingOcr } from "Components/Onboarding/Components/ProcessingOcr";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { BlFileInputForm } from "Components/Shared/Inputs/Form/BlFileInputForm";
import { ValuesBox } from "Components/Shared/ValuesBox/ValuesBox";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { IdentityVerificationStatus } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

const StepResources = Resources.Onboarding.Steps.Documents;

const ExistingPersonalDocumentsInfo = () => {
  const user = useLoggedInUser();
  const { data } = usePartyQuery(user?.partyPublicID);
  const { t } = useResource();
  const { nextStep } = useOnboardingContext();

  if (!data) {
    return null;
  }

  const handleSubmit = () => {
    nextStep(data);
  };

  return (
    <StepBox
      title={t(StepResources.LegalInformationBlock1)}
      subTitle={t(StepResources.LegalInformationBlock2)}
      submitButtonLabel={t(Resources.Common.Continue)}
      withDivider
      onSubmitClick={handleSubmit}
    >
      <Typography fontWeight="bold" fontSize="15px">
        Vaše doklady jsou již nahrány a probíhá jejich zpracování. Můžete
        pokračovat dalším krokem.
      </Typography>
    </StepBox>
  );
};

export const PersonalDocumentsStep = () => {
  const { data, isLoading, isError } = useVerificationsQuery();
  if (isLoading) {
    return <CircularProgress />;
  }

  if (isError || !data) {
    return <>Chyba, nepodařilo se načíst dokumenty</>;
  }

  const hasDocumentsUploaded =
    data.identityVerificationStatus === IdentityVerificationStatus.Processing;
  return hasDocumentsUploaded ? (
    <ExistingPersonalDocumentsInfo />
  ) : (
    <PersonalDocumentsStepContent />
  );
};

const PersonalDocumentsStepContent = () => {
  const { t } = useResource();
  const { getFormValues, nextStep, isLoadingNextStep } = useOnboardingContext();
  const [validationSchema] = useState<z.ZodType<PersonalDocumentModel>>(
    z.object({
      firstDocument: z.object({
        frontSide: z.instanceof(File, {
          message: t(StepResources.Validations.ForegroundMissing),
        }),
        backSide: z.instanceof(File, {
          message: t(StepResources.Validations.BackgroundMissing),
        }),
      }),
      secondDocument: z.object({
        frontSide: z.instanceof(File, {
          message: t(StepResources.Validations.SecondForegroundMissing),
        }),
      }),
    }),
  );

  const { control, handleSubmit } = useForm<PersonalDocumentModel>({
    defaultValues: getFormValues(OnboardingSteps.PersonalDocument),
    resolver: zodResolver(validationSchema),
  });

  const onSubmit = async (data: PersonalDocumentModel) => {
    nextStep(data);
  };

  if (isLoadingNextStep) {
    return <ProcessingOcr />;
  }

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        title={t(StepResources.LegalInformationBlock1)}
        subTitle={t(StepResources.LegalInformationBlock2)}
        submitButtonLabel={t(Resources.Common.Continue)}
        withDivider
      >
        <IdentityCardForms
          control={control}
          frontSideName="firstDocument.frontSide"
          backSideName="firstDocument.backSide"
        />

        <ValuesBox title={t(StepResources.SecondDocumentTitle)} withTopMargin>
          <Typography>{t(StepResources.SecondDocumentDescription)}</Typography>
          <BlFileInputForm
            control={control}
            label={t(StepResources.Forms.SecondDocumentFrontSide)}
            name="secondDocument.frontSide"
            title={t(StepResources.Forms.DocumentTitle)}
            description={t(StepResources.Forms.DocumentDescription)}
          />
        </ValuesBox>
      </StepBox>
    </FullHeightForm>
  );
};
