import {
  AdditionalInfoStatus,
  BankVerificationStatus,
  IdentityVerificationStatus,
  RiskProfileStatus,
  type UserVerificationDetailDto,
} from "Api/Api";

export function isNotFullyVerified(detail: UserVerificationDetailDto | null) {
  if (!detail) {
    return false;
  }

  const {
    additionalInfoStatus,
    riskProfile,
    identityVerificationStatus,
    bankVerificationStatus,
  } = detail;

  return (
    riskProfile === RiskProfileStatus.NotVerified ||
    additionalInfoStatus === AdditionalInfoStatus.NotVerified ||
    bankVerificationStatus === BankVerificationStatus.NotVerified ||
    identityVerificationStatus === IdentityVerificationStatus.NotVerified
  );
}

export function isFullyVerified(detail: UserVerificationDetailDto | null) {
  if (!detail) {
    return false;
  }

  const {
    additionalInfoStatus,
    riskProfile,
    identityVerificationStatus,
    bankVerificationStatus,
  } = detail;

  return (
    riskProfile === RiskProfileStatus.NoRisk &&
    additionalInfoStatus === AdditionalInfoStatus.Verified &&
    bankVerificationStatus === BankVerificationStatus.Verified &&
    identityVerificationStatus === IdentityVerificationStatus.Verified
  );
}
