import { zodResolver } from "@hookform/resolvers/zod";
import { EditValues } from "Components/Onboarding/Components/PersonalInformation/EditValues";
import { ViewValues } from "Components/Onboarding/Components/PersonalInformation/ViewValues";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { useUserDetailValidationSchema } from "Components/Onboarding/NaturalPerson/Hooks/useUserDetailValidationSchema";
import type { UserDetailModel } from "Components/Onboarding/types";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { AddressType } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

const StepResources = Resources.Onboarding.Steps.PersonalInformation;

type FormModel = {
  isUserConfirmed: boolean;
};

type Props = {
  initialValues: Partial<UserDetailModel>;
  onSave: (values: UserDetailModel) => void;
};

export const PersonalInformation = ({ initialValues, onSave }: Props) => {
  const { t } = useResource();
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState<Partial<UserDetailModel>>({
    ...initialValues,
    address: { ...initialValues.address, type: AddressType.Permanent },
  });
  const { userDetailValidationSchema } = useUserDetailValidationSchema();

  const validationSchema = z.object({
    isUserConfirmed: z.boolean().refine((value) => value, {
      message: t(StepResources.ConfirmDataRequest),
    }),
  });

  const { control, handleSubmit, watch } = useForm<FormModel>({
    resolver: zodResolver(validationSchema),
  });

  const isUserConfirmed = watch("isUserConfirmed");

  const onEdit = () => {
    setIsEdit(true);
  };

  const handleSave = (values: UserDetailModel) => {
    setValues(values);
    setIsEdit(false);
  };

  const onSubmit = () => {
    if (userDetailValidationSchema.safeParse(values).success) {
      onSave(values as UserDetailModel);
      return;
    }
    toast.error(t(Resources.Common.FillMissing));
  };

  if (isEdit) {
    return <EditValues onSave={handleSave} initialValues={values} />;
  }

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        title={t(StepResources.Title)}
        submitButtonLabel={t(Resources.Common.Continue)}
        submitDisabled={!isUserConfirmed}
        withDivider
      >
        <ViewValues onEdit={onEdit} userDetail={values} />
        <CheckboxInput
          label={t(StepResources.DataConfirmation)}
          control={control}
          name="isUserConfirmed"
        />
      </StepBox>
    </FullHeightForm>
  );
};
