import { Box } from "@mui/material";
import { useBankingAccountsSync } from "Api/Mutations/Accounts/useBankingAccountsSync";
import { BalanceWithPaymentActions } from "Components/Dashboard/BalanceWithPaymentActions/BalanceWithPaymentActions";
import { ConnectedAccounts } from "Components/Dashboard/ConnectedAccounts/ConnectedAccounts";
import { LimitedAccountPopup } from "Components/Dashboard/LimitedAccountPopup";
import { TransactionsComponent } from "Components/Dashboard/TransactionsComponent";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { BlDivider } from "Components/Shared/BlDivider";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { SignUpSubsidyPopup } from "Components/Voucher/SignUpSubsidyPopup";
import { useIsMobile } from "Hooks/useIsMobile";
import { CurrencyCode } from "Models/CurrencyCodes";
import { getRatesHistoryAsync } from "State/Rates/GerRatesHistoryState";
import { resetTransactionListPagination } from "State/Transactions/TransactionReducer";
import { subDays } from "date-fns";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const MerchantDashboardPage = () => {
  const { mutate: syncAccounts } = useBankingAccountsSync();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  useEffect(() => {
    return () => {
      dispatch(resetTransactionListPagination());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getRatesHistoryAsync.request({
        askCurrency: CurrencyCode.CZK,
        bidCurrency: CurrencyCode.BTC,
        startDate: subDays(new Date(), 3),
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    syncAccounts();
  }, [syncAccounts]);

  return (
    <AuthenticatedLayout
      title="Přehled"
      isTitleHiddenBelowLgScreen
      isPaddingDisabled={isMobile}
    >
      <StyledPageWrapper>
        <Box className="left-content">
          <Box
            mx={1}
            mb={6}
            display="flex"
            flexDirection="column"
            gap={isMobile ? 2 : 1}
          >
            <BalanceWithPaymentActions />
            <ConnectedAccounts />
            <LimitedAccountPopup />
            <BlDivider />
            <Box mx={2}>
              <TransactionsComponent />
            </Box>
            <SignUpSubsidyPopup />
            {/* <BitcoinTicker
            fiatPrice={btcRate?.askRate}
            fiatCurrency={CurrencyCode.CZK}
            rateHistory={rateHistory}
          /> */}
            {/*<DashboardPopup />*/}
            {/* <FeaturedList /> */}
          </Box>
        </Box>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default MerchantDashboardPage;
