import { Typography } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PlusMathIcon } from "Components/Shared/Icons";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { Resources, useResource } from "Translations/Resources";
import { getAppUrl } from "Utils/UrlUtils";
import styled from "styled-components";

const Button = styled(PrimaryButton)`
  margin-top: ${(props) => props.theme.spacing(2)};
  max-height: 52px;
  justify-content: flex-start;
  text-transform: none;
`;

const PageResources = Resources.Balance.NoAcount;

export const NoBankAccount = () => {
  const appUrl = getAppUrl();
  const { t } = useResource();

  return (
    <PrimaryPaper>
      <PaperTitle>{t(PageResources.Title)}</PaperTitle>
      <Typography marginTop={2} textAlign="left">
        {t(PageResources.Description)}
      </Typography>

      <UnstyledLink to={appUrl("banking-auth")}>
        <Button
          fullWidth
          color="primary"
          startIcon={<PlusMathIcon width={24} />}
        >
          {t(PageResources.ActionButton)}
        </Button>
      </UnstyledLink>
    </PrimaryPaper>
  );
};
