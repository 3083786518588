import { Typography } from "@mui/material";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import {
  OnboardingSteps,
  useOnboardingContext,
} from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import { getUserDetailFromOCRResult } from "Components/Onboarding/NaturalPerson/Utils/ocrUtils";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { Resources, useResource } from "Translations/Resources";

const StepResources = Resources.Onboarding.Steps.Documents;

export const ExistingDocumentsInfo = () => {
  const { t } = useResource();
  const user = useLoggedInUser();
  const { data } = usePartyQuery(user?.partyPublicID);
  const { nextStep, setFormValues } = useOnboardingContext();

  if (!data) {
    return null;
  }

  const handleSubmit = () => {
    setFormValues(OnboardingSteps.UserDetail, getUserDetailFromOCRResult(data));
    nextStep();
  };

  return (
    <StepBox
      title={t(StepResources.LegalInformationBlock1)}
      subTitle={t(StepResources.LegalInformationBlock2)}
      submitButtonLabel={t(Resources.Common.Continue)}
      withDivider
      onSubmitClick={handleSubmit}
    >
      <Typography fontWeight="bold" fontSize="15px">
        Vaše doklady jsou již nahrány a probíhá jejich zpracování. Můžete
        pokračovat dalším krokem.
      </Typography>
    </StepBox>
  );
};
