import { IdentityProvider } from "Api/Api";
import { AuthPageLayout } from "Components/Auth/Shared/AuthPageLayout";
import { AuthRedirectProgress } from "Components/Auth/Shared/AuthRedirectProgress";
import { StyledContainer } from "Components/Auth/Styles";
import { signInSsoAsync } from "State/Auth/SignIn/SignInSsoState";
import React from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

export const AppleRedirectPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const code = new URLSearchParams(location.search).get("code") || "";

  React.useEffect(() => {
    dispatch(
      signInSsoAsync.request({
        model: {
          token: code,
          identityProvider: IdentityProvider.Apple,
          affiliateCode: localStorage.getItem("affiliateCode"),
        },
        navigate,
      }),
    );
  }, [code, dispatch, navigate]);

  return (
    <AuthPageLayout title="Přesměrování" isLoading={false} pageType="other">
      <StyledContainer>
        <AuthRedirectProgress />
      </StyledContainer>
    </AuthPageLayout>
  );
};

export default AppleRedirectPage;
