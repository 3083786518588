import type { ColorsType } from "Components/Layout/Themes/Themes";

export const Colors: ColorsType = {
  PrimaryMain: "#006AFF",
  PrimaryDark: "#085FD9",
  PrimaryText: "#ffffff",
  SecondaryMain: "#65EE9C",
  SecondaryDark: "#65EE9C",
  SecondaryText: "#353539",
  Body: "#ffffff",
  Red: "#DD0606",
  RedDark: "#DA4242",
  DeleteRed: "#E02020",
  Warning: "#F7B500",
  Green: "#70f0a6",
  Text: "#353539",
  TextLight: "#69696E",
  Black: "#353539",
  White: "#ffffff",
  Gray: "#F9F9FA",
  DarkGrey: "#555555",
  Border: "#DDDDDD",
  BackgroundGrey: "#FAFAFA", //"#F5F5F5",
  Link: "#006AFF",
};
