import { HunterAffiliatePageContent } from "Components/Affiliate/HunterAffiliatePageContent";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useIsCurrentUserHunter } from "Hooks/useIsCurrentUserHunter";
import { useNavigationWithPreviousPage } from "Hooks/useNavigateBackManager";

export const AffiliatePage = () => {
  const isUserHunter = useIsCurrentUserHunter();
  const { previousPageUrl } = useNavigationWithPreviousPage();

  return (
    <AuthenticatedLayout title="Pozvat přítele" goBackUrl={previousPageUrl}>
      <StyledPageWrapper>
        <div className="left-content">
          {/* Currently only users with Hunter role will be let onto this page (see Routes.ts), later we can let more roles here and show different content for each role */}
          {isUserHunter ? <HunterAffiliatePageContent /> : "Coming soon"}
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default AffiliatePage;
