import { Box, Skeleton } from "@mui/material";
import { useIsMobile } from "Hooks/useIsMobile";

export const LicenseDetailSkeleton = () => {
  const isMobile = useIsMobile();

  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent={isMobile ? "center" : "space-between"}
      flexWrap="wrap-reverse"
      gap={3}
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={6}
        justifyContent="space-between"
      >
        {[1, 2, 3, 4, 5].map((i) => (
          <Box key={i}>
            <Skeleton height={21} width={100} />
            <Skeleton height={36} width={150} />
          </Box>
        ))}
      </Box>
      <Skeleton variant="rounded" height={425} width="45%" />
    </Box>
  );
};
