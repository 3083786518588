import { useMutation } from "@tanstack/react-query";
import { getAdminUserQueryKey } from "Api/Queries/User/useAdminUserDataQuery";
import { USERS_LIST_QUERY_KEY } from "Api/Queries/User/useUsersListQuery";
import { queryClient } from "App";
import {
  type UserAdminDetailDto,
  postUserAdminDetail,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: (parameters: UserAdminDetailDto) => void;
  onError?: (error: Error) => void;
};

export const useUpdateUserMutation = ({ onSuccess, onError }: Props = {}) =>
  useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      (data: UserAdminDetailDto) => postUserAdminDetail(data),
    ),
    onSuccess: (_, parameters: UserAdminDetailDto) => {
      queryClient.invalidateQueries({
        queryKey: getAdminUserQueryKey(parameters.userID),
      });
      queryClient.invalidateQueries({
        queryKey: [USERS_LIST_QUERY_KEY],
      });
      onSuccess?.(parameters);
    },
    onError: (e) => onError?.(e),
  });
