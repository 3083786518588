import { UnstyledButton } from "Components/Shared/Buttons/UnstyledButton";
import { LogoutRoundedIcon } from "Components/Shared/Icons";
import { resetUser } from "State/Auth/AuthReducer";
import { useDispatch } from "react-redux";

export const LogoutButton = () => {
  const dispatch = useDispatch();

  return (
    <UnstyledButton
      onClick={() => dispatch(resetUser({ isPageReloadDisabled: true }))}
      sx={{
        color: ({ palette }) => palette.info.main,
        display: "flex",
        alignItems: "center",
        gap: 1,
      }}
    >
      <LogoutRoundedIcon height={15} width={15} />
      Opustit registraci
    </UnstyledButton>
  );
};
