import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { usePartyQuery } from "Api/Queries/Party/usePartyQuery";
import { getPartyDataFromForm } from "Components/Onboarding/NaturalPerson/Utils/partyUtils";
import type { UserDetailModel } from "Components/Onboarding/types";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import {
  type ReactNode,
  createContext,
  useContext,
  useRef,
  useState,
} from "react";
import { toast } from "sonner";

export enum OnboardingSteps {
  PersonalDocument = 0,
  UserDetail = 1,
  LastStep = 2,
}

export type PersonalDocumentModel = {
  frontSide: File;
  backSide: File;
};

export type LastStepModel = {
  phone: string;
  politicallyExposed: boolean;
};

export type FormModel = {
  [OnboardingSteps.PersonalDocument]: PersonalDocumentModel;
  [OnboardingSteps.UserDetail]: UserDetailModel;
  [OnboardingSteps.LastStep]: LastStepModel;
};

type GetFormValuesInterface = <T extends OnboardingSteps>(
  step: T,
) => Partial<FormModel[T]> | undefined;

type OnboardingContextType = {
  step: OnboardingSteps;
  nextStep: () => void;
  prevStep: () => void;
  setFormValues: (step: OnboardingSteps, values: unknown) => void;
  getFormValues: GetFormValuesInterface;
  hasPrevStep: boolean;
};

const OnboardingContext = createContext<OnboardingContextType>(
  {} as OnboardingContextType,
);

export const OnboardingContextProvider = ({
  children,
}: { children: ReactNode }) => {
  const [step, setStep] = useState<OnboardingSteps>(
    OnboardingSteps.PersonalDocument,
  );
  const user = useLoggedInUser();
  const { data: apiPartyData } = usePartyQuery(user?.partyPublicID);
  const { mutate: updateParty } = usePartyMutation({
    onError: () => toast.error("Při ukládání údajů se vyskytla chyba"),
  });

  const formValues = useRef<Partial<FormModel>>({});

  const nextStep = () => {
    if (step === OnboardingSteps.LastStep) {
      return;
    }

    if (step === OnboardingSteps.UserDetail) {
      if (!user?.partyPublicID || !apiPartyData || !formValues.current) {
        return;
      }

      const partyData = getPartyDataFromForm(formValues.current, apiPartyData);
      updateParty({ data: partyData, publicID: user.partyPublicID });
    }

    setStep(step + 1);
  };

  const prevStep = () => {
    setStep((s) => Math.max(0, s - 1));
  };

  const getFormValues: GetFormValuesInterface = (step) =>
    formValues.current[step];
  const setFormValues = (step: OnboardingSteps, values: unknown) => {
    formValues.current = {
      ...formValues.current,
      [step]: values,
    };
  };

  return (
    <OnboardingContext.Provider
      value={{
        step,
        nextStep,
        prevStep,
        getFormValues,
        setFormValues,
        hasPrevStep: step > OnboardingSteps.PersonalDocument,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};

export const useOnboardingContext = () => useContext(OnboardingContext);
