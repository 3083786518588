import type { ApplicationLink } from "Types/types";

export const filterByAccessRightCode = (
  links: Array<ApplicationLink>,
  allowedAccessRightCodes: Array<string>,
) =>
  links.filter(
    ({ accessRightCode }) =>
      !accessRightCode ||
      allowedAccessRightCodes.some((code) => code === accessRightCode),
  );
