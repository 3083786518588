import { Box, Typography } from "@mui/material";
import { Balance } from "Components/Shared/Balance";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { ValueItems } from "Components/Shared/ValuesBox/ValueItems";
import { ValuesBox } from "Components/Shared/ValuesBox/ValuesBox";
import type { TransactionDetailDto2 } from "Infrastructure/Api/Api";
import type { FC } from "react";

type Props = {
  isLoading: boolean;
  isError: boolean;
  transactionDetail?: TransactionDetailDto2;
};

export const PayPaymentDetail: FC<Props> = ({
  isError,
  isLoading,
  transactionDetail,
}) => {
  const values = [
    // {
    //     label: "Prodávající",
    //     value: "TODO",
    // },
    {
      label: "Variabilní symbol",
      value: transactionDetail?.paymentReference,
    },
    {
      label: "Číslo protiúčtu",
      value: transactionDetail?.creditorIBAN,
    },
    {
      label: "Poznámka",
      value: transactionDetail?.noteForDebtor,
    },
  ];

  return (
    <Box marginBottom={6}>
      <PaperTitle>Detail platby</PaperTitle>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Typography fontWeight={400}>Částka</Typography>
        <Balance
          isLoading={isLoading}
          balance={transactionDetail?.amount ?? 0}
          isBigCurrency
        />
      </Box>
      <Box>
        <ValuesBox>
          <ValueItems values={values} />
        </ValuesBox>
      </Box>
    </Box>
  );
};
