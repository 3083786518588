import { Grid, Skeleton, Typography } from "@mui/material";
import { useTransactions } from "Api/Queries/Transactions/useTransactions";
import { TransactionItemSkeleton } from "Components/Shared/Transactions/TransactionItem";
import { TransactionItems } from "Components/Transactions/TransactionItems";
import type { TransactionItem as TransactionItemType } from "Infrastructure/Api/Api";
import type { FC, PropsWithChildren } from "react";
import styled from "styled-components";

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
`;

type Props = {
  limit?: number;
  isShowMoreShown?: boolean;
  isExportShown?: boolean;
};

const ClickableText = styled(Typography)`
  color: ${(props) => props.theme.palette.primary.main};
  cursor: pointer;
  font-weight: 600;
`;

export const TransactionListTable: FC<PropsWithChildren<Props>> = ({
  limit = 5,
  isShowMoreShown,
  isExportShown,
}) => {
  const {
    data: transactions,
    isLoading,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useTransactions({
    limit: 3,
  });

  const transactionItems =
    transactions?.pages?.flatMap(({ items }) => items) ?? [];

  return (
    <>
      <Grid
        container
        display="flex"
        justifyContent="space-between"
        alignItems="stretch"
      >
        <Grid item>
          <Typography fontWeight={600}>Transakce</Typography>
        </Grid>
        {isExportShown && (
          <Grid>
            <ClickableText
            //onClick={() => dispatch(downloadTransactionCsvAsync.request())}
            >
              Exportovat
            </ClickableText>
          </Grid>
        )}
      </Grid>
      {isLoading && !transactions ? (
        Array.from({ length: limit }).map((_, index) => (
          <TransactionItemSkeleton key={index} />
        ))
      ) : (
        <TransactionItems transactions={transactionItems ?? []} />
      )}

      {isFetchingNextPage &&
        Array.from({ length: 10 }).map((_, index) => (
          <Skeleton key={index} height={62} />
        ))}

      <ButtonWrapper>
        {isShowMoreShown && hasNextPage && !isFetchingNextPage && (
          <ClickableText onClick={() => fetchNextPage()}>
            Načíst další
          </ClickableText>
        )}
      </ButtonWrapper>
    </>
  );
};
