import { Box, ListItem, Skeleton, Stack, Typography } from "@mui/material";
import { useGetLeadListQuery } from "Api/Queries/Lead/useGetLeadListQuery";
import { translateStatus } from "Components/Affiliate/utils";
import { Colors } from "Components/Layout/Themes/Colors";
import type { ReactNode } from "react";

export const InvitationsList = () => {
  const { data: leadList, isLoading } = useGetLeadListQuery();

  if (isLoading) {
    return (
      <Layout>
        <Stack>
          <Skeleton width="100%" height={38} />
          <Skeleton width="100%" height={38} />
          <Skeleton width="100%" height={38} />
        </Stack>
      </Layout>
    );
  }

  if (!leadList) {
    return <Layout>Chyba, seznam pozvánek se nepodařilo načíst</Layout>;
  }

  if (leadList.items.length === 0) {
    return <Layout>Zatím jste neodeslali žádné pozvánky.</Layout>;
  }

  return (
    <Layout>
      {leadList.items.map(({ email, status }) => (
        <ListItem key={email} divider sx={{ px: 0 }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Typography>{email}</Typography>
            <Typography fontWeight={600}>{translateStatus(status)}</Typography>
          </Box>
        </ListItem>
      ))}
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => (
  <Box>
    <Typography fontWeight={600} color={Colors.DarkGrey} pb={1}>
      Vaše pozvánky
    </Typography>
    {children}
  </Box>
);
