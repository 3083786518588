import { Box, Typography } from "@mui/material";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { BanksList } from "Components/Onboarding/BusinessEntity/Components/BanksList/BanksList";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useBankingAuth } from "Hooks/useBankingAuth";
import type { BankEnvironment } from "Infrastructure/Api/Api";
import { resetTransactionListPagination } from "State/Transactions/TransactionReducer";
import { Resources, useResource } from "Translations/Resources";
import { appUrl } from "Utils/UrlUtils";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

const PageResources = Resources.Banking.Auth;

export const BankingAuthPage = () => {
  const dispatch = useDispatch();
  const { t } = useResource();

  const { isPending, authorize, selectedEnvironment } = useBankingAuth();

  useEffect(() => {
    return () => {
      dispatch(resetTransactionListPagination());
    };
  }, [dispatch]);

  const onBankSelected = (environment: BankEnvironment) => {
    if (!isPending) {
      authorize({ environment });
    }
  };

  return (
    <AuthenticatedLayout
      title={t(PageResources.Title)}
      goBackUrl={appUrl("bank-address-list")}
    >
      <StyledPageWrapper>
        <PrimaryPaper>
          <PaperTitle>Nabídka bank</PaperTitle>
          <Box mt={5} mb={2}>
            <Typography>{t(PageResources.Description)}</Typography>
          </Box>
          <BanksList
            actionType="add"
            onSelect={onBankSelected}
            pendingEnvironment={isPending ? selectedEnvironment : undefined}
          />
        </PrimaryPaper>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default BankingAuthPage;
