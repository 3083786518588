import MenuIcon from "@mui/icons-material/Menu";
import { Box, Drawer } from "@mui/material";
import { Avatar } from "Components/Layout/Avatar";
import { MenuItem } from "Components/Layout/Menu/MenuItem";
import { DefaultButton } from "Components/Shared/Buttons/DefaultButton";
import { PageTitleMobile } from "Components/Shared/PageTitles/PageTitleMobile";
import { useMenu } from "Hooks/Layout/useMenu";
import { useCurrentUserRole } from "Hooks/useCurrentUserAccessRole";
import type { ApplicationLink, UserType } from "Types/types";
import { useState } from "react";
import { useLocation } from "react-router";
import styled from "styled-components";

const drawerWidth = 310;

const StyledWrapper = styled.div``;

const StyledMenuIconWrapper = styled(Box)<{ $isVisible: boolean }>`
  position: absolute;
  left: 5px;
  top: 10px;
  z-index: 1;

  ${(props) => props.theme.breakpoints.down("md")} {
    ${(props) =>
      !props.$isVisible &&
      `
      display: none;
    `}
  }
`;

const StyledMenu = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: ${drawerWidth}px;
  height: ${(props) => props.theme.vh(100)};
  background-color: ${(props) => props.theme.colors.gray};
  display: grid;
  grid-template-rows: auto 1fr;
  overflow: auto;
  padding-left: ${(props) => props.theme.spacing(2)};
`;

const StyledList = styled.div`
  margin-top: ${(props) => props.theme.spacing(1)};
  display: flex;
  flex-direction: column;
`;

const StyledMenuIcon = styled(MenuIcon)`
  color: ${(props) => props.theme.palette.text.primary};
  width: 24px;
  height: auto;
`;

type Props = {
  isVisible: boolean;
  title: string;
  goBackUrl?: string;
  onChevronClick?: () => void;
};

const LinkItem = (props: ApplicationLink) => {
  const { pathname } = useLocation();

  if (props.emptySpaceSize) {
    return (
      <Box sx={{ height: `calc(var(--vh) * ${props.emptySpaceSize * 6})` }} />
    );
  }
  return <MenuItem item={props} pathname={pathname} />;
};

const MenuLinks = ({ userRole }: { userRole: UserType }) => {
  const { topMenu, bottomMenu } = useMenu(userRole);

  return (
    <StyledMenu>
      <Avatar />
      <StyledList>
        {topMenu.map((link, index) => (
          <LinkItem {...link} key={link.to || index} />
        ))}
      </StyledList>
      <StyledList>
        {bottomMenu.map((link, index) => (
          <LinkItem {...link} key={link.to || index} />
        ))}
      </StyledList>
    </StyledMenu>
  );
};

const Menu = ({ isVisible, title, goBackUrl, onChevronClick }: Props) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const userRole = useCurrentUserRole();

  if (!userRole) {
    return null;
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const container =
    window !== undefined ? () => window.document.body : undefined;

  return (
    <StyledWrapper>
      <StyledMenuIconWrapper
        $isVisible={isVisible}
        sx={{
          display: {
            sm: "block",
            md: "none",
          },
        }}
      >
        {!goBackUrl ? (
          <DefaultButton>
            <StyledMenuIcon onClick={handleDrawerToggle} />
            <PageTitleMobile title={title} />
          </DefaultButton>
        ) : (
          <PageTitleMobile
            title={title}
            goBackUrl={goBackUrl}
            onChevronClick={onChevronClick}
          />
        )}
      </StyledMenuIconWrapper>
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", md: "none" },
          border: "none",
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent",
          },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            border: "none",
            boxShadow: "none",
          },
        }}
      >
        <MenuLinks userRole={userRole} />
      </Drawer>
      <Drawer
        open
        variant="permanent"
        sx={{
          display: { xs: "none", md: "block" },
          height: "100%",
          border: "none",
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            position: "unset",
            border: "none",
            boxShadow: "none",
            backgroundColor: "transparent",
          },
        }}
      >
        <MenuLinks userRole={userRole} />
      </Drawer>
    </StyledWrapper>
  );
};

export { Menu };
