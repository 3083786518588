import { Box, Skeleton, Stack, Typography } from "@mui/material";
import { InvoiceQRCode } from "Components/Invoices/InvoiceQRCode";
import { BlDivider } from "Components/Shared/BlDivider";
import { Resources, useResource } from "Translations/Resources";
import { formatCurrency } from "Utils/CurrencyUtils";
import styled from "styled-components";

const Row = styled.div`
  display: grid;
  grid-template-columns: 0.8fr 1fr;
`;

const Left = styled(Typography)`
  font-size: 1rem;
  text-align: left;
`;

const Right = styled(Typography)`
  font-size: 1.125rem;
  font-weight: 500;
  justify-self: flex-end;
  word-break: break-word;
  text-align: end;
`;

const RowSkeleton = () => (
  <Row>
    <Left>
      <Skeleton width="60px" />
    </Left>
    <Right>
      <Skeleton width="125px" />
    </Right>
  </Row>
);

type Props = {
  isLoading?: boolean;
  iban?: string;
  amount?: number;
  accountNumber?: string;
  paymentReference?: string;
  note?: string;
  paymentCode?: string;
  paymentDescriptor?: string;
  qrInstructions?: string;
  isNoteMandatory?: boolean;
};

export const QrCodePayment = ({
  isLoading,
  iban,
  accountNumber,
  paymentReference,
  note,
  paymentCode,
  paymentDescriptor,
  amount,
  qrInstructions,
  isNoteMandatory,
}: Props) => {
  const { t } = useResource();

  if (isLoading) {
    return (
      <Stack gap={3}>
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
        <RowSkeleton />
      </Stack>
    );
  }

  return (
    <Stack>
      <Stack gap={3}>
        {iban && (
          <Row>
            <Left>{t(Resources.Common.IBAN)}</Left>
            <Right>{iban}</Right>
          </Row>
        )}

        {accountNumber && (
          <Row>
            <Left>{t(Resources.Common.AccountNumber)}</Left>
            <Right>{accountNumber}</Right>
          </Row>
        )}

        {paymentReference && (
          <Row>
            <Left>{t(Resources.Common.VariableSymbol)}</Left>
            <Right>{paymentReference}</Right>
          </Row>
        )}

        {amount && (
          <Row>
            <Left>{t(Resources.Common.Amount)}</Left>
            <Right>{formatCurrency(amount)}</Right>
          </Row>
        )}

        {note && (
          <Row>
            <Left>{t(Resources.Common.Note)}</Left>
            <Right>{note}</Right>
          </Row>
        )}

        {isNoteMandatory && (
          <Typography>
            {t(Resources.Common.MandatoryNoteDescription)}
          </Typography>
        )}

        {paymentCode && (
          <Row>
            <Left>{t(Resources.Common.TransactionPaymentCode)}</Left>
            <Right>{paymentCode}</Right>
          </Row>
        )}
      </Stack>
      {qrInstructions && (
        <>
          <BlDivider />
          <Typography textAlign="left">{qrInstructions}</Typography>
        </>
      )}

      {paymentDescriptor && (
        <Box mt={7}>
          <InvoiceQRCode paymentDescriptor={paymentDescriptor} size={200} />
        </Box>
      )}
    </Stack>
  );
};
