import { Box, Typography } from "@mui/material";
import { useUsersListQuery } from "Api/Queries/User/useUsersListQuery";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { UserListTable } from "Components/Users/UserListTable";
import { createLicensePaymentReferenceFromUserID } from "Components/Users/utils";
import type { ReactNode } from "react";

const ALL_USERS_PARAMS = {
  limit: 1000000, //hardcoding a milion, if we actually get over that, we'll have different problems to worry about anyways
  offset: 0,
};

export const UserListPage = () => {
  const { data, isLoading, isRefetching, isError } =
    useUsersListQuery(ALL_USERS_PARAMS);

  if (isError) {
    return (
      <Layout>
        <Typography color="error.main">
          Během načítání se objevil neočekávaný problém. Zkuste akci prosím
          opakovat později.
        </Typography>
      </Layout>
    );
  }

  const dataWithReference = (data?.items || []).map((row) => ({
    ...row,
    paymentReference: createLicensePaymentReferenceFromUserID(row.userID),
  }));

  return (
    <Layout>
      <UserListTable
        isLoading={isLoading}
        isRefetching={isRefetching}
        data={dataWithReference}
      />
    </Layout>
  );
};

const Layout = ({ children }: { children: ReactNode }) => (
  <AuthenticatedLayout title="Uživatelé">
    {/* override maxWidth, because we want the table wider than the default 600px limit of StyledPageWrapper */}
    <StyledPageWrapper maxWidth="unset !important">
      <Box display="grid" alignItems="start">
        {children}
      </Box>
    </StyledPageWrapper>
  </AuthenticatedLayout>
);

export default UserListPage;
