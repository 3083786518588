import { Button } from "@mui/material";
import type { RootStateType } from "State/Store";
import { downloadUserIdentificationZipAsync } from "State/Users/Detail/GetUserIdentificationZipState";
import { useDispatch, useSelector } from "react-redux";

type DownloadIdentificationsButtonProps = {
  login: string;
  userID: number;
};

export const DownloadIdentificationsButton = ({
  login,
  userID,
}: DownloadIdentificationsButtonProps) => {
  const dispatch = useDispatch();
  const { isZipDownloading } = useSelector(({ user }: RootStateType) => user); // TODO review, is the loading state needed in redux? maybe refactor with react query?

  return (
    <Button
      fullWidth
      sx={{ py: 4 }}
      color="info"
      variant="outlined"
      type="button"
      loading={isZipDownloading}
      onClick={() =>
        dispatch(downloadUserIdentificationZipAsync.request({ login, userID }))
      }
    >
      Stáhnout doklady
    </Button>
  );
};
