import { Box, Grid2, Stack, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import {
  MoneyBoxIcon,
  ShoppingCartIcon,
  VerificationBadgeIcon,
} from "Components/Shared/Icons";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";

export const HunterRoleExplanation = () => (
  <Stack gap={8}>
    <Typography variant="h2" textAlign="center">
      JAK FUNGUJE ROLE HUNTERA?
    </Typography>
    <Grid2 container spacing={8} sx={{ display: "flex" }}>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <PrimaryPaper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: 4,
          }}
        >
          <VerificationBadgeIcon width={50} height={50} color={Colors.PrimaryMain}/>
          <Typography variant="h6">1. Získáš licenci</Typography>
          <Typography>
            Vybereš si úroveň licence, projdeš úvodním školením a získáš přístup
            k platformě.
          </Typography>
        </PrimaryPaper>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <PrimaryPaper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: 4,
          }}
        >
          <ShoppingCartIcon color={Colors.PrimaryMain} />
          <Typography variant="h6">2. Pomáháš obchodníkům</Typography>
          <Typography>
            Představíš jim výhody přímých plateb, pomůžeš se zapojením, předáš
            terminál či pokladnu.
          </Typography>
        </PrimaryPaper>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 4 }}>
        <PrimaryPaper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: 4,
          }}
        >
          <MoneyBoxIcon color={Colors.PrimaryMain}/>
          <Typography variant="h6">3. Buduješ pravidelný příjem</Typography>
          <Typography>
            Z každé uskutečněné transakce získáváš malou provizi – den za dnem,
            obchod po obchodu.
          </Typography>
        </PrimaryPaper>
      </Grid2>
    </Grid2>

    <Grid2 container spacing={8} sx={{ display: "flex" }}>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <PrimaryPaper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: 4,
          }}
        >
          <Typography variant="h6">Příklad výdělku</Typography>
          <Typography sx={{ "> strong": { mx: 1 } }}>
            •<strong>50</strong>aktivních obchodníků = cca
            <strong>28 000 Kč měsíčně</strong>
            <br />•<strong>100</strong>obchodníků = až
            <strong>112 000 Kč měsíčně</strong>
          </Typography>
        </PrimaryPaper>
      </Grid2>
      <Grid2 size={{ xs: 12, md: 6 }}>
        <PrimaryPaper
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: 4,
          }}
        >
          <Typography variant="h6">Co získáš jako nový Hunter?</Typography>
          <Box
            component="ul"
            sx={{
              listStyleType: "none",
              p: 0,
              m: 0,
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: 2,
              fontWeight: 300,
            }}
          >
            <Box component="li">• Vstup do uzavřené sítě Legi.one</Box>
            <Box component="li">• Přístup k více než 10 hodinám školení</Box>
            <Box component="li">
              • Osobního mentora pro prvních 10 obchodníků
            </Box>
            <Box component="li">
              • Možnost vyzkoušet testovací licenci za 1 Kč
            </Box>
            <Box component="li">
              • Přístup na náš Discord a komunitní podporu
            </Box>
          </Box>
        </PrimaryPaper>
      </Grid2>
    </Grid2>

    <Typography textAlign="center">
      Licencí je omezený počet – 998 v ČR, 338 na Slovensku. Každý Hunter si
      může portfolio obchodníků udržet, rozšířit, nebo prodat.
    </Typography>
  </Stack>
);
