import { type AddressDto, AddressType } from "Infrastructure/Api/Api";
import { z } from "zod";

export const addressValidationSchema: z.ZodType<AddressDto> = z.object({
  type: z.nativeEnum(AddressType),
  addressID: z.number().nullish(),
  street: z.string().min(1),
  streetNumber: z.string().min(1),
  orientationNumber: z.string().nullish(),
  municipality: z.string().min(1),
  postalCode: z.string().nullish(),
});

export const businessAddressValidationSchema: z.ZodType<AddressDto> = z.object({
  type: z.nativeEnum(AddressType),
  addressID: z.number().nullish(),
  street: z.string().nullish(),
  streetNumber: z.string().nullish(),
  orientationNumber: z.string().nullish(),
  municipality: z.string().nullish(),
  postalCode: z.string().nullish(),
});
