import { AverageTransactionValue } from "Infrastructure/Api/Api";
import type { CodeListItem } from "Models/ICodeListDto";
import { Resources, useResource } from "Translations/Resources";
import { useMemo } from "react";

type ListItem = CodeListItem<AverageTransactionValue>;
type Result = ListItem[];

export const useAverageTransactionValueList = () => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      Object.entries(AverageTransactionValue).map<ListItem>(([key]) => ({
        code: key as AverageTransactionValue,
        name: t(
          Resources.Form.AverageTransactionValue[
            key as AverageTransactionValue
          ],
        ),
      })),
    [t],
  );
};
