import type {
  AddWithdrawalToQueueCommandResult,
  GetBalanceQueryResult,
  SellCommandResult,
  SellRequest,
  WithdrawalRequest,
} from "Api/Api";
import type { NavigateFunction } from "react-router-dom";
import {
  type ActionType,
  createAction,
  createAsyncAction,
  createReducer,
} from "typesafe-actions";

type WithdrawalStep = "form" | "success";

type BalanceState = {
  balance: GetBalanceQueryResult | null;
  isBalanceLoading: boolean;
  isWithdrawing: boolean;
  withdrawalStep: WithdrawalStep;
};

export const getBalanceAsync = createAsyncAction(
  "@balance/GET_REQUEST",
  "@balance/GET_SUCCESS",
  "@balance/GET_FAILURE",
)<void, GetBalanceQueryResult, Error>();

export const withdrawAsync = createAsyncAction(
  "@balance/WITHDRAW_REQUEST",
  "@balance/WITHDRAW_SUCCESS",
  "@balance/WITHDRAW_FAILURE",
)<WithdrawalRequest, AddWithdrawalToQueueCommandResult, Error>();

export const sellAsync = createAsyncAction(
  "@balance/SELL_REQUEST",
  "@balance/SELL_SUCCESS",
  "@balance/SELL_FAILURE",
)<
  { request: SellRequest; navigate: NavigateFunction },
  SellCommandResult,
  Error
>();

export const setWithdrawalStep = createAction(
  "@balance/SET_WITHDRAWAL_STEP",
)<WithdrawalStep>();

type BalanceAction =
  | ActionType<typeof setWithdrawalStep>
  | ActionType<typeof getBalanceAsync>
  | ActionType<typeof withdrawAsync>;

export const balanceReducer = createReducer<BalanceState, BalanceAction>({
  balance: null,
  isBalanceLoading: false,
  isWithdrawing: false,
  withdrawalStep: "form",
})
  .handleAction(getBalanceAsync.request, (state) => {
    return { ...state, isBalanceLoading: true };
  })
  .handleAction(getBalanceAsync.success, (state, action) => {
    return { ...state, isBalanceLoading: false, balance: action.payload };
  })
  .handleAction(getBalanceAsync.failure, (state, _action) => {
    return {
      ...state,
      isBalanceLoading: false,
    };
  })
  .handleAction(withdrawAsync.request, (state) => {
    return { ...state, isWithdrawing: true };
  })
  .handleAction(withdrawAsync.success, (state, _action) => {
    return { ...state, isWithdrawing: false };
  })
  .handleAction(withdrawAsync.failure, (state, _action) => {
    return {
      ...state,
      isWithdrawing: false,
    };
  })
  .handleAction(setWithdrawalStep, (state, action) => {
    return { ...state, withdrawalStep: action.payload };
  });
