import { DialogActions } from "@mui/material";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { ModalDialog } from "Components/Shared/Dialogs/ModalDialog";
import { Resources, useResource } from "Translations/Resources";
import type { ReactNode } from "react";

type Props = {
  isOpen: boolean;
  handleResult: (wasConfirmed: boolean) => void;
  modalTitle: string;
  modalContent: ReactNode;
  confirmButtonText?: string;
  isDestructive?: boolean;
};

export const ConfirmDialog = ({
  modalContent,
  modalTitle,
  confirmButtonText,
  handleResult,
  isOpen,
  isDestructive,
}: Props) => {
  const { t } = useResource();

  const closeDialog = () => handleResult(false);

  return (
    <ModalDialog isOpen={isOpen} onClose={closeDialog} title={modalTitle}>
      {modalContent}
      <DialogActions sx={{ px: 0 }}>
        <PrimaryButton
          size="small"
          variant="text"
          onClick={() => handleResult(true)}
          color={isDestructive ? "error" : "info"}
        >
          {confirmButtonText ?? t(Resources.Common.Confirm)}
        </PrimaryButton>
        <PrimaryButton onClick={closeDialog} color="info" size="small">
          {t(Resources.Common.Cancel)}
        </PrimaryButton>
      </DialogActions>
    </ModalDialog>
  );
};
