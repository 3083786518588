import { useQuery } from "@tanstack/react-query";
import {
  type TransactionStatus2,
  getTransactionsSum,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

type Props = {
  startDate?: Date;
  endDate?: Date;
  allowedStatuses?: TransactionStatus2[];
};

export const useTransactionsSum = ({
  startDate,
  endDate,
  allowedStatuses,
}: Props = {}) =>
  useQuery({
    queryKey: ["TRANSACTIONS_SUM", { startDate, endDate, allowedStatuses }],
    queryFn: processResponseWithApiProblemDetails(() =>
      getTransactionsSum(
        startDate?.toString(),
        endDate?.toString(),
        allowedStatuses,
      ),
    ),
    refetchInterval: 30_000,
  });
