import {
  type ApplyVoucherCommandResult,
  ApplyVoucherCommandResultStatus,
  type ApplyVoucherRequest,
  type ValidateVoucherCommand,
  ValidateVoucherCommandResultStatus,
  postVoucherApply,
  postVoucherValidate,
} from "Api/Api";
import {
  type PasswordlessSignUpCommand,
  type SignInResult,
  SignInStatus,
  postAuthSignUpPasswordless,
} from "Infrastructure/Api/Api";
import { signInAsync } from "State/Auth/SignIn/SignInState";
import { getCodeListCollectionAsync } from "State/CodeLists/CodeListReducer";
import {
  RecaptchaStatus,
  setRecaptchaStatus,
} from "State/Voucher/VoucherReducer";
import { AppHeaders, initHeaders } from "Utils/HeaderUtils";
import { setToken } from "Utils/LocalStorageUtils";
import { trackUserIdentification } from "Utils/TrackingUtils";
import type { NavigateFunction } from "react-router";
import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const applyVoucherAsync = createAsyncAction(
  "@voucher/APPLY_REQUEST",
  "@voucher/APPLY_SUCCESS",
  "@voucher/APPLY_FAILURE",
)<
  {
    request: ApplyVoucherRequest;
    validationToken: string;
    applyToken: string;
    navigate: NavigateFunction;
  },
  ApplyVoucherCommandResult,
  Error
>();

function* applyVoucher(
  action: ReturnType<typeof applyVoucherAsync.request>,
): Generator {
  try {
    const validationResponse = yield* call(
      postVoucherValidate,
      {
        code: action.payload.request.code,
      } as ValidateVoucherCommand,
      initHeaders(
        AppHeaders.RecaptchaToken,
        action.payload.validationToken || "",
      ),
    );

    if (
      validationResponse.json.status !==
      ValidateVoucherCommandResultStatus.Valid
    ) {
      yield put(
        applyVoucherAsync.success({
          status: ApplyVoucherCommandResultStatus.NotFound,
        }),
      );
      return;
    }

    const signUpResponse = yield* call(postAuthSignUpPasswordless, {
      login: action.payload.request.login,
    } as PasswordlessSignUpCommand);

    if (signUpResponse.status === 200) {
      const signInResult = signUpResponse.data.signInResult as SignInResult;
      if (!!signInResult && signInResult.status === SignInStatus.Success) {
        setToken(signInResult);

        yield put(getCodeListCollectionAsync.request());
        trackUserIdentification();

        yield put(signInAsync.success(signInResult));
      }
    }

    const response = yield* call(
      postVoucherApply,
      action.payload.request,
      initHeaders(AppHeaders.RecaptchaToken, action.payload.applyToken || ""),
    );
    if (response.status === 200) {
      yield put(applyVoucherAsync.success(response.json));
    } else if (response.status === 403) {
      yield put(setRecaptchaStatus(RecaptchaStatus.Invalid));
    } else {
      yield put(
        applyVoucherAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(applyVoucherAsync.failure(err as Error));
  }
}
export function* applyVoucherSaga() {
  yield takeLatest(getType(applyVoucherAsync.request), applyVoucher);
}
