import { LicenseCountryCode } from "Components/Onboarding/BusinessEntity/types";
import { LicenseCode } from "Infrastructure/Api/Api";
import { z } from "zod";

export const schema = z
  .object({
    license: z.nativeEnum(LicenseCode),
    countryAlpha2Code: z.nativeEnum(LicenseCountryCode),
    isUsingPrivateWallet: z.boolean().optional(),
    privateWalletAddress: z.string().optional(),
  })
  .refine(
    (data) => {
      // If isUsingPrivateWallet is checked, privateWalletAddress must not be empty
      return (
        !data.isUsingPrivateWallet ||
        (data.privateWalletAddress &&
          data.privateWalletAddress.trim().length > 0)
      );
    },
    {
      message: "U vlastní peněženky musíte vyplnit její adresu.",
      path: ["privateWalletAddress"],
    },
  );
