﻿import { useMutation } from "@tanstack/react-query";
import {
  type ApiProblemDetails,
  type FetchResponse,
  type InviteLeadRequest,
  type LeadInvitationCommandResult,
  LeadInvitationCommandResultStatus,
  postLeadsInvite,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSettled?: (data: LeadInvitationCommandResultStatus) => void;
};

export const useInviteLeadMutation = ({ onSettled }: Props = {}) => {
  return useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      (data: InviteLeadRequest) => postLeadsInvite(data),
    ),
    onSettled: (
      data: LeadInvitationCommandResult | undefined,
      error: FetchResponse<ApiProblemDetails, 409> | null,
    ) => {
      if (data) {
        onSettled?.(data.status);
        return;
      }

      if (!error) {
        return;
      }

      if (error.data.status !== 409) {
        onSettled?.(LeadInvitationCommandResultStatus.Error);
        return;
      }

      if (error.data.errors.User) {
        onSettled?.(LeadInvitationCommandResultStatus.UserExists);
        return;
      }

      if (error.data.errors.Lead) {
        onSettled?.(LeadInvitationCommandResultStatus.LeadExists);
        return;
      }
    },
  });
};
