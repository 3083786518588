import { Paper, Typography } from "@mui/material";
import { AuthButtons } from "Components/Auth/Shared/AuthButtons";
import { AuthRedirectProgress } from "Components/Auth/Shared/AuthRedirectProgress";
import {
  AuthFooterSection,
  AuthHeader,
  AuthPage,
  AuthPageContent,
} from "Components/Auth/Shared/AuthStyledComponents";
import { UnauthenticatedHeader } from "Components/Layout/UnauthenticatedHeader";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import type { RootStateType } from "State/Store";
import type { UserType } from "Types/types";
import { getAppUrl } from "Utils/UrlUtils";
import type { ReactNode } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";

type Props = {
  title: string;
  isLoading: boolean;
  pageType: "sign-up" | "sign-in" | "other";
  children: ReactNode;
  userType?: UserType;
};

const StyledPaper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(7, 2)};
  border-radius: 10px;
`;

export const AuthPageLayout = ({
  children,
  isLoading,
  pageType,
  title,
  userType,
}: Props) => {
  const ssoIsLoading = useSelector((e: RootStateType) => e.auth.ssoIsLoading);

  const appUrl = getAppUrl();

  return (
    <UnauthenticatedLayout title={title}>
      <AuthPage>
        <AuthPageContent>
          <div>
            <UnauthenticatedHeader userType={userType} />
          </div>
          <StyledPaper>
            <AuthHeader variant="h2">{title}</AuthHeader>

            {ssoIsLoading === true ? (
              <>
                <AuthRedirectProgress />
              </>
            ) : (
              <>
                {children}
                {pageType !== "other" && (
                  <>
                    {/* <AuthDivider>
                        <Typography paddingX="10px">nebo</Typography>
                      </AuthDivider> */}

                    <AuthButtons isLoading={isLoading} />
                  </>
                )}
              </>
            )}
          </StyledPaper>

          <AuthFooterSection
            alignItems="center"
            gap="6px"
            justifyContent="center"
          >
            <Typography>
              {pageType !== "sign-in"
                ? "Už máte účet?"
                : "Ještě nemáte svůj účet?"}
            </Typography>

            <UnstyledLink
              to={appUrl(pageType !== "sign-in" ? "sign-in" : "sign-up")}
            >
              {pageType !== "sign-in" ? "Přihlaste se" : "Zaregistrujte se"}
            </UnstyledLink>
          </AuthFooterSection>
        </AuthPageContent>
      </AuthPage>
    </UnauthenticatedLayout>
  );
};
