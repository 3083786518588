import { Box, Skeleton, Stack } from "@mui/material";
import { BlDivider } from "Components/Shared/BlDivider";

export const QRPaymentSkeleton = () => (
  <Stack gap={4}>
    <RowSkeleton />
    <RowSkeleton />
    <RowSkeleton />
    <RowSkeleton />
    <BlDivider />
    <Skeleton
      width={200}
      height={200}
      variant="rounded"
      sx={{ alignSelf: "center" }}
    />
  </Stack>
);

const RowSkeleton = () => (
  <Box display="flex" justifyContent="space-between">
    <Skeleton width="30%" />
    <Skeleton width="50%" />
  </Box>
);
