import { Box, Button, Divider, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { FormItemsBox } from "Components/Shared/FormItemsBox";
import type { FC, PropsWithChildren } from "react";

type Props = {
  title: string;
  onEdit?: () => void;
  hideDivider?: boolean;
};

export const ValuesBox: FC<PropsWithChildren<Props>> = ({
  children,
  onEdit,
  title,
  hideDivider,
}) => {
  return (
    <>
      <FormItemsBox>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography fontSize={16} fontWeight={600} textTransform="uppercase">
            {title}
          </Typography>
          {!!onEdit && (
            <Button
              variant="text"
              onClick={onEdit}
              sx={{
                fontSize: "14px",
                fontWeight: 600,
                color: Colors.Link,
                padding: 0,
                textTransform: "none",
              }}
            >
              Upravit
            </Button>
          )}
        </Box>
        {children}
      </FormItemsBox>
      {!hideDivider && <Divider sx={{ marginY: 4 }} />}
    </>
  );
};
