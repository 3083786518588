import { useTransactionInvoiceSend } from "Api/Mutations/Transactions/useTransactionInvoiceSend";
import { EmailForm } from "Components/Transactions/Anonymous/EmailForm";

// const ComponentResources = Resources.Transactions;

type Props = { transactionPublicID: string; onEmailSent: () => void };

export const EmailOrDownloadInvoiceForm = ({
  transactionPublicID,
  onEmailSent,
}: Props) => {
  // const { t } = useResource();
  const { mutate: sendEmailInvoice, isPending } = useTransactionInvoiceSend({
    onSuccess: onEmailSent,
  });

  const handleReceiptSubmission = (email: string) => {
    sendEmailInvoice({ transactionPublicID, email });
  };

  // const onReceiptDownload = () => {}; //TODO: implement receipt download

  return (
    <>
      <EmailForm onSubmit={handleReceiptSubmission} isPending={isPending} />
      {/* <BlDivider text="nebo" />
      <Box display="flex" justifyContent="center">
        <BlTextButton onClick={onReceiptDownload}>
          {t(ComponentResources.Anonymous.DownloadReceipt)}
        </BlTextButton>
      </Box> */}
    </>
  );
};
