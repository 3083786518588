import { Box, Typography } from "@mui/material";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import type { FC } from "react";

type Props = {
  withoutPaper?: boolean;
  paddingY?: number;
  message?: string;
};

export const CommonError: FC<Props> = ({
  withoutPaper,
  paddingY = 3,
  message,
}) => {
  const messageContent = (
    <Typography color="red">
      {message ||
        "To nás velmi mrzí. Během načítání se objevil neočekávaný problém. Zkuste akci, prosím, později."}
    </Typography>
  );

  if (withoutPaper) {
    return <Box paddingY={paddingY}>{messageContent}</Box>;
  }

  return <PrimaryPaper>{messageContent}</PrimaryPaper>;
};
