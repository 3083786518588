import { Box } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import { LicenseDetail } from "Components/Licenses/LicensesPageContent/LicenseDetail";
import { LicenseOrderActions } from "Components/Licenses/LicensesPageContent/LicenseOrderActions";
import { BlDivider } from "Components/Shared/BlDivider";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import {
  LicensePaymentStatus,
  type PartyLicenseDto,
} from "Infrastructure/Api/Api";

type Props = {
  license: PartyLicenseDto;
};

export const LicenseDetailWithPaymentActions = ({ license }: Props) => {
  const isInactive = license.paymentStatus !== LicensePaymentStatus.Success;

  return (
    <PrimaryPaper
      sx={{ border: isInactive ? `1px solid ${Colors.Red}` : "inherit" }}
    >
      <LicenseDetail license={license} />
      {isInactive && (
        <>
          <BlDivider />
          <Box display="flex" flexDirection="column" gap={2}>
            <LicenseOrderActions
              transactionPublicID={license.transactionPublicID}
              licensePublicID={license.publicID}
            />
          </Box>
        </>
      )}
    </PrimaryPaper>
  );
};
