import type {
  EntityListOfTransactionListItemDto,
  GetTransactionDetailQueryResult,
  TransactionListItemDto,
} from "Api/Api";
import {
  getTransactionDetailAsync,
  resetTransactionDetail,
} from "State/Transactions/Detail/TransactionDetailState";
import type { downloadTransactionCsvAsync } from "State/Transactions/DownloadCsv/DownloadTransactionCsvState";
import { getTransactionListAsync } from "State/Transactions/List/GetTransactionListState";
import { type ActionType, createAction, createReducer } from "typesafe-actions";

type TransactionListEntityResult = Omit<
  EntityListOfTransactionListItemDto,
  "items"
>;

type TransactionState = {
  isTransactionListLoading: boolean;
  transactionEntityList: TransactionListEntityResult | null;
  transactionItems: TransactionListItemDto[];
  detail: GetTransactionDetailQueryResult | null;
};

export const resetTransactionListPagination = createAction(
  "@transaction/RESET_TRANSACTION_LIST_PAGINATION",
)<void>();

type TransactionAction =
  | ActionType<typeof getTransactionListAsync>
  | ActionType<typeof downloadTransactionCsvAsync>
  | ActionType<typeof getTransactionDetailAsync>
  | ActionType<typeof resetTransactionListPagination>
  | ActionType<typeof resetTransactionDetail>;

export const transactionReducer = createReducer<
  TransactionState,
  TransactionAction
>({
  isTransactionListLoading: false,
  transactionEntityList: null,
  detail: null,
  transactionItems: [],
})
  .handleAction(getTransactionListAsync.request, (state) => {
    return { ...state, isTransactionListLoading: true };
  })
  .handleAction(getTransactionListAsync.success, (state, action) => {
    const transactionItems =
      state.transactionEntityList === null ||
      action.payload.offset < state.transactionEntityList.offset
        ? action.payload.items
        : [...state.transactionItems, ...action.payload.items];
    return {
      ...state,
      isTransactionListLoading: false,
      transactionEntityList: action.payload,
      transactionItems: transactionItems,
    };
  })
  .handleAction(getTransactionListAsync.failure, (state, _action) => {
    return {
      ...state,
      isTransactionListLoading: false,
    };
  })
  .handleAction(getTransactionDetailAsync.success, (state, action) => {
    return {
      ...state,
      detail: action.payload,
    };
  })
  .handleAction(resetTransactionListPagination, (state, _action) => {
    return {
      ...state,
      transactionEntityList: null,
    };
  })
  .handleAction(resetTransactionDetail, (state, _action) => {
    return {
      ...state,
      detail: null,
    };
  });
