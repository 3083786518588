import { Box, Button, Typography } from "@mui/material";
import { Colors } from "Components/Layout/Themes/Colors";
import type { FC, PropsWithChildren } from "react";

type Props = {
  title?: string;
  onEdit?: () => void;
  withTopMargin?: boolean;
};

export const ValuesBox: FC<PropsWithChildren<Props>> = ({
  children,
  onEdit,
  title,
  withTopMargin,
}) => {
  return (
    <Box display="grid" rowGap={3} marginTop={withTopMargin ? 6 : undefined}>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {!!title && (
          <Typography fontSize={16} fontWeight={600} textTransform="uppercase">
            {title}
          </Typography>
        )}
        {!!onEdit && (
          <Button
            variant="text"
            onClick={onEdit}
            sx={{
              fontSize: "14px",
              fontWeight: 600,
              color: Colors.Link,
              padding: 0,
              textTransform: "none",
            }}
          >
            Upravit
          </Button>
        )}
      </Box>
      {children}
    </Box>
  );
};
