import { zodResolver } from "@hookform/resolvers/zod";
import { PoliticallyExposedCheckbox } from "Components/Onboarding/Components/PoliticallyExposedCheckbox";
import { StepBox } from "Components/Onboarding/NaturalPerson/Components/StepBox";
import { ValuesBox } from "Components/Onboarding/NaturalPerson/Components/ValuesBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { z } from "zod";

const StepResource = Resources.Onboarding.Steps.FinalStep;

const validationSchema = z.object({
  phone: z.string().min(12, "Prosím, zadejte platné telefonní číslo"),
  politicallyExposed: z.boolean(),
  isResidingInCzechia: z.boolean(),
  hasNoOtherNationality: z.boolean(),
});

export type FinalStepFormModel = z.infer<typeof validationSchema>;

type Props = {
  defaultValues?: Partial<FinalStepFormModel>;
  onSubmit: (data: FinalStepFormModel) => void;
  isSubmitting?: boolean;
};

export const FinalStepForm = ({
  onSubmit,
  defaultValues,
  isSubmitting,
}: Props) => {
  const { t } = useResource();

  const { control, handleSubmit, watch } = useForm<FinalStepFormModel>({
    resolver: zodResolver(validationSchema),
    defaultValues,
  });

  const politicallyExposed = watch("politicallyExposed");

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        submitButtonLabel={t(StepResource.SubmitButton)}
        submitDisabled={politicallyExposed}
        isSubmitting={isSubmitting}
      >
        <ValuesBox title={t(Resources.Common.Contact)} hideDivider>
          <PoliticallyExposedCheckbox
            control={control}
            name="politicallyExposed"
            isChecked={politicallyExposed}
          />
          <CheckboxInput
            label="Nejsem hlášen k pobytu mimo ČR, ani déle než rok nepobývám mimo ČR"
            control={control}
            name="isResidingInCzechia"
          />
          <CheckboxInput
            label="Nemám jinou státní příslušnost"
            control={control}
            name="hasNoOtherNationality"
          />
          <FormInput
            control={control}
            name="phone"
            label={t(Resources.Common.Phone)}
            mask="+000 000 000 000"
            inputProps={{
              inputMode: "tel",
            }}
          />
        </ValuesBox>
      </StepBox>
    </FullHeightForm>
  );
};
