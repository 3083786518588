import { Box, type ButtonProps } from "@mui/material";

export const UnstyledButton = ({ sx, ...restProps }: ButtonProps) => (
  <Box
    component="button"
    sx={{
      m: 0,
      p: 0,
      display: "flex",
      background: "none",
      border: "none",
      cursor: "pointer",
      fontFamily: "inherit",
      ...sx,
    }}
    {...restProps}
  />
);
