import { getCompanyName } from "Components/Onboarding/BusinessEntity/Utils/partyUtils";
import {
  type FormModel,
  OnboardingSteps,
} from "Components/Onboarding/BusinessEntity/types";
import type { PartyDto } from "Infrastructure/Api/Api";
import type { DeepPartial } from "types";

export const getMappedAresToForm = (
  party: PartyDto,
  formData: DeepPartial<FormModel>,
): DeepPartial<FormModel> => {
  const address = party.addresses?.find((a) => a.type === "Headquarters");
  const formBusinessInformation = formData[OnboardingSteps.BusinessInformation];

  return {
    ...formData,
    [OnboardingSteps.BusinessInformation]: {
      ...formBusinessInformation,
      address,
      partyType: party.type,
      companyName: getCompanyName(party),
      identificationNumber: party.companyNumber,
    },
  };
};
