import { Box, Typography } from "@mui/material";
import { useDeleteLicenseMutation } from "Api/Mutations/Licenses/useDeleteLicenseMutation";
import { Colors } from "Components/Layout/Themes/Colors";
import { UnstyledButton } from "Components/Shared/Buttons/UnstyledButton";
import { TransactionPaymentOptions } from "Components/Transactions/TransactionPaymentOptions";

type Props = {
  transactionPublicID: string;
  licensePublicID: string;
};

export const LicenseOrderActions = ({
  transactionPublicID,
  licensePublicID,
}: Props) => {
  const { mutate: deleteLicense, isPending } = useDeleteLicenseMutation();

  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <Typography color={Colors.Red}>
        Licence bude aktivována po připsání platby. Pokud máte již zaplaceno,
        vyčkejte na připsání peněz.
        <UnstyledButton
          onClick={() =>
            window.confirm("Opravdu chcete vybranou objednávku zrušit?") &&
            deleteLicense(licensePublicID)
          }
          sx={{
            ml: 1,
            color: Colors.Red,
            display: "inline-block",
            textDecoration: "underline",
          }}
        >
          Zrušit objednávku
        </UnstyledButton>
      </Typography>
      <TransactionPaymentOptions
        transactionPublicID={transactionPublicID}
        isDisabled={isPending}
      />
    </Box>
  );
};
