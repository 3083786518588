import { ChevronRight } from "@mui/icons-material";
import { Grid, Typography } from "@mui/material";
import { UnstyledLink } from "Components/Routes/UnstyledLink";
import type { ReactNode } from "react";
import styled from "styled-components";

const StyledGrid = styled(Grid)<{
  $isDestructiveAction?: boolean;
}>`
  cursor: pointer;
  color: ${(props) => props.theme.palette.primary.dark};
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};

  ${(props) => props.theme.breakpoints.up("md")} {
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }

  &.MuiGrid-container {
    padding-left: 0;
    padding-right: 0;
  }

  ${(props) =>
    props.$isDestructiveAction
      ? `color: ${props.theme.palette.error.main};
     &:hover {
       color: ${props.theme.palette.error.main};
     }`
      : `color: ${props.theme.palette.text.secondary};
     &:hover {
       color: ${props.theme.palette.text.secondary};
     }`}
`;

const StyledChevron = styled(ChevronRight)`
  color: ${(props) => props.theme.palette.primary.main};
`;

const IconWrapper = styled.div`
  display: flex;
  svg {
    height: 16px;
    width: 16px;
  }
`;

type Props = {
  text: string;
  url: string;
  icon: JSX.Element;
  isDestructiveAction?: boolean;
  isExternalLink?: boolean;
  rightIcon?: JSX.Element;
};

export const SettingsRowLink = ({
  text,
  url,
  icon,
  isDestructiveAction,
  isExternalLink,
  rightIcon,
}: Props) => (
  <UnstyledLink to={url} target={isExternalLink ? "_blank" : undefined}>
    <StyledGrid
      container
      display="flex"
      justifyContent="center"
      alignItems="center"
      $isDestructiveAction={isDestructiveAction}
      paddingY={4}
    >
      <Grid item xs={1}>
        <IconWrapper>{icon}</IconWrapper>
      </Grid>
      <Grid item xs={10}>
        <Typography fontSize={16}>{text}</Typography>
      </Grid>
      <Grid item xs={1} justifyContent="flex-end" display="flex">
        {rightIcon ? rightIcon : <StyledChevron />}
      </Grid>
    </StyledGrid>
  </UnstyledLink>
);
