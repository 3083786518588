import {
  type ResetPasswordCommand,
  type ResetPasswordCommandResult,
  postAuthResetPassword,
} from "Api/Api";
import type { ResetPasswordStep } from "State/Auth/Models/AuthStateModels";
import { put, takeLatest } from "redux-saga/effects";
import { call } from "typed-redux-saga";
import { createAction, createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

export const resetPasswordAsync = createAsyncAction(
  "@auth/RESET_PASSWORD_REQUEST",
  "@auth/RESET_PASSWORD_SUCCESS",
  "@auth/RESET_PASSWORD_FAILURE",
)<ResetPasswordCommand, ResetPasswordCommandResult, Error>();

export const setPasswordResetStep = createAction(
  "@auth/SET_RESET_PASSWORD_STEP",
)<ResetPasswordStep>();

function* resetPassword(
  action: ReturnType<typeof resetPasswordAsync.request>,
): Generator {
  try {
    const response = yield* call(postAuthResetPassword, action.payload);
    if (response.status === 200) {
      yield put(resetPasswordAsync.success(response.json));
    } else {
      yield put(
        resetPasswordAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(resetPasswordAsync.failure(err as Error));
  }
}
export function* resetPasswordSaga() {
  yield takeLatest(getType(resetPasswordAsync.request), resetPassword);
}
