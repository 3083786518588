import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { useBusinessActivityList } from "Hooks/Options/useBusinessActivityList";
import type { Control, FieldPath, FieldValues } from "react-hook-form";

type Props<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  label?: string;
  disabled?: boolean;
};

export const BusinessActivityDropdown = <FormType extends FieldValues>({
  control,
  name,
  label,
  disabled,
}: Props<FormType>) => {
  const codeList = useBusinessActivityList();

  return (
    <Dropdown
      codeList={codeList}
      name={name}
      control={control}
      label={label}
      disabled={disabled}
    />
  );
};
