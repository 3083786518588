import { UnstyledLink } from "Components/Routes/UnstyledLink";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { getAppUrl } from "Utils/UrlUtils";

export const InviteButton = () => {
  const appUrl = getAppUrl();

  return (
    <UnstyledLink to={appUrl("invite-lead")}>
      {/* FIXME: low-prio, this is invalid HTML nesting, <button> can't be inside <a>, however it still works */}
      <PrimaryButton type="button" variant="contained" color="info" fullWidth>
        Pozvat přítele
      </PrimaryButton>
    </UnstyledLink>
  );
};
