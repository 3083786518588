import { Box } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
  maxWidth?: number;
};

export const CarouselInnerContainer = ({ children, maxWidth = 210 }: Props) => (
  <Box
    sx={{
      maxWidth,
      width: "100%",
      margin: "auto",
      textAlign: "center",
    }}
  >
    {children}
  </Box>
);
