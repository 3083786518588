import { CssBaseline, GlobalStyles } from "@mui/material";
import {
  StyledEngineProvider,
  type Theme,
  ThemeProvider,
} from "@mui/material/styles";
import { getTheme } from "Components/Layout/Themes/Themes";
import { getCodeListCollectionAsync } from "State/CodeLists/CodeListReducer";
import { getAppUrl } from "Utils/UrlUtils";
import { type ReactNode, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import PullToRefresh from "react-simple-pull-to-refresh";
import { Toaster } from "sonner";
import styled, {
  type DefaultTheme,
  ThemeProvider as StyledThemeProvider,
} from "styled-components";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: ${(props) => props.theme.vh(100)};
  background-color: ${(props) => props.theme.colors.gray};

  ${(props) => props.theme.breakpoints.up("md")} {
    justify-content: center;
  }
`;

const onRefresh = () =>
  new Promise((resolve) => {
    window.location.reload();
    setTimeout(resolve, 200);
  });

const isPwa = () =>
  ["fullscreen", "standalone", "minimal-ui"].some(
    (displayMode) =>
      window.matchMedia(`(display-mode: ${displayMode})`).matches,
  );

const isOnWhitelistedPage = (path: string) => {
  const appUrl = getAppUrl();
  return path === appUrl("dashboard") || path === appUrl("transactions");
};

export const Layout = ({ children }: { children: ReactNode }) => {
  const [theme, setTheme] = useState<DefaultTheme | null>(null);
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCodeListCollectionAsync.request());
  }, [dispatch]);

  useEffect(() => {
    setTheme(getTheme());
  }, []);

  const isPullable = isPwa() && isOnWhitelistedPage(location.pathname);

  return (
    <>
      {theme && (
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <GlobalStyles
              styles={{
                "#sentry-feedback": {
                  [theme.breakpoints.down("md")]: {
                    "--bottom": "72px",
                  },
                },
              }}
            />
            <CssBaseline />
            <StyledThemeProvider theme={theme}>
              <Toaster richColors position="top-right" />
              <PullToRefresh
                isPullable={isPullable}
                onRefresh={onRefresh}
                resistance={4}
                pullingContent=""
              >
                <StyledWrapper>{children}</StyledWrapper>
              </PullToRefresh>
            </StyledThemeProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      )}
    </>
  );
};
