import { Box, Skeleton, Typography } from "@mui/material";
import { useTransactions } from "Api/Queries/Transactions/useTransactions";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { CommonError } from "Components/Shared/Errors/CommonError";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { StyledFlex } from "Components/Shared/StyledComponents";
import {
  TransactionItem,
  TransactionItemSkeleton,
} from "Components/Shared/Transactions/TransactionItem";
import { useLoggedInUser } from "Hooks/useLoggedInUser";
import { Resources, useResource } from "Translations/Resources";
import { getTransactionDirection } from "Utils/TransactionUtils";
import { appUrl } from "Utils/UrlUtils";
import type { ReactNode } from "react";
import { useNavigate } from "react-router";

export const TransactionsComponent = () => {
  const { t } = useResource();
  const navigate = useNavigate();
  const { data, isLoading, isError } = useTransactions({ limit: 3 });
  const user = useLoggedInUser();
  const currentUserID = user?.userID || -1; // We should always have UserID at this point

  const transactions = data?.pages?.[0]?.items || [];
  const hasTransactions = !!transactions.length;

  if (isLoading) {
    return (
      <Layout title={t(Resources.Transactions.History)}>
        <TransactionsSkeleton />
      </Layout>
    );
  }

  if (isError) {
    return (
      <Layout title={t(Resources.Transactions.History)}>
        <CommonError withoutPaper paddingY={0} />
      </Layout>
    );
  }

  if (!hasTransactions) {
    return (
      <Layout title={t(Resources.Transactions.History)}>
        <Typography>Nejsou k dispozici žádné transakce.</Typography>
      </Layout>
    );
  }

  return (
    <Layout title={t(Resources.Transactions.History)}>
      {transactions.map((transaction) => (
        <TransactionItem
          key={transaction.publicID}
          item={transaction}
          direction={getTransactionDirection(transaction, currentUserID)}
        />
      ))}
      <StyledFlex justifyContent="center">
        <PrimaryButton
          variant="text"
          color="info"
          onClick={() => navigate(appUrl("transactions"))}
        >
          {t(Resources.Common.ShowAll)}
        </PrimaryButton>
      </StyledFlex>
    </Layout>
  );
};

type LayoutProps = {
  children: ReactNode;
  title: string;
};

const Layout = ({ children, title }: LayoutProps) => (
  <Box component="section">
    <PaperTitle mb={1} component="h2">
      {title}
    </PaperTitle>
    {children}
  </Box>
);

const TransactionsSkeleton = () => (
  <>
    <TransactionItemSkeleton />
    <TransactionItemSkeleton />
    <TransactionItemSkeleton />
    <StyledFlex justifyContent="center">
      <Skeleton height={10} variant="text" />
    </StyledFlex>
  </>
);
