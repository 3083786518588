import { Typography } from "@mui/material";
import { InfoGroup } from "Components/Contact/InfoGroup";

export const CompanyInfo = () => (
  <InfoGroup title="Provozovatel">
    ZNPay a.s.
    <br />
    IČO: 02013517
    <br />
    DIČ: CZ02013517
    <br />
    Sídlem Dělnická 213/12, Holešovice, 170 00 Praha 7 zapsána v obchodním
    rejstříku u Městského soudu v Praze, sp.zn. B 23823
    <br />
  </InfoGroup>
);
