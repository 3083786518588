import { Box, Button, Typography } from "@mui/material";
import type { PaymentActionsProps } from "Components/Dashboard/BalanceWithPaymentActions/types";
import { ForwardArrowIcon, PlusMathIcon } from "Components/Shared/Icons";
import { Link } from "react-router-dom";

export const PaymentActions = ({
  payMeAction,
  sendPaymentAction,
  isDisabled,
}: PaymentActionsProps) => (
  <Box display="flex" gap={4} mt={4} height="53px">
    <SendPaymentButton {...sendPaymentAction} isDisabled={isDisabled} />
    <PayMeButton {...payMeAction} isDisabled={isDisabled} />
  </Box>
);

type PaymentButtonProps = {
  isDisabled: boolean;
  link: string;
  text: string;
};

const SendPaymentButton = ({ isDisabled, link, text }: PaymentButtonProps) => (
  <Button
    component={Link}
    to={link}
    fullWidth
    color="primary"
    variant="contained"
    disabled={isDisabled}
    startIcon={<PlusMathIcon width={20} height={20} />}
  >
    <Typography fontWeight={600} fontSize={14}>
      {text}
    </Typography>
  </Button>
);

const PayMeButton = ({ isDisabled, link, text }: PaymentButtonProps) => (
  <Button
    component={Link}
    to={link}
    fullWidth
    color="info"
    variant="outlined"
    disabled={isDisabled}
    startIcon={<ForwardArrowIcon width={20} height={20} />}
  >
    <Typography fontWeight={600} fontSize={14}>
      {text}
    </Typography>
  </Button>
);
