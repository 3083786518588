import { Box, IconButton, Skeleton, Snackbar } from "@mui/material";
import { useGetAffiliateLink } from "Api/Queries/Lead/useGetAffiliateLink";
import { CopyIcon, DoneIcon } from "Components/Shared/Icons";
import { useState } from "react";

export const AffiliateLink = () => {
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const { data, isLoading } = useGetAffiliateLink();

  const handleCopyClick = async (url: string) => {
    await navigator.clipboard.writeText(url);
    setIsCopySuccess(true);
  };

  if (isLoading) {
    return <Skeleton width="90%" height={42} variant="rectangular" />;
  }

  if (!data?.url) {
    return <>Váš affiliate kód se nepodařilo získat.</>;
  }

  return (
    <Box display="flex" alignItems="center">
      <code>{data.url}</code>
      <IconButton
        disableRipple
        onClick={() => handleCopyClick(data.url)}
        aria-label="Kopírovat odkaz"
        sx={{ p: 0, ml: 1 }}
      >
        {isCopySuccess ? (
          <DoneIcon height={24} width={24} />
        ) : (
          <CopyIcon height={24} width={24} />
        )}
      </IconButton>
      <Snackbar
        open={isCopySuccess}
        autoHideDuration={2000}
        sx={{ mb: [15, 11, 0] }}
        message="Odkaz byl zkopírován"
        onClose={() => setIsCopySuccess(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      />
    </Box>
  );
};
