import { EditValues } from "Components/Onboarding/BusinessEntity/Components/OwnerModal/EditValues";
import { ModalDialog } from "Components/Shared/Dialogs/ModalDialog";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  isOpen: boolean;
  onSave: (publicID: string) => void;
  onClose: () => void;
  onDelete?: (publicID: string) => void;
  publicID?: string;
};

const ModalResources = Resources.Onboarding.BusinessOnboarding.Steps.OwnerModal;

export const OwnerModal = ({
  isOpen,
  onSave,
  onClose,
  onDelete,
  publicID,
}: Props) => {
  const { t } = useResource();

  return (
    <ModalDialog
      isOpen={isOpen}
      onClose={onClose}
      title={t(ModalResources.Title)}
    >
      <EditValues publicID={publicID} onSave={onSave} onDelete={onDelete} />
    </ModalDialog>
  );
};
