import { Box } from "@mui/material";
import {
  type PointerEvent,
  type ReactNode,
  type TouchEvent,
  forwardRef,
} from "react";
import { GAP_WIDTH } from "../constants";

type Props = {
  children: ReactNode;
  height?: number;
  currentIndex: number;
  dragDistance: number;
  isDisabled?: boolean;
  onPointerDown: (e: PointerEvent) => void;
  onPointerMove: (e: PointerEvent) => void;
  onPointerUp: () => void;
  onPointerLeave: () => void;
  onTouchStart: (e: TouchEvent) => void;
  onTouchMove: (e: TouchEvent) => void;
  onTouchEnd: () => void;
};

export const CarouselTrack = forwardRef<HTMLDivElement, Props>(
  (
    {
      children,
      currentIndex,
      dragDistance,
      height = 400,
      isDisabled,
      ...restProps // touch and pointer event handlers
    },
    ref,
  ) => (
    <Box
      ref={ref}
      sx={{
        height,
        display: "flex",
        gap: `${GAP_WIDTH}px`,
        transition: "transform 0.5s ease",
        transform: `translateX(calc(-${currentIndex * 100}% + ${
          dragDistance - GAP_WIDTH * currentIndex
        }px))`,
      }}
      {...(isDisabled ? [] : restProps)}
    >
      {children}
    </Box>
  ),
);
