import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { LeadInvitationCommandResultStatus } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { type ObjectSchema, object, string } from "yup";

type Props = {
  isLoading: boolean;
  status: LeadInvitationCommandResultStatus | null;
  onSubmit: (model: LeadInvitationFormModel) => void;
};

type LeadInvitationFormModel = {
  email: string;
};

const StyledSubmitButton = styled(PrimaryButton)`
  margin: ${(props) => props.theme.spacing(4, 0)};
`;

const StyledForm = styled.form`
  margin-top: ${(props) => props.theme.spacing(1)};
`;

export const LeadInvitationForm = (props: Props) => {
  const { t } = useResource();
  const validationSchema: ObjectSchema<LeadInvitationFormModel> = object({
    email: string()
      .email("E-mail není validní")
      .required(t(Resources.Validation.Required)),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    control,
    setError,
  } = useForm<LeadInvitationFormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      email: "",
    },
  });

  useEffect(() => {
    if (props.status === LeadInvitationCommandResultStatus.UserExists) {
      setError("email", { message: "Uživatel již Moone používá." });
    }

    if (props.status === LeadInvitationCommandResultStatus.LeadExists) {
      setError("email", {
        message: "Na tento e-mail jste již poslal pozvánku.",
      });
    }

    if (props.status === LeadInvitationCommandResultStatus.Error) {
      setError("email", {
        message: "Při pozvání se vyskytla chyba.",
      });
    }
  }, [props.status, setError]);

  return (
    <>
      <StyledForm onSubmit={handleSubmit(props.onSubmit)}>
        <FormInput
          autoFocus
          control={control}
          name="email"
          errors={errors}
          label="E-mail"
        />

        <StyledSubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="info"
          disabled={props.isLoading}
        >
          Odeslat pozvánku
        </StyledSubmitButton>
      </StyledForm>
    </>
  );
};
