import { Grid2, Stack } from "@mui/material";
import { useBankingAccountsOrder } from "Api/Mutations/Accounts/useBankingAccountsOrder";
import { Card } from "Components/BankAccounts/BankAccountList/AccountOrderEditor/Card";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";

const PageResources = Resources.BankAccounts.List;
const isTouch = "ontouchstart" in window || navigator.maxTouchPoints > 0;

type Props = {
  onSave: () => void;
  initialItems: Array<BankAccountListItem>;
};

export const AccountOrderEditor = ({ onSave, initialItems }: Props) => {
  const [items, setItems] = useState<Array<BankAccountListItem>>(initialItems);
  const { mutateAsync: saveOrder, isPending } = useBankingAccountsOrder();
  const { t } = useResource();

  const saveItemsOrder = async () => {
    await saveOrder(items);
    onSave();
  };

  const moveCard = (dragIndex: number, hoverIndex: number) => {
    const draggedCard = items[dragIndex];
    const newItems = [...items];
    newItems.splice(dragIndex, 1);
    newItems.splice(hoverIndex, 0, draggedCard);
    setItems(newItems);
  };

  return (
    <Stack gap={6}>
      <DndProvider backend={isTouch ? TouchBackend : HTML5Backend}>
        <Stack gap={2}>
          {items.map((account, index) => (
            <Card
              key={account.bankAccountID}
              index={index}
              moveCard={moveCard}
              bankAccount={account}
              isTouch={isTouch}
            />
          ))}
        </Stack>
      </DndProvider>
      <Grid2 container spacing={4}>
        <Grid2 size={{ xs: 12, md: 7 }}>
          <PrimaryButton
            fullWidth
            color="info"
            onClick={saveItemsOrder}
            isLoading={isPending}
          >
            {t(PageResources.SaveOrder)}
          </PrimaryButton>
        </Grid2>
        <Grid2 size={{ xs: 12, md: 5 }}>
          <PrimaryButton
            fullWidth
            color="info"
            variant="outlined"
            onClick={onSave}
            isLoading={isPending}
          >
            {t(Resources.Common.CancelEdit)}
          </PrimaryButton>
        </Grid2>
      </Grid2>
    </Stack>
  );
};
