import {
  Box,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  type TextFieldProps,
} from "@mui/material";
import { Account } from "Components/Dashboard/ConnectedAccounts/Components/Account";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import {
  type Control,
  Controller,
  type FieldPath,
  type FieldValues,
} from "react-hook-form";

type Props<FormType extends FieldValues> = TextFieldProps & {
  name: FieldPath<FormType>;
  label?: string;
  control: Control<FormType, object>;
  disabled?: boolean;
  bankAccounts: BankAccountListItem[];
};

export const BankingAccountSelector = <T extends object>({
  control,
  name,
  label,
  bankAccounts,
}: Props<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, ...field }, fieldState: { error } }) => (
        <FormControl
          component="fieldset"
          sx={{ width: "100%", marginBottom: 2 }}
          error={!!error}
        >
          <FormLabel component="legend" sx={{ marginBottom: 1 }}>
            {label}
          </FormLabel>
          <RadioGroup
            {...field}
            onChange={(e) => onChange(Number.parseInt(e.target.value))}
          >
            {bankAccounts.map((account, index, items) => (
              <FormControlLabel
                key={account.bankAccountID}
                value={account.bankAccountID}
                control={
                  <Radio
                    sx={{ display: "none" }}
                    checked={field.value === account.bankAccountID}
                  />
                }
                sx={{
                  width: "100%",
                  marginBottom: index < items.length - 1 ? 2 : 0,
                  marginLeft: 0,
                  "& .MuiFormControlLabel-label": {
                    width: "100%",
                    margin: 0,
                  },
                }}
                label={
                  <AccountOption
                    account={account}
                    isSelected={field.value === account.bankAccountID}
                  />
                }
              />
            ))}
          </RadioGroup>
          <FormHelperText sx={{ margin: 0 }}>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
};

const AccountOption = ({
  isSelected,
  account,
}: { isSelected: boolean; account: BankAccountListItem }) => (
  <Box
    display="flex"
    justifyContent="space-between"
    boxShadow="none"
    borderRadius="10px"
    py={1}
    px={isSelected ? "8px" : "9px"}
    my={isSelected ? 0 : "1px"}
    sx={({ palette }) => ({
      "&:hover": {
        backgroundColor: palette.grey[100],
      },
      border: isSelected ? "2px solid" : "1px solid",
      borderColor: isSelected ? palette.primary.main : palette.grey[300],
    })}
  >
    <Account {...account} />
  </Box>
);
