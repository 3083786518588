import { API_ROUTES, postUserBankAccountCreate } from "Api/Api";
import { setFetchProcess } from "State/Upload/UploadReducer";
import { getUserVerificationDetailAsync } from "State/Verification/GetUserVerificationDetail/GetUserVerificationDetailState";
import { JWT_KEY } from "Utils/AuthUtils";
import { nameof } from "Utils/ObjectUtils";
import { END, eventChannel } from "redux-saga";
import { put, takeLatest } from "redux-saga/effects";
import { call, take } from "typed-redux-saga";
import { createAsyncAction } from "typesafe-actions";
import { getType } from "typesafe-actions";

type UploadIdentificationRequest = {
  firstIDCardFrontSide: File;
  firstIDCardBackSide: File;
  secondIDCardFrontSide: File;
};

export const uploadUserIdentificationAsync = createAsyncAction(
  "@verification/UPLOAD_USER_IDENTIFICATION_REQUEST",
  "@verification/UPLOAD_USER_IDENTIFICATION_SUCCESS",
  "@verification/UPLOAD_USER_IDENTIFICATION_FAILURE",
)<UploadIdentificationRequest, void, Error>();

function* uploadUserIdentification(
  action: ReturnType<typeof uploadUserIdentificationAsync.request>,
): Generator {
  try {
    const form = new FormData();

    form.append(
      nameof<UploadIdentificationRequest>("firstIDCardFrontSide"),
      action.payload.firstIDCardFrontSide,
    );
    form.append(
      nameof<UploadIdentificationRequest>("firstIDCardBackSide"),
      action.payload.firstIDCardBackSide,
    );
    form.append(
      nameof<UploadIdentificationRequest>("secondIDCardFrontSide"),
      action.payload.secondIDCardFrontSide,
    );

    const request = new XMLHttpRequest();

    request.open(
      "POST",
      `${import.meta.env.VITE_API_URL}${
        API_ROUTES.postVerificationUploadIdentification
      }`,
    );

    yield call(uploadProgressWatch, request, form);

    if (request.status === 200) {
      yield* call(postUserBankAccountCreate);
      yield put(getUserVerificationDetailAsync.request());
      yield put(uploadUserIdentificationAsync.success());
    } else {
      yield put(
        uploadUserIdentificationAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: request.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(uploadUserIdentificationAsync.failure(err as Error));
  }
}
export function* uploadUserIdentificationSaga() {
  yield takeLatest(
    getType(uploadUserIdentificationAsync.request),
    uploadUserIdentification,
  );
}

function getUploadProcess(request: XMLHttpRequest) {
  return eventChannel<{
    isDone: boolean;
    percentage: number;
  }>((emitter) => {
    request.upload.addEventListener("progress", (e) => {
      emitter({
        isDone: false,
        percentage: Math.round((e.loaded / e.total) * 100),
      });
    });

    request.addEventListener("load", () => {
      emitter({
        isDone: true,
        percentage: 100,
      });
      emitter(END);
    });

    return () => {};
  });
}

function* uploadProgressWatch(request: XMLHttpRequest, form: FormData) {
  try {
    request.setRequestHeader(
      "Authorization",
      localStorage.getItem(JWT_KEY) ?? "",
    );
    const uploadProgress = yield* call(getUploadProcess, request);
    request.send(form);

    while (true) {
      const progress = yield* take(uploadProgress);
      yield put(
        setFetchProcess({
          isBatch: false,
          progress: progress.percentage,
        }),
      );

      if (progress.isDone) {
        yield put(
          setFetchProcess({
            isBatch: false,
            progress: null,
          }),
        );
        break;
      }
    }
  } finally {
  }
}
