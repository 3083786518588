import { Box } from "@mui/material";
import { BankInfo } from "Components/Contact/BankInfo";
import { CompanyInfo } from "Components/Contact/CompanyInfo";
import { ContactInfo } from "Components/Contact/ContactInfo";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useNavigationWithPreviousPage } from "Hooks/useNavigateBackManager";

export const ContactPage = () => {
  const { previousPageUrl } = useNavigationWithPreviousPage();

  return (
    <AuthenticatedLayout title="Kontakt" goBackUrl={previousPageUrl}>
      <StyledPageWrapper>
        <div className="left-content">
          <PrimaryPaper>
            <Box display="flex" flexDirection="column" gap={3}>
              <ContactInfo />
              <CompanyInfo />
              <BankInfo />
            </Box>
          </PrimaryPaper>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default ContactPage;
