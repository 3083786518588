import { Box, Skeleton } from "@mui/material";
import { LicenseDetailSkeleton } from "Components/Licenses/LicensesPageContent/LicenseDetailSkeleton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { useIsMobile } from "Hooks/useIsMobile";

export const LicensesPageContentSkeleton = () => {
  const isMobile = useIsMobile();

  return (
    <Box
      mx={1}
      mb={6}
      display="flex"
      flexDirection="column"
      gap={isMobile ? 2 : 1}
    >
      <PrimaryPaper>
        <LicenseDetailSkeleton />
      </PrimaryPaper>
      <Box mt={3}>
        <Skeleton variant="rounded" height={60} />
      </Box>
    </Box>
  );
};
