import { Typography } from "@mui/material";
import {
  StyledFormControl,
  StyledFormInput,
  StyledFormLabelWrapper,
  StyledValidationText,
} from "Components/Shared/FormStyles";
import { Dropdown as DropdownInput } from "Components/Shared/Inputs/Dropdown";
import { StyledInputLabel } from "Components/Shared/StyledComponents";
import type { CodeListItem } from "Models/ICodeListDto";
import {
  type Control,
  Controller,
  type FieldPath,
  type FieldValues,
} from "react-hook-form";

type Props<FormType extends FieldValues> = {
  codeList: CodeListItem[];
  name: FieldPath<FormType>;
  formControlClass?: "long" | "medium" | "tiny";
  label?: string;
  control: Control<FormType, object>;
  disabled?: boolean;
  placeholder?: string;
  accessibilityLabel?: string;
};

export const Dropdown = <T extends object>({
  codeList,
  name,
  control,
  label,
  disabled,
  formControlClass,
  placeholder,
  accessibilityLabel,
}: Props<T>) => (
  <Controller
    control={control}
    name={name}
    render={({
      field: { onChange, value, onBlur, ref },
      fieldState: { error },
    }) => {
      const formValue = value
        ? codeList.some(({ id, code }) => id === value || code === value)
          ? (value as any)
          : ""
        : "";

      return (
        <StyledFormControl
          className={`${formControlClass} ${!label && "no-label"}`}
        >
          {!!label && (
            <StyledFormLabelWrapper>
              <StyledInputLabel htmlFor={name} hasError={!!error}>
                <Typography>{label}</Typography>
              </StyledInputLabel>
            </StyledFormLabelWrapper>
          )}
          <StyledFormInput>
            <DropdownInput
              value={formValue}
              onChange={(value) => {
                const formattedValue = Number.isNaN(
                  Number.parseInt(value.toString()),
                )
                  ? value
                  : Number(value);

                return onChange(formattedValue);
              }}
              onBlur={onBlur}
              disabled={disabled}
              hasError={!!error}
              name={name}
              placeholder={placeholder}
              options={codeList.map(({ name, id, code }) => ({
                Name: name,
                Value: id ?? code,
              }))}
              inputRef={ref}
              accessibilityLabel={accessibilityLabel}
            />
          </StyledFormInput>
          <StyledValidationText>
            {!!error && error.message}
          </StyledValidationText>
        </StyledFormControl>
      );
    }}
  />
);
