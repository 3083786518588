import type * as React from "react";
export const ArrowRightIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={34.392}
      height={23.876}
      viewBox="0 0 34.392 23.876"
      {...props}
    >
      <path
        d="M29.844,13.094a.994.994,0,0,0-.781.7,1,1,0,0,0,.281,1.016L38.531,24H8.906A1,1,0,0,0,9,26H38.531l-9.188,9.188a1,1,0,1,0,1.406,1.406L41.656,25.719,42.344,25l-.687-.719L30.75,13.406a1,1,0,0,0-.812-.312Z"
        transform="translate(-7.952 -13.091)"
        fill="currentColor"
      />
    </svg>
  );
};
