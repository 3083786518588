import { Skeleton, Typography } from "@mui/material";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { PaymentForm } from "Components/Customer/Payment/PaymentForm";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { Resources, useResource } from "Translations/Resources";

const ComponentResources = Resources.Customer.Pay.DescriptionText;

type Props = {
  publicID?: string | null;
  transactionPublicID?: string | null;
};

export const CustomerPaymentPayComponent = ({
  publicID,
  transactionPublicID,
}: Props) => {
  const { t } = useResource();
  const { data, isLoading } = useBankingAccounts();

  if (isLoading) {
    return (
      <PrimaryPaper>
        <Skeleton height={270} />
        <Skeleton height={62} />
      </PrimaryPaper>
    );
  }

  if (!data) {
    return <PrimaryPaper>Chyba - Nepodařilo se načíst Vaše účty</PrimaryPaper>;
  }

  const bankAccounts = data.items;

  const getSelectedBankAccountID = () => {
    let bankAccountID: number | undefined = undefined;

    if (publicID) {
      bankAccountID = bankAccounts.find(
        (account) => account.publicID === publicID,
      )?.bankAccountID;
    } else {
      bankAccountID = bankAccounts.sort((a, b) => b.rank - a.rank)?.[0]
        ?.bankAccountID;
    }

    return bankAccountID;
  };

  const defaultValues = {
    publicID: transactionPublicID ?? undefined,
    bankAccountID: getSelectedBankAccountID(),
  };

  return (
    <PrimaryPaper>
      <Typography fontWeight={700} marginBottom={2}>
        {t(ComponentResources)}
      </Typography>
      <PaymentForm defaultValues={defaultValues} bankAccounts={bankAccounts} />
    </PrimaryPaper>
  );
};
