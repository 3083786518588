import { Box } from "@mui/material";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { CountryDropdown } from "Components/Shared/Inputs/Form/Dropdowns/CountryDropdown";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { AverageTransactionValueDropdown } from "Components/Shared/SelectorsForm/AverageTransactionValueDropdown";
import { BusinessActivityDropdown } from "Components/Shared/SelectorsForm/BusinessActivityDropdown";
import { ExpectedMonthlyRevenueDropdown } from "Components/Shared/SelectorsForm/ExpectedMonthlyRevenueDropdown.";
import { IncomeSourceDropdown } from "Components/Shared/SelectorsForm/IncomeSourceDropdown";
import {
  BusinessActivity,
  SourceOfIncome,
  type UserVerificationUpdateDto,
} from "Infrastructure/Api/Api";
import type { Control, UseFormWatch } from "react-hook-form";

type VerificationFormFieldsProps = {
  control: Control<UserVerificationUpdateDto>;
  watch: UseFormWatch<UserVerificationUpdateDto>;
};

export const VerificationFormFields = ({
  control,
  watch,
}: VerificationFormFieldsProps) => {
  const businessActivity = watch("businessActivity");
  const sourceOfIncome = watch("sourceOfIncome");

  return (
    <Box component="fieldset" sx={{ all: "unset", display: "grid" }}>
      <CountryDropdown
        control={control}
        name="taxResidencyCountryID"
        label="Daňová rezidence"
      />
      <BusinessActivityDropdown
        control={control}
        name="businessActivity"
        label="Předmět podnikání"
      />
      {businessActivity === BusinessActivity.Other && (
        <FormInput
          control={control}
          name="otherBusinessActivityDetails"
          label="Upřesněte předmět podnikání"
        />
      )}
      <IncomeSourceDropdown
        control={control}
        name="sourceOfIncome"
        label="Zdroj příjmů"
      />
      {sourceOfIncome === SourceOfIncome.Other && (
        <FormInput
          control={control}
          name="otherSourceOfIncomeDetails"
          label="Upřesněte zdroj příjmů"
        />
      )}
      <ExpectedMonthlyRevenueDropdown
        control={control}
        name="expectedMonthlyRevenue"
        label="Očekávaný měsíční objem"
      />
      <AverageTransactionValueDropdown
        control={control}
        name="averageTransactionValue"
        label="Průměrná hodnota jedné transakce"
      />
      <Box mb="1rem">
        <CheckboxInput
          control={control}
          name="isPoliticallyExposedPerson"
          label="Politicky exponovaná osoba"
        />
      </Box>
    </Box>
  );
};
