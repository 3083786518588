import { type Page, appUrl } from "Utils/UrlUtils";
import { useSearchParams } from "react-router-dom";

const PREV_PARAM_NAME = "from";

// Overloaded signature - specify current page when calling the hook
export function useNavigationWithPreviousPage(currentPage: Page): {
  previousPageUrl?: string;
  buildNavLinkWithPreviousPage: (to: Page, from?: Page) => string;
};
// Overloaded signature - specify current page later when building the link, or not at all if only accessing the previousPageUrl
export function useNavigationWithPreviousPage(): {
  previousPageUrl?: string;
  buildNavLinkWithPreviousPage: (to: Page, from: Page) => string;
};

export function useNavigationWithPreviousPage(currentPage?: Page) {
  const [searchParams] = useSearchParams();

  const buildNavLinkWithPreviousPage = (to: Page, from = currentPage) => {
    if (!from) {
      throw new Error(
        "'from' parameter is required when currentPage is not provided",
      );
    }
    return `${appUrl(to)}?${PREV_PARAM_NAME}=${from}`;
  };

  const previousPage = searchParams.get(PREV_PARAM_NAME) as Page | null;
  const previousPageUrl = previousPage ? appUrl(previousPage) : undefined;

  return { previousPageUrl, buildNavLinkWithPreviousPage };
}
