import type * as React from "react";

export const InstagramVerificationBadgeIcon = (
  props: React.SVGProps<SVGSVGElement>,
) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0413 9.99791L19.3193 7.49991L16.9625 5.97711L16.8209 3.17471L14.0185 3.03471L12.4945 0.678711L9.99767 1.95871L7.49967 0.680311L5.97727 3.03751L3.17527 3.17911L3.03447 5.98191L0.678467 7.50591L1.95847 10.0027L0.680867 12.4999L3.03767 14.0227L3.17927 16.8251L5.98167 16.9651L7.50567 19.3215L10.0025 18.0415L12.5005 19.3195L14.0229 16.9623L16.8249 16.8207L16.9657 14.0183L19.3217 12.4943L18.0413 9.99791ZM8.89607 13.0247L6.16807 10.2971L7.01647 9.44871L8.90927 11.3411L12.9901 7.38591L13.8253 8.24751L8.89607 13.0247Z"
      fill="currentColor"
    />
  </svg>
);
