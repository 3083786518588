import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import type { UserBankAccountDetailDto } from "Api/Api";
import { InvoiceQRCode } from "Components/Invoices/InvoiceQRCode";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import { CurrencyCode } from "Models/CurrencyCodes";
import { getPaymentDescriptor } from "Utils/BankUtils";
import { formatCurrency } from "Utils/CurrencyUtils";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { type ObjectSchema, mixed, object } from "yup";

type Props = { detail: UserBankAccountDetailDto };

const RowFactory = (isCZK: boolean) => styled.div`
  display: grid;
  grid-template-columns: ${isCZK ? "1fr 1fr" : "2fr 3fr"};
`;

const Trivia = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  margin: ${(props) => props.theme.spacing(1, 0, 3, 0)};
  font-size: 13px;
`;

const RadioButtonsContainer = styled.div`
  margin-bottom: ${(props) => props.theme.spacing(1)};
  > div {
    display: flex;

    .MuiFormGroup-root :first-child span {
      padding-left: 0px;
    }
  }

  label {
    margin: 0;
  }
`;

type FormModel = {
  currencyCode: CurrencyCode;
};

export const BankAccountDetail: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props) => {
  const { detail } = props;

  const message = "Overeni Moone.io";

  const validationSchema: ObjectSchema<FormModel> = object({
    currencyCode: mixed<CurrencyCode>()
      .oneOf(Object.values(CurrencyCode))
      .required(),
  }).defined();
  const { control, watch } = useForm<FormModel>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      currencyCode: CurrencyCode.CZK,
    },
  });

  const currencyCode = watch("currencyCode");

  const iban =
    currencyCode === CurrencyCode.CZK
      ? detail.accountNumberIBAN
      : detail.accountNumberIBANEUR;

  const paymentDescriptor = getPaymentDescriptor(
    iban,
    detail.paymentReference,
    1,
    currencyCode,
    message,
  );

  const Row = RowFactory(currencyCode === CurrencyCode.CZK);

  return (
    <>
      <Typography>
        Pro ověření musíte odeslat 1 Kč nebo 1 euro z Vašeho bankovního účtu.
        Tento účet musí být veden v bance na Vaše jméno.
      </Typography>
      <br />

      <RadioButtonsContainer>
        <RadioButtons
          codeList={[
            { code: CurrencyCode.CZK, name: CurrencyCode.CZK },
            { code: CurrencyCode.EUR, name: CurrencyCode.EUR },
          ]}
          control={control}
          name="currencyCode"
          row={true}
        />
      </RadioButtonsContainer>
      <Typography fontWeight={600}>Platební údaje:</Typography>

      {currencyCode === CurrencyCode.CZK && (
        <>
          <Row>
            <Typography>Číslo účtu:</Typography>
            <Typography>{detail?.accountNumber}</Typography>
          </Row>

          <Row>
            <Typography>Variabilní symbol:</Typography>
            <Typography>{detail?.paymentReference}</Typography>
          </Row>
        </>
      )}

      {currencyCode === CurrencyCode.EUR && (
        <>
          <Row>
            <Typography>IBAN:</Typography>
            <Typography>{iban}</Typography>
          </Row>

          <Row>
            <Typography>Reference platby:</Typography>
            <Typography>{`VS${detail?.paymentReference}/SS/KS`}</Typography>
          </Row>
        </>
      )}
      <Row>
        <Typography>Částka:</Typography>
        <Typography>{formatCurrency(1, currencyCode)}</Typography>
      </Row>
      <Row>
        <Typography>Poznámka:</Typography>
        <Typography>{message}</Typography>
      </Row>
      <Trivia>
        Poznámku je nutné uvést! Bez poznámky nebude platba spárována.
      </Trivia>

      {!!paymentDescriptor && (
        <InvoiceQRCode
          key={currencyCode}
          paymentDescriptor={paymentDescriptor}
          size={200}
        />
      )}

      <Typography>
        Ověření může trvat až 2 pracovní dny, záleží na rychlosti bankovního
        převodu. Po ověření Vám bude platba vrácena.
      </Typography>
    </>
  );
};
