import { Stack, Typography } from "@mui/material";
import { BanksList } from "Components/Onboarding/BusinessEntity/Components/BanksList/BanksList";
import { useBankingAuth } from "Hooks/useBankingAuth";
import type { BankEnvironment } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";

type Props = {
  transactionPublicID: string;
};

const ComponentResources = Resources.Customer.Pay;

export const BankSelector = ({ transactionPublicID }: Props) => {
  const { t } = useResource();
  const { isPending, authorize, selectedEnvironment } = useBankingAuth();

  const onBankSelected = (environment: BankEnvironment) => {
    if (!isPending) {
      authorize({ environment, transactionPublicID });
    }
  };

  return (
    <Stack gap={4}>
      <Typography>{t(ComponentResources.ChooseBank)}</Typography>
      <BanksList
        actionType="select"
        onSelect={onBankSelected}
        pendingEnvironment={isPending ? selectedEnvironment : undefined}
      />
    </Stack>
  );
};
