import { Stack, Typography } from "@mui/material";
import { useTransactionsDetail } from "Api/Queries/Transactions/useTransactionsDetail";
import { PaymentStatus } from "Components/Merchant/InitiatePaymentDetail/PaymentStatus";
import { QRPaymentSkeleton } from "Components/QRPayment/QRPaymentSkeleton";
import { QrCodePayment } from "Components/Shared/Payments/QrCodePayment";
import { Resources, useResource } from "Translations/Resources";
import { getLocalBankAccountNumberFromIBAN } from "Utils/AccountUtils";
import { getPaymentDescriptor } from "Utils/BankUtils";

type Props = {
  publicID: string;
  description?: string;
};

export const InitiateQRPaymentComponent = ({
  publicID,
  description,
}: Props) => {
  const { data, isLoading } = useTransactionsDetail(publicID || "");
  const { t } = useResource();

  if (isLoading) {
    return <QRPaymentSkeleton />;
  }

  if (!data) {
    return (
      <>Omlouváme se, ale něco se pokazilo, zkuste to prosím znovu později.</>
    );
  }

  const {
    amount,
    currencyCode,
    creditorIBAN,
    paymentReference,
    noteForDebtor,
    transactionStatus,
    paymentInstructionStatus,
  } = data;

  const paymentDescriptor = getPaymentDescriptor(
    creditorIBAN,
    paymentReference || "",
    amount,
    currencyCode,
    noteForDebtor || "",
  );

  const accountNumber = getLocalBankAccountNumberFromIBAN(creditorIBAN);

  return (
    <Stack gap={6} textAlign="center">
      {description && <Typography fontSize="1rem">{description}</Typography>}
      <QrCodePayment
        iban={creditorIBAN}
        amount={amount}
        isLoading={isLoading}
        note={noteForDebtor || ""}
        paymentReference={paymentReference || ""}
        accountNumber={accountNumber}
        paymentDescriptor={paymentDescriptor}
        qrInstructions={`Neopisujte údaje ručně, využijte QR kód, který načtete ve vašem mobilním bankovnictví! ${t(Resources.Transactions.BankTransferInfo)}`}
      />
      <PaymentStatus
        transactionStatus={transactionStatus}
        paymentInstructionStatus={paymentInstructionStatus ?? undefined}
      />
    </Stack>
  );
};
