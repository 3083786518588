import type * as React from "react";

export const PlusMathIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={50}
    height={50}
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M23 9V23H9V27H23V41H27V27H41V23H27V9H23Z" fill="currentColor" />
  </svg>
);
