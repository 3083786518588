import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { AddressFields } from "Components/Users/UserDetailPageContent/Components/AddressFields";
import { FormSection } from "Components/Users/UserDetailPageContent/Components/FormSection";
import type { PartyFormFieldsProps } from "Components/Users/UserDetailPageContent/Components/PartyForm/types";
import { usePartyTypeList } from "Hooks/Options/usePartyTypeList";

export const LegalEntityFields = ({
  control,
  addresses,
}: PartyFormFieldsProps) => {
  const partyTypeList = usePartyTypeList({ onlyBusiness: false });

  return (
    <>
      <Dropdown
        codeList={partyTypeList}
        name="type"
        control={control}
        label="Typ osoby"
        disabled
      />
      <FormSection legend="Společnost">
        <FormInput
          control={control}
          name="companyName"
          label="Název společnosti"
        />
        <FormInput control={control} name="companyNumber" label="IČO" />
        <FormInput control={control} name="taxNumber" label="DIČ" />
      </FormSection>
      <AddressFields addresses={addresses} control={control} />
    </>
  );
};
