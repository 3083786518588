import { zodResolver } from "@hookform/resolvers/zod";
import { Stack } from "@mui/material";
import {
  type ErrorType,
  getTransactionErrorMessage,
  handleTransactionPayError,
  useTransactionPay,
} from "Api/Mutations/Transactions/useTransactionPay";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BankingAccountSelector } from "Components/Shared/Inputs/Form/BankingAccountSelector";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import type { ErrorResponse } from "Infrastructure/Api/Api";
import { useForm } from "react-hook-form";
import { z } from "zod";

const FormResources = Resources.Merchant.Initiate.Form;
const PageResources = Resources.Customer.Pay;

const initiateMerchantSchema = z.object({
  bankAccountID: z.number(),
  publicID: z.string().min(1),
});

type FormModel = z.infer<typeof initiateMerchantSchema>;

type Props = {
  bankAccounts: Array<BankAccountListItem>;
  defaultValues: Partial<FormModel>;
};

export const PaymentForm = ({ defaultValues, bankAccounts }: Props) => {
  const { t } = useResource();

  const { mutateAsync: payTransaction, isPending } = useTransactionPay();

  const { handleSubmit, setError, control } = useForm<FormModel>({
    resolver: zodResolver(initiateMerchantSchema),
    defaultValues,
  });

  const showErrorInForm = (error: ErrorType) => {
    setError("publicID", { message: getTransactionErrorMessage(error) });
  };

  const onSubmit = async ({ bankAccountID, publicID }: FormModel) => {
    const payload = { publicID, senderBankAccountID: bankAccountID };

    try {
      await payTransaction(payload, {
        onError: (error) => showErrorInForm(error as unknown as ErrorType), //this is typed as Error, but actually passes ErrorType
      });
    } catch (e) {
      handleTransactionPayError(e as ErrorResponse);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={1}>
        <BankingAccountSelector
          name="bankAccountID"
          control={control}
          label={t(FormResources.AccountSelectTitle)}
          bankAccounts={bankAccounts}
        />
        <FormInput
          control={control}
          name="publicID"
          label="Identifikátor transakce"
          type="text"
        />
      </Stack>
      <PrimaryButton
        fullWidth
        type="submit"
        color="primary"
        disabled={isPending}
        sx={{ mt: 2 }}
      >
        {t(PageResources.Title)}
      </PrimaryButton>
    </form>
  );
};
