import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { verifyEmailAsync } from "State/Auth/Verifications/EmailVerificationState";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

export const EmailVerificationPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (token) {
      dispatch(verifyEmailAsync.request({ command: { token }, navigate }));
    }
  }, [dispatch, navigate, token]);

  return <UnauthenticatedLayout title="Verifikace e-mailu" />;
};

export default EmailVerificationPage;
