import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { InitiateQRPaymentComponent } from "Components/QRPayment/InitiateQRPaymentComponent";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { useParams } from "react-router-dom";

export const InitiateQRPaymentPage = () => {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    return null;
  }

  return (
    <AuthenticatedLayout title="QR platba">
      <StyledPageWrapper>
        <PrimaryPaper>
          <InitiateQRPaymentComponent publicID={id} />
        </PrimaryPaper>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default InitiateQRPaymentPage;
