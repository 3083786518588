import {
  AdditionalInfoStatus,
  BankVerificationStatus,
  IdentityVerificationStatus,
  type UserVerificationDetailDto,
} from "Api/Api";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { AdditionalVerificationStep } from "Components/Verification/AdditionalVerificationStep";
import { BankAccountStep } from "Components/Verification/BankAccountStep";
import { GuidepostVerificationStep } from "Components/Verification/GuidepostVerificationStep";
import { IdentityCardsStep } from "Components/Verification/IdentityCardsStep";
import { InProgressStep } from "Components/Verification/InProgressStep";
import { NeedsInvestigationStep } from "Components/Verification/NeedsInvestigationStep";
import { VerifiedStep } from "Components/Verification/VerifiedStep";
import type { RootStateType } from "State/Store";
import { getUserVerificationDetailAsync } from "State/Verification/GetUserVerificationDetail/GetUserVerificationDetailState";
import { getAppUrl } from "Utils/UrlUtils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

type VerificationStep =
  | "additionalInfo"
  | "needsInvestigation"
  | "guidepost"
  | "identityCards"
  | "bankAccount"
  | "in-progress"
  | "verified"
  | "none";

export enum VerificationType {
  Application = "Application",
  BankID = "BankID",
}

export const VerificationPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const { detail } = useSelector((e: RootStateType) => e.verification);

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getUserVerificationDetailAsync.request());
  }, [dispatch]);

  const appUrl = getAppUrl();

  const [verificationType, setVerificationType] =
    React.useState<VerificationType | null>(null);

  const step = getStep(detail, verificationType);

  return (
    <AuthenticatedLayout title="Verifikace" goBackUrl={appUrl("settings")}>
      <StyledPageWrapper>
        <div className="left-content">
          <>
            {step === "additionalInfo" && <AdditionalVerificationStep />}
            {step === "needsInvestigation" && <NeedsInvestigationStep />}
            {step === "guidepost" && (
              <GuidepostVerificationStep
                onSubmit={(verificationType) =>
                  setVerificationType(verificationType)
                }
              />
            )}
            {step === "identityCards" && <IdentityCardsStep />}
            {step === "bankAccount" && <BankAccountStep />}
            {step === "in-progress" && <InProgressStep />}
            {step === "verified" && <VerifiedStep />}
          </>
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default VerificationPage;

function getStep(
  detail: UserVerificationDetailDto | null,
  verificationType: VerificationType | null,
): VerificationStep {
  if (!detail) {
    return "none";
  }

  if (detail.additionalInfoStatus === AdditionalInfoStatus.NotVerified) {
    return "additionalInfo";
  }

  if (detail.additionalInfoStatus === AdditionalInfoStatus.NeedsInvestigation) {
    return "needsInvestigation";
  }

  if (
    detail.identityVerificationStatus === IdentityVerificationStatus.NotVerified
  ) {
    return verificationType === VerificationType.Application
      ? "identityCards"
      : "guidepost";
  }

  if (detail.bankVerificationStatus === BankVerificationStatus.NotVerified) {
    return "bankAccount";
  }

  if (
    detail.identityVerificationStatus ===
      IdentityVerificationStatus.Processing ||
    detail.bankVerificationStatus === BankVerificationStatus.InProgress
  ) {
    return "in-progress";
  }

  return "verified";
}
