import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import CandlestickChartIcon from "@mui/icons-material/CandlestickChart";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import { Card, CardContent, Skeleton, Typography } from "@mui/material";
import type { GetSystemReportQueryResult } from "Api/Api";
import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import {
  StyledFlex,
  StyledPageWrapper,
} from "Components/Shared/StyledComponents";
import { CurrencyCode } from "Models/CurrencyCodes";
import type { RootStateType } from "State/Store";
import { getSystemReportAsync } from "State/System/SystemReport/SystemReportState";
import { formatCurrency } from "Utils/CurrencyUtils";
import { getSystemVersion } from "Utils/VersioningUtils";
import BigNumber from "bignumber.js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const Grid = styled.div`
  margin-top: ${(props) => props.theme.spacing(3)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
`;

const getBalanceControlSum = (reportResult: GetSystemReportQueryResult) => {
  const balanceControlSum = new BigNumber(reportResult.walletBalance.balance)
    .plus(new BigNumber(reportResult.coldWalletBalance.balance))
    .plus(new BigNumber(reportResult.exchangeWithdrawSum))
    .plus(new BigNumber(reportResult.sellTransactionSum))
    .minus(new BigNumber(reportResult.userAccountBalance))
    .minus(new BigNumber(reportResult.withdrawalSum));

  console.log(
    "controlSum:",
    balanceControlSum.toNumber(),
    "report.walletBalance.balance:",
    reportResult.walletBalance.balance,
    "report.coldWalletBalance.balance:",
    reportResult.coldWalletBalance.balance,
    "btcExchangeBalance:",
    reportResult.exchangeWithdrawSum,
    "report.userAccountBalance:",
    reportResult.userAccountBalance,
    "report.sellTransactionSum:",
    reportResult.sellTransactionSum,
  );
  return balanceControlSum;
};

export const SystemReportPage: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getSystemReportAsync.request());

    return () => {
      dispatch(getSystemReportAsync.success(null));
    };
  }, [dispatch]);

  const { report } = useSelector((e: RootStateType) => e.system);

  return (
    <AuthenticatedLayout title="Systémový report">
      <StyledPageWrapper>
        <div className="left-content">
          <Grid>
            <StyledFlex gap="10px" alignItems="center">
              <AccountBalanceWalletIcon />
              <Typography variant="h3">Moone</Typography>
            </StyledFlex>
            <div />

            <div>
              <Card>
                <CardContent>
                  <Typography>+ Na Hot Wallet</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.walletBalance.balance,
                        CurrencyCode.BTC,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography>+ Na Cold Wallet</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.coldWalletBalance.balance,
                        CurrencyCode.BTC,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography> + K výběru z burzy </Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.exchangeWithdrawSum,
                        CurrencyCode.BTC,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography>- Na účtech uživatelů</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.userAccountBalance,
                        CurrencyCode.BTC,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </div>

            <Card>
              <CardContent>
                <Typography variant="h3">Kontrolní suma</Typography>
                {!report ? (
                  <Skeleton width={120} />
                ) : (
                  <Typography>
                    {formatCurrency(
                      getBalanceControlSum(report).toNumber(),
                      CurrencyCode.BTC,
                    )}
                  </Typography>
                )}
              </CardContent>
            </Card>

            <Card>
              <CardContent>
                <Typography variant="h3">
                  K výběru ze systémové peňeženky
                </Typography>
                {!report ? (
                  <Skeleton width={120} />
                ) : (
                  <Typography>
                    {formatCurrency(report.withdrawalSum, CurrencyCode.BTC)}
                  </Typography>
                )}
              </CardContent>
            </Card>

            <div>
              <Card>
                <CardContent>
                  <Typography variant="h3">Blockchain fee</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(report.blockchainFee, CurrencyCode.BTC)}
                      per vByte
                    </Typography>
                  )}
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography variant="h3">Mempool Fee Level</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>{report.mempoolFeeLevel}</Typography>
                  )}
                </CardContent>
              </Card>
            </div>

            <StyledFlex gap="10px" alignItems="center">
              <CurrencyExchangeIcon />
              <Typography variant="h3">Burza</Typography>
            </StyledFlex>
            <div />

            <div>
              <Card>
                <CardContent>
                  <Typography>K výběru z burzy </Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.exchangeWithdrawSum,
                        CurrencyCode.BTC,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography>Poplatek za výběr z burzy</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.exchangeWithdrawalFee,
                        CurrencyCode.BTC,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </div>
            <div>
              {report?.coinmateBalances.map((e) => (
                <Card key={e.currencyCode}>
                  <CardContent>
                    <Typography>{`Zústatek ${e.currencyCode} na burze`}</Typography>

                    {formatCurrency(e.availableBalance, e.currencyCode)}
                  </CardContent>
                </Card>
              ))}
            </div>
            <StyledFlex gap="10px" alignItems="center">
              <CandlestickChartIcon />
              <Typography variant="h3">Obchody</Typography>
            </StyledFlex>
            <div />
            <div>
              <Card>
                <CardContent>
                  <Typography>Obchodovaný objem</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.coinmateVolume.askedVolume,
                        report.coinmateVolume.currencyCode,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography>Za měsíc</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.coinmateMonthlyVolume.askedVolume,
                        report.coinmateMonthlyVolume.currencyCode,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography>Za týden</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.coinmateWeeklyVolume.askedVolume,
                        report.coinmateWeeklyVolume.currencyCode,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography>Za 24h</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.coinmateDailyVolume.askedVolume,
                        report.coinmateDailyVolume.currencyCode,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </div>
            <div>
              <Card>
                <CardContent>
                  <Typography>Nakoupené BTC</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.coinmateBoughtBtc,
                        CurrencyCode.BTC,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography>Vybráno nebo k výběru</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.withdrawalTransactionSum,
                        CurrencyCode.BTC,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>
              <Card>
                <CardContent>
                  <Typography>Suma účetní knihy BTC</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.accountingSumBTC,
                        CurrencyCode.BTC,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>

              <Card>
                <CardContent>
                  <Typography>Suma účetní knihy CZK</Typography>
                  {!report ? (
                    <Skeleton width={120} />
                  ) : (
                    <Typography>
                      {formatCurrency(
                        report.accountingSumCZK,
                        CurrencyCode.CZK,
                      )}
                    </Typography>
                  )}
                </CardContent>
              </Card>
            </div>
          </Grid>
          <br />
          Verze systému: {getSystemVersion()}
        </div>
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};

export default SystemReportPage;
