import { BalanceContainer } from "Components/Dashboard/BalanceWithPaymentActions/BalanceContainer";
import { DesktopBalanceSkeleton } from "Components/Dashboard/BalanceWithPaymentActions/DesktopBalance/Components/DesktopBalanceSkeleton";
import { PaymentActions } from "Components/Dashboard/BalanceWithPaymentActions/DesktopBalance/Components/PaymentActions";
import { Balance } from "Components/Shared/Balance";
import { CommonError } from "Components/Shared/Errors/CommonError";
import { PaperTitle } from "Components/Shared/PaperTitle";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { Resources, useResource } from "Translations/Resources";
const BalanceResources = Resources.Balance.BalanceContent;

export const DesktopBalance = () => {
  const { t } = useResource();

  return (
    <PrimaryPaper>
      <PaperTitle>{t(BalanceResources.Title)}</PaperTitle>
      <BalanceContainer
        renderBalance={Balance}
        renderActions={PaymentActions}
        renderLoading={DesktopBalanceSkeleton}
        renderError={() => <CommonError withoutPaper />}
      />
    </PrimaryPaper>
  );
};
