import { FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import {
  StyledFormControl,
  StyledFormInput,
  StyledFormLabelWrapper,
  StyledValidationText,
} from "Components/Shared/FormStyles";
import type { CodeListItem } from "Models/ICodeListDto";
import {
  type Control,
  Controller,
  type FieldPath,
  type FieldValues,
} from "react-hook-form";
import styled from "styled-components";

type Props<TFormType extends FieldValues> = {
  codeList: CodeListItem[];
  name: FieldPath<TFormType>;
  control: Control<TFormType, object>;
  label?: string;
  useCodesAsLabels?: boolean;
  disabled?: boolean;
  row?: boolean;
  onChange?: (value: string) => void;
};

const StyledFormControlLabel = styled(FormControlLabel)<{
  $isSelected: boolean;
}>`
  display: flex;
  flex-flow: row;
  align-items: flex-start;
  margin-left: ${(props) => props.theme.spacing(1)};
  margin-right: ${(props) => props.theme.spacing(5)};

  .MuiFormControlLabel-label {
    padding-top: 5px;
    p {
      font-weight: 500;
      font-size: 18px;
    }
  }

  color: ${(props) => props.$isSelected && props.theme.palette.primary.main};
`;

const StyledIcon = styled.div`
  width: 16px;
  min-width: 16px;
  height: 16px;
  position: relative;
  border-radius: 50%;
  border: 1px solid ${(props) => props.theme.palette.text.secondary};
`;

const StyledIconChecked = styled(StyledIcon)`
  &::before {
    content: "";
    position: absolute;
    left: 2px;
    top: 2px;
    width: 10px;
    height: 10px;
    background-color: ${(props) => props.theme.palette.primary.light};
    border-radius: 50%;
  }
`;

const Description = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 12px !important;
`;

export const RadioButtons = <T extends object>(props: Props<T>) => {
  const { codeList, name, control, useCodesAsLabels, label, disabled, row } =
    props;
  const defaultValue = codeList[0]?.code;

  return (
    <StyledFormControl>
      <StyledFormLabelWrapper>
        {/* Nice to have: should be <legend> in a <fieldset> instead of <label>  */}
        <label htmlFor={name}>{label}</label>
      </StyledFormLabelWrapper>
      <Controller
        control={control}
        defaultValue={defaultValue as any}
        name={name}
        render={({
          field: { value, name, onChange, onBlur, ref },
          fieldState: { error },
        }) => (
          <>
            <StyledFormInput>
              <RadioGroup
                value={value ?? ""}
                name={name}
                onChange={(e) => {
                  if (props.onChange) {
                    props.onChange(e.target.value);
                  }
                  onChange(e.target.value);
                }}
                onBlur={onBlur}
                row={row}
                className="radio-group"
                ref={ref}
              >
                {codeList.map((item) => (
                  <StyledFormControlLabel
                    $isSelected={item.code === value}
                    disabled={disabled}
                    key={item.code.toString()}
                    value={item.code.toString()}
                    control={
                      <Radio
                        icon={<StyledIcon />}
                        checkedIcon={<StyledIconChecked />}
                      />
                    }
                    label={
                      <div>
                        <Typography>
                          {useCodesAsLabels === true ? item.code : item.name}
                        </Typography>

                        {!!item.description && (
                          <Description>{item.description}</Description>
                        )}
                      </div>
                    }
                  />
                ))}
              </RadioGroup>
            </StyledFormInput>
            <StyledValidationText>{error?.message}</StyledValidationText>
          </>
        )}
      />
    </StyledFormControl>
  );
};
