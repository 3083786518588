import { useQuery } from "@tanstack/react-query";
import {
  PaymentInstructionStatus,
  type TransactionStatusResponse,
  getTransactionsStatus,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const useTransactionsStatus = (
  publicID: string,
  { enabled }: { enabled: boolean } = { enabled: true },
) =>
  useQuery<TransactionStatusResponse>({
    queryKey: ["TRANSACTIONS_STATUS", publicID],
    queryFn: processResponseWithApiProblemDetails(() =>
      getTransactionsStatus(publicID),
    ),
    refetchInterval: (data) => {
      if (
        data?.state?.data?.paymentInstructionStatus !==
        PaymentInstructionStatus.AcceptedSettlementCompleted
      ) {
        return 1_000;
      }

      return false;
    },
    enabled,
  });
