import { Balance } from "Components/Shared/Balance";
import { BankImage } from "Components/Shared/BankImage";
import { StyledFlex } from "Components/Shared/StyledComponents";
import type { Bank } from "Infrastructure/Api/Api";

type Props = {
  balance?: number;
  isLoading?: boolean;
  bank?: Bank;
  prefix?: string;
};

export const AccountBalance = ({ balance, isLoading, bank, prefix }: Props) => (
  <StyledFlex justifyContent="space-between" alignItems="flex-end">
    <Balance balance={balance ?? 0} isLoading={isLoading} prefix={prefix} />
    <BankImage bank={bank} isLoading={isLoading} />
  </StyledFlex>
);
