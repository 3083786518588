import type * as React from "react";
export const GmailLogoIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={50}
      height={50}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.5 7C3.01953 7 1 9.01953 1 11.5V11.9258L25 29L49 11.9258V11.5C49 9.01953 46.9805 7 44.5 7H5.5ZM6.35156 9H43.6445L25 22L6.35156 9ZM1 14.0273V38.5C1 40.9805 3.01953 43 5.5 43H44.5C46.9805 43 49 40.9805 49 38.5V14.0273L43 18.2969V41H7V18.2969L1 14.0273Z"
        fill="currentColor"
      />
    </svg>
  );
};
