import { Button, Stack, Typography } from "@mui/material";
import {
  handleTransactionPayError,
  useTransactionPay,
} from "Api/Mutations/Transactions/useTransactionPay";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { BankingAccountSelector } from "Components/Shared/Inputs/Form/BankingAccountSelector";
import type { ErrorResponse } from "Infrastructure/Api/Api";
import { FORCE_BANK_ACCOUNT_SELECT_STEP } from "Pages/Customer/AnonymousCustomerPaymentPayPage";
import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";

type FormModel = {
  bankAccountID: number;
};

const ComponentResources = Resources.Customer.Pay;

type BankAccountSelectorProps = {
  transactionPublicID: string;
  isAddingAccountDisabled?: boolean;
};

export const BankAccountSelector = ({
  transactionPublicID,
  isAddingAccountDisabled,
}: BankAccountSelectorProps) => {
  const { t } = useResource();
  const { data } = useBankingAccounts();
  const {
    control,
    handleSubmit,
    formState: { isDirty },
  } = useForm<FormModel>();
  const navigate = useNavigate();
  const { mutateAsync: payTransaction, isPending } = useTransactionPay();

  const onSubmit = async ({ bankAccountID }: FormModel) => {
    try {
      await payTransaction({
        publicID: transactionPublicID,
        senderBankAccountID: bankAccountID,
      });
    } catch (e) {
      handleTransactionPayError(e as ErrorResponse);
    }
  };

  const onAddBankAccount = () => {
    navigate({ search: `?step=${FORCE_BANK_ACCOUNT_SELECT_STEP}` });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={3}>
        <Typography>{t(ComponentResources.ChooseBankAccount)}</Typography>
        <BankingAccountSelector
          bankAccounts={data?.items ?? []}
          control={control}
          name="bankAccountID"
        />
        <PrimaryButton
          fullWidth
          type="submit"
          color="primary"
          disabled={!isDirty}
          isLoading={isPending}
        >
          {t(ComponentResources.PayButton)}
        </PrimaryButton>
        {!isAddingAccountDisabled && (
          <Button
            fullWidth
            type="button"
            color="info"
            variant="outlined"
            onClick={onAddBankAccount}
            sx={{ p: 3 }}
          >
            {t(ComponentResources.AddNextBankAccount)}
          </Button>
        )}
      </Stack>
    </form>
  );
};
