import { Box, LinearProgress, Typography } from "@mui/material";
import { useLicenseQuery } from "../../../Api/Queries/Licenses/useLicenseQuery";
import { DEFAULT_COUNTRY_ALPHA2_CODE } from "../LicenseOrderForm/constants";

const TARGET_PROGRESS_AMOUNT = 2_506_010; // 2_536_000 - 29_990

export const LicenseProgressBar = () => {
  const {
    data: { items: licenses } = {},
    isLoading: isLicensesLoading,
    isError: isLicensesError,
  } = useLicenseQuery(DEFAULT_COUNTRY_ALPHA2_CODE);

  if (isLicensesLoading || isLicensesError || !licenses) {
    return null;
  }

  const totalLicenseIncome = licenses.reduce(
    (acc, license) => acc + license.soldAmount * license.price,
    0,
  );
  const normalizedProgress = Math.sqrt(
    totalLicenseIncome / TARGET_PROGRESS_AMOUNT,
  );
  const progress = Math.min(normalizedProgress * 100, 100);

  return (
    <Box sx={{ width: "100%", my: 3 }}>
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Typography variant="body2" color="text.secondary">
          Ceny se zvýší o DPH jakmile splníme limit pro rok 2025
        </Typography>
      </Box>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: 10,
          borderRadius: 5,
          backgroundColor: (theme) => theme.palette.grey[300],
          "& .MuiLinearProgress-bar": {
            borderRadius: 5,
          },
        }}
      />
    </Box>
  );
};
