import { Stack, Typography } from "@mui/material";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import type { ReactNode } from "react";

export const FAQ = () => (
  <Stack gap={3}>
    <Typography variant="h2" mb={3} textAlign="center">
      NEJČASTĚJŠÍ DOTAZY (FAQ)
    </Typography>
    <QuestionWithAnswer
      question="Je to multilevel nebo síťový marketing?"
      answer={
        <Typography>
          Ne. Každý Hunter podniká samostatně. Bez struktur, bez povinného
          náboru.
        </Typography>
      }
    />
    <QuestionWithAnswer
      question="Musím při registraci nahrát občanku?"
      answer={
        <Typography>
          Ano, registrací do aplikace Mo.one se stáváte zákazníkem regulované
          společnosti ZNPay a.s., která je ze zákona povinna ověřit vaši
          totožnost.
        </Typography>
      }
    />
    <QuestionWithAnswer
      question="Musím mít zkušenosti?"
      answer={
        <Typography>
          Nemusíš. Vše potřebné tě naučíme – krok za krokem.
        </Typography>
      }
    />
    <QuestionWithAnswer
      question="Kolik si mohu vydělat?"
      answer={
        <Typography>
          Záleží jen na tvé aktivitě. Od několika tisíc měsíčně až po vlastní
          byznys, který lze prodat.
        </Typography>
      }
    />
    <QuestionWithAnswer
      question="Co když si to rozmyslím?"
      answer={
        <Stack>
          <Typography>
            Registrace do Mo.one v odkazu nahoře je zdarma.
          </Typography>
          <Typography>
            Můžeš si vyzkoušet jak vypadá prostředí, do kterého se registrují i
            obchodníci. Testovací licence za 1 Kč umožňuje vyzkoušet přímou
            platbu.
          </Typography>
          <Typography>
            Pokud se rozhodneš zakoupit licenci, můžeš ji následně kdykoliv
            prodat.
          </Typography>
        </Stack>
      }
    />
    <QuestionWithAnswer
      question="Proč vzniklo Legi.one?"
      answer={
        <Stack>
          <Typography>
            Každý den proběhnou v Česku bezhotovostní transakce za více než 4,5
            miliardy Kč. Na poplatcích se ročně vydělá přes 25 miliard Kč –
            většinu z toho inkasují zahraniční technologické firmy a banky.
          </Typography>
          <Typography my={2}>My to měníme.</Typography>
          <Typography>
            S produktem <strong>Mo.one</strong> zavádíme nový způsob přijímání plateb – přímou
            platbu bez prostředníků jako Visa nebo Mastercard.
            <br />
            Obchodník dostává peníze ihned. Ty jako Hunter jsi u toho – pomáháš,
            vzděláváš, stavíš vztah.
          </Typography>
        </Stack>
      }
    />
  </Stack>
);

const QuestionWithAnswer = ({
  question,
  answer,
}: { question: string; answer: ReactNode }) => (
  <PrimaryPaper
    sx={{ width: "100%", textAlign: "center", py: "16px !important" }}
  >
    <Stack gap={3}>
      <Typography variant="h6" fontWeight={600}>
        {question}
      </Typography>
      {answer}
    </Stack>
  </PrimaryPaper>
);
