import Typography from "@mui/material/Typography";
import { InvoiceQRCode } from "Components/Invoices/InvoiceQRCode";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { CurrencyCode } from "Models/CurrencyCodes";
import { getInvoiceListAsync } from "State/Invoices/List/GetInvoiceListState";
import type { RootStateType } from "State/Store";
import { getInvoicePaymentDescriptor } from "Utils/BankUtils";
import { formatCurrency } from "Utils/CurrencyUtils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

type Props = {
  amount?: number;
  currencyCode: CurrencyCode;
};

const RowFactory = (isCZK: boolean) => styled.div`
  display: grid;
  grid-template-columns: ${isCZK ? "1fr 1fr" : "2fr 4fr"};
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Trivia = styled(Typography)`
  color: ${(props) => props.theme.palette.text.secondary};
  font-size: 13px;
  margin: ${(props) => props.theme.spacing(1, 0, 3, 0)};
`;

const Left = styled(Typography)`
  font-size: 16px;
`;

const Right = styled(Typography)`
  font-size: 18px;
  font-weight: 500;
  justify-self: flex-end;
  word-break: break-word;
  text-align: end;
`;

const Wrapper = styled.div`
  > div {
    box-shadow: unset;
  }
`;

export const Invoice = ({ amount, currencyCode }: Props) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getInvoiceListAsync.request());
  }, [dispatch]);
  const { invoiceList } = useSelector((e: RootStateType) => e.invoice);
  const invoice = invoiceList?.items[0];

  const currencyAmount = amount
    ? amount
    : currencyCode === CurrencyCode.CZK
      ? 300
      : (amount ?? 300) / 25;

  const Row = RowFactory(currencyCode === CurrencyCode.CZK);

  return (
    <>
      {!!invoice && (
        <Wrapper>
          <PrimaryPaper>
            <Typography fontWeight={600}>Platební údaje:</Typography>

            {currencyCode === CurrencyCode.CZK && (
              <>
                <Row>
                  <Left>Číslo účtu:</Left>
                  <Right>{invoice.accountNumber}</Right>
                </Row>
                <Row>
                  <Left>Variabilní symbol:</Left>
                  <Right>{invoice.payerReference}</Right>
                </Row>
                <Row>
                  <Left>Specifický symbol:</Left>
                  <Right>{invoice.paymentReference}</Right>
                </Row>
              </>
            )}

            {currencyCode === CurrencyCode.EUR && (
              <>
                <Row>
                  <Left>IBAN:</Left>
                  <Right>{invoice.iban}</Right>
                </Row>
                <Row>
                  <Left>Reference platby:</Left>
                  <Right>{`VS${invoice.payerReference}/SS${invoice.paymentReference}/KS`}</Right>
                </Row>
              </>
            )}

            {!!amount && (
              <Row>
                <Left>Částka:</Left>
                <Right>{formatCurrency(currencyAmount, currencyCode)}</Right>
              </Row>
            )}

            <Row>
              <Left>Poznámka:</Left>
              <Right>Objednavka Moone</Right>
            </Row>

            <Trivia>
              Poznámku je nutné uvést! Bez poznámky nebude platba spárována
            </Trivia>
            <InvoiceQRCode
              key={currencyCode}
              paymentDescriptor={getInvoicePaymentDescriptor(
                invoice,
                currencyAmount,
                currencyCode,
              )}
            />
          </PrimaryPaper>
        </Wrapper>
      )}
    </>
  );
};
