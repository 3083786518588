import { Dropdown } from "Components/Shared/Inputs/Form/Dropdown";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { FormSection } from "Components/Users/UserDetailPageContent/Components/FormSection";
import { useAdditionalInfoStatusList } from "Hooks/Options/useAdditionalInfoStatusList";
import { useBankVerificationStatusList } from "Hooks/Options/useBankVerificationStatusList";
import { useIdentityVerificationStatusList } from "Hooks/Options/useIdentityVerificationStatusList";
import { useRiskProfileStatusList } from "Hooks/Options/useRiskProfileStatusList";
import { useUserStatusList } from "Hooks/Options/useUserStatusList";
import type { UserAdminDetailDto } from "Infrastructure/Api/Api";
import type { Control } from "react-hook-form";

type UserFormFieldsProps = {
  control: Control<UserAdminDetailDto>;
};

export const UserFormFields = ({ control }: UserFormFieldsProps) => {
  const additionalInfoList = useAdditionalInfoStatusList();
  const identityVerificationList = useIdentityVerificationStatusList();
  const riskProfileList = useRiskProfileStatusList();
  const bankVerificationList = useBankVerificationStatusList();
  const userStatusList = useUserStatusList();

  return (
    <FormSection>
      <FormInput control={control} name="login" label="Login" />
      <Dropdown
        name="status"
        label="Status"
        codeList={userStatusList}
        control={control}
      />
      <Dropdown
        name="verificationAdditionalInfoStatus"
        label="Status doplňujících informací"
        codeList={additionalInfoList}
        control={control}
      />
      <Dropdown
        name="verificationIdentityVerificationStatus"
        label="Status ověření identity"
        codeList={identityVerificationList}
        control={control}
      />
      <Dropdown
        name="verificationRiskProfile"
        label="Rizikový profil"
        codeList={riskProfileList}
        control={control}
      />
      <Dropdown
        name="verificationBankVerificationStatus"
        label="Status bankovní verifikace"
        codeList={bankVerificationList}
        control={control}
      />
      <FormInput
        isNumber
        name="verificationDailyBuyLimit"
        label="Denní limit"
        mask={Number}
        control={control}
      />
      <FormInput
        isNumber
        name="verificationMonthlyBuyLimit"
        label="Měsíční limit"
        mask={Number}
        control={control}
      />
    </FormSection>
  );
};
