import { VerificationCell } from "Components/Users/VerificationCell";
import { isUserVerified } from "Components/Users/utils";
import type { UserDto } from "Infrastructure/Api/Api";
import { formatDate } from "Utils/DateUtils";
import { appUrl } from "Utils/UrlUtils";
import {
  type MRT_ColumnDef,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { MRT_Localization_CS } from "material-react-table/locales/cs";
import { useNavigate } from "react-router-dom";

type UserWithLicensePaymentReference = UserDto & { paymentReference: string };

const COLUMNS: Array<MRT_ColumnDef<UserWithLicensePaymentReference>> = [
  {
    accessorKey: "CustomVerificationsBadge",
    header: "Ověření",
    size: 0,
    Cell: ({ row }) => <VerificationCell user={row.original} />,
    filterVariant: "select",
    filterSelectOptions: [
      { label: "Ověřeno", value: "verified" },
      { label: "Neověřeno", value: "notVerified" },
    ],
    filterFn: (row, _id, filterValue) => {
      const isVerified = isUserVerified(row.original);
      return filterValue === "verified" ? isVerified : !isVerified;
    },
  },
  {
    accessorKey: "login",
    header: "Login",
    size: 150,
  },
  {
    accessorKey: "party.firstName",
    header: "Jméno",
    size: 130,
  },
  {
    accessorKey: "party.lastName",
    header: "Příjmení",
    size: 130,
  },
  {
    accessorKey: "party.companyName",
    header: "Společnost",
    size: 200,
  },
  {
    accessorFn: ({ dateCreated }) => new Date(dateCreated), //convert to date for sorting and filtering
    header: "Datum vytvoření",
    size: 170,
    filterVariant: "date",
    filterFn: "greaterThanOrEqualTo",
    Cell: ({ cell }) => formatDate(cell.getValue<Date>().toString(), true), //convert back to string for display
  },
  {
    accessorKey: "paymentReference",
    header: "Var. s. platby licence",
    size: 180,
  },
];

type Props = {
  data: Array<UserWithLicensePaymentReference>;
  isRefetching?: boolean;
  isLoading?: boolean;
};

export const UserListTable = ({ data, isRefetching, isLoading }: Props) => {
  const navigate = useNavigate();
  const table = useMaterialReactTable({
    data,
    columns: COLUMNS,
    enableColumnResizing: true,
    columnResizeMode: "onChange",
    localization: MRT_Localization_CS,
    state: {
      isLoading,
      showProgressBars: isRefetching,
    },
    initialState: {
      density: "compact",
      pagination: { pageSize: 15, pageIndex: 0 },
    },
    muiTableBodyRowProps: ({ row }) => ({
      onClick: () =>
        navigate(`${appUrl("user-detail")}/${row.original.userID}`),
      sx: { cursor: "pointer" },
    }),
    muiTablePaperProps: {
      sx: { borderRadius: "10px" },
    },
  });

  return <MaterialReactTable table={table} />;
};
