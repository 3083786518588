import type * as React from "react";

export const DoneIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={51}
    height={50}
    viewBox="0 0 51 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M42.4375 8.62522C41.7734 8.64865 41.164 9.00022 40.8125 9.56272L22 38.344L9.81245 27.8127C9.28901 27.2697 8.50386 27.0666 7.7812 27.2932C7.06245 27.5158 6.52729 28.1252 6.40229 28.8674C6.27729 29.6135 6.57808 30.3635 7.18745 30.8127L21.125 42.8752C21.5625 43.2463 22.1406 43.4104 22.707 43.3283C23.2773 43.2424 23.7812 42.9182 24.0937 42.4377L44.1875 11.7502C44.6171 11.1213 44.6523 10.3088 44.2812 9.64475C43.9101 8.98459 43.1953 8.59006 42.4375 8.62522Z"
      fill="currentColor"
    />
  </svg>
);
