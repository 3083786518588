import { AuthenticatedLayout } from "Components/Layout/AuthenticatedLayout";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import type { RootStateType } from "State/Store";
import { isAuthenticated } from "Utils/AuthUtils";
import { useSelector } from "react-redux";

export const ModuleLoadingFallback: React.FunctionComponent<
  React.PropsWithChildren<unknown>
> = (_) => {
  const { user } = useSelector((e: RootStateType) => e.auth);

  if (!isAuthenticated(user)) {
    return null;
  }

  return (
    <AuthenticatedLayout title="Přehled">
      <StyledPageWrapper>
        <div className="left-content" />
      </StyledPageWrapper>
    </AuthenticatedLayout>
  );
};
