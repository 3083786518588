import { LeadStatus } from "Infrastructure/Api/Api";

export const translateStatus = (status: LeadStatus) => {
  switch (status) {
    case LeadStatus.Invited:
      return "Pozván";
    case LeadStatus.Registered:
      return "Registrován";
    case LeadStatus.Completed:
    case LeadStatus.CompletedRewardSent:
    case LeadStatus.CompletedRewardApplied:
      return "Dokončeno";
  }
};
