import { Stack } from "@mui/material";
import { useTransactionsDetail } from "Api/Queries/Transactions/useTransactionsDetail";
import { useTransactionsStatus } from "Api/Queries/Transactions/useTransactionsStatus";
import { PaymentStatus } from "Components/Merchant/InitiatePaymentDetail/PaymentStatus";
import { QrCodePayment } from "Components/Shared/Payments/QrCodePayment";
import { getPaymentLinkDescriptor } from "Utils/BankUtils";
import { useEffect, useState } from "react";

type Props = {
  publicID: string;
};

export const PaymentDetail = ({ publicID }: Props) => {
  const { data: transactionsDetail, isLoading: isLoadingTransactionsDetail } =
    useTransactionsDetail(publicID);
  const { data: status, isLoading: isLoadingStatus } =
    useTransactionsStatus(publicID);
  const [paymentDescriptor, setPaymentDescriptor] = useState<string>();

  useEffect(() => {
    if (transactionsDetail?.publicID) {
      setPaymentDescriptor(
        getPaymentLinkDescriptor(transactionsDetail.publicID),
      );
    }
  }, [transactionsDetail?.publicID]);

  useEffect(() => {
    if (transactionsDetail?.signingUrl) {
      window.location.href = transactionsDetail.signingUrl;
    }
  }, [transactionsDetail?.signingUrl]);

  const transactionStatus =
    status?.transactionStatus ?? transactionsDetail?.transactionStatus;
  const paymentInstructionStatus =
    status?.paymentInstructionStatus ??
    transactionsDetail?.paymentInstructionStatus;

  return (
    <Stack gap={10} textAlign="center">
      <QrCodePayment
        iban={transactionsDetail?.creditorIBAN}
        isLoading={isLoadingTransactionsDetail}
        amount={transactionsDetail?.amount}
        paymentReference={transactionsDetail?.paymentReference ?? undefined}
        paymentCode={transactionsDetail?.publicID}
        note={transactionsDetail?.noteForDebtor ?? undefined}
        paymentDescriptor={paymentDescriptor}
      />
      <PaymentStatus
        transactionStatus={transactionStatus}
        paymentInstructionStatus={paymentInstructionStatus ?? undefined}
        isLoading={
          isLoadingStatus && !transactionStatus && !paymentInstructionStatus
        }
      />
    </Stack>
  );
};
