import {
  Stack,
  Box,
  Toolbar,
  AppBar,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { Contacts } from "Components/Auth/SignUp/Hunter/Contacts";
import { FAQ } from "Components/Auth/SignUp/Hunter/FAQ";
import { GetLicenseButton } from "Components/Auth/SignUp/Hunter/GetLicenseButton";
import { Headline } from "Components/Auth/SignUp/Hunter/Headline";
import { HunterRoleExplanation } from "Components/Auth/SignUp/Hunter/HunterRoleExplanation";
import { MooneInfoDark } from "Components/Auth/SignUp/Hunter/MooneInfoDark";
import { MoreInfoButton } from "Components/Auth/SignUp/Hunter/MoreInfoButton";
import { Partners } from "Components/Auth/SignUp/Hunter/Partners";
import { SignUpFormSection } from "Components/Auth/SignUp/Hunter/SignUpFormSection";
import { Video } from "Components/Auth/SignUp/Hunter/Video";
import { UnauthenticatedLayout } from "Components/Layout/UnauthenticatedLayout";
import { useEffect, useRef, useState } from "react";

import legione from "Assets/Images/Images/legione.svg";
import { useLocation } from "react-router-dom";
import { useIsMobile } from "Hooks/useIsMobile";
import { Colors } from "Components/Layout/Themes/Colors";

export const HunterSignUpPage = () => {
  const emailFieldRef = useRef<HTMLDivElement>(null);

  const scrollToSignUp = () => {
    scrollToSection("signUpForm");

    setTimeout(() => {
      emailFieldRef.current?.focus();
    }, 700); //first scroll, then focus
  };

  const { hash } = useLocation();
  const isMobile = useIsMobile();
  const [activeSection, setActiveSection] = useState<LandingPageSection>(
    (hash.substring(1) as LandingPageSection) || "intro",
  );

  const scrollToSection = (id: LandingPageSection) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
      window.history.pushState(null, "", `#${id}`); // Update URL without page reload
      setActiveSection(id);
    }
  };

  const scrollMarginTop = isMobile ? 0 : 64;

  return (
    <UnauthenticatedLayout title="Staňte se Hunterem">
      <ScrollToHash />
      {!isMobile && (
        <Navbar
          activeSection={activeSection}
          scrollToSection={scrollToSection}
          scrollToSignUp={scrollToSignUp}
        />
      )}
      <Stack alignItems="center" gap={8} pb={4}>
        <Box component="section" id="intro" sx={{ scrollMarginTop }}>
          <Stack minHeight="100vh" justifyContent="space-between" gap={4}>
            <Box height="64px" />
            <Box height="100%" display="flex" flexGrow={1}>
              <Stack gap={4} alignSelf="center">
                <Headline />
                <Video />
                <Partners />
              </Stack>
            </Box>
            <Box mb={8} width="100%" display="flex" justifyContent="center">
              <MoreInfoButton onClick={() => scrollToSection("howItWorks")} />
            </Box>
          </Stack>
        </Box>
        <Box
          component="section"
          id="howItWorks"
          sx={{
            scrollMarginTop: scrollMarginTop + 16,
            width: ["95vw", "70vw"],
          }}
        >
          <HunterRoleExplanation />
        </Box>
        <MooneInfoDark />
        <Box
          component="section"
          id="signUpForm"
          sx={{ scrollMarginTop: scrollMarginTop + 16, maxWidth: "570px" }}
        >
          <Typography variant="h2" mt={6} mb={3} mx={2} textAlign="center">
            ZAČNĚTE TADY A TEĎ!
          </Typography>
          <SignUpFormSection emailFieldRef={emailFieldRef} />
        </Box>
        <Divider sx={{ width: "100%", mt: 10 }} />
        <Box
          component="section"
          id="faq"
          sx={{ scrollMarginTop: scrollMarginTop + 16, width: "70vw" }}
        >
          <FAQ />
        </Box>
        <GetLicenseButton onClick={scrollToSignUp} />
        <Contacts />
      </Stack>
    </UnauthenticatedLayout>
  );
};

export default HunterSignUpPage;

type LandingPageSection = "intro" | "howItWorks" | "faq" | "signUpForm";

const NAV_LINKS: Array<{ label: string; section: LandingPageSection }> = [
  { label: "Úvod", section: "intro" },
  { label: "Jak to funguje", section: "howItWorks" },
  { label: "Časté dotazy", section: "faq" },
];

const Navbar = ({
  activeSection,
  scrollToSection,
  scrollToSignUp,
}: {
  activeSection: LandingPageSection;
  scrollToSection: (id: LandingPageSection) => void;
  scrollToSignUp: () => void;
}) => {
  return (
    <AppBar sx={{ bgcolor: "white", borderBottom: "1px solid #F5F5F5" }}>
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 10,
        }}
      >
        {/* Left Logo */}
        <Box
          component="img"
          src={legione}
          height="32px"
          mb="-5px" //move a bit so it appears better centered
          onClick={() => scrollToSection("intro")}
        />

        {/* Navigation Links */}
        <Box sx={{ display: "flex", gap: 10 }}>
          {NAV_LINKS.map(({ label, section }) => (
            <Button
              key={section}
              variant="text"
              sx={{
                color: activeSection === section ? "" : Colors.Text,
                fontWeight: 500,
              }}
              onClick={() => scrollToSection(section)}
            >
              {label}
            </Button>
          ))}
        </Box>

        {/* Right Register Button */}
        <Button variant="contained" onClick={scrollToSignUp}>
          Registrovat
        </Button>
      </Toolbar>
    </AppBar>
  );
};

const ScrollToHash = () => {
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const section = document.getElementById(hash.substring(1));
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return null;
};
