import { Box, useMediaQuery, useTheme } from "@mui/material";
import mobileBg from "Assets/Images/Images/mobile-bg.webp";
import {
  BOTTOM_MENU_HEIGHT,
  BottomMenu,
} from "Components/Layout/Menu/BottomMenu";
import { PageTitle } from "Components/Shared/PageTitles/PageTitle";
import { useHasBackgroundImage } from "Hooks/Layout/useHasBackgroundImage";
import { useIsMobile } from "Hooks/useIsMobile";
import { Resources, useResource } from "Translations/Resources";
import type { ReactNode } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { Menu } from "./Menu/Menu";

const StyledWrapper = styled(Box)<{ $isPaddingDisabled?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ theme, $isPaddingDisabled }) =>
    $isPaddingDisabled ? 0 : theme.spacing(6, 3)};
  z-index: 2;
`;

const StyledChildrenWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
`;

type Props = {
  title: string;
  children: ReactNode;
  goBackUrl?: string;
  isTitleHiddenBelowLgScreen?: boolean;
  isPaddingDisabled?: boolean;
  onChevronClick?: () => void;
};

export const AuthenticatedLayout = ({
  title,
  children,
  goBackUrl,
  onChevronClick,
  isPaddingDisabled,
  isTitleHiddenBelowLgScreen,
}: Props) => {
  const { t } = useResource();
  const theme = useTheme();
  const isMobile = useIsMobile();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));
  const { hasBackgroundImage } = useHasBackgroundImage();

  useEffect(() => {
    document.title = `${title} | ${t(Resources.Common.AppName)}`;
  }, [title, t]);

  const isTitleVisible = isTitleHiddenBelowLgScreen ? isLargeScreen : true;

  return (
    <>
      <Box
        overflow="hidden"
        display="flex"
        paddingBottom={isMobile ? BOTTOM_MENU_HEIGHT : undefined}
      >
        {hasBackgroundImage && (
          <Box
            sx={{
              zIndex: "1",
              position: "absolute",
              top: 0,
              width: "100%",
              minWidth: "350px",
              height: "400px",
            }}
          >
            <img
              src={mobileBg}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
              alt="Top background"
            />
          </Box>
        )}
        {!isMobile && (
          <Menu
            title={title}
            isVisible={true}
            goBackUrl={goBackUrl}
            onChevronClick={onChevronClick}
          />
        )}
        <StyledWrapper
          marginTop={hasBackgroundImage ? 30 : 0}
          $isPaddingDisabled={isPaddingDisabled}
        >
          {isTitleVisible && (
            <Box mb={6}>
              <PageTitle
                title={title}
                goBackUrl={goBackUrl}
                onChevronClick={onChevronClick}
              />
            </Box>
          )}
          <StyledChildrenWrapper>{children}</StyledChildrenWrapper>
        </StyledWrapper>
      </Box>
      {isMobile && <BottomMenu />}
    </>
  );
};
