import { useQuery } from "@tanstack/react-query";
import { getBankingAuthCheckExpiration } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const BANKING_AUTHS_EXPIRATION_QUERY_KEY = [
  "BANKING_AUTHS_EXPIRING_ENVIRONMENTS",
];

export const useGetBankingAuthExpiration = () =>
  useQuery({
    queryKey: BANKING_AUTHS_EXPIRATION_QUERY_KEY,
    queryFn: processResponseWithApiProblemDetails(() =>
      getBankingAuthCheckExpiration(),
    ),
    staleTime: 1000 * 60 * 60, // 1h
  });
