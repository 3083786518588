import { yupResolver } from "@hookform/resolvers/yup";
import { Typography } from "@mui/material";
import { type InvoiceSettingsDto, OwnershipType } from "Api/Api";
import { OwnershipRadioButtons } from "Components/Settings/InvoiceSettings/OwnershipRadioButtons";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { Resources, useResource } from "Translations/Resources";
import { getCurrentNetwork } from "Utils/BlockchainUtils";
import { nameof } from "Utils/ObjectUtils";
import {
  type Network,
  validate as validateAddress,
} from "bitcoin-address-validation";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import { type ObjectSchema, mixed, object, string } from "yup";

type Props = {
  model: InvoiceSettingsDto;
  onSubmit: (model: InvoiceSettingsDto) => void;
};

const StyledForm = styled.form`
  margin-top: ${(props) => props.theme.spacing(1)};
`;

const StyledSubmitButton = styled(PrimaryButton)`
  margin: ${(props) => props.theme.spacing(2, 0, 2)};
`;

export const InvoiceSettingsForm: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = (props) => {
  const { model, onSubmit } = props;

  const { t } = useResource();
  const network = getCurrentNetwork();

  const validationSchema: ObjectSchema<InvoiceSettingsDto> = object({
    ownershipType: mixed<OwnershipType>()
      .oneOf(Object.values(OwnershipType))
      .defined(),
    cryptoAddress: string().when(
      nameof<InvoiceSettingsDto>("ownershipType"),
      ([ownershipType], schema) => {
        return ownershipType === OwnershipType.NonCustodial
          ? schema
              .required(t(Resources.Validation.Required))
              .test(
                "is-valid-address",
                "Bitcoinová adresa není validní",
                (address) => {
                  return (
                    !!address &&
                    validateAddress(address, network as unknown as Network)
                  );
                },
              )
          : schema.notRequired();
      },
    ),
  }).defined();

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
  } = useForm<InvoiceSettingsDto>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: model,
  });

  const submit = (data: InvoiceSettingsDto) => {
    onSubmit(data);
  };

  const ownershipType = watch("ownershipType");

  return (
    <>
      <StyledForm onSubmit={handleSubmit(submit)}>
        <OwnershipRadioButtons control={control} />
        {ownershipType === OwnershipType.NonCustodial && (
          <>
            <Typography />
            <FormInput
              control={control}
              name="cryptoAddress"
              errors={errors}
              placeholder=""
              label="Bitcoinová adresa"
            />
          </>
        )}

        <StyledSubmitButton
          type="submit"
          fullWidth
          variant="contained"
          color="info"
        >
          Uložit
        </StyledSubmitButton>
      </StyledForm>
    </>
  );
};
