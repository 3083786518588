import { Box, Typography } from "@mui/material";
import { TransactionPaymentOptions } from "Components/Transactions/TransactionPaymentOptions";

type Props = {
  transactionPublicID: string;
};

export const LicensePayment = ({ transactionPublicID }: Props) => (
  <Box display="flex" flexDirection="column" gap={5}>
    <Typography align="left">
      Licence bude aktivována po připsání platby. Pokud máte již zaplaceno,
      vyčkejte na připsání peněz.
    </Typography>
    <TransactionPaymentOptions transactionPublicID={transactionPublicID} />
  </Box>
);
