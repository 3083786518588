import { zodResolver } from "@hookform/resolvers/zod";
import { usePutVerificationsMutation } from "Api/Mutations/Verifications/useVerificationsMutation";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { StyledForm } from "Components/Users/UserDetailPageContent/Components/StyledForm";
import { VerificationFormFields } from "Components/Users/UserDetailPageContent/Components/VerificationFormFields";
import type { UserVerificationUpdateDto } from "Infrastructure/Api/Api";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { verificationValidationSchema } from "../../schemas";

type VerificationFormProps = {
  verification: UserVerificationUpdateDto;
};

export const VerificationForm = ({ verification }: VerificationFormProps) => {
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isDirty },
  } = useForm<UserVerificationUpdateDto>({
    resolver: zodResolver(verificationValidationSchema),
    defaultValues: verification,
  });

  const { mutate: updateVerifications, isPending } =
    usePutVerificationsMutation({
      onSuccess: (data) => {
        reset(data);
        toast.success("Uložení údajů ověření proběhlo úspěšně");
      },
      onError: () =>
        toast.error("Při ukládání údajů ověření se vyskytla chyba"),
    });

  const onSubmit = (data: UserVerificationUpdateDto) => {
    updateVerifications(data);
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <PrimaryPaper>
        <VerificationFormFields control={control} watch={watch} />
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={!isDirty}
          isLoading={isPending}
          variant="contained"
          color="primary"
        >
          Uložit
        </PrimaryButton>
      </PrimaryPaper>
    </StyledForm>
  );
};
