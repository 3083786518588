import { Dialog, Stack, Typography } from "@mui/material";
import { UnstyledButton } from "Components/Shared/Buttons/UnstyledButton";
import { CloseIcon } from "Components/Shared/Icons";
import type { ReactNode } from "react";

type Props = {
  title: string;
  children: ReactNode;
  onClose: () => void;
  isOpen?: boolean;
};

export const ModalDialog = ({
  title,
  onClose,
  children,
  isOpen = false,
}: Props) => (
  <Dialog
    open={isOpen}
    onClose={onClose}
    slotProps={{ paper: { sx: { borderRadius: "10px", width: "600px" } } }}
  >
    <Stack p={5} gap={4}>
      <UnstyledButton
        onClick={onClose}
        sx={{
          cursor: "pointer",
          position: "absolute",
          right: 20,
          top: 20,
        }}
      >
        <CloseIcon width={18} height={18} />
      </UnstyledButton>
      <Typography variant="h6" mr={5}>
        {title}
      </Typography>
      {children}
    </Stack>
  </Dialog>
);
