import { useQuery } from "@tanstack/react-query";
import { getLicensesPublicPartyID } from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

export const PARTY_LICENSE_QUERY_KEY = "PARTY_LICENSE";

export const getPartyLicensesQueryKey = (partyPublicID: string) => [
  PARTY_LICENSE_QUERY_KEY,
  partyPublicID,
];

export const usePartyLicensesQuery = (partyPublicID?: string) =>
  useQuery({
    queryKey: getPartyLicensesQueryKey(partyPublicID!),
    queryFn: processResponseWithApiProblemDetails(() =>
      getLicensesPublicPartyID(partyPublicID!),
    ),
    enabled: !!partyPublicID,
    refetchOnWindowFocus: false,
  });
