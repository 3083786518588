import { Box, IconButton, Typography } from "@mui/material";
import { ForwardIcon } from "Components/Shared/Icons";
import { Link } from "react-router-dom";

type PaymentButtonProps = {
  to: string;
  label: string;
  isDisabled?: boolean;
  isIconFlipped?: boolean;
};

export const PaymentAction = ({
  to,
  label,
  isDisabled,
  isIconFlipped,
}: PaymentButtonProps) => (
  <IconButton component={Link} to={to} disabled={isDisabled} sx={{ p: 0 }}>
    <Box
      component="span"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={1}
    >
      <Typography
        color={isDisabled ? "text.secondary" : "primary.contrastText"}
        display="flex"
        alignItems="center"
        justifyContent="center"
        sx={{
          borderRadius: "50%",
          width: 64,
          height: 64,
          transform: isIconFlipped ? "scaleX(-1)" : "none",
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          "&:hover": isDisabled
            ? {}
            : {
                backgroundColor: "rgba(255, 255, 255, 0.2)",
              },
        }}
      >
        <ForwardIcon />
      </Typography>
      <Typography
        variant="caption"
        color={isDisabled ? "text.secondary" : "primary.contrastText"}
      >
        {label}
      </Typography>
    </Box>
  </IconButton>
);
