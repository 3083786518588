import { Box, Link, Typography } from "@mui/material";
import { InfoGroup } from "Components/Contact/InfoGroup";

type TextWithLinkProps = {
  text: string;
  linkText: string;
  href: string;
};

const TextWithLink = ({ text, linkText, href }: TextWithLinkProps) => (
  <Box display="flex" gap={1}>
    {text}
    <Link href={href} fontWeight={500} sx={{ textDecoration: "inherit" }}>
      {linkText}
    </Link>
  </Box>
);

const PHONE = "226 633 296";
const EMAIL = "info@znpay.eu";

export const ContactInfo = () => (
  <InfoGroup>
    <TextWithLink text="E-mail:" linkText={EMAIL} href={`mailto:${EMAIL}`} />
    <TextWithLink
      text="Telefon:"
      linkText={PHONE}
      href={`tel:${PHONE.replaceAll(" ", "")}`}
    />
    <Typography
      sx={({ palette }) => ({
        fontSize: 13,
        mt: 1,
        color: palette.text.secondary,
      })}
    >
      Telefonicky nás můžete kontaktovat v pracovní dny od 9:00 do 16:00.
    </Typography>
  </InfoGroup>
);
