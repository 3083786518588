import {
  type SignUpCommand,
  SignUpCommandResultStatus,
  postAuthSignUp,
} from "Infrastructure/Api/Api";
import { getAppUrl } from "Utils/UrlUtils";
import type { NavigateFunction } from "react-router";
import { call, put, takeLatest } from "typed-redux-saga";
import { createAsyncAction, getType } from "typesafe-actions";

export const signUpAsync = createAsyncAction(
  "@auth/SIGN_UP_REQUEST",
  "@auth/SIGN_UP_SUCCESS",
  "@auth/SIGN_UP_FAILURE",
)<
  { model: SignUpCommand; navigate: NavigateFunction },
  { login: string },
  Error
>();

function* signUp(action: ReturnType<typeof signUpAsync.request>): Generator {
  try {
    const response = yield* call(postAuthSignUp, action.payload.model);
    if (
      response.status === 200 &&
      response.data.status === SignUpCommandResultStatus.Success
    ) {
      yield put(signUpAsync.success({ login: action.payload.model.login }));

      const url = getAppUrl();
      yield* call(() =>
        action.payload.navigate(
          url(
            action.payload.model.accessRoleCode === "Hunter"
              ? "awaiting-hunter-email-verification"
              : "awaiting-email-verification",
          ),
        ),
      );
    } else {
      yield put(
        signUpAsync.failure(
          new Error(response.data?.status, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(signUpAsync.failure(err as Error));
  }
}
export function* signUpSaga() {
  yield takeLatest(getType(signUpAsync.request), signUp);
}
