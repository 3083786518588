import type { FormModel } from "Components/Merchant/Initiate/InitiatePaymentForm";
import { BankingAccountSelector } from "Components/Shared/Inputs/Form/BankingAccountSelector";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { RadioButtons } from "Components/Shared/Inputs/Form/RadioButtons";
import type { BankAccountListItem } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { useFormContext } from "react-hook-form";

const FormResources = Resources.Merchant.Initiate.Form;

const OPTIONS = [
  { code: "bank", name: "Bankovní účet" },
  { code: "iban", name: "IBAN" },
];

type Props = {
  bankingAccounts: Array<BankAccountListItem>;
};

export const FormFields = ({ bankingAccounts }: Props) => {
  const { t } = useResource();
  const { control, watch } = useFormContext<FormModel>();

  const accountType = watch("accountType");

  return (
    <div>
      <RadioButtons
        label="Typ účtu pro převod"
        control={control}
        codeList={OPTIONS}
        name="accountType"
      />
      {accountType === "bank" ? (
        <BankingAccountSelector
          name="bankAccountID"
          control={control}
          label={t(FormResources.AccountSelectTitle)}
          bankAccounts={bankingAccounts}
        />
      ) : (
        <FormInput
          control={control}
          name="iban"
          label="IBAN příjemce"
          mask={/^[A-Za-z0-9 ]+$/}
          valueTransformer={(v) => v?.toUpperCase()}
          type="text"
        />
      )}
      <FormInput control={control} name="amount" label="Částka" type="number" />
    </div>
  );
};
