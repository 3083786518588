import {
  GenerateSystemAddressCommandResultStatus,
  type GetSystemReportQueryResult,
  type SystemSettingDto,
} from "Api/Api";
import { generateSystemAddressAsync } from "State/System/ColdWallet/GenerateSystemAddressState";
import { getSystemReportAsync } from "State/System/SystemReport/SystemReportState";
import { getSystemSettingAsync } from "State/System/SystemSettings/GetSystemSettingState";
import { produce } from "immer";
import { type ActionType, createReducer } from "typesafe-actions";

type SystemReducerState = {
  report: GetSystemReportQueryResult | null;
  addresses: string[];
  systemSettings: SystemSettingDto[];
  isSaving: boolean;
};

type SystemReducerAction =
  | ActionType<typeof getSystemReportAsync>
  | ActionType<typeof generateSystemAddressAsync>
  | ActionType<typeof getSystemSettingAsync>;

export const systemReducer = createReducer<
  SystemReducerState,
  SystemReducerAction
>({
  report: null,
  addresses: [],
  systemSettings: [],
  isSaving: false,
})
  .handleAction(getSystemReportAsync.request, (state) => {
    return { ...state };
  })
  .handleAction(getSystemReportAsync.success, (state, action) => {
    return { ...state, report: action.payload };
  })
  .handleAction(getSystemReportAsync.failure, (state, _action) => {
    return {
      ...state,
    };
  })
  .handleAction(generateSystemAddressAsync.success, (state, action) => {
    return produce(state, (draft) => {
      if (
        action.payload.status ===
        GenerateSystemAddressCommandResultStatus.Success
      ) {
        draft.addresses.push(action.payload.address);
      }
      return draft;
    });
  })
  .handleAction(getSystemSettingAsync.request, (state, _action) => {
    return {
      ...state,
      isSaving: true,
    };
  })
  .handleAction(getSystemSettingAsync.success, (state, action) => {
    return produce(state, (draft) => {
      draft.systemSettings = draft.systemSettings.filter(
        (e) => e.type !== action.payload.type,
      );
      draft.systemSettings.push(action.payload);
      draft.isSaving = false;
    });
  })
  .handleAction(getSystemSettingAsync.failure, (state, _action) => {
    return {
      ...state,
      isSaving: false,
    };
  });
