import { useMutation } from "@tanstack/react-query";
import {
  type RelatedPartyDto,
  putPartiesPublicIDRelation,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: () => void;
};

export const useReplacePartyRelationMutation = ({ onSuccess }: Props = {}) =>
  useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      ({
        data,
        partyPublicID,
      }: {
        data: RelatedPartyDto;
        partyPublicID: string;
      }) => putPartiesPublicIDRelation(data, partyPublicID),
    ),
    onSuccess: () => {
      onSuccess?.();
    },
  });
