import type * as React from "react";
export const UserIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.3735 13.0105C12.3141 12.3652 12.3375 11.9152 12.3375 11.3262C12.6297 11.173 13.1532 10.1965 13.2407 9.37148C13.4704 9.35273 13.8329 9.1293 13.9391 8.24492C13.9954 7.76992 13.7688 7.50273 13.6297 7.41836C14.0032 6.29492 14.7797 2.81836 12.1954 2.45898C11.9282 1.9918 11.2469 1.75586 10.3625 1.75586C6.82036 1.82148 6.3938 4.43086 7.17036 7.41836C7.0313 7.50273 6.80474 7.76992 6.86099 8.24492C6.9688 9.1293 7.32974 9.35273 7.55942 9.37148C7.64692 10.1965 8.19067 11.173 8.48442 11.3262C8.48442 11.9152 8.5063 12.3652 8.44692 13.0105C7.74692 14.8949 3.0188 14.3652 2.80005 17.9996H18C17.7813 14.3652 13.0735 14.8949 12.3735 13.0105Z"
        fill="#555555"
      />
    </svg>
  );
};
