import {
  type FormModel,
  OnboardingSteps,
} from "Components/Onboarding/NaturalPerson/Context/OnboardingContext";
import {
  type AddressDto,
  AddressType,
  Gender,
  type PartyDto,
  PartyType,
} from "Infrastructure/Api/Api";

export const getPartyDataFromForm = (
  form: Partial<FormModel>,
  party: PartyDto,
): PartyDto => {
  const userDetails = form[OnboardingSteps.UserDetail];
  const lastStepDetails = form[OnboardingSteps.LastStep];
  const identificationValidFrom =
    userDetails?.document.valid.from.toISOString();
  const identificationValidTo = userDetails?.document.valid.to.toISOString();

  const permanentAddress = party.addresses.find(
    ({ type }) => type === AddressType.Permanent,
  );
  const addresses: Array<AddressDto> = [
    {
      ...userDetails?.address,
      addressID: userDetails?.address?.addressID ?? permanentAddress?.addressID,
      type: AddressType.Permanent,
    },
  ];

  const partyDto: PartyDto = {
    ...party,
    type: PartyType.NaturalPerson,
    firstName: userDetails?.firstName,
    lastName: userDetails?.lastName,
    personalNumber: userDetails?.personalIdentificationNumber,
    birthPlace: userDetails?.birthPlace,
    nationalityCountryID: userDetails?.country,
    gender: userDetails?.gender || Gender.Male,
    isForeigner: false,
    phone: lastStepDetails?.phone ?? "",
    identification: userDetails?.document.type,
    identificationNumber: userDetails?.document.number,
    identificationValidFrom,
    identificationValidTo,
    identificationIssuer: userDetails?.document.issued,
    addresses,
  };

  return partyDto;
};
