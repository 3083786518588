import { zodResolver } from "@hookform/resolvers/zod";
import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { RelationTypeDropdown } from "Components/Shared/SelectorsForm/RelationTypeDropdown";
import { BeneficialOwnerPartyFormFields } from "Components/Users/UserDetailPageContent/Components/BeneficialOwnerPartyFormFields";
import { FormSection } from "Components/Users/UserDetailPageContent/Components/FormSection";
import { RelatedPartyFormFields } from "Components/Users/UserDetailPageContent/Components/RelatedPartyFormFields";
import { StyledForm } from "Components/Users/UserDetailPageContent/Components/StyledForm";
import { createPartyValidationSchema } from "Components/Users/schemas";
import { createBeneficialOwnerValidationSchema } from "Components/Users/schemas";
import { PartyType } from "Infrastructure/Api/Api";
import { RelationType } from "Infrastructure/Api/Api";
import type { PartyDto } from "Infrastructure/Api/Api";
import { Resources, useResource } from "Translations/Resources";
import { useForm } from "react-hook-form";
import { toast } from "sonner";

const RELATION_RESOURCES = Resources.Form.RelationType;

type RelatedPartyFormData = PartyDto & {
  relationType?: RelationType;
  isOwner?: boolean;
};

type RelatedPartyFormProps = {
  relationType: RelationType;
  partyData: PartyDto;
  isOwner: boolean;
};

export const RelatedPartyForm = ({
  relationType,
  partyData,
  isOwner,
}: RelatedPartyFormProps) => {
  const { t } = useResource();
  const isRelatedPartyOwner = relationType === RelationType.BeneficialOwner;
  const schema = isRelatedPartyOwner
    ? createBeneficialOwnerValidationSchema(t)
    : createPartyValidationSchema(partyData.type || PartyType.NaturalPerson);

  const {
    control,
    formState: { isDirty },
    reset,
    handleSubmit,
  } = useForm<RelatedPartyFormData>({
    resolver: zodResolver(schema),
    defaultValues: { ...partyData, relationType, isOwner },
  });

  const { mutate: updateParty, isPending } = usePartyMutation({
    onSuccess: ({ data }) => {
      reset({ ...data, isOwner, relationType }); // TODO: if we make the isOwner and relation type editable this should come from form (watch), not props
      toast.success("Uložení údajů související osoby proběhlo úspěšně");
    },
    onError: () =>
      toast.error("Při ukládání údajů související osoby se vyskytla chyba"),
  });

  const onSubmit = (data: RelatedPartyFormData) => {
    updateParty({
      publicID: data.publicID!,
      data: data,
    });
  };

  return (
    <StyledForm onSubmit={handleSubmit(onSubmit)}>
      <PrimaryPaper>
        <FormSection>
          <RelationTypeDropdown
            control={control}
            name="relationType"
            label="Role"
            disabled
          />
          <CheckboxInput
            label="Osoba je skutečným vlastníkem"
            control={control}
            name="isOwner"
            disabled
          />
        </FormSection>
        {isRelatedPartyOwner ? (
          <BeneficialOwnerPartyFormFields
            control={control}
            addresses={partyData.addresses}
          />
        ) : (
          <RelatedPartyFormFields
            control={control}
            relationType={relationType}
            addresses={partyData.addresses}
          />
        )}
        <PrimaryButton
          fullWidth
          type="submit"
          disabled={!isDirty}
          isLoading={isPending}
          variant="contained"
          color="primary"
        >
          Uložit | {t(RELATION_RESOURCES[relationType])}
        </PrimaryButton>
      </PrimaryPaper>
    </StyledForm>
  );
};
