import { Box } from "@mui/material";
import type { ReactNode } from "react";

type Props = {
  children: ReactNode;
  maxWidth?: number;
};

export const CarouselContainer = ({ children, maxWidth }: Props) => (
  <Box
    sx={{
      maxWidth,
      margin: ({ spacing }) => `0 -${spacing(6)} 0 -${spacing(6)}`, //negative margin to bypass padding of the container
      overflow: "hidden",
      py: "0.75rem",
      userSelect: "none",
    }}
  >
    {children}
  </Box>
);
