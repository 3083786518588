import type { FC, ReactElement } from "react";
import type { RouteProps } from "react-router";

type Props = RouteProps & {
  isUserAuthenticated: boolean;
  isAnonymousUserAuthenticated: boolean;
  unauthenticatedComponent: ReactElement;
  authenticatedComponent: ReactElement;
};

export const AnonymousRoute: FC<Props> = ({
  isUserAuthenticated,
  isAnonymousUserAuthenticated,
  authenticatedComponent,
  unauthenticatedComponent,
}) =>
  // for authenticated component, user must be authenticated and not anonymous
  isUserAuthenticated && !isAnonymousUserAuthenticated
    ? authenticatedComponent
    : unauthenticatedComponent;
