import { useMutation } from "@tanstack/react-query";
import { USERS_LIST_QUERY_KEY } from "Api/Queries/User/useUsersListQuery";
import {
  VERIFICATIONS_QUERY_KEY,
  getVerificationsQueryKey,
} from "Api/Queries/Verifications/useVerificationsQuery";
import { queryClient } from "App";
import {
  type UserVerificationUpdateDto,
  postVerifications,
  putVerifications,
} from "Infrastructure/Api/Api";
import { processResponseWithApiProblemDetails } from "Infrastructure/Api/ApiUtils";
import { toast } from "sonner";

type Props = {
  onSuccess?: () => void;
  onError?: () => void;
};

export const useVerificationsMutation = ({ onSuccess, onError }: Props) =>
  useMutation({
    mutationFn: processResponseWithApiProblemDetails(() => postVerifications()),
    onSuccess,
    onError:
      onError || (() => toast.error("Registrační údaje se nepodařilo ověřit")),
  });

type UsePutVerificationsMutationProps = {
  onSuccess?: (verificationData: UserVerificationUpdateDto) => void;
  onError?: () => void;
};

export const usePutVerificationsMutation = ({
  onSuccess,
  onError,
}: UsePutVerificationsMutationProps) =>
  useMutation({
    mutationFn: processResponseWithApiProblemDetails(
      (verifications: UserVerificationUpdateDto) =>
        putVerifications(verifications),
    ),
    onSuccess: (_, data: UserVerificationUpdateDto) => {
      queryClient.invalidateQueries({
        queryKey: data.requestedUserID
          ? getVerificationsQueryKey(data.requestedUserID)
          : VERIFICATIONS_QUERY_KEY,
      });
      queryClient.invalidateQueries({
        queryKey: [USERS_LIST_QUERY_KEY],
      });
      onSuccess?.(data);
    },
    onError,
  });
